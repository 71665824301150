export const defaultErrorMsgs: Record<string, string | ((variables: any) => string)> = {
    // general
    "unautherized": 'Unauthorized operation',
    "wrong password": `Wrong password`,

    // account
    "account not found": ({ username }: any) => username ?
        `${username} account not found, try refreshing the page`
        :
        `Can't find your account, try refreshing the page`,
    "username already exists": `Username already exists, choose a different one`,

    // badge
    "badge not found": ({ badge }: any) => `${badge ? badge + ' ' : ''}badge not found for the account`,

    // group
    "group not found": 'Group not found, try refreshing the page',
    "already a member": 'You are already a member of this group',
    "invite not found": `Can't find the invite, try refreshing the page`,
    "already invited": 'The user is already invited',
    "group invite not found": `Can't find the invite, try refreshing the page`,
    "only one admin": `At least 1 admin is required`,
    'closed group': 'Closed group, invites only',
    "not a member": ({ username }) => `${username} is not member`,
    "already maintainer": ({ username }) => `${username} is already a maintainer`,
    'already admin': ({ username }) => `${username} is already an admin`,
    "admin not found": ({ username }) => `${username} is not an admin`,
    "at least one admin required": `At least 1 admin is required`,
    "group invitation not found": `Can't find the invite, try refreshing the page`,
    "invitation not found": `Can't find the invite, try refreshing the page`,

    // post
    "post not found": 'Post not found, try refreshing the page',
    "poll ended": 'Poll already ended',
};