import React, { useMemo } from 'react';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import Hexagon from '../Design/Hexagon/Hexagon';

import { useAccountImage } from '../../CustomHooks/useAccountImage';
import { useAccountInfo } from '../../CustomHooks/useAccountInfo';

export interface AccountProfileHexagonStyleProps {
    levelTextSize: string,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        profileHexagonContainer: {
            position: 'relative',
        },
        levelHexagon: {
            position: 'absolute',
            bottom: '0',
            right: '0',
            backgroundColor: 'var(--secondary-color)',
        },
        levelText: {
            fontSize: ({ levelTextSize }: AccountProfileHexagonStyleProps) => levelTextSize,
            fontWeight: 700,
            color: 'white',
        },
    }));

const levelSizeMap = {
    small: {
        hexagon: '30%',
        text: '1em',
    },
    medium: {
        hexagon: '50%',
        text: '0.625em',
    },
};

export type AccountProfileHexagonLevelSize = "small" | "medium";
export interface AccountProfileHexagonProps {
    size?: string,
    progress?: number,
    showLevel?: boolean,
    levelSize?: AccountProfileHexagonLevelSize,
};
const AccountProfileHexagon = ({ size = '3em', progress = 78, showLevel = true, levelSize = "small" }: AccountProfileHexagonProps) => {
    const accountProfileImageUrl = useAccountImage('profile');

    const { accountInfo: { level, username } } = useAccountInfo();

    const levelSizeConfig = useMemo(() => levelSizeMap[levelSize] || {},
        [levelSize]
    );

    const classes = useStyles({ levelTextSize: levelSizeConfig.text });

    return (
        <div className={classes.profileHexagonContainer}>
            <Hexagon progress={progress} size={size}>
                <img src={accountProfileImageUrl} alt={username} style={{ width: '87%', height: '87%', paddingTop: '7%' }} />
            </Hexagon>
            {
                showLevel &&
                <Hexagon size={levelSizeConfig.hexagon} strokeWidth="80px" progress={0} fillColor="var(--content-bg)" trackColor="var(--content-bg)" className={classes.levelHexagon}>
                    <Typography variant="body1" className={classes.levelText}>
                        {level}
                    </Typography>
                </Hexagon>
            }
        </div>
    );
};

export default AccountProfileHexagon;