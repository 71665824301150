import React, { MouseEventHandler } from 'react';

import { Button, createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonRoot: {
            minWidth: '0',
            width: '100%',
            borderRadius: '0em 0em 0em 0em',
            border: 'none',
            boxShadow: 'none',
            '&:hover': {
                border: 'none',
                boxShadow: 'none',
            },
            '& svg': {
                transition: 'all 0.3s ease-in-out',
                fill: 'var(--icon-fill)',
            },
            '&.active svg': {
                fill: 'white',
            },
        },
    })
);

export interface TextEditorToolbarButtonProps {
    active?: boolean,
    tooltip: string,
    onClick?: () => void,
    children?: React.ReactNode,
};
const TextEditorToolbarButton = ({ active = false, onClick, children, tooltip }: TextEditorToolbarButtonProps) => {
    const classes = useStyles();

    const supressMouseDown: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
    };

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        onClick?.();
    };

    return (
        <Tooltip placement="top" title={tooltip}>
            <Button size="small" color="secondary" variant="outlined" onClick={handleClick} onMouseDown={supressMouseDown}
                classes={{ root: clsx(classes.buttonRoot, "text-editor-toolbar-button", { active: active }) }}>
                {children}
            </Button>
        </Tooltip>
    );
};


export default TextEditorToolbarButton;