import React, { ReactElement } from 'react';

import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => createStyles({
    iconButtonRoot: {
        color: 'white',
        fontSize: '1em',
        '& svg': {
            transition: 'all 0.2s ease-in-out',
            fill: 'var(--top-bar-icon-fill)',
            height: '1.25em',
            width: '1.25em',
        },
        '&:hover': {
            '& svg': {
                fill: 'white',
            },
        },
    },
}));

export interface Props {
    children?: ReactElement,
    innerRef?: React.Ref<any>,
    onClick?: (...args: any[]) => any
    className?: string,
}
const TopBarIcon = ({ children, innerRef, onClick, className }: Props) => {
    let classes = useStyles();

    return (
        <IconButton innerRef={innerRef} classes={{ root: clsx(classes.iconButtonRoot, className) }} onClick={onClick}>
            {children}
        </IconButton>
    );
};

export default TopBarIcon;