import { useMemo } from "react";

export const useHandlersArray = <T extends unknown>() => {
    const handlers = useMemo(() => {
        const handlers: T[] = [];
        const addHandler = (handler: T) => handlers.push(handler);
        const removeHandler = (handler: T) => {
            const index = handlers.indexOf(handler);
            handlers.splice(index, 1);
        };

        return {
            handlers,
            addHandler,
            removeHandler,
        };
    }, []);

    return handlers;
};