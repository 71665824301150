import { useMemo } from "react";
import { InfiniteData } from "react-query";

type InfinitePage = {
    [index: string]: any
    more: boolean,
};

export const useMergeInfiniteQueryData = <R extends any, T extends InfinitePage = InfinitePage>(data: InfiniteData<T> | undefined, field: string): R[] => {
    const mergedData = useMemo(() => {
        if (!data)
            return [] as R[];

        const mergedData: R[] = [];
        data?.pages.forEach((p) => {
            mergedData.push(...p[field]);
        });

        return mergedData;
    }, [data, field]);

    return mergedData;
};