import React from 'react';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Link } from 'react-router-dom';

import { Button, createStyles, Divider, makeStyles, Paper, Theme, Typography } from '@material-ui/core';

import Hexagon from '../../Design/Hexagon/Hexagon';

import AccountProfileHexagon, { AccountProfileHexagonLevelSize } from '../../AccountProfileHexagon/AccountProfileHexagon';
import AccountInfoProvider from '../../InfoProviders/AccountInfoProvider/AccountInfoProvider';

import ProfileStats from '../ProfileStats/ProfileStats';
import { SearchPageViewType } from '../CardSearchTemplatePage/CardSearchTemplatePage';

import { useAccountImage } from '../../../CustomHooks/useAccountImage';
import { useBreakpoint } from '../../../CustomHooks/useBreakpoint';

import { Account } from '../../../utils/types';
import { AddFriendIcon, MessagesIcon } from '../../../utils/Icons';

export interface MemberGridCardStyleProps {
    viewType?: SearchPageViewType,
    breakpointIndex?: number,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            height: '100%',
            borderRadius: '1em',
            overflow: 'hidden',
        },
        paperGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        accountProfileImageCell: {
            alignItems: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? 'center' : 'end',
            justifyItems: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? 'end' : 'center',
        },
        accountNameCell: {
            display: 'grid',
            textAlign: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? 'start' : 'center',
            alignItems: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? 'end' : 'center',
        },
        accountNameText: {
            fontSize: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? '0.9em' : '1.25em',
        },
        accountUsernameCell: {
            textAlign: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? 'start' : 'center',
        },
        accountProfileImageContainer: {
            backgroundColor: 'var(--content-bg)',
        },
        accountUsernameText: {
            fontWeight: 700,
            color: 'var(--text-label-color)',
            fontSize: '0.6875em',
        },
        buttonsCell: {
            backgroundColor: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? 'transparent' : 'var(--content-secondary-bg)',
            borderTop: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? 'none' : '1px solid var(--content-inside-border-color)',
            paddingLeft: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? '1em' : '0',
            paddingRight: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? '1em' : '0',
        },
        buttonsGrid: {
            justifyItems: 'center',
            '&[class*="Grid"]': {
                height: '100%',
                gridGap: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? '0.25em' : '0.5em',
            },
            paddingTop: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? '0.5em' : '0',
            paddingBottom: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? '0.5em' : '0',
            boxSizing: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? 'border-box' : 'initial',
        },
        buttonDividerCell: {
            display: 'grid',
            alignItems: 'center',
        },
        buttonRoot: {
            height: ({ viewType, breakpointIndex }: MemberGridCardStyleProps) => viewType === "list" && breakpointIndex != null && breakpointIndex <= 1 ? '100%' : '70%',
            paddingRight: ({ viewType, breakpointIndex }: MemberGridCardStyleProps) => viewType === "list" && breakpointIndex != null && breakpointIndex <= 1 ? '0' : '1em',
            paddingLeft: ({ viewType, breakpointIndex }: MemberGridCardStyleProps) => viewType === "list" && breakpointIndex != null && breakpointIndex <= 1 ? '0' : '1em',
            width: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? '3em' : 'auto',
            minWidth: ({ viewType, breakpointIndex }: MemberGridCardStyleProps) => viewType === "list" && breakpointIndex != null && breakpointIndex <= 1 ? '0' : '5em',
            '& svg': {
                fill: ({ viewType }: MemberGridCardStyleProps) => viewType === "list" ? 'white' : '',
                transition: 'all 0.2s ease-in-out',
            },
            '&:hover': {
                '& svg': {
                    fill: 'white',
                },
            },
        },
        primaryButton: {
            '&:hover': {
                backgroundColor: 'var(--primary-color)',
            },
        },
        secondaryButton: {
            '&:hover': {
                backgroundColor: 'var(--secondary-color)',
            },
        },
    })
);

const accountCoverHeightMap = {
    gridCard: 1,
    list: 2,
};

interface CoverImageCellProps {
    viewType: SearchPageViewType,
};
const CoverImageCell = ({ viewType }: CoverImageCellProps) => {
    const accountCoverImageUrl = useAccountImage('cover');

    return (
        <Cell top={1} left={1} width={1} height={accountCoverHeightMap[viewType]}
            style={{ background: `rgba(0, 0, 0, 0) url("${accountCoverImageUrl}") no-repeat scroll center center / cover` }}>
        </Cell>
    );
};

const accountImageLayoutMap = {
    gridCard: {
        width: 1,
        height: 2,
    },
    list: {
        width: 2,
        height: 2,
    }
};
const accountImageSizeMap = {
    gridCard: {
        backgroundSize: 10,
        size: 8,
        levelSize: 'small' as AccountProfileHexagonLevelSize,
    },
    list: {
        backgroundSize: 4,
        size: 3,
        levelSize: 'medium' as AccountProfileHexagonLevelSize,
    },
};

interface AccountImageProps {
    username: string,
    viewType: SearchPageViewType,
};
const AccountImage = ({ username, viewType }: AccountImageProps) => {
    const classes = useStyles({ viewType });

    return (
        <Cell top={1} left={1} {...accountImageLayoutMap[viewType]} className={clsx("center-horizontal", classes.accountProfileImageCell)}>
            <Link to={`/profile/${username}`}>
                <Hexagon progress={0} size={`${accountImageSizeMap[viewType].backgroundSize}em`} trackColor="var(--content-bg)" fillColor="var(--content-bg)" className={classes.accountProfileImageContainer}>
                    <AccountProfileHexagon size={`${accountImageSizeMap[viewType].size}em`} levelSize={accountImageSizeMap[viewType].levelSize} />
                </Hexagon>
            </Link>
        </Cell>
    );
};

const gridLayoutMap = {
    gridCard: {
        columns: "1fr",
        rows: "6em 4em 2em 2em auto 4em",
        areas: [".", ".", "name", "username", "badges", "stats", "buttons"],
    },
    list: {
        columns: "5em 1.5em 1fr auto auto auto",
        rows: "1fr 1fr",
        areas: [". . name badges stats buttons", ". . username badges stats buttons"],
    },
};

const screenConfigMap = {
    gridCard: {
        showButtonsDivider: true,
        buttonVariant: 'text' as 'text',
        buttonsGridSingleRow: true,
        buttonsGridSingleColumn: true,
    },
    list: {
        showButtonsDivider: false,
        buttonVariant: 'contained' as 'contained',
        buttonsGridSingleRow: false,
        buttonsGridSingleColumn: false,
    },
};

export interface MemberBigCardProps {
    data: Account,
    viewType: SearchPageViewType,
};
const AccountCard = ({ data: account, viewType }: MemberBigCardProps) => {
    const { index: breakpointIndex = 0 } = useBreakpoint();

    const classes = useStyles({ viewType, breakpointIndex });

    return (
        <AccountInfoProvider accountData={account}>
            <Paper classes={{ root: classes.paperRoot }}>
                <Grid {...gridLayoutMap[viewType]} className={classes.paperGrid}>
                    {/* cover photo */}
                    <CoverImageCell viewType={viewType} />
                    {/* profile icon */}
                    <AccountImage viewType={viewType} username={account.username} />
                    <Cell area="name" className={classes.accountNameCell}>
                        <Typography variant="h6" className={classes.accountNameText}>
                            {account.name}
                        </Typography>
                    </Cell>
                    <Cell area="username" className={classes.accountUsernameCell}>
                        <Typography variant="body2" className={classes.accountUsernameText}>
                            {`@${account.username}`}
                        </Typography>
                    </Cell>
                    {
                        breakpointIndex >= 3 &&
                        <Cell area="badges">
                            badges
                        </Cell>
                    }
                    {
                        breakpointIndex >= 2 &&
                        <Cell area="stats" className="center-horizontal center-vertical">
                            <ProfileStats />
                        </Cell>
                    }
                    <Cell area="buttons" className={classes.buttonsCell}>
                        <Grid className={classes.buttonsGrid} rows={breakpointIndex > 1 || screenConfigMap[viewType].buttonsGridSingleRow ? "1fr" : "1fr 1fr"}
                            columns={breakpointIndex > 1 || screenConfigMap[viewType].buttonsGridSingleColumn ? "1fr auto 1fr" : '1fr'} >
                            <Cell className="center-vertical-soft">
                                <Button variant={screenConfigMap[viewType].buttonVariant} color="secondary"
                                    classes={{ root: clsx(classes.buttonRoot, { [classes.secondaryButton]: viewType !== "list" }) }}>
                                    <AddFriendIcon width="1.5em" fill="var(--icon-fill)" />
                                </Button>
                            </Cell>
                            {
                                (breakpointIndex > 1 || screenConfigMap[viewType].showButtonsDivider) &&
                                <Cell className={classes.buttonDividerCell}>
                                    <Divider orientation="vertical" />
                                </Cell>
                            }
                            <Cell className="center-vertical-soft">
                                <Button variant={screenConfigMap[viewType].buttonVariant} color="primary"
                                    classes={{ root: clsx(classes.buttonRoot, { [classes.primaryButton]: viewType !== "list" }) }}>
                                    <MessagesIcon width="1.5em" fill="var(--icon-fill)" />
                                </Button>
                            </Cell>
                        </Grid>
                    </Cell>
                </Grid>
            </Paper>
        </AccountInfoProvider >
    );
};

export default AccountCard;