import React from 'react';
import { GroupMemberDetails } from '../../../../utils/types';

import CardSearchTemplatePage from '../../../Templates/CardSearchTemplatePage/CardSearchTemplatePage';
import AccountCard from '../../../Templates/AccountCard/AccountCard';

import { useFetchValidation } from '../../../../utils/api/apiHelpers';
import { useGroupMembersDetailsQuery } from '../../../../utils/queries/groupsQueries';

import { useGroupInfo } from '../../../../CustomHooks/useGroupInfo';

const GroupProfilePageMembers = () => {
    const { groupInfo: { groupId } } = useGroupInfo();

    const allGroupMembersQuery = useFetchValidation(useGroupMembersDetailsQuery, {
        queryArgs: [groupId],
        errorMsg: 'Error loading group members',
        options: {
            placeholderData: [] as GroupMemberDetails[],
            enabled: groupId != null
        },
    });

    return (
        <CardSearchTemplatePage showBanner={false} data={allGroupMembersQuery.data as GroupMemberDetails[]} isLoading={allGroupMembersQuery.isLoading}
            searchLabel="Search Members" gridCardComponent={AccountCard} baseUrl="/members" filterKey={['name', 'username']} />
    );
};

export default GroupProfilePageMembers;