import React, { useMemo } from 'react';

import { Cell, Grid } from 'styled-css-grid';
import clsx from 'clsx';

import { createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';

import { addDays, eachDayOfInterval, endOfMonth, format, startOfMonth, isToday, startOfDay, isSameDay, endOfDay } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dateCellText: {
            width: '100%',
        },
        dateCellExtraDateText: {
            color: 'var(--text-label-color)',
        },
        dateCellDisabledText: {
            color: 'var(--text-disabled-color)',
        },
        dateCellToday: {
            border: '1px solid var(--secondary-color)',
        },
        dateCellButton: {
            width: '100%',
            padding: '0',
            '&:hover': {
                backgroundColor: 'var(--icon-bg-hover)',
            },
        },
        dateCellSelected: {
            backgroundColor: 'var(--secondary-color)',
            '&:hover': {
                backgroundColor: 'var(--button-secondary-bg-hover)',
            },
        },
    })
);

interface DateCellProps {
    date: Date,
    shownDate: Date,
    value: Date,
    minDate?: Date,
    maxDate?: Date,
    disabledDates?: Date[] | ((date: Date) => boolean),
    onClick: (date: Date) => void,
};
const DateCell = ({ date, shownDate, value, disabledDates, minDate, maxDate, onClick }: DateCellProps) => {
    const classes = useStyles();

    const isDisabled = useMemo(() => {
        const endOfDate = endOfDay(date);
        if (minDate && endOfDate < minDate)
            return true;
        if (maxDate && endOfDate > maxDate)
            return true;

        if (disabledDates) {
            if (typeof disabledDates === "function")
                return disabledDates(date);

            const refrenceDate = startOfDay(date).valueOf();
            return disabledDates.find(d => startOfDay(d).valueOf() === refrenceDate) != null;
        }
    }, [minDate, maxDate, date, disabledDates]);

    const handleClick = () => {
        onClick(date);
    };

    return (
        <IconButton disabled={isDisabled} size="small" onClick={handleClick} classes={{
            root: clsx(classes.dateCellButton, {
                [classes.dateCellToday]: isToday(date),
                [classes.dateCellSelected]: isSameDay(date, value),
            })
        }}>
            <Typography variant="body1" className={clsx(classes.dateCellText, {
                [classes.dateCellExtraDateText]: date.getMonth() !== shownDate.getMonth(),
                [classes.dateCellDisabledText]: isDisabled,
            })}>
                {date.getDate()}
            </Typography>
        </IconButton>
    );
};

export interface AntDateTimePickerDateViewProps {
    onDateSelect: (date: Date) => void,
    value: Date,
    shownDate: Date,
    minDate?: Date,
    maxDate?: Date,
    disabledDates?: Date[] | ((date: Date) => boolean),
};
const AntDateTimePickerDateView = ({ onDateSelect, value, shownDate, minDate, maxDate, disabledDates = [] }: AntDateTimePickerDateViewProps) => {
    const dateRange = useMemo(() => eachDayOfInterval({
        start: addDays(startOfMonth(shownDate), -startOfMonth(shownDate).getDay()),
        end: addDays(endOfMonth(shownDate), 6 - endOfMonth(shownDate).getUTCDay() + 7),
    }).slice(0, 42).map(d => {
        d.setHours(value.getHours());
        d.setMinutes(value.getMinutes());
        d.setSeconds(value.getSeconds());

        return d;
    }), [shownDate, value]);

    return (
        <Grid columns="repeat(7, 1.5em)" rows="repeat(7, 1.5em)">
            {
                dateRange.slice(0, 7).map((d, idx) => (
                    <Cell key={idx} className="center-vertical center-horizontal">
                        {format(d, 'eeeeee')}
                    </Cell>
                ))
            }
            {
                dateRange.map(d => (
                    <Cell key={d.valueOf()} className="center-vertical center-horizontal">
                        <DateCell date={d} shownDate={shownDate} value={value} disabledDates={disabledDates} minDate={minDate} maxDate={maxDate} onClick={onDateSelect} />
                    </Cell>
                ))
            }
        </Grid>
    );
};

export default AntDateTimePickerDateView;