import React, { ComponentType, useMemo, useRef, useState } from 'react';
import { Account, Post } from '../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { CommentIcon, ShareIcon, ThumbsUpIcon } from '../../../../../utils/Icons';

import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import Loader from '../../../../Design/Loader/Loader';
import PostReactionsMenu from './components/PostReactionsMenu/PostReactionsMenu';

import { usePostInfo } from '../../../../../CustomHooks/usePostInfo';
import { useCurrentAccountInfo } from '../../../../../CustomHooks/useAccountInfo';

import { getReactionImageUrl } from '../../../../../utils/api/Posts';
import { useSharePostMutation } from '../../../../../utils/mutations/postsMutation';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        postCardActionsGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        postCardActionButtonRoot: {
            width: '100%',
            height: '100%',
            color: 'var(--text-label-color)',
            '& svg': {
                transition: 'all 0.2s ease-in-out',
                fill: 'var(--icon-fill)',
            },
            '&:hover': {
                backgroundColor: 'var(--icon-bg-hover)',
                color: 'white',
                '& svg': {
                    fill: 'var(--icon-fill-hover)',
                },
            },
        },
        postCardActionButtonText: {
            transition: 'all 0.2s ease-in-out',
            paddingLeft: '1em',
            fontWeight: 700,
        },
        currentReactionImage: {
            width: '2em',
            height: '2em',
        },
    })
);

interface ActionButtonProps {
    icon: ComponentType<any>,
    imgSrc?: string,
    label: string,
    onClick?: () => void,
    innerRef?: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined,
};
const ActionButton = ({ icon: Icon, label, innerRef, onClick, imgSrc }: ActionButtonProps) => {
    const classes = useStyles();

    return (
        <Button ref={innerRef} classes={{ root: classes.postCardActionButtonRoot, label: "center-vertical" }} onClick={onClick}>
            {
                imgSrc ?
                    <img src={imgSrc} alt="reaction" className={classes.currentReactionImage} />
                    :
                    <Icon width="1.5em" fill="inherit" />
            }
            <Typography variant="body2" color="inherit" className={classes.postCardActionButtonText}>
                {label}
            </Typography>
        </Button>
    );
};

export interface PostCardActionsProps {
    toggleCreateComment: () => void,
};
const PostCardActions = ({ toggleCreateComment }: PostCardActionsProps) => {
    const { post: postInfo } = usePostInfo();
    const { reactions, _id: postId, shareSource } = postInfo as Post;
    const { accountInfo: { accountId: currentAccountId } } = useCurrentAccountInfo();

    const [reactionMenuOpen, setReactionMenuOpen] = useState(false);

    const reactionCellRef = useRef(null);

    const currentReaction = useMemo(() => reactions?.find(r => (r.account as Account).accountId === currentAccountId),
        [currentAccountId, reactions]
    );

    const sharePost = useSharePostMutation();

    const classes = useStyles();

    const openReactionMenu = () => {
        setReactionMenuOpen(true);
    };

    const closeReactionMenu = () => {
        setReactionMenuOpen(false);
    };

    const onPostShare = () => {
        sharePost.mutate((shareSource as Post)?._id || postId);
    };

    return (
        <>
            <Grid rows="1fr" columns="1fr 1fr 1fr" className={classes.postCardActionsGrid}>
                <Cell ref={reactionCellRef}>
                    <ActionButton icon={ThumbsUpIcon} imgSrc={currentReaction ? getReactionImageUrl(currentReaction.reaction) : undefined} label="React" onClick={openReactionMenu} />
                    <PostReactionsMenu
                        open={reactionMenuOpen}
                        closeMenu={closeReactionMenu}
                        anchorEl={reactionCellRef.current}
                    />
                </Cell>
                <Cell>
                    <ActionButton icon={CommentIcon} label="Comment" onClick={toggleCreateComment} />
                </Cell>
                <Cell>
                    {
                        sharePost.isLoading ?
                            <Loader />
                            :
                            <ActionButton icon={ShareIcon} label="Share" onClick={onPostShare} />
                    }
                </Cell>
            </Grid>
        </>
    );
};

export default PostCardActions;