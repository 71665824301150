import { UseMutationHandlerOptions } from "./types";

import { login, logout, verifyAuthentication } from "../api/Auth";

import { useMutationValidation } from "../api/apiHelpers";

export const useLoginMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    return useMutationValidation(login, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
        errorMsg: 'Error logging in',
    });
};

export const useVerifyAuthenticationMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    return useMutationValidation(verifyAuthentication, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
    });
};

export const useLogoutMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    return useMutationValidation(logout, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
    });
};