import { useQuery, UseQueryOptions } from "react-query";
import { Group, GroupInviteeDetails, GroupMemberDetails } from "../types";

import { getAllGroups, getAllMaintainbaleGroups, getGroup, getGroupId, getGroupInviteesDetails, getGroupMembersDetails } from "../api/Groups";

const parseGroupData = (data: Group): Group => {
    data.createdAt = new Date(data.createdAt);

    data.invites?.forEach(i => {
        i.sentAt = new Date(i.sentAt);
    });

    data.members.forEach(m => {
        m.joinedAt = new Date(m.joinedAt);
    });

    return data;
};

export const useGetGroupIdQuery = (groupName: string, queryOptions?: UseQueryOptions<string>) => {
    return useQuery(['groups', groupName], () => getGroupId(groupName), {
        ...queryOptions
    });
};

export const useGetGroupInfoQuery = (groupId: string, queryOptions?: UseQueryOptions<Group>) => {
    return useQuery(['groups', groupId], () => getGroup(groupId), {
        ...queryOptions,
        select: parseGroupData,
    });
};

export const useAllGroupsQuery = (queryOptions?: UseQueryOptions<Group[]>) => {
    return useQuery(['groups', 'all'], () => getAllGroups(), {
        select: (data) => {
            data.forEach((g: Group) => {
                parseGroupData(g);
            });

            return data;
        },
        ...queryOptions
    });
};

export const useAllMaintainableGroupsQuery = (queryOptions?: UseQueryOptions<Group[]>) => {
    return useQuery(['groups', 'maintainable'], () => getAllMaintainbaleGroups(), {
        select: (data) => {
            data.forEach((g: Group) => {
                parseGroupData(g);
            });

            return data;
        },
        ...queryOptions
    });
};

export const useGroupMembersDetailsQuery = (groupId: string, queryOptions?: UseQueryOptions<GroupMemberDetails[]>) => {
    return useQuery(['groups', groupId, 'membersDetails'], () => getGroupMembersDetails(groupId), {
        ...queryOptions
    });
};

export const useGroupInviteesDetailsQuery = (groupId: string, queryOptions?: UseQueryOptions<GroupInviteeDetails[]>) => {
    return useQuery(['groups', groupId, 'inviteesDetails'], () => getGroupInviteesDetails(groupId), {
        ...queryOptions
    });
};