import React from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { Card, CardContent, createStyles, makeStyles, Theme } from '@material-ui/core';

import Hexagon from '../../../../../Design/Hexagon/Hexagon';

import AccountProfileHexagon from '../../../../../AccountProfileHexagon/AccountProfileHexagon';
import UploadImageCard from '../../../../../Templates/UploadImageCard/UploadImageCard';

import { AvatarIcon, ImageIcon } from '../../../../../../utils/Icons';

import { useAccountImage } from '../../../../../../CustomHooks/useAccountImage';
import { useUploadAccountAvatarMutation, useUploadAccountCoverMutation } from '../../../../../../utils/mutations/accountMutations';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainGrid: {
            gridAutoRows: '9em !important',
            justifyContent: 'center',
        },
        cardRoot: {
            width: '100%',
            height: '100%',
            transition: 'all 0.2s linear',
            '& svg': {
                transition: 'all 0.2s linear',
            },
        },
        previewCardContentRoot: {
            padding: '0 !important',
            height: '100%',
        },
        cardPreviewGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        accountProfileImageContainer: {
            backgroundColor: 'var(--content-bg)',
        },
    })
);

const ProfileImagesSelection = () => {
    const accountCoverImageUrl = useAccountImage('cover');

    const uploadAccountAvatar = useUploadAccountAvatarMutation();
    const uploadAccountCover = useUploadAccountCoverMutation();

    const classes = useStyles();

    const handleUploadAccountAvatar = (image: string) => {
        uploadAccountAvatar.mutate(image);
    };

    const handleUploadAccountCover = (image: string) => {
        uploadAccountCover.mutate(image);
    };

    return (
        <Grid gap="1em" columns="repeat(auto-fill, 18em)" rows="repeat(auto-fill, 9em)" className={classes.mainGrid}>
            <Cell>
                <Card classes={{ root: classes.cardRoot }}>
                    <CardContent classes={{ root: classes.previewCardContentRoot }}>
                        <Grid gap="0" columns="1fr" rows="1fr 1fr" className={classes.cardPreviewGrid}>
                            {/* profile icon */}
                            <Cell top={1} left={1} width={1} height={2} className="center-vertical center-horizontal">
                                <Hexagon progress={0} size="7em" trackColor="var(--content-bg)" fillColor="var(--content-bg)" className={classes.accountProfileImageContainer}>
                                    <AccountProfileHexagon size="6em" />
                                </Hexagon>
                            </Cell>
                            {/* cover photo */}
                            <Cell top={1} left={1} width={1} height={1}
                                style={{ background: `rgba(0, 0, 0, 0) url("${accountCoverImageUrl}") no-repeat scroll center center / cover` }}>
                            </Cell>
                        </Grid>
                    </CardContent>
                </Card>
            </Cell>
            <Cell>
                <UploadImageCard icon={<AvatarIcon fill="var(--icon-fill)" height="1.3em" width="1.3em" />} title="Change Avatar" subtitle="110x110px size minimum"
                    onSelect={handleUploadAccountAvatar} isLoading={uploadAccountAvatar.isLoading} />
            </Cell>
            <Cell>
                <UploadImageCard icon={<ImageIcon fill="var(--icon-fill)" height="1.3em" width="1.3em" />} title="Change Cover" subtitle="1184x300px size minimum"
                    onSelect={handleUploadAccountCover} isLoading={uploadAccountCover.isLoading} />
            </Cell>
        </Grid>
    );
};

export default ProfileImagesSelection;