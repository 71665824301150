import { useContext } from "react";
import { Account } from "../utils/types";

import { AccountInfoContext, CurrentAccountInfoContext } from "../context/AccountInfoContext";

export const useAccountInfo = () => {
    const accountInfoContextValue = useContext(AccountInfoContext) || ({} as Account);
    const currentAccountInfoContextValue = useContext(CurrentAccountInfoContext) || ({} as Account);

    if (Object.keys(accountInfoContextValue.accountInfo).length === 0 && !accountInfoContextValue.isLoading)
        return currentAccountInfoContextValue;

    return accountInfoContextValue;
};

export const useCurrentAccountInfo = () => {
    const currentAccountInfoContextValue = useContext(CurrentAccountInfoContext) || ({} as Account);

    return currentAccountInfoContextValue;
};