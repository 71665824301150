export const realData = true;

export const baseServerUrl = 'https://badger-server.ofirl.com';
// export const baseServerUrl = 'http://localhost:3055';

export const baseAssetUrl = 'https://res.cloudinary.com/ofirl/image/upload/Badger';
export const baseImageUrl = `${baseAssetUrl}/images`;

export const baseBadgesImageUrl = `${baseImageUrl}/badges`;
export const baseQuestsImageUrl = `${baseImageUrl}/quests`;
export const baseReactionsImageUrl = `${baseImageUrl}/reactions`;
export const baseBannerPageImages = `${baseImageUrl}/bannerIcons`;