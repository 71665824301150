import React from 'react';

import clsx from 'clsx';

import { Card, CardProps, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardRoot: {
            backgroundColor: 'var(--content-disabled-bg)',
        },
    })
);

const DisabledCard = ({ className, children, ...others }: CardProps) => {
    const classes = useStyles();

    return (
        <Card className={clsx(className, classes.cardRoot)} {...others}>
            {children}
        </Card>
    );
};

export default DisabledCard;