import React, { useEffect, useState } from 'react';

import { Redirect } from 'react-router';

import { useVerifyAuthenticationMutation } from '../../utils/mutations/authMutations';

export interface AuthCheckProps {
    onVerify: () => void
};
const AuthCheck = ({ onVerify }: AuthCheckProps) => {
    const [verified, setVerified] = useState<boolean | undefined>();

    const verifyAuthentication = useVerifyAuthenticationMutation({
        onSuccess: onVerify,
        onSettled: (data) => setVerified(data === true),
    });

    useEffect(() => {
        verifyAuthentication.mutate({});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return verified === false ? <Redirect to="/login" /> : null;
};

export default AuthCheck;