import React from 'react';
import { Descendant } from 'slate';
import { CreatePostData } from '../../../../../utils/types';

import TextEditorInput from '../../../TextEditorInput/TextEditorInput';

export interface CreatePostStatusProps {
    postData: CreatePostData,
    onChange: (data: CreatePostData) => void,
    clearTextEditorSelection?: React.MutableRefObject<() => void>,
};
const CreatePostStatus = ({ postData, onChange, clearTextEditorSelection }: CreatePostStatusProps) => {
    const handlePostChange = (data: Descendant[]) => {
        onChange({ text: data });
    };

    return (
        <TextEditorInput value={postData.text} onChange={handlePostChange} placeholder="Share your post here..." clearTextEditorSelection={clearTextEditorSelection} />
    );
};

export default CreatePostStatus;