import React from 'react';
import { Post } from '../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';

import usePostComments from '../../../../../CustomHooks/usePostComments';
import { usePostInfo } from '../../../../../CustomHooks/usePostInfo';

import PostCardComment from './components/PostCardComment/PostCardComment';
import Loader from '../../../../Design/Loader/Loader';

export interface PostCardCommentsStyleProps {
    level: number
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        commentListGrid: {
            padding: '0 1em 1em 1em',
            paddingBottom: ({ level }: PostCardCommentsStyleProps) => level ? '0em' : '1em',
        },
    })
);

export interface PostCardCommentsProps {
    level?: number,
};
const PostCardCommentsList = ({ level = 0 }: PostCardCommentsProps) => {
    const { post: { _id: postId } = {} as Post } = usePostInfo();

    const { data: comments, fetchNextPage: fetchNextCommentsPage, hasNextPage, isLoading } = usePostComments(postId);

    const classes = useStyles({ level });

    const loadMoreComments = () => {
        fetchNextCommentsPage();
    };

    return (
        <Grid gap="0" columns="1fr" className={classes.commentListGrid}>
            {
                comments.map(c => (
                    <Cell key={c._id}>
                        <PostCardComment comment={c} level={level} />
                    </Cell>
                ))
            }
            {
                hasNextPage &&
                (
                    isLoading ?
                        <Loader />
                        :
                        <Cell>
                            <Button color="primary" classes={{ root: 'minimal' }} onClick={loadMoreComments}>
                                {`Load more`}
                            </Button>
                        </Cell>
                )
            }
        </Grid>
    );
};

export default PostCardCommentsList;