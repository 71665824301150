import React, { ComponentType, useMemo, useRef, useState } from 'react';
import { CreatePostData, Post, PostLocationType, PostType } from '../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, IconButton, makeStyles, Paper, Tab, Tabs, Theme, Tooltip, Typography } from '@material-ui/core';

import { CameraIcon, PollIcon, StatusIcon, TagIcon } from '../../../utils/Icons';

import Loader from '../../Design/Loader/Loader';

import PostTagChip from '../PostCard/components/PostTagChip/PostTagChip';
import CreatePostStatus from './components/CreatePostStatus/CreatePostStatus';
import CreatePostPoll from './components/CreatePostPoll/CreatePostPoll';

import { useCurrentAccountInfo } from '../../../CustomHooks/useAccountInfo';
import { useGroupInfo } from '../../../CustomHooks/useGroupInfo';

import { useCreateGroupPostMutation, useCreatePostMutation, useUpdatePostMutation } from '../../../utils/mutations/postsMutation';

export interface CreatePostCardStyleProps {
    update?: boolean,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            borderRadius: '1em',
            boxShadow: ({ update }: CreatePostCardStyleProps) => update ? 'none' : '',
        },
        tabsRoot: {
            height: '4em',
        },
        tabsFlexContainer: {
            height: '100%',
        },
        tabRoot: {
            padding: '0',
            '&:after': {
                content: '""',
                backgroundColor: 'var(--icon-fill)',
                height: '1.5em',
                width: '1px',
                position: 'relative',
            },
        },
        tabWrapper: {
            height: '100%',
        },
        tabLabelText: {
            fontWeight: 700,
            fontSize: '0.875em',
            textTransform: 'initial',
        },
        textAreaCell: {
            borderTop: '1px solid var(--content-inside-border-color)',
            borderBottom: '1px solid var(--content-inside-border-color)',
            boxSizing: 'border-box',
            position: 'relative',
            paddingBottom: '1em',
            justifyItems: 'start',
        },
        textAreaRoot: {
            '& .MuiInput-underline:after': {
                border: 'none',
            },
            '&:hover .MuiInput-underline:before': {
                border: 'none',
            },
            '& .MuiInput-underline:before': {
                border: 'none',
            },
            '& textArea': {
                paddingLeft: '1em',
                paddingRight: '1em',
            },
        },
        lengthIndicator: {
            position: 'absolute',
            bottom: '0.2em',
            right: '2em',
        },
        lengthIndicatorMax: {
            color: 'red',
            fontWeight: 700,
        },
        bottomRow: {
            paddingRight: '2em',
            paddingLeft: '2em',
        },
        postTagsCell: {
            padding: '1em',
            display: 'flex',
            gridColumnGap: '0.5em',
            gridRowGap: '0.2em',
            flexDirection: 'row',
            flexWrap: 'wrap',
            boxSizing: 'border-box',
        },
    })
);

interface TabLabelProps {
    icon: ComponentType<any>,
    label: string,
};
const TabLabel = ({ label, icon: IconComp }: TabLabelProps) => {
    const classes = useStyles({});

    return (
        <Grid gap="1em" columns="auto 1fr" rows="1fr">
            <Cell className="center-vertical">
                <IconComp width="1.5em" height="1.5em" fill="white" />
            </Cell>
            <Cell className="center-vertical">
                <Typography variant="body2" className={classes.tabLabelText}>
                    {label}
                </Typography>
            </Cell>
        </Grid>
    );
};

type PostTypeEditor = {
    initialData?: Post,
    postData: CreatePostData,
    onChange: (data: CreatePostData) => void
    clearTextEditorSelection?: React.MutableRefObject<() => void>,
};
const postTypeEditorsMap: Record<PostType, ComponentType<PostTypeEditor>> = {
    status: CreatePostStatus,
    poll: CreatePostPoll,
};

export interface CreatePostCardProps {
    locationType?: PostLocationType,
    update?: boolean,
    initialData?: Post,
    onClose?: () => void,
};
const CreatePostCard = ({ locationType = "Account", update = false, initialData, onClose }: CreatePostCardProps) => {
    const { accountInfo: { accountId } } = useCurrentAccountInfo();
    const { groupInfo: { groupId } } = useGroupInfo();

    const [postType, setPostType] = useState<PostType>(initialData?.postType || 'status');
    const [postData, setPostData] = useState<CreatePostData>(initialData || {});
    const [postTags, setPostTags] = useState<string[]>(initialData?.tags || []);

    const clearTextEditorSelectionRef = useRef<() => void>(() => { });

    const clearPost = () => {
        if (update)
            onClose && onClose();
        else {
            clearTextEditorSelectionRef.current?.();
            setPostData({});
            setPostTags([]);
        }
    };

    const createPost = useCreatePostMutation({ onSuccess: clearPost });
    const createGroupPost = useCreateGroupPostMutation({ onSuccess: clearPost });
    const updatePost = useUpdatePostMutation({ onSuccess: clearPost });

    const locationIdMap: Record<PostLocationType, string> = useMemo(() => ({
        Account: accountId,
        Group: groupId,
    }), [groupId, accountId]);

    const PostDataEditor = useMemo(() => postTypeEditorsMap[postType],
        [postType]
    );

    const classes = useStyles({ update });

    const handlePostDataChange = (data: CreatePostData) => {
        setPostData({
            ...initialData,
            ...data
        });
    };

    const handleTabChange = (e: React.ChangeEvent<{}>, value: PostType) => {
        setPostType(value);
    };

    const handleCreatePostClick = () => {
        // update
        if (update) {
            updatePost.mutate({
                postId: initialData?._id as string,
                postData: {
                    ...postData as Post,
                    tags: postTags,
                }
            });
            return;
        }

        // create
        if (locationType === 'Group') { // group
            createGroupPost.mutate({
                groupId,
                postData: {
                    ...postData as Post,
                    postType,
                    postLocation: {
                        locationType,
                        location: locationIdMap[locationType],
                    },
                    tags: postTags,
                }
            });
        }
        else { // account
            createPost.mutate({
                ...postData as Post,
                postType,
                postLocation: {
                    locationType,
                    location: locationIdMap[locationType],
                },
                tags: postTags,
            });
        }
    };

    const handleAddPostTag = () => {
        const tags = [...postTags];

        tags.push('');
        setPostTags(tags);
    };

    const handleDeletePostTag = (idx: number) => {
        const tags = [...postTags];

        tags.splice(idx, 1);
        setPostTags(tags);
    };

    const onTagChange = (idx: number, value: string) => {
        const tags = [...postTags];
        tags[idx] = value;

        setPostTags(tags);
    };

    return (
        <Paper classes={{ root: classes.paperRoot }}>
            <Grid gap="0" columns="1fr" rows="auto 1fr auto 5em">
                <Cell>
                    {
                        !update &&
                        <Tabs value={postType} variant="fullWidth" onChange={handleTabChange}
                            classes={{ root: classes.tabsRoot, flexContainer: classes.tabsFlexContainer }}>
                            <Tab value="status" label={<TabLabel icon={StatusIcon} label="Status" />} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
                            <Tab value="poll" label={<TabLabel icon={PollIcon} label="Poll" />} classes={{ wrapper: classes.tabWrapper }} />
                        </Tabs>
                    }
                </Cell>
                <Cell className={classes.textAreaCell}>
                    <PostDataEditor postData={postData} onChange={handlePostDataChange} initialData={initialData} clearTextEditorSelection={clearTextEditorSelectionRef} />
                </Cell>
                <Cell className={classes.postTagsCell}>
                    {
                        postTags.map((tag, idx) => (
                            <PostTagChip onDelete={() => handleDeletePostTag(idx)} label={tag} editable onLabelChange={(value) => onTagChange(idx, value)} />
                        ))
                    }
                </Cell>
                <Cell className="center-vertical">
                    <Grid gap="1em" rows="1fr" columns="auto auto 1fr auto auto" className={classes.bottomRow} areas={["camera tag . discard post"]}>
                        <Cell area="camera" className="center-vertical">
                            <Tooltip title="Insert Photo" placement="top">
                                <IconButton size="small" color="primary">
                                    <CameraIcon width="1em" fill="inherit" />
                                </IconButton>
                            </Tooltip>
                        </Cell>
                        <Cell area="tag" className="center-vertical">
                            <Tooltip title="Insert Tag" placement="top">
                                <IconButton size="small" color="primary" onClick={handleAddPostTag}>
                                    <TagIcon width="1em" fill="inherit" />
                                </IconButton>
                            </Tooltip>
                        </Cell>
                        <Cell area="discard" className="center-vertical">
                            <Button disabled={createPost.isLoading || updatePost.isLoading || Object.keys(postData).length === 0} variant="text" color="primary" onClick={clearPost}>
                                {
                                    update ? 'Cancel' : 'Discard'
                                }
                            </Button>
                        </Cell>
                        <Cell area="post" className="center-vertical">
                            <Button disabled={Object.keys(postData).length === 0} variant="contained" color="secondary" onClick={handleCreatePostClick}>
                                {
                                    createPost.isLoading || updatePost.isLoading ?
                                        <Loader height="1.5em" />
                                        :
                                        update ? 'Update' : 'Post'
                                }
                            </Button>
                        </Cell>
                    </Grid>
                </Cell>
            </Grid>
        </Paper>
    );
};

export default CreatePostCard;