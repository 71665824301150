import React from 'react';

import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { RenderLeafProps } from 'slate-react';

export interface LeafElementStyleProps extends Partial<RenderLeafProps['leaf']> { };
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: 700,
        },
        italic: {
            fontStyle: 'italic',
        },
        decoration: {
            textDecoration: ({ underline, lineThrough }: LeafElementStyleProps) => `${underline ? 'underline' : ''} ${lineThrough ? 'line-through' : ''}`,
        },
        code: {
            backgroundColor: '#80808063',
        },
    })
);

const LeafElement = ({ attributes, children, leaf: { bold, italic, underline, lineThrough, code } }: RenderLeafProps) => {
    const classes = useStyles({ underline, lineThrough });

    return code ?
        (
            <code {...attributes} className={
                clsx(classes.code, {
                    [classes.bold]: bold,
                    [classes.italic]: italic,
                    [classes.decoration]: underline || lineThrough,
                })
            }>
                { children}
            </code>
        )
        :
        (
            <span {...attributes} className={
                clsx({
                    [classes.bold]: bold,
                    [classes.italic]: italic,
                    [classes.decoration]: underline || lineThrough,
                })
            }>
                { children}
            </span>
        );
};

export default LeafElement;