import React, { Component, ReactElement } from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleText: {
            fontSize: '1em',
        },
    })
);

export interface GroupSettingsTabTemplateProps {
    title?: string,
    titleAdornment?: Component<any>,
    children?: ReactElement
    titleGap?: string,
};
const GroupSettingsTabTemplate = ({ title, children, titleAdornment, titleGap = "2em" }: GroupSettingsTabTemplateProps) => {
    const classes = useStyles();

    return (
        <Grid gap={titleGap} columns="1fr" rows="2em auto auto">
            <Cell>
                <Typography variant="h6" className={classes.titleText}>
                    {title}
                </Typography>
                {titleAdornment}
            </Cell>
            <Cell>
                {children}
            </Cell>
        </Grid>
    );
};

export default GroupSettingsTabTemplate;