import React from 'react';

import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        datePickerRoot: {
            '& .MuiPickersBasePicker-pickerView': {
                backgroundColor: 'var(--content-bg)',
            },
            '& .MuiPickersCalendarHeader-dayLabel': {
                color: 'var(--caption-color)',
            },
        },
        calendarArrowRoot: {
            backgroundColor: 'transparent',
            color: 'white',
            '&:hover': {
                backgroundColor: '#ffffff0a',
            },
        },
    })
);

export interface MaterialDatePickerProps extends DatePickerProps {

};
const MaterialDatePicker = ({ ...others }: MaterialDatePickerProps) => {
    const classes = useStyles();

    return (
        <div className={classes.datePickerRoot}>
            <DatePicker
                size="small"
                leftArrowButtonProps={{ classes: { root: classes.calendarArrowRoot } }}
                rightArrowButtonProps={{ classes: { root: classes.calendarArrowRoot } }}
                PopoverProps={{ classes: { root: classes.datePickerRoot } }}
                {...others}
            />
        </div>
    );
};

export default MaterialDatePicker;