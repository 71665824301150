import React from 'react';

import NotificationsMenu from '../../../TopBar/components/NotificationsMenu/NotificationsMenu';
import AccountHubPageTemplate from '../AccountHubPageTemplate/AccountHubPageTemplate';

export interface AccountHubNotificationsProps {
    section: string,
    title: string,
};
const AccountHubNotifications = ({ section, title }: AccountHubNotificationsProps) => {
    return (
        <AccountHubPageTemplate section={section} title={title}>
            <NotificationsMenu showHeader={false} dense={false} />
        </AccountHubPageTemplate>
    );
};

export default AccountHubNotifications;