import { Group } from "../types";

import { baseImageUrl, baseServerUrl } from "./apiConsts";
import { simpleRequest } from "./apiHelpers";

import { mockGroupInviteeDetail, mockGroupMemberDetail, mockGroups } from "../MockData";

export const getGroupImageUrl = (groupId: string, type: string): string => `${baseImageUrl}/groups/${groupId}/${type}`;

export const getAllGroups = async () => {
    return simpleRequest('get', `${baseServerUrl}/groups/all`, mockGroups);
};

export const getAllMaintainbaleGroups = async () => {
    return simpleRequest('get', `${baseServerUrl}/groups/allMaintainable`, mockGroups);
};

export const getGroupId = async (groupName: string) => {
    return simpleRequest('post', `${baseServerUrl}/groups/getId`, 'asd', {
        body: { groupName }
    });
};

export const getGroup = async (groupId: string) => {
    return simpleRequest('get', `${baseServerUrl}/groups/${groupId}`, mockGroups[0]);
};

export interface InviteToGroupProps {
    groupId: string,
    invitee: string,
    username: string,
};
export const inviteToGroup = async ({ groupId, invitee }: InviteToGroupProps) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/groups/${groupId}/invite`, true, {
        body: { invitee }
    });
};

export const updateGroup = async (group: Group) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/groups/${group.groupId}/update`, true, {
        body: group
    });
};

export const deleteGroup = async (groupId: string) => {
    return simpleRequest<boolean>('get', `${baseServerUrl}/groups/${groupId}/delete`, true);
}

export const createGroup = async (group: Partial<Group>) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/groups/create`, true, {
        body: group
    });
}

export const getGroupMembersDetails = async (groupId: string) => {
    return simpleRequest('get', `${baseServerUrl}/groups/${groupId}/getMembersDetails`, [mockGroupMemberDetail]);
};

export const getGroupInviteesDetails = async (groupId: string) => {
    return simpleRequest('get', `${baseServerUrl}/groups/${groupId}/getInviteesDetails`, [mockGroupInviteeDetail]);
};

export interface AddGroupAdminProps {
    groupId: string,
    accountId: string,
};
export const addGroupAdmin = async ({ groupId, accountId }: AddGroupAdminProps) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/groups/${groupId}/addAdmin`, true, {
        body: { accountId }
    });
};

export interface RevokeGroupAdminProps {
    groupId: string,
    accountId: string,
};
export const revokeGroupAdmin = async ({ groupId, accountId }: RevokeGroupAdminProps) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/groups/${groupId}/revokeAdmin`, true, {
        body: { accountId }
    });
};

export interface RevokeGroupMaintainerProps {
    groupId: string,
    accountId: string,
};
export const revokeGroupMaintainer = async ({ groupId, accountId }: RevokeGroupMaintainerProps) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/groups/${groupId}/revokeMaintainer`, true, {
        body: { accountId }
    });
};

export interface AddGroupMaintainerProps {
    groupId: string,
    accountId: string,
};
export const addGroupMaintainer = async ({ groupId, accountId }: AddGroupMaintainerProps) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/groups/${groupId}/addMaintainer`, true, {
        body: { accountId }
    });
};

export interface RemoveGroupMemberProps {
    groupId: string,
    accountId: string,
};
export const removeGroupMember = async ({ groupId, accountId }: RemoveGroupMemberProps) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/groups/${groupId}/removeMember`, true, {
        body: { accountId }
    });
};

export const leaveGroup = async (groupId: string) => {
    return simpleRequest<boolean>('get', `${baseServerUrl}/groups/${groupId}/leave`, true);
};

export const joinGroup = async (groupId: string) => {
    return simpleRequest<boolean>('get', `${baseServerUrl}/groups/${groupId}/join`, true);
};

export interface CancelGroupInviteProps {
    groupId: string,
    accountId: string,
};
export const cancelGroupInvite = async ({ groupId, accountId }: CancelGroupInviteProps) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/groups/${groupId}/cancelInvite`, true, { body: { accountId } });
};

export interface UploadGroupImageProps {
    image: string,
    groupId: string,
};
export const uploadGroupAvatar = async ({ image, groupId }: UploadGroupImageProps) => {
    return simpleRequest('post', `${baseServerUrl}/groups/${groupId}/uploadAvatar`, true, { body: { image } });
};
export const uploadGroupCover = async ({ image, groupId }: UploadGroupImageProps) => {
    return simpleRequest('post', `${baseServerUrl}/groups/${groupId}/uploadCover`, true, { body: { image } });
};