import React, { FormEvent, useEffect, useState } from 'react';

import { Cell, Grid } from 'styled-css-grid';
import clsx from 'clsx';

import { Link, Redirect } from 'react-router-dom';

import { Button, CircularProgress, createStyles, makeStyles, TextField, Theme, Tooltip } from '@material-ui/core';

import { CheckIcon, CloseIcon, VikingerLogo } from '../../utils/Icons';

import { useCreateAccountMutation } from '../../utils/mutations/accountMutations';
import { useUsernameAvailabilityQuery } from '../../utils/queries/accountQueries';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainForm: {
            height: '100%',
        },
        containerGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        createAccountGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        logoCell: {
            paddingBottom: '5em',
        },
        loginButtonRoot: {
            width: '100%',
        },
        availabiltyLoader: {
            width: '1em !important',
            height: '1em !important',
        },
    })
)

const CreateAccountPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');

    const [passwordMatch, setPasswordMatch] = useState(false);

    const [redirectToLogin, setRedirectToLogin] = useState(false);

    const { data: usernameAvailable, isLoading: isLoadingUsernameAvailability } = useUsernameAvailabilityQuery(username);

    const createAccount = useCreateAccountMutation({
        onSuccess: () => {
            setRedirectToLogin(true);
        },
    });

    useEffect(() => {
        setPasswordMatch(password || confirmPassword ? password === confirmPassword : true);
    }, [confirmPassword, password]);

    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();

    const generateOnInputHandler = (setFunc: (value: string) => void) => {
        return (e: FormEvent<HTMLDivElement>) => setFunc((e.target as HTMLInputElement).value);
    };

    const attemptCreatingAccount = () => {
        if (!username || !password || !confirmPassword) {
            enqueueSnackbar('Not all fields are filled', { variant: 'error' });
            return;
        }

        if (!passwordMatch) {
            enqueueSnackbar('Passwords do not match', { variant: 'error' });
            return;
        }

        createAccount.mutate({ username, password });
    };

    const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        attemptCreatingAccount();
    };

    return redirectToLogin ?
        <Redirect to="/login" />
        :
        (
            <form className={classes.mainForm} onSubmit={onFormSubmit}>
                <Grid rows="1fr auto 1fr" columns="1fr auto 1fr" areas={[". . .", ". content .", ". . ."]} className={classes.containerGrid}>
                    <Cell area="content">
                        <Grid columns="1fr" rows="20em repeat(5 ,auto)" className={clsx(classes.createAccountGrid, "center-vertical")}>
                            <Cell className={clsx(classes.logoCell, "center-horizontal")}>
                                <VikingerLogo fill="white" width="14em" />
                            </Cell>
                            <Cell>
                                <Grid rows="1fr" columns="1fr 2em">
                                    <Cell>
                                        <TextField autoFocus onInput={generateOnInputHandler(setUsername)} label="Username" variant="outlined" />
                                    </Cell>
                                    <Cell className="center-vertical center-horizontal">
                                        {
                                            username &&
                                            (
                                                isLoadingUsernameAvailability ?
                                                    <CircularProgress className={classes.availabiltyLoader} />
                                                    :
                                                    (usernameAvailable ?
                                                        <Tooltip title="Username is available">
                                                            <div>
                                                                <CheckIcon width="1em" fill="var(--primary-color)" />
                                                            </div>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title="Username is not available">
                                                            <div>
                                                                <CloseIcon width="1em" fill="red" />
                                                            </div>
                                                        </Tooltip>
                                                    )
                                            )
                                        }
                                    </Cell>
                                </Grid>
                            </Cell>
                            <Cell>
                                <TextField error={!passwordMatch} onInput={generateOnInputHandler(setPassword)} label="Password" type="password" variant="outlined" />
                            </Cell>
                            <Cell>
                                <TextField error={!passwordMatch} onInput={generateOnInputHandler(setconfirmPassword)} label="Confirm Password" type="password" variant="outlined" />
                            </Cell>
                            <Cell>
                                <Button variant="contained" color="primary" type="submit" classes={{ root: classes.loginButtonRoot }}>
                                    {`Create Account`}
                                </Button>
                            </Cell>
                            <Cell>
                                <Grid columns="1fr auto" rows="1fr" areas={[". alreadyHaveAccount"]}>
                                    <Cell area="alreadyHaveAccount">
                                        <Link to="/login">
                                            <Button variant="text" color="secondary">
                                                {`Already have an account?`}
                                            </Button>
                                        </Link>
                                    </Cell>
                                </Grid>
                            </Cell>
                        </Grid>
                    </Cell>
                </Grid>
            </form>
        );
};

export default CreateAccountPage;