import { useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from "react-query";
import { Account, AccountBadge } from "../types";

import { checkUsernameAvailability, getAccountProfile, getAllAccounts } from "../api/Account";
import { GetAllAccountsReturn } from "../MockData";

const parseAccountData = (account: Account): Account => {
    account.badges?.forEach((b: AccountBadge) => {
        b.recievedDate = new Date(b.recievedDate);
    });

    if (account.birthday)
        account.birthday = new Date(account.birthday);

    if (account.createdAt)
        account.createdAt = new Date(account.createdAt);

    if (account.groupInvites)
        account.groupInvites.forEach(i => {
            i.sentAt = new Date(i.sentAt);
        });

    if (account.notifications) {
        account.notifications.forEach(n => {
            n.date = new Date(n.date);
        });
        account.notifications = account.notifications.reverse();
    }

    return account;
};

export const useAllAccountsQuery = (queryOptions?: UseInfiniteQueryOptions<GetAllAccountsReturn>) => {
    return useInfiniteQuery(['accounts', 'all'], ({ pageParam: page = 0 }) => getAllAccounts(page), {
        ...queryOptions,
        onSuccess: (accounts) => {
            accounts.pages.forEach(page => page.accounts.forEach(parseAccountData));

            queryOptions?.onSuccess?.(accounts);
        },
        getNextPageParam: (lastPage, allPages) => lastPage.more ? allPages.length : undefined,
    });
};

export const useAccountInfoQuery = (username: string, queryOptions?: UseQueryOptions<Account>) => {
    return useQuery(['accounts', username], () => getAccountProfile(username), {
        select: parseAccountData,
        ...queryOptions
    });
};

export const useUsernameAvailabilityQuery = (username: string, queryOptions?: UseQueryOptions<boolean>) => {
    return useQuery(['accounts', username, 'availability'], () => checkUsernameAvailability(username), {
        ...queryOptions
    });
};