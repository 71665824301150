import { useQueryClient } from "react-query";
import { UseMutationHandlerOptions } from "./types";

import { acceptGroupInvite, declineGroupInvite } from "../api/Account";
import {
    addGroupAdmin, addGroupMaintainer, cancelGroupInvite, createGroup, deleteGroup, inviteToGroup, joinGroup, leaveGroup, removeGroupMember,
    revokeGroupAdmin, revokeGroupMaintainer, updateGroup, uploadGroupAvatar, uploadGroupCover
} from "../api/Groups";

import { useMutationValidation } from '../api/apiHelpers';

export const useAcceptGroupInviteMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(acceptGroupInvite, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, groupId, context) => {
            queryClient.invalidateQueries(['groups', groupId]);
            queryClient.invalidateQueries(['accounts', undefined]);

            onSuccess && onSuccess(data, groupId, context);
        },
        errorMsg: `Accepting invite failed`,
        successMsg: `Invite accepted`,
    });
};

export const useDeclineGroupInviteMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(declineGroupInvite, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, groupId, context) => {
            queryClient.invalidateQueries(['groups', groupId]);
            queryClient.invalidateQueries(['accounts', undefined]);

            onSuccess && onSuccess(data, groupId, context);
        },
        errorMsg: `Declining invite failed`,
        successMsg: `Invite declined`,
    });
};

export const useLeaveGroupMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(leaveGroup, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, groupId, context) => {
            queryClient.invalidateQueries(['groups']);
            queryClient.invalidateQueries(['accounts', undefined]);
            queryClient.invalidateQueries(['posts', 'all']);

            onSuccess && onSuccess(data, groupId, context);
        },
        errorMsg: `Leaving group failed`,
        successMsg: `Group left`,
    });
};

export const useJoinGroupMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(joinGroup, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, groupId, context) => {
            queryClient.invalidateQueries(['groups']);
            queryClient.invalidateQueries(['accounts', undefined]);
            queryClient.invalidateQueries(['posts', 'all']);

            onSuccess && onSuccess(data, groupId, context);
        },
        errorMsg: `Joining group failed`,
        successMsg: `Joined group`,
    });
};

export const useInviteToGroupMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(inviteToGroup, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['groups']);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: ({ username }) => `Inviting ${username} failed`,
        successMsg: `Invite sent`,
    });
};

export const useRemoveGroupMemberMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(removeGroupMember, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['groups', variables.groupId]);
            queryClient.invalidateQueries(['accounts']);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: 'removing member failed',
        successResponseMsgMap: {
            "unautherized": 'Only maintainers can remove members',
        },
    });
};

export const useAddGroupMaintainerMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(addGroupMaintainer, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['groups', variables.groupId]);
            queryClient.invalidateQueries(['accounts']);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: 'Adding maintainer failed',
    });
};

export const useRevokeGroupMaintainerMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(revokeGroupMaintainer, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['groups', variables.groupId]);
            queryClient.invalidateQueries(['accounts']);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: 'Revoking maintainer failed',
        successResponseMsgMap: {
            "unautherized": 'only admins can revoke a maintainer autherizations',
        },
    });
};

export const useAddGroupAdminMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(addGroupAdmin, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['groups', variables.groupId]);
            queryClient.invalidateQueries(['accounts']);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: 'Adding admin failed',
    });
};

export const useRevokeGroupAdminMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(revokeGroupAdmin, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['groups', variables.groupId]);
            queryClient.invalidateQueries(['accounts']);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: `Revoking admin failed`,
    });
};

export const useDeleteGroupMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(deleteGroup, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, groupId, context) => {
            queryClient.invalidateQueries(['groups']);

            onSuccess && onSuccess(data, groupId, context);
        },
        errorMsg: `Deleting group failed`,
        successMsg: `Group deleted`,
    });
};

export const useUpdateGroupMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(updateGroup, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, group, context) => {
            queryClient.invalidateQueries(['groups']);

            onSuccess && onSuccess(data, group, context);
        },
        errorMsg: 'Updating group failed',
        successMsg: `Group updated`,
    });
};

export const useCreateGroupMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(createGroup, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, group, context) => {
            queryClient.invalidateQueries(['groups']);

            onSuccess && onSuccess(data, group, context);
        },
        errorMsg: 'Creating group failed',
        successMsg: `Group created`,
    });
};

export const useCancelInviteMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(cancelGroupInvite, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['groups', variables.groupId]);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: 'Canceling invitation failed',
        successMsg: `Invite canceled`,
    });
};

export const useUploadGroupAvatarMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    // const queryClient = useQueryClient();

    return useMutationValidation(uploadGroupAvatar, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
        errorMsg: `Error uploading image`,
        successMsg: `Image uploaded, will take effect in a minute`,
    });
};

export const useUploadGroupCoverMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    // const queryClient = useQueryClient();

    return useMutationValidation(uploadGroupCover, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
        errorMsg: `Error uploading image`,
        successMsg: `Image uploaded, will take effect in a minute`,
    });
};