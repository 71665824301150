import React, { FormEvent, useEffect, useState } from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, makeStyles, Paper, TextField, Theme, Typography } from '@material-ui/core';

import AccountHubPageTemplate from '../AccountHubPageTemplate/AccountHubPageTemplate';
import { useChangeAccountPasswordMutation } from '../../../../utils/mutations/accountMutations';
import Loader from '../../../Design/Loader/Loader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            padding: '2em',
            borderRadius: '1em',
        },
        buttonRoot: {
            width: '100%',
            height: '100%',
        },
    })
);

const AccountHubChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [passwordMatch, setPasswordMatch] = useState(false);

    const changePassword = useChangeAccountPasswordMutation();

    const classes = useStyles();

    useEffect(() => {
        setPasswordMatch(newPassword || confirmNewPassword ? newPassword === confirmNewPassword : true);
    }, [confirmNewPassword, newPassword]);

    const generateOnInputHandler = (setFunc: (value: string) => void) => {
        return (e: FormEvent<HTMLDivElement>) => setFunc((e.target as HTMLInputElement).value);
    };

    const handleForgotYourPasswordClick = () => {

    };

    const handleChangePasswordClick = () => {
        changePassword.mutate({ newPassword, oldPassword }, {
            onSuccess: () => {
                setOldPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
            },
        });
    };

    return (
        <AccountHubPageTemplate section="Account" title="Change Password">
            <Paper classes={{ root: classes.paperRoot }}>
                <Grid gap="2em" rows="1fr 1fr 1em 3em" columns="1fr 1fr" areas={["old old", "new confirmNew", "error error", "forgot change"]}>
                    <Cell area="old">
                        <TextField value={oldPassword} variant="outlined" type="password" label="Confirm Your Current Password" onInput={generateOnInputHandler(setOldPassword)} />
                    </Cell>
                    <Cell area="new">
                        <TextField value={newPassword} error={!passwordMatch} variant="outlined" type="password" label="Your New Password" onInput={generateOnInputHandler(setNewPassword)} />
                    </Cell>
                    <Cell area="confirmNew">
                        <TextField value={confirmNewPassword} error={!passwordMatch} variant="outlined" type="password" label="Confirm Your New Password" onInput={generateOnInputHandler(setConfirmNewPassword)} />
                    </Cell>
                    <Cell area="error">
                        {
                            !passwordMatch &&
                            <Typography variant="body1" color="error">
                                {`Passwords must match`}
                            </Typography>
                        }
                    </Cell>
                    <Cell area="forgot">
                        <Button variant="contained" color="secondary" classes={{ root: classes.buttonRoot }} onClick={handleForgotYourPasswordClick}>
                            {`Forgot Your Password?`}
                        </Button>
                    </Cell>
                    <Cell area="change">
                        <Button variant="contained" disabled={!passwordMatch} color="primary" classes={{ root: classes.buttonRoot }} onClick={handleChangePasswordClick} >
                            {
                                changePassword.isLoading ?
                                    <Loader />
                                    :
                                    `Change Password Now!`
                            }
                        </Button>
                    </Cell>
                </Grid>
            </Paper>
        </AccountHubPageTemplate>
    );
};

export default AccountHubChangePassword;