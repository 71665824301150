import { useMemo } from "react";

import { useGroupInfo } from "./useGroupInfo";

import { getGroupImageUrl } from "../utils/api/Groups";

export const useGroupImage = (type: string) => {
    const { groupInfo: { groupId }, isLoading } = useGroupInfo();

    const groupImageUrl = useMemo(() => groupId && !isLoading ? getGroupImageUrl(groupId, type) : '',
        [groupId, type, isLoading]
    );

    return groupImageUrl;
};