import React from 'react';

import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';

import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonRoot: {
            width: '100%',
            maxWidth: '10em',
        },
    }));

const LogoutButton = () => {
    const classes = useStyles();

    return (
        <Link to="/logout" className={classes.buttonRoot}>
            <Button variant="contained" color="secondary" classes={{ root: classes.buttonRoot }}>
                Logout
            </Button>
        </Link>
    );
};

export default LogoutButton;