import React from 'react';
import { Group } from '../../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import GroupInfoProvider from '../../../../../InfoProviders/GroupInfoProvider/GroupInfoProvider';
import GroupProfileHexagon from '../../../../../GroupProfileHexagon/GroupProfileHexagon';

import { GroupIcon } from '../../../../../../utils/Icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rowLink: {
            width: '100%',
        },
        rowGrid: {
            width: '100%',
        },
        nameText: {
            fontWeight: 700,
        },
    })
);

export interface SearchResultAccountRowProps {
    data: Group
};
const SearchResultGroupRow = ({ data: group }: SearchResultAccountRowProps) => {
    const classes = useStyles();

    return (
        <GroupInfoProvider groupData={group}>
            <Grid rowGap="0" columnGap="1em" columns="3em 1fr auto" rows="auto auto" areas={["icon name symbol", "icon mutualFriends symbol"]} className={classes.rowGrid}>
                <Cell area="icon">
                    <GroupProfileHexagon size="3em" />
                </Cell>
                <Cell area="name">
                    <Typography variant="body1" className={classes.nameText}>
                        {group.name}
                    </Typography>
                </Cell>
                <Cell area="mutualFriends">
                    <Typography variant="caption" className={classes.nameText}>
                        {`${group.members.length} members`}
                    </Typography>
                </Cell>
                <Cell area="symbol" className="center-vertical">
                    <GroupIcon width="1.5em" fill="var(--icon-fill)" />
                </Cell>
            </Grid>
        </GroupInfoProvider>
    );
};

export default SearchResultGroupRow;