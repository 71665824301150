import React from 'react';

import { Grid, Cell } from 'styled-css-grid';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import AccountProfileHexagon from '../AccountProfileHexagon/AccountProfileHexagon';

import { Link } from 'react-router-dom';
import { useAccountInfo } from '../../CustomHooks/useAccountInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuHiCell: {
            display: 'flex',
            alignItems: 'end',
        },
        menuHiMesssage: {
            fontWeight: 700,
            lineHeight: '1',
            color: 'white',
        },
    })
);

export interface SmallMenuHeaderProps {
    onClose?: () => void,
};
const SmallMenuHeader = ({ onClose }: SmallMenuHeaderProps) => {
    const { accountInfo: { username } } = useAccountInfo();

    const classes = useStyles();

    return (
        <Link to={`/profile/${username}`} onClick={onClose}>
            <Grid columnGap="0.5em" gap="0" columns="auto 1fr" rows="1fr 1fr" areas={["profilePicture hi", "profilePicture username"]}>
                <Cell area="profilePicture">
                    <AccountProfileHexagon levelSize="medium" />
                </Cell>
                <Cell area="hi" className={classes.menuHiCell}>
                    <Typography variant="body1" className={classes.menuHiMesssage}>
                        {`Hi ${username}!`}
                    </Typography>
                </Cell>
                <Cell area="username">
                    <Typography variant="caption">
                        {`@${username}`}
                    </Typography>
                </Cell>
            </Grid>
        </Link>
    );
};

export default SmallMenuHeader;