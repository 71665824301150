import { baseBadgesImageUrl, baseServerUrl } from "./apiConsts";

import { mockBadges } from "../MockData";
import { simpleRequest } from "./apiHelpers";

// export const getBadgeIconUrl = (badgeId: string): string => `${baseServerUrl}/static/badges/${badgeId}.png`;
export const getBadgeIconUrl = (badgeId: string): string => `${baseBadgesImageUrl}/${badgeId}`;

export const getAllBadges = async () => {
    return simpleRequest('get', `${baseServerUrl}/badges/all`, mockBadges);
};