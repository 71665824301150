import React, { KeyboardEvent, useCallback, useEffect, useMemo } from 'react';

import { Cell, Grid } from 'styled-css-grid';
import clsx from 'clsx';

import { createStyles, makeStyles, TextFieldProps, Theme, Typography } from '@material-ui/core';

import { maxPostLength } from '../../../utils/consts';

import { createEditor, Descendant, Editor, Node } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'

import LeafElement from './components/LeafElement/LeafElement';
import TextEditorToolbar from './components/TextEditorToolbar/TextEditorToolbar';
import BlockElement from './components/BlockElement/BlockElement';
import { CustomElement } from './types';
import MentionsPortal from './components/MentionsPortal/MentionsPortal';
import { useHandlersArray } from '../../../CustomHooks/useHandlersArray';

export interface TextEditorInputStyleProps {
    readOnly?: boolean
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        editableRoot: {
            textAlign: 'initial',
            minHeight: ({ readOnly }: TextEditorInputStyleProps) => readOnly ? '0' : '5em !important',
        },
        lengthIndicator: {
            position: 'absolute',
            bottom: '0em',
            right: '2em',
        },
        lengthIndicatorMax: {
            color: 'red',
            fontWeight: 700,
        },
    })
);

const withMentions = (editor: Editor) => {
    const { isInline, isVoid } = editor;

    editor.isInline = (element: CustomElement) => element.type === 'mention' ? true : isInline(element);
    editor.isVoid = (element: CustomElement) => element.type === 'mention' ? true : isVoid(element);

    return editor;
}

export type TextEditorChangeHandler = (editor: Editor) => void;
export type TextEditorKeyDownHandler = (e: KeyboardEvent<HTMLDivElement>) => void;
export type TextEditorInputData = {
    text?: Descendant[],
};

export interface TextEditorInputProps {
    value?: Descendant[],
    onChange?: (newValue: Descendant[]) => void,
    inputRef?: React.Ref<any>,
    variant?: TextFieldProps["variant"],
    placeholder?: string,
    readOnly?: boolean,
    showCommandToolbar?: boolean,
    clearTextEditorSelection?: React.MutableRefObject<() => void>,
};
const TextEditorInput = ({ value: text = [{ type: 'paragraph', children: [{ text: '' }] }], onChange, inputRef, variant = "standard",
    placeholder, readOnly = false, showCommandToolbar = true, clearTextEditorSelection }: TextEditorInputProps) => {

    const editor = useMemo(() => withMentions(withReact(createEditor())),
        []
    );

    const textLength = useMemo(() => editor.children.reduce((prev, curr) => prev + Node.string(curr).length, 0),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editor, text]
    );

    const { handlers: changeHandlers, addHandler: addEditorChangeHandler, removeHandler: removeEditorChangeHandler } = useHandlersArray<TextEditorChangeHandler>();
    const { handlers: keyDownHandlers, addHandler: addEditorKeyDownHandler, removeHandler: removeEditorKeyDownHandler } = useHandlersArray<TextEditorKeyDownHandler>();

    const classes = useStyles({ readOnly });

    const clearEditorSelection = useCallback(() => {
        editor.selection = null;
    }, [editor]);

    useEffect(() => {
        if (clearTextEditorSelection)
            clearTextEditorSelection.current = clearEditorSelection;

        return () => {
            if (clearTextEditorSelection)
                clearTextEditorSelection.current = () => { };
        };
    }, [clearEditorSelection, clearTextEditorSelection]);

    const handleInputChange = (newValue: Descendant[]) => {
        onChange?.(newValue);

        changeHandlers.forEach(handler => {
            handler(editor);
        });
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (textLength >= maxPostLength) { //TODO: fix! it's not good! locks the input!
            e.preventDefault();
            return;
        }

        keyDownHandlers.forEach(handler => {
            handler(e);
        });
    };

    return (
        <>
            <Slate
                editor={editor}
                value={text}
                onChange={handleInputChange}
            >
                <Grid gap="0" columns="1fr" rows="auto 1fr">
                    <Cell>
                        {
                            !readOnly && showCommandToolbar &&
                            <TextEditorToolbar />
                        }
                    </Cell>
                    <Cell>
                        <Editable renderElement={BlockElement} renderLeaf={LeafElement} className={classes.editableRoot} spellCheck
                            placeholder={placeholder} readOnly={readOnly} onKeyDown={handleKeyDown} />
                        <MentionsPortal value={text} addEditorChangeHandler={addEditorChangeHandler} removeEditorChangeHandler={removeEditorChangeHandler}
                            addEditorKeyDownHandler={addEditorKeyDownHandler} removeEditorKeyDownHandler={removeEditorKeyDownHandler} />
                    </Cell>
                </Grid>
            </Slate>
            {
                !readOnly &&
                <div className={classes.lengthIndicator}>
                    <Typography variant="caption" className={clsx({ [classes.lengthIndicatorMax]: textLength >= maxPostLength })}>
                        {`${textLength}/${maxPostLength}`}
                    </Typography>
                </div>
            }
        </>
    );
};

export default TextEditorInput;