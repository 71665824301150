import React, { ComponentType, useState } from "react";

import { Cell, Grid } from "styled-css-grid";

import { Accordion, AccordionDetails, AccordionSummary, createStyles, List, ListItem, makeStyles, Theme, Typography } from "@material-ui/core";

import { Link, useParams } from "react-router-dom";

import AccountHubProfileInfo from "../AccountHubProfileInfo/AccountHubProfileInfo";
import AccountHubProfileMessages from "../AccountHubProfileMessages/AccountHubProfileMessages";
import AccountHubChangePassword from "../AccountHubChangePassword/AccountHubChangePassword";
import AccountHubGeneralSettings from "../AccountHubGeneralSettings/AccountHubGeneralSettings";
import AccountHubNotifications from "../AccountHubNotifications/AccountHubNotifications";
import AccountHubManageGroups from "../AccountHubManageGroups/AccountHubManageGroups";
import AccountHubGroupInvitations from "../AccountHubGroupInvitations/AccountHubGroupInvitations";

import { AboutIcon, PlusIcon, MinusIcon, SettingsIcon, GroupIcon } from "../../../../utils/Icons";
import { pathParams } from "../../../../utils/types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        MenuSectionGrid: {
            width: '100%',
            textAlign: 'start',
            justifyContent: 'start',
        },
        menuSubsectionList: {
            width: '100%',
        },
    })
);

const accountHubMenuIconProps = {
    fill: "var(--primary-color)",
    color: "var(--primary-color)",
    style: { paddingTop: '0.25em' },
};
export type AccountHubMenuSubsection = {
    id: string,
    title: string,
    component: ComponentType<any>,
    saveOption?: boolean,
};
export type AccountHubMenuSectionType = {
    id: string,
    title: string,
    subtitle: string,
    icon: ComponentType<any>,
    saveOption?: boolean,
    subsections: AccountHubMenuSubsection[]
};
export const accountHubMenuConfig: AccountHubMenuSectionType[] = [
    {
        id: 'profile',
        title: 'My Profile',
        subtitle: 'Change your avatar & cover, accept friends, read messages and more!',
        icon: AboutIcon,
        subsections: [
            {
                id: 'profileInfo',
                title: 'Profile Info',
                component: AccountHubProfileInfo,
            },
            {
                id: 'notifications',
                title: 'Notifications',
                component: AccountHubNotifications,
            },
            {
                id: 'messages',
                title: 'Messages',
                component: AccountHubProfileMessages,
            },
        ],
    },
    {
        id: 'account',
        title: 'Account',
        subtitle: 'Change settings, configure notifications, and review your privacy',
        icon: SettingsIcon,
        subsections: [
            {
                id: 'changePassword',
                title: 'Change Password',
                component: AccountHubChangePassword,
            },
            {
                id: 'settings',
                title: 'General Settings',
                component: AccountHubGeneralSettings,
            },
        ],
    },
    {
        id: 'groups',
        title: 'Groups',
        subtitle: 'Create new groups, manage the ones you created or accept invites!',
        icon: GroupIcon,
        subsections: [
            {
                id: 'manageGroups',
                title: 'Manage Groups',
                component: AccountHubManageGroups,
            },
            {
                id: 'groupInvitations',
                title: 'Invitations',
                component: AccountHubGroupInvitations,
            },
        ],
    },
];

export interface AccountHubMenuSectionProps extends AccountHubMenuSectionType {
    selectedSection: string,
    selectedSubsection: string,
    onSectionChange: (value: string) => void,
};
const AccountHubMenuSection = ({ id, icon: IconComp, subtitle, title, subsections, selectedSection, selectedSubsection,
    onSectionChange }: AccountHubMenuSectionProps) => {
    const classes = useStyles();

    const handleChange = (e: React.ChangeEvent<{}>, isExpanded: boolean) => {
        onSectionChange(isExpanded ? id : '');
    };

    return (
        <Accordion expanded={selectedSection === id} onChange={handleChange}>
            <AccordionSummary>
                <Grid columns="1em 1fr 1em" rows="auto 1fr" className={classes.MenuSectionGrid} areas={["icon title expandIcon", "icon subtitle expandIcon"]}>
                    <Cell area="icon">
                        <IconComp {...accountHubMenuIconProps} />
                    </Cell>
                    <Cell area="expandIcon">
                        {
                            selectedSection === id ?
                                <MinusIcon fill="white" height="0.35em" />
                                :
                                <PlusIcon fill="white" height="0.35em" />
                        }
                    </Cell>
                    <Cell area="title">
                        <Typography variant="h6">
                            {title}
                        </Typography>
                    </Cell>
                    <Cell>
                        <Typography variant="caption">
                            {subtitle}
                        </Typography>
                    </Cell>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <List classes={{ root: classes.menuSubsectionList }}>
                    {
                        subsections.map(s => (
                            <ListItem key={s.id} component={Link} to={`/accounthub/${id}/${s.id}`} button selected={selectedSubsection === s.id} >
                                <Typography variant="body2" className="font-weight-700">
                                    {s.title}
                                </Typography>
                            </ListItem>
                        ))
                    }
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

export interface AccountHubMenuProps {
    menuButtonsContainerRef: React.MutableRefObject<HTMLDivElement | null>
};
const AccountHubMenu = ({ menuButtonsContainerRef }: AccountHubMenuProps) => {
    const { hubSection = "profile", hubSubsection = "profileInfo" } = useParams<pathParams>();
    const [currentSection, setCurrentSection] = useState<string>(hubSection);

    const handleSectionChange = (newSection: string) => {
        setCurrentSection(newSection);
    }

    return (
        <>
            {
                accountHubMenuConfig.map(section => (
                    <AccountHubMenuSection key={section.id} {...section} selectedSection={currentSection} selectedSubsection={hubSubsection}
                        onSectionChange={handleSectionChange} />
                ))
            }
            <div ref={menuButtonsContainerRef} />
        </>
    );
};

export default AccountHubMenu;