import React, { useMemo } from 'react';

import { Cell, Grid } from 'styled-css-grid';
import clsx from 'clsx';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { AppBar, createStyles, Divider, makeStyles, Toolbar } from '@material-ui/core';

import GeneralSearchField from './components/GeneralSearchField/GeneralSearchField';
import BottomBar from './components/BottomBar/BottomBar';
import SettingsMenuButton from './components/SettingsMenuButton/SettingsMenuButton';
import NotificationsMenuButton from './components/NotificationsMenuButton/NotificationsMenuButton';

import { VikingerLogo } from '../../utils/Icons';

import { useBreakpoint } from '../../CustomHooks/useBreakpoint';

export interface showBottomMenuStyleProps {
    topBarHeight: string,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBarRoot: {
            height: ({ topBarHeight }: showBottomMenuStyleProps) => topBarHeight,
            display: 'grid',
        },
        appBarGrid: {
            width: '100%',
            '&[class*="Grid"]': {
                height: '100%',
            }
        },
        logoCell: {
            fill: 'white',
            '& svg': {
                height: '2.5em',
                width: '2.5em',
            },
        },
        menuToggleCell: {
            '& svg': {
                height: '1.25em',
                width: '1.25em',
            },
        },
        searchCell: {
            position: 'relative',
        },
        topBarDivider: {
            backgroundColor: 'var(--top-bar-icon-fill)',
        },
        toolbarRootSmallScreen: {
            height: ({ topBarHeight }: showBottomMenuStyleProps) => topBarHeight,
            minHeight: 'auto',
            paddingTop: '0',
            paddingRight: '0',
            paddingBottom: '0',
        },
        menuToggleButtonCell: {
            paddingRight: '1em',
        },
    }));

export const getTopBarHeight = (showBottomMenu: boolean): string => showBottomMenu ? '4em' : '5em';

export const topBarStyles = useStyles;

export interface TopBarProps {
    sideBarToggleButton: React.MutableRefObject<HTMLDivElement | null>
}
const TopBar = ({ sideBarToggleButton }: TopBarProps) => {
    const { index: breakpointIndex } = useBreakpoint();

    const showBottomMenu = useMemo(() => breakpointIndex != null && breakpointIndex < 2,
        [breakpointIndex]
    );

    const topBarHeight = useMemo(() => getTopBarHeight(showBottomMenu),
        [showBottomMenu]
    );

    const classes = useStyles({ topBarHeight });

    return (
        <>
            <AppBar color="primary" position="fixed" classes={{ root: clsx(classes.appBarRoot, "center-vertical") }}>
                <Toolbar classes={{ root: clsx({ [classes.toolbarRootSmallScreen]: showBottomMenu }) }}>
                    <Grid gap={showBottomMenu ? "0" : "1em"} className={classes.appBarGrid} rows="1fr" columns="auto auto 1fr auto auto auto auto"
                        areas={["logo menuToggle search divider1 notifications divider2 settings"]}>
                        <Cell area="logo" className={clsx(classes.logoCell, "center-vertical")}>
                            <VikingerLogo />
                        </Cell>
                        {
                            showBottomMenu &&
                            <Cell key={'menuToggleButton'} ref={sideBarToggleButton} area="menuToggle" className={clsx(classes.menuToggleCell, classes.menuToggleButtonCell, "center-vertical")}>
                            </Cell>
                        }
                        <Cell area="search" className={clsx(classes.searchCell, { "center-vertical": !showBottomMenu })}>
                            <GeneralSearchField size={showBottomMenu ? 'small' : undefined} />
                        </Cell>
                        {
                            !showBottomMenu &&
                            <>
                                <Cell area="divider1" className="center-vertical-soft">
                                    <Divider orientation="vertical" classes={{ root: classes.topBarDivider }} />
                                </Cell>
                                <Cell area="notifications" className={"center-vertical"}>
                                    <NotificationsMenuButton />
                                </Cell>
                                <Cell area="divider2" className="center-vertical-soft">
                                    <Divider orientation="vertical" classes={{ root: classes.topBarDivider }} />
                                </Cell>
                                <Cell area="settings" className={"center-vertical"}>
                                    <SettingsMenuButton />
                                </Cell>
                            </>
                        }
                    </Grid>
                </Toolbar>
            </AppBar>
            {
                showBottomMenu &&
                <BottomBar />
            }
        </>
    );
};

export default TopBar;