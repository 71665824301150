import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { Group, GroupMemberType } from '../../../../../../utils/types';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Button, CircularProgress, createStyles, List, ListItem, makeStyles, Theme, Typography } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import Hexagon from '../../../../../Design/Hexagon/Hexagon';

import GroupProfileHexagon from '../../../../../GroupProfileHexagon/GroupProfileHexagon';
import GroupSettingsInfo from './component/GroupSettingsInfo/GroupSettingsInfo';
import GroupSettingsSettings from './component/GroupSettingsSettings/GroupSettingsSettings';
import GroupSettingsDelete from './component/GroupSettingsDelete/GroupSettingsDelete';
import GroupSettingsMembers from './component/GroupSettingsMembers/GroupSettingsMembers';

import { useGroupInfo } from '../../../../../../CustomHooks/useGroupInfo';
import { useGroupImage } from '../../../../../../CustomHooks/useGroupImage';
import { useBreakpoint } from '../../../../../../CustomHooks/useBreakpoint';
import { useCurrentAccountInfo } from '../../../../../../CustomHooks/useAccountInfo';

import { useCreateGroupMutation, useUpdateGroupMutation } from '../../../../../../utils/mutations/groupMutations';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupSettingsWindowMainGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        groupProfileImageCell: {
            alignItems: 'end',
            justifyItems: 'center',
        },
        groupProfileImageContainer: {
            backgroundColor: 'var(--content-bg)',
        },
        groupIconSkeleton: {
            width: '6em',
            height: '6em',
        },
        groupNameCell: {
            display: 'grid',
            textAlign: 'center',
            alignItems: 'center',
            paddingRight: '1em',
            paddingLeft: '1em',
        },
        imagesCell: {
            paddingBottom: '2em',
        },
        menuCell: {
            backgroundColor: 'var(--content-secondary-bg)',
            borderTop: '1px solid var(--content-inside-border-color)',
            borderBottom: '1px solid var(--content-inside-border-color)',
        },
        contentCell: {
            borderLeft: '1px solid var(--content-inside-border-color)',
            padding: '2em',
            boxSizing: 'border-box',
        },
        dialogButtonsGrid: {
            padding: '2em',
            boxSizing: 'border-box',
        },
        dialogButtonRoot: {
            width: '100%',
            paddingTop: '0.75em',
            paddingBottom: '0.75em',
        },
        menuText: {
            fontWeight: 700,
            paddingLeft: '4em',
        },
        loader: {
            height: '1.5em !important',
            width: '1.5em !important',
        },
    })
);

type GroupSettingsMenuConfigItem = {
    id: string,
    title: string,
    component: ComponentType<any>,
    newGroupEnabled?: boolean,
    permissions?: GroupMemberType[],
};
const groupSettingsMenuConfig: Record<string, GroupSettingsMenuConfigItem> = {
    info: {
        id: 'info',
        title: 'Group Info',
        component: GroupSettingsInfo,
        newGroupEnabled: true,
    },
    // images: {
    //     id: 'images',
    //     title: 'Avatar and Cover',
    //     component: ({ onChange }: { onChange: (change: Partial<Group>) => void }) => <div> avatar and cover </div>,
    //     newGroupEnabled: false,
    // },
    settings: {
        id: 'settings',
        title: 'Settings',
        component: GroupSettingsSettings,
        newGroupEnabled: true,
    },
    members: {
        id: 'members',
        title: 'Members',
        component: GroupSettingsMembers,
    },
    delete: {
        id: 'delete',
        title: 'Delete Group',
        component: GroupSettingsDelete,
        permissions: ['admin'],
    },
};

const groupSettingsLayoutMap = {
    big: {
        columns: "18em 1fr",
        rows: "auto 1fr auto",
        areas: ["images content", "menu content", "buttons content"],
    },
    small: {
        columns: "1fr",
        rows: "repeat(4, auto)",
        areas: ["images", "menu", "buttons", "content"],
    },
}

type SettingsMenuTabs = keyof typeof groupSettingsMenuConfig;

export interface GroupSettingsWindowProps {
    closeWindow?: (reloadAllGroups?: boolean) => void,
    newGroup?: boolean,
};
const GroupSettingsWindow = ({ closeWindow, newGroup = false }: GroupSettingsWindowProps) => {
    const { accountInfo: { username: currentUsername } } = useCurrentAccountInfo();
    const groupCoverImageUrl = useGroupImage('cover');
    const { groupInfo, isLoading } = useGroupInfo();
    const { name, admins } = groupInfo;

    const [updatedGroup, setUpdatedGroup] = useState(groupInfo);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setUpdatedGroup(groupInfo);
    }, [groupInfo]);

    const [currentTab, setCurrentTab] = useState<SettingsMenuTabs>('info');

    const { index: breakpointIndex } = useBreakpoint();

    const screenSize = useMemo(() => breakpointIndex < 2 ? 'small' : 'big',
        [breakpointIndex]
    );

    const groupMemberType = useMemo(() => {
        if (!admins)
            return 'admin';
        return admins.includes(currentUsername) ? 'admin' : 'maintainer'
    }, [currentUsername, admins]);

    const classes = useStyles();

    const generateMenuClickHandler = (id: SettingsMenuTabs) => {
        return () => setCurrentTab(id);
    }

    const discardChanges = () => {
        setUpdatedGroup(groupInfo);
    };

    const updateGroup = useUpdateGroupMutation({ onSuccess: closeWindow });
    const createGroup = useCreateGroupMutation({ onSuccess: closeWindow });

    const handleUpdateGroup = () => {
        updateGroup.mutate(updatedGroup);
    };

    const handleCreateGroup = () => {
        createGroup.mutate(updatedGroup);
    };

    const saveChanges = () => {
        setIsSaving(true);

        if (newGroup)
            handleCreateGroup();
        else
            handleUpdateGroup();
    };

    const handleChange = (change: Partial<Group>) => {
        setUpdatedGroup({ ...updatedGroup, ...change });
    };

    return (
        <Grid gap="0" {...groupSettingsLayoutMap[screenSize]} className={classes.groupSettingsWindowMainGrid}>
            <Cell area="images" className={classes.imagesCell}>
                <Grid columns="1fr" rows="5em 3em auto" areas={[".", ".", "name"]}>
                    <Cell top={1} left={1} width={1} height={1}
                        style={{ background: `rgba(0, 0, 0, 0) url("${groupCoverImageUrl}") no-repeat scroll center center / cover` }}>
                    </Cell>
                    <Cell top={1} left={1} width={1} height={2} className={clsx("center-horizontal", classes.groupProfileImageCell)}>
                        {
                            isLoading ?
                                (
                                    <Skeleton variant="circle" className={classes.groupIconSkeleton} />
                                )
                                :
                                (
                                    <Hexagon progress={0} size={`7em`} trackColor="var(--content-bg)" fillColor="var(--content-bg)" className={classes.groupProfileImageContainer}>
                                        <GroupProfileHexagon size={`6em`} />
                                    </Hexagon>
                                )
                        }
                    </Cell>
                    <Cell area="name" className={classes.groupNameCell}>
                        <Typography variant="h6">
                            {
                                isLoading ?
                                    (
                                        <Skeleton variant="text" />
                                    )
                                    :
                                    (
                                        name
                                    )
                            }
                        </Typography>
                    </Cell>
                </Grid>
            </Cell>
            <Cell area="menu" className={classes.menuCell}>
                <List>
                    {
                        Object.entries(groupSettingsMenuConfig)
                            .filter(([key, { permissions }]) => !permissions || permissions.includes(groupMemberType))
                            .filter(([key, { newGroupEnabled }]) => !newGroup || newGroupEnabled)
                            .map(([key, { title }]) => (
                                <ListItem key={key} button selected={currentTab === key} onClick={generateMenuClickHandler(key as SettingsMenuTabs)}>
                                    <Typography variant="body2" className={classes.menuText}>
                                        {title}
                                    </Typography>
                                </ListItem>
                            ))
                    }
                </List>
            </Cell>
            <Cell area="buttons">
                <Grid gap="1em" columns="1fr" rows="auto auto" className={classes.dialogButtonsGrid}>
                    <Cell>
                        <Button variant="contained" color="secondary" classes={{ root: classes.dialogButtonRoot }} onClick={saveChanges}>
                            {
                                isSaving ?
                                    <CircularProgress color="primary" classes={{ root: classes.loader }} />
                                    :
                                    (
                                        newGroup ?
                                            `Create Group!`
                                            :
                                            `Save Changes!`
                                    )
                            }
                        </Button>
                    </Cell>
                    <Cell>
                        <Button disabled={isSaving} variant="outlined" color="primary" classes={{ root: classes.dialogButtonRoot }} onClick={discardChanges}>
                            Discard All
                        </Button>
                    </Cell>
                </Grid>
            </Cell>
            <Cell area="content" className={classes.contentCell}>
                {
                    (() => {
                        let Comp = groupSettingsMenuConfig[currentTab].component;
                        return <Comp group={updatedGroup} onChange={handleChange} closeWindow={closeWindow} />
                    })()
                }
            </Cell>
        </Grid>
    );
};

export default GroupSettingsWindow;