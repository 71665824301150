import React from 'react';
import { Group } from '../../utils/types';

import { useAllGroupsQuery } from '../../utils/queries/groupsQueries';

import CardSearchTemplatePage from '../Templates/CardSearchTemplatePage/CardSearchTemplatePage';

import GroupGridCard from './components/GroupGridCard/GroupGridCard';

import { useFetchValidation } from '../../utils/api/apiHelpers';

const GroupsPage = () => {
    const allGroupsQuery = useFetchValidation<Group[]>(useAllGroupsQuery, {
        errorMsg: 'Error loading groups',
        options: {
            placeholderData: [] as Group[],
        }
    });

    return (
        <CardSearchTemplatePage bannerTitle="Groups" bannerSubtitle="Browse all the groups of the community!" data={allGroupsQuery.data as Group[]} searchLabel="Search Groups"
            gridCardComponent={GroupGridCard} baseUrl="/groups" filterKey={['name']} />
    );
};

export default GroupsPage;