import React from 'react';

import { createStyles, List, ListItem, makeStyles, Theme, Typography } from '@material-ui/core';

import { accountHubMenuConfig } from '../../../AccountHubPage/components/AccountHubMenu/AccountHubMenu';
import SmallMenuHeader from '../../../SmallMenuHeader/SmallMenuHeader';

import { Link } from 'react-router-dom';
import LogoutButton from '../../../LogoutButton/LogoutButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuListRoot: {
            width: '10em',
            color: 'white',
        },
        menuItemSectionText: {
            textTransform: 'uppercase',
            fontWeight: 700,
            fontSize: '0.6785em',
            lineHeight: '1',
            paddingTop: '2em',
        },
        menuItemText: {
            fontWeight: 700,
            fontSize: '0.75em',
            lineHeight: '1',
        },
    })
);

export interface SettingsMenuProps {
    showHeader?: boolean,
    showLogout?: boolean,
    onClose?: () => void,
};
const SettingsMenu = ({ onClose, showHeader = true, showLogout = true }: SettingsMenuProps) => {
    const classes = useStyles();

    return (
        <List classes={{ root: classes.menuListRoot }}>
            {
                showHeader &&
                <ListItem>
                    <SmallMenuHeader onClose={onClose} />
                </ListItem>
            }
            {
                accountHubMenuConfig.map((section) => (
                    <React.Fragment key={section.id}>
                        <ListItem>
                            <Typography variant="caption" className={classes.menuItemSectionText}>
                                {section.title}
                            </Typography>
                        </ListItem>
                        {
                            section.subsections.map(s => (
                                <ListItem key={s.id} component={Link} to={`/accounthub/${section.id}/${s.id}`} button onClick={onClose}>
                                    <Typography variant="body2" className={classes.menuItemText}>
                                        {s.title}
                                    </Typography>
                                </ListItem>
                            ))
                        }
                    </React.Fragment>
                ))
            }
            {
                showLogout &&
                <ListItem>
                    <LogoutButton />
                </ListItem>
            }
        </List>
    );
};

export default SettingsMenu;