import React, { useRef } from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { Route, Switch } from 'react-router';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import BannerPageTemplate from '../Templates/BannerPageTemplate/BannerPageTemplate';

import AccountHubMenu, { accountHubMenuConfig } from './components/AccountHubMenu/AccountHubMenu';
import AccountHubProfileInfo from './components/AccountHubProfileInfo/AccountHubProfileInfo';

import { baseBannerPageImages } from '../../utils/api/apiConsts';

import { useBreakpoint } from '../../CustomHooks/useBreakpoint';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hubMainGrid: {
            maxWidth: '75em',
            width: '100%',
        },
    })
);

const pageLayoutMap = {
    big: {
        columns: "18em 1fr",
        rows: '1fr'
    },
    small: {
        columns: '1fr',
        rows: 'auto 1fr',
    },
};

const AccountHubPage = () => {
    const { index: breakpointIndex } = useBreakpoint();

    const menuButtonsContainerRef = useRef<HTMLDivElement>(null);

    const classes = useStyles();

    return (
        <BannerPageTemplate title="Account Hub" subtitle="Profile info, messages, settings and much more!" iconUrl={`${baseBannerPageImages}/accounthub-icon`} centerContent>
            <Grid columnGap="1em" {...pageLayoutMap[breakpointIndex != null && breakpointIndex < 3 ? 'small' : 'big']} className={classes.hubMainGrid}>
                <Cell>
                    <AccountHubMenu menuButtonsContainerRef={menuButtonsContainerRef} />
                </Cell>
                <Cell>
                    <Switch>
                        {
                            accountHubMenuConfig.map(section => section.subsections.map(({ id, title, component: ContentComp }) => (
                                <Route path={`/accounthub/${section.id}/${id}`}>
                                    <ContentComp menuButtonsContainer={menuButtonsContainerRef.current} section={section.title} title={title} />
                                </Route>
                            )))
                        }
                        <Route>
                            <AccountHubProfileInfo menuButtonsContainer={menuButtonsContainerRef.current} section={'MY PROFILE'} title={'Profile Info'} />
                        </Route>
                    </Switch>
                </Cell>
            </Grid>
        </BannerPageTemplate>
    );
};

export default AccountHubPage;