import React from 'react';
import { MenuLink } from '../types';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Link, useLocation } from 'react-router-dom';

import { Chip, createStyles, Drawer, List, ListItem, ListItemIcon, makeStyles, Theme } from '@material-ui/core';

import { menuLinks } from '../Sidebar';
import AccountProfileHexagon from '../../AccountProfileHexagon/AccountProfileHexagon';

import { useAccountInfo } from '../../../CustomHooks/useAccountInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawerRoot: {
            height: '100%',
            whiteSpace: 'nowrap',
            color: 'white',
        },
        drawerPaper: {
            backgroundColor: 'var(--content-bg)',
            color: 'var(--icon-fill)',
            fill: 'var(--icon-fill)',
            overflow: 'visible',
            top: 'unset',
            paddingTop: '0.5em',
        },
        drawerWidth: {
            width: '5em',
        },
        sidebarContainer: {
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        menuListItemRoot: {
            display: 'grid',
            justifyContent: 'center',
            marginTop: '0.25em',
            marginBottom: '0.25em',
            width: '3em',
            height: '3em',
            transition: 'max-width 0.5s cubic-bezier(.55,.06,.44,.55), background-color 0.2s ease-in-out',
            borderRadius: '0.5em',
        },
        menuListItemRootActive: {
            backgroundColor: 'var(--button-primary-bg)',
            boxShadow: '4px 7px 12px 0 rgba(64,208,79,.2)',
            color: 'white',
            fill: 'white',
            '&:hover, &:focus': {
                backgroundColor: 'var(--button-primary-bg)',
                '& .menuLinkText': {
                    opacity: 1,
                    visibility: 'visible',
                    left: '100%',
                },
            },
        },
        menuListItemRootNotActive: {
            '&:hover, &:focus': {
                backgroundColor: 'var(--icon-bg-hover)',
                color: 'var(--icon-fill-hover)',
                fill: 'var(--icon-fill-hover)',
                '& .menuLinkText': {
                    opacity: 1,
                    visibility: 'visible',
                    left: '100%',
                },
            },
        },
        menuListItemIconRoot: {
            color: 'inherit',
            minWidth: 'auto',
            width: '1.25em',
            height: '1.25em',
        },
        menuLink: {
            width: '3em',
        },
        menuItemChipText: {
            color: 'white',
            backgroundColor: 'var(--icon-bg-hover)',
            position: 'absolute',
            visibility: 'hidden',
            left: '120%',
            marginLeft: '1em',
            opacity: 0,
            transition: 'all 0.3s ease-in-out',
            cursor: 'pointer',
            fontWeight: 700,
        },
    })
);

const MenuLinkItem = ({ title, icon, target }: MenuLink) => {
    let { pathname } = useLocation();

    let classes = useStyles();

    return (
        <Link to={target} className={clsx(classes.menuLink, "center-horizontal", "center-vertical")} tabIndex={-1}>
            <ListItem button classes={{ root: clsx(classes.menuListItemRoot, "center-horizontal", pathname === target ? classes.menuListItemRootActive : classes.menuListItemRootNotActive) }}>
                <ListItemIcon classes={{ root: clsx(classes.menuListItemIconRoot, "center-horizontal") }}>
                    {icon}
                </ListItemIcon>
                <Chip classes={{ root: clsx(classes.menuItemChipText, "menuLinkText") }} label={title} />
            </ListItem>
        </Link>
    );
};

const PermanentSideBar = () => {
    const { accountInfo: { username } } = useAccountInfo();

    const classes = useStyles();

    return (
        <Drawer variant="permanent" className={clsx(classes.drawerRoot, classes.drawerWidth)} classes={{ paper: clsx(classes.drawerPaper, classes.drawerWidth) }}>
            <Grid gap="0" rows="3.5em 1fr" columns="1fr" className={classes.sidebarContainer} areas={["avatar", "menu"]}>
                <Cell area="avatar" className={clsx("center-horizontal", "pointer")}>
                    <Link to={`/profile/${username}`}>
                        <AccountProfileHexagon size="3em" levelSize="medium" />
                    </Link>
                </Cell>
                <Cell area="menu">
                    <List classes={{ root: "center-horizontal" }}>
                        {
                            menuLinks.map(menuLink => <MenuLinkItem key={menuLink.title} {...menuLink} />)
                        }
                    </List>
                </Cell>
            </Grid>
        </Drawer>
    );
};

export default PermanentSideBar;