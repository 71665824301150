import React from 'react';
import { pathParams } from '../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { useParams } from 'react-router';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import GroupInfoProvider from '../InfoProviders/GroupInfoProvider/GroupInfoProvider';

import GroupProfilePageAbout from './components/GroupProfilePageAbout/GroupProfilePageAbout';
import GroupProfilePageMembers from './components/GroupProfilePageMembers/GroupProfilePageMembers';
import GroupProfilePageHeader from './components/GroupProfilePageHeader/GroupProfilePageHeader';
import GroupProfileTabs from './components/GroupProfileTabs/GroupProfileTabs';
import GroupProfilePagePosts from './components/GroupProfilePagePosts/GroupProfilePagePosts';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainGrid: {
            padding: '2em',
            maxWidth: '74em',
            width: '100%',
        },
        mainGridCell: {
            backgroundColor: 'var(--content-bg)',
            borderRadius: '1em',
        },
    })
);

const tabsContent = {
    about: <GroupProfilePageAbout />,
    members: <GroupProfilePageMembers />,
    posts: <GroupProfilePagePosts />,
};

const GroupProfilePage = () => {
    const { currentTab = "about", groupName } = useParams<pathParams>();

    const classes = useStyles();

    return (
        <GroupInfoProvider groupName={groupName}>
            <Grid columns="1fr" rows="28em 5em 1fr" className={classes.mainGrid}>
                <Cell className={classes.mainGridCell}>
                    <GroupProfilePageHeader />
                </Cell>
                <Cell className={classes.mainGridCell}>
                    <GroupProfileTabs />
                </Cell>
                <Cell>
                    {tabsContent[currentTab as keyof typeof tabsContent]}
                </Cell>
            </Grid>
        </GroupInfoProvider>
    );
};

export default GroupProfilePage;