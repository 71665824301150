import React, { FormEvent } from 'react';
import { Group } from '../../../../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { TextField } from '@material-ui/core';

import GroupSettingsTabTemplate from '../GroupSettingsTabTemplate/GroupSettingsTabTemplate';

export interface GroupSettingsInfoProps {
    group: Group,
    onChange: (change: Partial<Group>) => void,
};
const GroupSettingsInfo = ({ group: { name, description, tagline }, onChange }: GroupSettingsInfoProps) => {
    const generateChangeHandler = (id: string) =>
        (e: FormEvent<HTMLDivElement>) => onChange({ [id]: (e.target as HTMLInputElement).value });

    return (
        <GroupSettingsTabTemplate title="Group Info">
            <Grid gap="2em" columns="1fr" rows="auto auto auto">
                <Cell>
                    <TextField variant="outlined" label="Group Name" value={name} onInput={generateChangeHandler('name')} />
                </Cell>
                <Cell>
                    <TextField variant="outlined" label="Tagline" value={tagline} onInput={generateChangeHandler('tagline')} />
                </Cell>
                <Cell>
                    <TextField size="small" variant="outlined" placeholder="Write a litle description about the group..."
                        multiline rows="8" value={description} onInput={generateChangeHandler('description')} />
                </Cell>
            </Grid>
        </GroupSettingsTabTemplate>
    );
};

export default GroupSettingsInfo;