import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { Group, GroupInviteeDetails, GroupMemberDetails, GroupMemberType } from '../../../../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core';

import { DownArrowIcon, UpArrowIcon, LeaveGroupIcon } from '../../../../../../../../utils/Icons';

import GroupSettingsTabTemplate from '../GroupSettingsTabTemplate/GroupSettingsTabTemplate';
import AccountInfoProvider from '../../../../../../../InfoProviders/AccountInfoProvider/AccountInfoProvider';
import SmallAccountRow from '../../../../../../../Templates/SmallAccountRow/SmallAccountRow';

import Loader from '../../../../../../../Design/Loader/Loader';

import { useAccountInfo } from '../../../../../../../../CustomHooks/useAccountInfo';
import { useGroupInfo } from '../../../../../../../../CustomHooks/useGroupInfo';
import { useDebounce } from '../../../../../../../../CustomHooks/useDebounce';

import {
    useAddGroupAdminMutation, useRevokeGroupMaintainerMutation, useAddGroupMaintainerMutation, useRemoveGroupMemberMutation, useCancelInviteMutation,
    useRevokeGroupAdminMutation
} from '../../../../../../../../utils/mutations/groupMutations';
import { useFetchValidation } from '../../../../../../../../utils/api/apiHelpers';
import { useGroupInviteesDetailsQuery, useGroupMembersDetailsQuery } from '../../../../../../../../utils/queries/groupsQueries';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        membersGrid: {
            '&[class*="Grid"]': {
                gridAutoRows: '4em',
            },
        },
        memberActionsGrid: {
            '&[class*="Grid"]': {
                height: '3.5em',
            },
        },
        memberArrowButtons: {
            minWidth: 'auto',
            height: '100%',
        },
        filterInput: {
            paddingBottom: '1em',
        },
    })
);

interface MemberRowProps {
    groupId: string,
    joinedAt: Date,
    type: GroupMemberType,
    isSaving?: boolean,
    setIsSaving: (value: boolean) => void,
    onChange: (change: Partial<Group>) => void,
};
const MemberRow = ({ groupId, joinedAt, type, isSaving: disabledButtons = false, setIsSaving: disableOtherRows, onChange }: MemberRowProps) => {
    const { accountInfo: { accountId } } = useAccountInfo();
    const { groupInfo: { admins, maintainers, members } } = useGroupInfo();

    const [isSaving, setIsSaving,] = useState(false);

    const postMutation = useCallback(() => {
        setIsSaving(false);
        disableOtherRows(false);
        onChange({} as Partial<Group>);
    }, [disableOtherRows, onChange]);

    const addGroupAdmin = useAddGroupAdminMutation({ onSettled: postMutation });
    const revokeGroupAdmin = useRevokeGroupAdminMutation({ onSettled: postMutation });
    const revokeGroupMaintainer = useRevokeGroupMaintainerMutation({ onSettled: postMutation });
    const addGroupMaintainer = useAddGroupMaintainerMutation({ onSettled: postMutation });
    const removeGroupMember = useRemoveGroupMemberMutation({ onSettled: postMutation });
    const cancelGroupInvite = useCancelInviteMutation({ onSettled: postMutation });

    const classes = useStyles();

    const arrowFunctionsMap = {
        admin: {
            up: addGroupAdmin,
            down: revokeGroupAdmin,
        },
        maintainer: {
            up: addGroupAdmin,
            down: revokeGroupMaintainer,
        },
        member: {
            up: addGroupMaintainer,
            down: removeGroupMember,
        },
        invitee: {
            up: cancelGroupInvite,
            down: cancelGroupInvite,
        },
    };

    const handleArrowClick = (direction: 'up' | 'down') => {
        disableOtherRows(true);
        setIsSaving(true);

        arrowFunctionsMap[type][direction].mutate({ groupId, accountId });
    };

    const handleUpClick = () => {
        handleArrowClick('up');
    };

    const handleDownClick = () => {
        handleArrowClick('down');
    };

    return (
        <SmallAccountRow>
            <Grid gap="4px" rows="1fr 1fr" columns="1fr" areas={["up", "down"]} className={classes.memberActionsGrid}>
                {
                    isSaving ?
                        <Loader />
                        :
                        <>
                            {
                                type !== "admin" && type !== "invitee" &&
                                <Cell area="up">
                                    <Button disabled={disabledButtons} variant="outlined" color="secondary" className={classes.memberArrowButtons} onClick={handleUpClick}>
                                        <UpArrowIcon width="1em" fill="white" />
                                    </Button>
                                </Cell>
                            }
                            {
                                (type !== "admin" || (admins.length > 1 || maintainers.length > 0 || members.length > 0)) &&
                                <Cell area="down">
                                    <Button disabled={disabledButtons} variant="outlined" color="secondary" className={classes.memberArrowButtons} onClick={handleDownClick}>
                                        {
                                            type !== "member" && type !== "invitee" ?
                                                <DownArrowIcon width="1em" fill="white" />
                                                :
                                                <LeaveGroupIcon width="1em" fill="white" />
                                        }
                                    </Button>
                                </Cell>
                            }
                        </>
                }
            </Grid>
        </SmallAccountRow>
    );
};

interface GroupSettingsMembersSectionProps {
    groupId: string,
    membersData?: GroupMemberDetails[] | GroupInviteeDetails[] | null,
    memberRowType: GroupMemberType,
    isLoading: boolean,
    isSaving: boolean,
    setIsSaving: (value: boolean) => void,
    onChange: (change: Partial<Group>) => void,
    emptyMsg?: string,
    title?: string,
};
const GroupSettingsMembersSection = ({ groupId, membersData, isLoading, isSaving, setIsSaving, onChange, emptyMsg, title, memberRowType }: GroupSettingsMembersSectionProps) => {
    const classes = useStyles();

    return (
        <GroupSettingsTabTemplate title={title} titleGap="1em">
            <Grid columns="1fr" className={classes.membersGrid}>
                {
                    membersData && !isLoading ?
                        (
                            membersData.length ?
                                (membersData as any[]).map(m => (
                                    <Cell key={m.username}>
                                        <AccountInfoProvider accountData={m}>
                                            <MemberRow groupId={groupId} joinedAt={(m as GroupMemberDetails).joinedAt || (m as GroupInviteeDetails).sentAt}
                                                type={memberRowType} isSaving={isSaving} setIsSaving={setIsSaving} onChange={onChange} />
                                        </AccountInfoProvider>
                                    </Cell>
                                ))
                                :
                                <Typography variant="body1">
                                    {emptyMsg}
                                </Typography>
                        )
                        :
                        <Loader />
                }
            </Grid>
        </GroupSettingsTabTemplate>
    );
};

export interface GroupSettingsMembersProps {
    group: Group,
    onChange: (change: Partial<Group>) => void,
    closeWindow?: (reloadAllGroups?: boolean) => void,
};
const GroupSettingsMembers = ({ group: { groupId, members, maintainers, admins }, onChange, closeWindow }: GroupSettingsMembersProps) => {
    const [isSaving, setIsSaving] = useState(false);

    const [filter, setFilter] = useState('');

    const { isLoading } = useGroupInfo();

    const handleFilterInput = useDebounce(
        useCallback((e: FormEvent<HTMLDivElement>) => {
            setFilter((e.target as HTMLInputElement).value);
        }, [])
    );

    const membersDetails = useFetchValidation(useGroupMembersDetailsQuery, { errorMsg: 'Error loading group members details', queryArgs: [groupId] });
    const inviteesDetailsQuery = useFetchValidation(useGroupInviteesDetailsQuery, { errorMsg: 'Error loading group invitees details', queryArgs: [groupId] });

    const classes = useStyles();

    const filterRegEx = useMemo(() => {
        try {
            return new RegExp(filter);
        }
        catch (e: unknown) {
            return new RegExp('');
        }
    }, [filter]);

    const filteredMembers = useMemo(() => membersDetails?.data?.filter(m => !admins.includes(m.username) && !maintainers.includes(m.username))
        .filter(m => filterRegEx.test(m.name) || filterRegEx.test(m.username)),
        [membersDetails, maintainers, admins, filterRegEx]
    );

    const maintainersDetails = useMemo(() => membersDetails ?
        maintainers?.map(m => membersDetails.data?.find(f => f.username === m) || {} as GroupMemberDetails)
            .filter(m => filterRegEx.test(m.name) || filterRegEx.test(m.username))
        : null,
        [maintainers, membersDetails, filterRegEx]
    );

    const adminsDetails = useMemo(() => membersDetails ?
        admins?.map(m => membersDetails.data?.find(f => f.username === m) || {} as GroupMemberDetails)
            .filter(m => filterRegEx.test(m.name) || filterRegEx.test(m.username))
        : null,
        [admins, membersDetails, filterRegEx]
    );

    const inviteesDetails = useMemo(() => membersDetails ?
        inviteesDetailsQuery.data?.filter(m => filterRegEx.test(m.name) || filterRegEx.test(m.username))
        : null,
        [inviteesDetailsQuery.data, membersDetails, filterRegEx]
    );

    return (
        <>
            <TextField variant="outlined" label="filter" size="small" className={classes.filterInput} onInput={handleFilterInput} />
            <GroupSettingsMembersSection groupId={groupId} title="Group Admins" emptyMsg="No admins assigned???" isLoading={isLoading} isSaving={isSaving}
                onChange={onChange} setIsSaving={setIsSaving} membersData={adminsDetails} memberRowType="admin" />
            <GroupSettingsMembersSection groupId={groupId} title="Group Maintainers" emptyMsg="No maintainers assigned" isLoading={isLoading} isSaving={isSaving}
                onChange={onChange} setIsSaving={setIsSaving} membersData={maintainersDetails} memberRowType="maintainer" />
            <GroupSettingsMembersSection groupId={groupId} title="Group Members" emptyMsg="No members yet..." isLoading={isLoading} isSaving={isSaving}
                onChange={onChange} setIsSaving={setIsSaving} membersData={filteredMembers} memberRowType="member" />
            <GroupSettingsMembersSection groupId={groupId} title="Group Invitees" emptyMsg="No pending invites" isLoading={isLoading} isSaving={isSaving}
                onChange={onChange} setIsSaving={setIsSaving} membersData={inviteesDetails} memberRowType="invitee" />
        </>
    );
};

export default GroupSettingsMembers;