export const israelCities = [`אום אל-פחם`, `אופקים`, `אור יהודה`, `אור עקיבא`, `אילת`, `אלעד`, `אריאל`, `אשדוד`, `אשקלון`, `באקה אל-גרביה`, `באר שבע`, `בית שאן`,
    `בית שמש`, `ביתר עילית`, `בני ברק`, `בת ים`, `גבעת שמואל`, `גבעתיים`, `דימונה`, `הוד השרון`, `הרצלייה`, `חדרה`, `חולון`, `חיפה`, `טבריה`, `טייבה`, `טירה`,
    `טירת כרמל`, `טמרה`, `יבנה`, `יהוד`, `יקנעם עילית`, `ירושלים`, `כפר יונה`, `כפר סבא`, `כפר קאסם`, `כרמיאל`, `לוד`, `מגדל העמק`, `מודיעין עילית`,
    `מודיעין-מכבים-רעות`, `מעלה אדומים`, `מעלות-תרשיחא`, `נהרייה`, `נוף הגליל`, `נס ציונה`, `נצרת`, `נשר`, `נתיבות`, `נתניה`, `סח'נין`, `עכו`, `עפולה`,
    `עראבה`, `ערד`, `פתח תקווה`, `צפת`, `קלנסווה`, `קריית אונו`, `קריית אתא`, `קריית ביאליק`, `קריית גת`, `קריית ים`, `קריית מוצקין`, `קריית מלאכי`,
    `קריית שמונה`, `ראש העין`, `ראשון לציון`, `רהט`, `רחובות`, `רמלה`, `רמת גן`, `רמת השרון`, `רעננה`, `שדרות`, `שפרעם`, `תל אביב -יפו`, `אבו גוש`,
    `אבו סנאן`, `אבן יהודה`, `אורנית`, `אזור`, `אכסאל`, `אליכין`, `אלפי מנשה`, `אלקנה`, `אעבלין`, `אפרת`, `באר יעקב`, `בועיינה-נוג'ידאת`, `בוקעאתא`,
    `ביר אל-מכסור`, `בית אל`, `בית אריה-עופרים`, `בית ג'ן`, `בית דגן`, `בני עי"ש`, `בנימינה-גבעת עדה*`, `בסמ"ה`, `בסמת טבעון`, `בענה`, `גבעת זאב`,
    `ג'דיידה-מכר`, `גדרה`, `ג'ולס`, `ג'לג'וליה`, `גן יבנה`, `גני תקווה`, `ג'סר א-זרקא`, `ג'ש (גוש חלב)`, `ג'ת`, `דאלית אל-כרמל`, `דבורייה`, `דייר אל-אסד`,
    `דייר חנא`, `הר אדר`, `זכרון יעקב`, `זמר`, `זרזיר`, `חורה`, `חורפיש`, `חצור הגלילית`, `חריש`, `טובא-זנגרייה`, `טורעאן`, `יאנוח-ג'ת`, `יבנאל`,
    `יסוד המעלה`, `יפיע`, `ירוחם`, `ירכא`, `כאבול`, `כאוכב אבו אל-היג'א`, `כוכב יאיר`, `כסיפה`, `כסרא-סמיע`, `כעביה-טבאש-חג'אג'רה`, `כפר ברא`, `כפר ורדים`,
    `כפר יאסיף`, `כפר כמא`, `כפר כנא`, `כפר מנדא`, `כפר קרע`, `כפר שמריהו`, `כפר תבור`, `להבים`, `לקיה`, `מבשרת ציון`, `מגאר`, `מג'ד אל-כרום`, `מגדל`,
    `מג'דל שמס`, `מזכרת בתיה`, `מזרעה`, `מטולה`, `מיתר`, `מסעדה`, `מעיליא`, `מעלה אפרים`, `מעלה עירון`, `מצפה רמון`, `משהד`, `נחף`, `סאג'ור`, `סביון`,
    `ע'ג'ר`, `עומר`, `עיילבון`, `עילוט`, `עין מאהל`, `עין קנייא`, `עמנואל`, `עספיא`, `ערערה`, `ערערה-בנגב`, `פוריידיס`, `פסוטה`, `פקיעין (בוקייעה)`,
    `פרדס חנה-כרכור`, `פרדסייה`, `קדומים`, `קדימה-צורן`, `קצרין`, `קריית ארבע`, `קריית טבעון`, `קריית יערים`, `קריית עקרון`, `קרני שומרון`, `ראמה`,
    `ראש פינה`, `ריינה`, `רכסים`, `רמת ישי`, `שבלי - אום אל-גנם`, `שגב-שלום`, `שוהם`, `שלומי`, `שעב`, `תל מונד`, `תל שבע`, `אל קסום`, `אל-בטוף`, `אלונה`,
    `אשכול`, `באר טוביה`, `בוסתן אל-מרג'`, `בני שמעון`, `ברנר`, `גדרות`, `גולן`, `גוש עציון`, `גזר`, `גן רווה`, `דרום השרון`, `הגלבוע`, `הגליל העליון`,
    `הגליל התחתון`, `הערבה התיכונה`, `הר חברון`, `זבולון`, `חבל אילות`, `חבל יבנה`, `חבל מודיעין`, `חוף אשקלון`, `חוף הכרמל`, `חוף השרון`, `יואב`,
    `לב השרון`, `לכיש`, `מבואות החרמון`, `מגידו`, `מגילות ים המלח`, `מטה אשר`, `מטה בנימין`, `מטה יהודה`, `מנשה`, `מעלה יוסף`, `מרום הגליל`, `מרחבים`,
    `משגב`, `נווה מדבר`, `נחל שורק`, `עמק הירדן`, `עמק המעיינות`, `עמק חפר`, `עמק יזרעאל`, `ערבות הירדן`, `רמת נגב`, `שדות דן`, `שדות נגב`, `שומרון`,
    `שער הנגב`, `שפיר`, `תמר`,
];

export const maxPostLength = 1000;

export const postReactionTypes = ['like', 'love', 'dislike', 'happy', 'funny', 'wow', 'angry', 'sad'] as const;