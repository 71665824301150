
import { UseMutationHandlerOptions } from "./types";

import { useMutationValidation } from "../api/apiHelpers";

import { generalSearch } from "../api/General";

export const useGeneralSearchMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    return useMutationValidation(generalSearch, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
        errorMsg: 'Search failed',
    });
};