import React, { useCallback, useRef, useState } from "react";
import { BadgeList } from "../../utils/types";

import { Cell, Grid } from "styled-css-grid";

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from "@material-ui/core";

import Sidebar from "../SideBar/Sidebar";
import TopBar from "../TopBar/TopBar";

import AuthCheck from "../AuthCheck/AuthCheck";
import LoginPage from "../LoginPage/LoginPage";
import LogoutPage from "../LogoutPage/LogoutPage";
import NewsfeedPage from "../NewsfeedPage/NewsfeedPage";
import PostPage from "../PostPage/PostPage";
import BadgesPage from "../BadgesPage/BadgesPage";
import ProfilePage from "../ProfilePage/ProfilePage";
import AccountHubPage from "../AccountHubPage/AccountHubPage";
import AccountsPage from "../AccountsPage/AccountsPage";
import GroupsPage from "../GroupsPage/GroupsPage";
import GroupProfilePage from "../GroupProfilePage/GroupProfilePage";
import QuestsPage from "../QuestsPage/QuestsPage";

import AccountInfoProvider from "../InfoProviders/AccountInfoProvider/AccountInfoProvider";

import { BadgesContext } from "../../context/BadgesContext";
import { BreakpointContext } from "../../context/BreakpointContext";

import { useAllBadgesQuery } from "../../utils/queries/badgesQueries";
import { useFetchValidation } from "../../utils/api/apiHelpers";

import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import CreateAccountPage from "../CreateAccountPage/CreateAccountPage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainGrid: {
            minHeight: '100%',
        },
        contentCell: {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '5em',
            boxSizing: 'border-box',
        },
    })
);

export interface Props {

}
const MainContainer = () => {
    const [authVerified, setAuthVerified] = useState(false);

    const breakpoint = useBreakpoint();

    const sideBarToggleButtonRef = useRef(null);

    const allBadgesQuery = useFetchValidation(useAllBadgesQuery, { errorMsg: 'Error fetching badges', options: { enabled: authVerified } });

    let classes = useStyles();

    const onVerifyAuth = useCallback(() => {
        setAuthVerified(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLogout = () => {
        setAuthVerified(false);
    };

    return (
        <BreakpointContext.Provider value={breakpoint}>
            <AccountInfoProvider enabled={authVerified} currentAccount>
                <BadgesContext.Provider value={allBadgesQuery.data as BadgeList}>
                    <Router>
                        <AuthCheck onVerify={onVerifyAuth} />
                        <Switch>
                            <Route path="/login">
                                <LoginPage onVerify={onVerifyAuth} />
                            </Route>
                            <Route path="/logout">
                                <LogoutPage onLogout={onLogout} />
                            </Route>
                            <Route path="/newAccount">
                                <CreateAccountPage />
                            </Route>
                            {
                                authVerified &&
                                <Route>
                                    <Grid gap="0" columns="auto 1fr" rows="5em 1fr" className={classes.mainGrid} areas={["appbar appbar", "sidebar content"]}>
                                        <Cell area="appbar">
                                            <TopBar sideBarToggleButton={sideBarToggleButtonRef} />
                                        </Cell>
                                        <Cell area="sidebar">
                                            <Sidebar toggleButton={sideBarToggleButtonRef.current} />
                                        </Cell>
                                        <Cell area="content" className={classes.contentCell}>
                                            <Switch>
                                                <Route path="/" exact>
                                                    <NewsfeedPage />
                                                </Route>
                                                <Route path="/profile/:userId?/:currentTab?">
                                                    <ProfilePage />
                                                </Route>
                                                <Route path="/newsfeed">
                                                    <NewsfeedPage />
                                                </Route>
                                                <Route path="/post/:postId">
                                                    <PostPage />
                                                </Route>
                                                <Route path="/groups/:groupName/:currentTab?">
                                                    <GroupProfilePage />
                                                </Route>
                                                <Route path="/groups">
                                                    <GroupsPage />
                                                </Route>
                                                <Route path="/accounts/:currentTab?">
                                                    <AccountsPage />
                                                </Route>
                                                <Route path="/badges">
                                                    <BadgesPage />
                                                </Route>
                                                <Route path="/quests">
                                                    <QuestsPage />
                                                </Route>
                                                <Route path="/events">
                                                    Events
                                                </Route>
                                                <Route path="/accounthub/:hubSection?/:hubSubsection?">
                                                    <AccountHubPage />
                                                </Route>
                                                <Route>
                                                    Page not found
                                                </Route>
                                            </Switch>
                                        </Cell>
                                    </Grid>
                                </Route>
                            }
                        </Switch>
                    </Router>
                </BadgesContext.Provider>
            </AccountInfoProvider>
        </BreakpointContext.Provider>
    );
};

export default MainContainer;