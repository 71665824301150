import React from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        questsGrid: {
            width: '100%',
            maxWidth: '75em',
            textAlign: 'initial',
        },
        subHeaderText: {
            fontWeight: 600,
        },
    })
);


export interface QuestPageSectionProps {
    subHeader?: string,
    title: string,
    children?: React.ReactNode,
};
const QuestPageSection = ({ title, subHeader, children }: QuestPageSectionProps) => {
    const classes = useStyles();

    return (
        <Grid columns="1fr" rows="auto 2em 1fr" className={classes.questsGrid}>
            <Cell>
                <Typography variant="caption" className={classes.subHeaderText}>
                    {subHeader}
                </Typography>
            </Cell>
            <Cell>
                <Typography variant="h5">
                    {title}
                </Typography>
            </Cell>
            <Cell>
                {children}
            </Cell>
        </Grid>
    );
};

export default QuestPageSection;