import React, { useMemo } from 'react';
import { Account } from '../../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { Card, CardActions, CardContent, CardHeader, TextField } from '@material-ui/core';
import { useBreakpoint } from '../../../../../../CustomHooks/useBreakpoint';

export interface ProfileInterestsProps {
    accountInfo: Account
    onChange: (id: string, value: any) => void,
};
const ProfileInterests = ({ accountInfo, onChange }: ProfileInterestsProps) => {
    const { index: breakpointIndex } = useBreakpoint();

    const singleColumnLayout = useMemo(() => breakpointIndex < 2,
        [breakpointIndex]
    );

    const createInputHandler = (id: string): React.FormEventHandler<HTMLDivElement> => {
        return (e: React.FormEvent<HTMLDivElement>) => onChange('interests', { ...accountInfo.interests, [id]: (e.target as HTMLInputElement).value });
    };

    return (
        <Card>
            <CardHeader title="Interests" />
            <CardContent>
                <Grid gap="1.5em" columns={singleColumnLayout ? '1fr' : "1fr 1fr"}>
                    <Cell>
                        <TextField value={accountInfo.interests?.tv || ''} variant="outlined" size="small" multiline rows="6" label="Favourite TV Shows"
                            onInput={createInputHandler('tv')} />
                    </Cell>
                    <Cell>
                        <TextField value={accountInfo.interests?.music || ''} variant="outlined" size="small" multiline rows="6" label="Favourite Music Bands/Artists"
                            onInput={createInputHandler('music')} />
                    </Cell>
                    <Cell>
                        <TextField value={accountInfo.interests?.movies || ''} variant="outlined" size="small" multiline rows="6" label="Favourite Movies"
                            onInput={createInputHandler('movies')} />
                    </Cell>
                    <Cell>
                        <TextField value={accountInfo.interests?.books || ''} variant="outlined" size="small" multiline rows="6" label="Favourite Books"
                            onInput={createInputHandler('books')} />
                    </Cell>
                    <Cell>
                        <TextField value={accountInfo.interests?.games || ''} variant="outlined" size="small" multiline rows="6" label="Favourite Games"
                            onInput={createInputHandler('games')} />
                    </Cell>
                    <Cell>
                        <TextField value={accountInfo.interests?.hobbies || ''} variant="outlined" size="small" multiline rows="6" label="My Hobbies"
                            onInput={createInputHandler('hobbies')} />
                    </Cell>
                </Grid>
            </CardContent>
            <CardActions>
            </CardActions>
        </Card>
    );
};

export default ProfileInterests;