import React from 'react';
import { Account } from '../../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import AccountProfileHexagon from '../../../../../AccountProfileHexagon/AccountProfileHexagon';

import AccountInfoProvider from '../../../../../InfoProviders/AccountInfoProvider/AccountInfoProvider';

import { FriendIcon } from '../../../../../../utils/Icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rowLink: {
            width: '100%',
        },
        rowGrid: {
            width: '100%',
        },
        nameText: {
            fontWeight: 700,
        },
    })
);

export interface SearchResultAccountRowProps {
    data: Account
};
const SearchResultAccountRow = ({ data: account }: SearchResultAccountRowProps) => {
    const classes = useStyles();

    return (
        <AccountInfoProvider accountData={account}>
            <Grid rowGap="0" columnGap="1em" columns="3em 1fr auto" rows="auto auto" areas={["icon name symbol", "icon mutualFriends symbol"]} className={classes.rowGrid}>
                <Cell area="icon">
                    <AccountProfileHexagon size="3em" levelSize="medium" />
                </Cell>
                <Cell area="name">
                    <Typography variant="body1" className={classes.nameText}>
                        {account.name}
                    </Typography>
                </Cell>
                <Cell area="mutualFriends">
                    <Typography variant="caption" className={classes.nameText}>
                        {`@${account.username}`}
                    </Typography>
                </Cell>
                <Cell area="symbol" className="center-vertical">
                    <FriendIcon width="1.5em" fill="var(--icon-fill)" />
                </Cell>
            </Grid>
        </AccountInfoProvider>
    );
};

export default SearchResultAccountRow;