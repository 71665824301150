import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { MenuLink } from '../types';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { createStyles, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core';

import { Link } from 'react-router-dom';

import { menuLinks } from '../Sidebar';
import TopBarIcon from '../../TopBar/components/TopBarIcon/TopBarIcon';
import SettingsMenu from '../../TopBar/components/SettingsMenu/SettingsMenu';
import SmallMenuHeader from '../../SmallMenuHeader/SmallMenuHeader';
import LogoutButton from '../../LogoutButton/LogoutButton';

import { HamburgerMenuIcon } from '../../../utils/Icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawerRoot: {
            height: '100%',
            whiteSpace: 'nowrap',
            color: 'white',
        },
        drawerPaper: {
            backgroundColor: 'var(--content-bg)',
            color: 'var(--icon-fill)',
            fill: 'var(--icon-fill)',
            top: 'unset',
            paddingTop: '0.5em',
        },
        drawerWidth: {
            width: '19em',
        },
        sidebarContainer: {
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        headerCell: {
            paddingLeft: '2.5em',
        },
        logoutButtonCell: {
            paddingTop: '1em',
            paddingLeft: '2.5em',
            paddingBottom: '1em',
        },
        sectionsTitleCell: {
            paddingLeft: '2.5em',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
        },
        sectionsTitle: {
            fontWeight: 700,
            color: 'var(--text-label-color)',
        },
        menuLink: {
            paddingLeft: '1.5em',
            paddingRight: '1.5em',
        },
        menuListItemRoot: {
            marginTop: '0.25em',
            marginBottom: '0.25em',
            height: '3em',
            borderRadius: '0.5em',
            '&:hover, &:focus': {
                backgroundColor: 'var(--icon-bg-hover)',
                color: 'var(--icon-fill-hover)',
                fill: 'var(--icon-fill-hover)',
                '& .menuLinkIcon': {
                    transform: 'translateX(-0.3em)',
                },
            },
        },
        menuListItemIconRoot: {
            color: 'inherit',
            minWidth: 'auto',
            width: '1.25em',
            height: '1.25em',
            transition: 'all 0.2s linear',
        },
        menuItemText: {
            color: 'white',
            left: '120%',
            marginLeft: '1em',
            fontSize: '0.875em',
            fontWeight: 700,
        },
        settingsMenuCell: {
            paddingLeft: '1em',
            fontSize: '1.1em',
        },
    }));

export interface MenuLinkItemProps extends MenuLink {
    onClick?: () => void
};
const MenuLinkItem = ({ title, icon, target, onClick }: MenuLinkItemProps) => {
    let classes = useStyles();

    return (
        <Link to={target} className={clsx(classes.menuLink, "center-vertical")} tabIndex={-1} onClick={onClick}>
            <ListItem button classes={{ root: clsx(classes.menuListItemRoot) }}>
                <ListItemIcon classes={{ root: clsx(classes.menuListItemIconRoot, "menuLinkIcon") }}>
                    {icon}
                </ListItemIcon>
                <ListItemText className={clsx("menuLinkText", "center-vertical")}>
                    <Typography variant="body1" className={classes.menuItemText}>
                        {title}
                    </Typography>
                </ListItemText>
            </ListItem>
        </Link>
    );
};

export interface DrawerToggleProps {
    toggleButton: HTMLElement | null,
    onClick: () => void,
}
const DrawerToggle = ({ toggleButton, onClick }: DrawerToggleProps) => {
    return toggleButton ? createPortal(
        <TopBarIcon onClick={onClick}>
            <HamburgerMenuIcon />
        </TopBarIcon>,
        toggleButton
    ) : null;
};

export interface DrawerSideBarProps {
    toggleButton: HTMLElement | null,
}
const DrawerSideBar = ({ toggleButton }: DrawerSideBarProps) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const classes = useStyles();

    const openDrawer = () => {
        setDrawerOpen(true);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    return (
        <>
            <DrawerToggle toggleButton={toggleButton} onClick={openDrawer} />
            <Drawer open={drawerOpen} variant="temporary" className={clsx(classes.drawerRoot, classes.drawerWidth)} onClose={closeDrawer}
                classes={{ paper: clsx(classes.drawerPaper, classes.drawerWidth) }}>
                <Grid gap="0" rows="3.5em auto auto auto 1fr" columns="1fr" className={classes.sidebarContainer} areas={["avatar", "logout", "menuTitle", "menu"]}>
                    <Cell area="avatar" className={classes.headerCell}>
                        <SmallMenuHeader onClose={closeDrawer} />
                    </Cell>
                    <Cell className={classes.logoutButtonCell}>
                        <LogoutButton />
                    </Cell>
                    <Cell area="menuTitle" className={classes.sectionsTitleCell}>
                        <Typography variant="overline" className={classes.sectionsTitle}>
                            Sections
                        </Typography>
                    </Cell>
                    <Cell area="menu">
                        <List classes={{ root: "" }}>
                            {
                                menuLinks.map(menuLink => <MenuLinkItem key={menuLink.title} {...menuLink} onClick={closeDrawer} />)
                            }
                        </List>
                    </Cell>
                    <Cell className={classes.settingsMenuCell}>
                        <SettingsMenu onClose={closeDrawer} showHeader={false} showLogout={false} />
                    </Cell>
                </Grid>
            </Drawer>
        </>
    );
};

export default DrawerSideBar;