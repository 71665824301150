import React, { useMemo } from 'react';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Card, CardContent, CardHeader, createStyles, Divider, makeStyles, Theme, Typography } from '@material-ui/core';

import Circle from '../../../../../Design/Circle/Circle';

import { useAccountInfo } from '../../../../../../CustomHooks/useAccountInfo';
import { useBadges } from '../../../../../../CustomHooks/useBadges';
import { baseBadgesImageUrl, baseQuestsImageUrl } from '../../../../../../utils/api/apiConsts';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        completionStatText: {
            fontSize: '0.875em',
            fontWeight: 700,
        },
        completionDivider: {
            paddingRight: '1em',
            paddingLeft: '1em',
        },
    })
);

interface CompletionStatProps {
    current: number,
    total: number,
    type: string,
    action: string,
    icon: string,
};
const CompletionStat = ({ current = 0, total = 0, type, action, icon }: CompletionStatProps) => {
    const classes = useStyles();

    return (
        <Grid columns="1fr" rows="2em 4em 2em">
            <Cell>
                <Typography variant="h5">
                    {`${current} / ${total}`}
                </Typography>
            </Cell>
            <Cell>
                <Typography variant="body1" className={classes.completionStatText}>
                    {type}
                </Typography>
                <Typography variant="caption" className={classes.completionStatText}>
                    {action}
                </Typography>
            </Cell>
            <Cell>
                <img src={icon} alt="unlockedBadge" />
            </Cell>
        </Grid>
    );
};

const ProfilePageAboutCompletionSection = () => {
    const { accountInfo: { badges: accountBadges } } = useAccountInfo();
    const badges = useBadges();

    const classes = useStyles();

    const profileProgress = useMemo(() => Math.round((accountBadges?.length / badges.length) * 100),
        [accountBadges, badges]
    );

    return (
        <Card>
            <CardHeader title="Completion" />
            <CardContent>
                <Grid columns="1fr" rows="repeat(4, auto)">
                    <Cell className="center-horizontal">
                        <Circle progress={profileProgress} />
                    </Cell>
                    <Cell className="center-horizontal">
                        <Typography variant="h6">
                            Profile Completion
                        </Typography>
                    </Cell>
                    <Cell className="center-horizontal">
                        <Typography variant="body1">
                            {`Complete your profile by completing quests & unlocking badges`}
                        </Typography>
                    </Cell>
                    <Cell className="center-horizontal">
                        <Grid columns="1fr auto 1fr" rows="1fr">
                            <Cell>
                                <CompletionStat type="Quests" action="COMPLETED" current={0} total={10}
                                    icon={`${baseQuestsImageUrl}/completedQuest`} />
                            </Cell>
                            <Cell className={clsx(classes.completionDivider, 'center-vertical-soft')}>
                                <Divider orientation="vertical" />
                            </Cell>
                            <Cell>
                                <CompletionStat type="Badges" action="UNLOCKED" current={accountBadges?.length} total={badges.length}
                                    icon={`${baseBadgesImageUrl}/unlockedBadge`} />
                            </Cell>
                        </Grid>
                    </Cell>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ProfilePageAboutCompletionSection;