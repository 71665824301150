import React, { useContext } from 'react';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import { Cell, Grid } from 'styled-css-grid';

import { baseBannerPageImages } from '../../utils/api/apiConsts';

import { BreakpointContext } from '../../context/BreakpointContext';

export interface BannerHeaderStyleParams {
    breakpointIndex: number | undefined,
};
const useStyles = makeStyles((theme: Theme) => createStyles({
    bannerHeaderMainGrid: {
        background: `url(${baseBannerPageImages}/banner-bg) no-repeat 50%`,
        '&[class*="Grid"]': {
            height: ({ breakpointIndex }: BannerHeaderStyleParams) => breakpointIndex != null && breakpointIndex > 2 ? '10em' : '100%',
        },
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '1em',
        maxWidth: '74em',
    },
    bannerHeaderIconCell: {
        width: ({ breakpointIndex }: BannerHeaderStyleParams) => breakpointIndex != null && breakpointIndex > 2 ? '11em' : 'auto',
        height: ({ breakpointIndex }: BannerHeaderStyleParams) => breakpointIndex != null && breakpointIndex > 2 ? '10em' : 'auto',
        position: 'relative',
    },
    bannerHeaderIcon: {
        height: ({ breakpointIndex }: BannerHeaderStyleParams) => breakpointIndex != null && breakpointIndex > 2 ? 'auto' : '100%',
        width: ({ breakpointIndex }: BannerHeaderStyleParams) => breakpointIndex != null && breakpointIndex > 2 ? '11.5em' : '5.5em',
        position: ({ breakpointIndex }: BannerHeaderStyleParams) => breakpointIndex != null && breakpointIndex > 2 ? 'absolute' : 'static',
        bottom: ({ breakpointIndex }: BannerHeaderStyleParams) => breakpointIndex != null && breakpointIndex > 2 ? '0' : undefined,
        left: ({ breakpointIndex }: BannerHeaderStyleParams) => breakpointIndex != null && breakpointIndex > 2 ? '0' : undefined,
    },
}));

export interface BannerHeaderProps {
    title?: string,
    subtitle?: string,
    iconUrl?: string,
};
const BannerHeader = ({ title, subtitle, iconUrl }: BannerHeaderProps) => {
    const { index: breakpointIndex } = useContext(BreakpointContext);

    const classes = useStyles({ breakpointIndex });

    return (
        <Grid gap="0" columnGap="1em" columns="auto 1fr" rows="1fr" areas={["icon title"]} className={classes.bannerHeaderMainGrid}>
            <Cell area="icon" className={classes.bannerHeaderIconCell}>
                <img src={iconUrl} alt="banner-icon" className={classes.bannerHeaderIcon} />
            </Cell>
            <Cell area="title" className='center-vertical'>
                <Typography variant="h5" className="font-weight-700">
                    {title}
                </Typography>
                <Typography variant="body2" className="font-weight-500">
                    {subtitle}
                </Typography>
            </Cell>
        </Grid>
    );
};

export default BannerHeader;