import React from 'react';
import { Post, PostReactionType } from '../../../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';
import clsx from 'clsx';

import { CircularProgress, createStyles, IconButton, makeStyles, Popover, PopoverProps, Theme, Tooltip } from '@material-ui/core';

import { postReactionTypes } from '../../../../../../../utils/consts';
import { useReactToPostMutation } from '../../../../../../../utils/mutations/postsMutation';
import { getReactionImageUrl } from '../../../../../../../utils/api/Posts';

import { usePostInfo } from '../../../../../../../CustomHooks/usePostInfo';

export interface PostReactionsMenuStyleProps {
    size?: 'small' | 'medium',
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            backgroundColor: 'var(--icon-bg-hover)',
            borderRadius: '5em',
            padding: ({ size }: PostReactionsMenuStyleProps) => size === "small" ? '0.25em' : '0.75em',
        },
        reactionsButtonCell: {
            '& .reactionButton': {
                position: 'relative',
            },
            '&:hover .reactionButton': {
                transform: ({ size }: PostReactionsMenuStyleProps) => size === "small" ? 'translateY(-0.25em);' : 'translateY(-0.5em);',
            },
        },
        ReactionButtonImage: {
            width: ({ size }: PostReactionsMenuStyleProps) => size === "small" ? '1.5em' : '',
        },
        loaderCircle: {
            width: ({ size }: PostReactionsMenuStyleProps) => size === "small" ? '1.5em !important' : '',
            height: ({ size }: PostReactionsMenuStyleProps) => size === "small" ? '1.5em !important' : '',
        },
    })
);

interface ReactionButtonProps {
    reaction: PostReactionType
    onClick?: () => void,
    size?: 'small' | 'medium',
};
const ReactionButton = ({ reaction, onClick, size }: ReactionButtonProps) => {
    const { post: { _id: postId } = {} as Post, fatherPost: { _id: fatherPostId } = {} as Post } = usePostInfo();

    const reactToPost = useReactToPostMutation();

    const classes = useStyles({ size });

    const handleReactionButtonClick = () => {
        reactToPost.mutate({ postId, reaction, fatherPostId: fatherPostId }, {
            onSettled: onClick
        });
    };

    return reactToPost.isLoading ?
        (
            <CircularProgress classes={{ root: classes.loaderCircle }} />
        )
        :
        (
            <Tooltip title={reaction} placement="top">
                <IconButton size="small" classes={{ root: "reactionButton" }} onClick={handleReactionButtonClick}>
                    <img src={getReactionImageUrl(reaction)} alt={reaction} className={classes.ReactionButtonImage} />
                </IconButton>
            </Tooltip>
        );
};

export interface PostReactionsMenuContentProps {
    onReactionClick?: () => void,
    size?: 'small' | 'medium',
};
export const PostReactionsMenuContent = ({ onReactionClick, size }: PostReactionsMenuContentProps) => {
    const classes = useStyles({ size });

    return (
        <Grid gap={size === "small" ? '0' : '0.5em'} rows="1fr" columns={`repeat(${postReactionTypes.length}, 1fr)`}>
            {
                postReactionTypes.map(r => (
                    <Cell key={r} className={clsx("center-vertical center-horizontal", classes.reactionsButtonCell)}>
                        <ReactionButton reaction={r} onClick={onReactionClick} size={size} />
                    </Cell>
                ))
            }
        </Grid>
    );
};

export interface PostReactionsMenuProps extends PopoverProps {
    closeMenu?: () => void,
    size?: 'small' | 'medium',
};
const PostReactionsMenu = ({ children, closeMenu, size, ...others }: PostReactionsMenuProps) => {
    const classes = useStyles({ size });

    return (
        <Popover
            classes={{ paper: classes.paperRoot }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={closeMenu}
            {...others}
        >
            <PostReactionsMenuContent onReactionClick={closeMenu} size={size} />
            {children}
        </Popover>
    );
};

export default PostReactionsMenu;