import React, { useMemo, useState } from 'react';
import { Post } from '../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';

import PostInfoProvider from '../../InfoProviders/PostInfoProvider/PostInfoProvider';
import AccountInfoProvider from '../../InfoProviders/AccountInfoProvider/AccountInfoProvider';

import PostCardHeader from './components/PostCardHeader/PostCardHeader';
import PostCardDetails from './components/PostCardDetails/PostCardDetails';
import PostCardActions from './components/PostCardActions/PostCardActions';
import PostCardCommentsList from './components/PostCardCommentsList/PostCardCommentsList';
import CreateComment from './components/CreateComment/CreateComment';
import UpdatePostEditor from '../UpdatePostEditor/UpdatePostEditor';

import { useCountPostCommentsQuery } from '../../../utils/queries/postsQueries';
import PostCardPoll from './components/PostCardPoll/PostCardPoll';
import TextEditorInput from '../TextEditorInput/TextEditorInput';

export interface PostCardStyleProps {
    shared: boolean,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            borderRadius: '1em',
            border: ({ shared }: PostCardStyleProps) => shared ? '1px solid var(--content-inside-border-color)' : '',
        },
        postMainGrid: {
            textAlign: 'initial',
            borderRadius: '0 0 1em 1em',
            '& > div:last-child': {
                borderRadius: '0 0 1em 1em',
            },
        },
        postHeaderCell: {
            paddingTop: '1.5em',
            paddingRight: '1.5em',
            paddingLeft: '1.5em',
        },
        postContentCell: {
            paddingRight: '1.5em',
            paddingLeft: '1.5em',
        },
        postDetailsCell: {
            paddingRight: '1.5em',
            paddingLeft: '1.5em',
        },
        postActionsCell: {
            backgroundColor: 'var(--content-secondary-bg)',
            borderTop: '1px solid var(--content-inside-border-color)',
            borderBottom: '1px solid var(--content-inside-border-color)',
            boxSizing: 'border-box',
            paddingRight: '1.5em',
            paddingLeft: '1.5em',
            paddingBottom: '0.5em',
            paddingTop: '0.5em',
        },
    })
);

export interface PostCardProps {
    post: Post,
    defaultCommentsOpen?: boolean,
    shared?: boolean,
};
const PostCard = ({ post, defaultCommentsOpen = false, shared = false }: PostCardProps) => {
    const { text, _id: postId, shareSource, pollDefinition } = post;
    const [commentsOpen, setCommentsOpen] = useState(defaultCommentsOpen);
    const [commentEditorOpen, setCommentEditorOpen] = useState(false);
    const [editPost, setEditPost] = useState(false);

    const { data: commentsCount = 0 } = useCountPostCommentsQuery(post._id);

    const showComments = useMemo(() => commentsOpen && commentsCount > 0,
        [commentsCount, commentsOpen]
    );

    const classes = useStyles({ shared });

    const toggleComments = () => {
        setCommentsOpen(!commentsOpen);
    };

    const toggleCommentEditor = () => {
        setCommentEditorOpen(!commentEditorOpen);
    };

    const commentPosted = () => {
        toggleCommentEditor();
        setCommentsOpen(true);
    };

    const toggleEditPost = () => {
        setEditPost(!editPost);
    };

    return (
        <PostInfoProvider post={post}>
            <AccountInfoProvider username={post.writer.username}>
                <Paper classes={{ root: classes.paperRoot }}>
                    <Grid rowGap="1.5em" className={classes.postMainGrid} columns="1fr" rows={`auto 1fr auto ${shareSource ? 'auto' : ''} 4em ${showComments ? 'auto' : ''}`}
                        areas={["header", "content", ...shareSource ? ["shareSource"] : [], "details", "actions", ...commentEditorOpen ? ["createComment"] : [], ...showComments ? ["comments"] : []]} >
                        <Cell area="header" className={classes.postHeaderCell}>
                            <PostCardHeader post={post} toggleEditPost={toggleEditPost} shared={shared} />
                        </Cell>
                        <Cell area="content" className={classes.postContentCell}>
                            {
                                editPost ?
                                    <UpdatePostEditor onClose={toggleEditPost} />
                                    :
                                    <>
                                        <TextEditorInput readOnly value={text} />
                                        {
                                            // text?.split('\n').map((line, idx) => (
                                            //     <Typography key={idx} variant="body1">
                                            //         {/* NOT A SPACE!!! alt code 0160 */}
                                            //         {line || ` `}
                                            //     </Typography>
                                            // ))
                                        }
                                        {
                                            pollDefinition &&
                                            <PostCardPoll />
                                        }
                                    </>
                            }
                        </Cell>
                        {
                            shareSource &&
                            <Cell area="shareSource" className={classes.postContentCell}>
                                <PostCard post={shareSource as Post} shared />
                            </Cell>
                        }
                        {
                            !shared &&
                            <>
                                <Cell area="details" className={classes.postDetailsCell}>
                                    <PostCardDetails toggleComments={toggleComments} />
                                </Cell>
                                <Cell area="actions" className={classes.postActionsCell}>
                                    <PostCardActions toggleCreateComment={toggleCommentEditor} />
                                </Cell>
                                {
                                    commentEditorOpen &&
                                    <Cell area="createComment">
                                        <CreateComment postId={postId} onClose={commentPosted} />
                                    </Cell>
                                }
                                {
                                    showComments &&
                                    <Cell area="comments">
                                        <PostCardCommentsList />
                                    </Cell>
                                }
                            </>
                        }
                    </Grid>
                </Paper>
            </AccountInfoProvider>
        </PostInfoProvider>
    );
};

export default PostCard;