import React from 'react';

import BannerPageTemplate from '../Templates/BannerPageTemplate/BannerPageTemplate';

import PostList from '../PostList/PostList';

import { useGetAllPostsQuery } from '../../utils/queries/postsQueries';
import { baseBannerPageImages } from '../../utils/api/apiConsts';

const NewsfeedPage = () => {
    const { data: postsQueryData, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useGetAllPostsQuery();

    return (
        <BannerPageTemplate centerContent iconUrl={`${baseBannerPageImages}/newsfeed-icon`} title="Newsfeed" subtitle="Check what your friends have been up to!">
            <PostList locationType="Account" data={postsQueryData} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage} isLoading={isLoading} refetch={refetch} />
        </BannerPageTemplate>
    );
};

export default NewsfeedPage;