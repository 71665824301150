import React from 'react';
import { Post } from '../../../../../../../utils/types';

import { createStyles, List, ListItem, makeStyles, Theme, Typography } from '@material-ui/core';

import Loader from '../../../../../../Design/Loader/Loader';

import { useDeletePostMutation } from '../../../../../../../utils/mutations/postsMutation';
import { useCurrentAccountInfo } from '../../../../../../../CustomHooks/useAccountInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        settingsListItemText: {
            fontSize: '0.75em',
            fontWeight: 700,
        },
    })
);

export interface PostSettingsMenuProps {
    post: Post,
    onClose?: () => void,
    toggleEditPost?: () => void,
};
const PostSettingsMenu = ({ post, onClose, toggleEditPost }: PostSettingsMenuProps) => {
    const { _id: postId, writer: { accountId: writerAccountId } } = post;

    const { accountInfo: { accountId: currentAccountId } } = useCurrentAccountInfo();

    const deletePost = useDeletePostMutation();

    const classes = useStyles();

    const handleDeletePost = () => {
        deletePost.mutate({ postId }, {
            onSettled: () => {
                onClose && onClose();
            }
        });
    };

    const handleEditPost = () => {
        toggleEditPost && toggleEditPost();
        onClose && onClose();
    };

    const settingsMenuActions = [
        ...(
            writerAccountId === currentAccountId ?
                [{
                    label: 'Edit post',
                    action: handleEditPost,
                }]
                :
                []
        ),
        {
            label: 'Delete post',
            action: handleDeletePost,
        },
    ];

    return deletePost.isLoading ?
        <Loader />
        :
        (
            <List>
                {
                    settingsMenuActions.map(actionDefinition => (
                        <ListItem key={actionDefinition.label} button onClick={actionDefinition.action}>
                            <Typography variant="body1" className={classes.settingsListItemText}>
                                {actionDefinition.label}
                            </Typography>
                        </ListItem>
                    ))
                }
            </List>
        );
};

export default PostSettingsMenu;