import React, { useMemo } from 'react';

import { MenuLink } from './types';

import PermanentSideBar from './PermanentSideBar/PermanentSideBar';
import DrawerSideBar from './DrawerSideBar/DrawerSideBar';

import { AvatarIcon, BadgesIcon, EventsIcon, GroupsIcon, NewsfeedIcon, QuestsIcon } from '../../utils/Icons';

import { useBreakpoint } from '../../CustomHooks/useBreakpoint';

export const menuLinks: MenuLink[] = [
    {
        title: 'Newsfeed',
        icon: <NewsfeedIcon />,
        target: '/newsfeed'
    },
    {
        title: 'Groups',
        icon: <GroupsIcon />,
        target: '/groups'
    },
    {
        title: 'Accounts',
        icon: <AvatarIcon />,
        target: '/accounts'
    },
    {
        title: 'Badges',
        icon: <BadgesIcon />,
        target: '/badges'
    },
    {
        title: 'Quests',
        icon: <QuestsIcon />,
        target: '/quests'
    },
    {
        title: 'Events',
        icon: <EventsIcon />,
        target: '/events'
    },
];

export interface SidebarProps {
    toggleButton: HTMLElement | null,
}
const Sidebar = ({ toggleButton }: SidebarProps) => {
    const { index: breakpointIndex } = useBreakpoint();

    const MenuComp = useMemo(() => {
        if (breakpointIndex == null)
            return PermanentSideBar;

        if (breakpointIndex >= 2)
            return PermanentSideBar;
        else
            return DrawerSideBar;
    }, [breakpointIndex]);

    return (
        <MenuComp toggleButton={toggleButton} />
    );
};

export default Sidebar;