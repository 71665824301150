import { useCallback, useEffect, useRef } from "react";

export const useDebounce = <A extends unknown[]>(func: (...args: A) => void, waitTime = 500) => {
    const timeoutRef = useRef<number>();

    const debouncedFunc = useCallback((...args: A) => {
        if (timeoutRef.current)
            clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout((...args: A) => {
            func(...args);
        }, waitTime, ...args);
    }, [func, waitTime]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current)
                clearTimeout(timeoutRef.current);
        };
    }, []);

    return debouncedFunc;
};