import { useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from "react-query";
import { BasePost, Comment, Post } from "../types";

import { getAllPosts, getPostComments, countPostComments, getGroupPosts, getPost, getMyPollAnswer, getPollResults } from "../api/Posts";
import { GetAllPostsReturn, PollResultReturn, PostCommentsReturn } from "../MockData";

const parsePost = (post: BasePost | Post | Comment) => {
    post.date = new Date(post.date);
    if (post.editedAt)
        post.editedAt = new Date(post.editedAt);

    if ("shareSource" in post && post.shareSource)
        (post.shareSource as Post).date = new Date((post.shareSource as Post).date);

    if (post.reactions?.length)
        post.reactions.forEach(r => {
            r.date = new Date(r.date);
        });

    if ("pollDefinition" in post && post.pollDefinition?.expirationDate)
        post.pollDefinition.expirationDate = new Date(post.pollDefinition?.expirationDate);
};

export const useGetPostQuery = (postId: string, queryOptions?: UseQueryOptions<Post>) => {
    return useQuery(['posts', postId], () => getPost(postId), {
        ...queryOptions,
        select: (data) => {
            parsePost(data);
            return data;
        },
    });
};

export const useGetAllPostsQuery = (queryOptions?: UseInfiniteQueryOptions<GetAllPostsReturn>) => {
    return useInfiniteQuery(['posts', 'all'], ({ pageParam: page = 0 }) => getAllPosts(page), {
        ...queryOptions,
        onSuccess: (posts) => {
            posts.pages.forEach(page => page.posts.forEach(parsePost));

            queryOptions?.onSuccess?.(posts);
        },
        getNextPageParam: (lastPage, allPages) => lastPage.more ? allPages.length : undefined,
    });
};

export const useGetGroupPostsQuery = (groupId: string, queryOptions?: UseInfiniteQueryOptions<GetAllPostsReturn>) => {
    return useInfiniteQuery(['posts', groupId], ({ pageParam: page = 0 }) => getGroupPosts({ groupId, page }), {
        ...queryOptions,
        onSuccess: (posts) => {
            posts.pages.forEach(page => page.posts.forEach(parsePost));

            queryOptions?.onSuccess?.(posts);
        },
        getNextPageParam: (lastPage, allPages) => lastPage.more ? allPages.length : undefined,
    });
};

export const useGetPostCommentsQuery = (postId: string, queryOptions?: UseInfiniteQueryOptions<PostCommentsReturn>) => {
    return useInfiniteQuery(['posts', postId, 'comments'], ({ pageParam: page = 0 }) => getPostComments({ postId, page }), {
        ...queryOptions,
        getNextPageParam: (lastPage, allPages) => lastPage.more ? allPages.length : undefined,
        onSuccess: (posts) => {
            posts.pages[posts.pages.length - 1].comments.forEach(parsePost);

            queryOptions?.onSuccess?.(posts);
        },
    });
};

export const useCountPostCommentsQuery = (postId: string, queryOptions?: UseQueryOptions<number>) => {
    return useQuery(['posts', postId, 'comments', 'count'], () => countPostComments(postId), {
        ...queryOptions,
    });
};

export const useGetMyPollAnswerQuery = (postId: string, queryOptions?: UseQueryOptions<number>) => {
    return useQuery(['posts', postId, "poll", "myVote"], () => getMyPollAnswer(postId), {
        ...queryOptions,
    });
};

export const useGetPollResultsQuery = (postId: string, queryOptions?: UseQueryOptions<PollResultReturn[]>) => {
    return useQuery(['posts', postId, "poll", "results"], () => getPollResults(postId), {
        ...queryOptions,
    });
};