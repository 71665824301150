import React from 'react';
import { Post } from '../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';

import PostTagChip from '../PostTagChip/PostTagChip';
import PostCardReactions from './components/PostCardReactions/PostCardReactions';

import { usePostInfo } from '../../../../../CustomHooks/usePostInfo';

import { useCountPostCommentsQuery } from '../../../../../utils/queries/postsQueries';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        lowerCell: {
            paddingTop: '0.5em',
            borderTop: '1px solid var(--content-inside-border-color)',
        },
        tagCell: {
            display: 'flex',
            gridColumnGap: '0.5em',
            gridRowGap: '0.2em',
        },
        tagChip: {
            backgroundColor: 'var(--button-secondary-bg)',
            '&:hover, &:focus': {
                backgroundColor: 'var(--button-secondary-bg-hover)',
            },
        },
    })
);

export interface PostCardDetailsProps {
    toggleComments: () => void,
};
const PostCardDetails = ({ toggleComments }: PostCardDetailsProps) => {
    const { post: { tags, _id: postId } = {} as Post } = usePostInfo() as { post: Post };

    const { data: commentsCount, isLoading: loadingCommentsCount } = useCountPostCommentsQuery(postId);

    const classes = useStyles();

    return (
        <Grid rowGap="1em" columns="1fr" rows="auto auto">
            <Cell className={classes.tagCell}>
                {tags?.map(t => (
                    <PostTagChip key={t} label={t} clickable />
                ))}
            </Cell>
            <Cell className={classes.lowerCell}>
                <Grid rows="1fr" columns="1fr auto">
                    <Cell className="center-vertical">
                        <PostCardReactions />
                    </Cell>
                    <Cell>
                        <Button color="primary" classes={{ root: 'minimal' }} onClick={toggleComments}>
                            {`${loadingCommentsCount ? '?' : commentsCount} comments`}
                        </Button>
                    </Cell>
                </Grid>
            </Cell>
        </Grid>
    );
};

export default PostCardDetails;