import { useQuery, UseQueryOptions } from "react-query";
import { Badge, BadgeList } from "../types";

import { getBadgeIconUrl, getAllBadges } from "../api/Badge";

export const useAllBadgesQuery = (queryOptions?: UseQueryOptions<BadgeList>) => {
    return useQuery(['badges', 'all'], () => getAllBadges(), {
        select: (data) => {
            data.forEach((b: Badge) => {
                b.iconUrl = getBadgeIconUrl(b.badgeId);
            });

            return data;
        },
        ...queryOptions
    });
};