import React, { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GeneralSearchResult, Group } from '../../../../utils/types';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Link } from 'react-router-dom';

import { createStyles, List, ListItem, makeStyles, TextField, Theme, Typography } from '@material-ui/core';

import Loader from '../../../Design/Loader/Loader';

import SearchResultAccountRow from './components/SearchResultAccountRow/SearchResultAccountRow';
import SearchResultGroupRow from './components/SearchResultGroupRow/SearchResultGroupRow';

import { SearchIcon } from '../../../../utils/Icons';

import { useDebounce } from '../../../../CustomHooks/useDebounce';

import { useGeneralSearchMutation } from '../../../../utils/mutations/generalSearch';

export interface GeneralSearchFieldStyleProps {
    size?: 'small' | 'large',
};
const useStyles = makeStyles((theme: Theme) => createStyles({
    containerDiv: {
        height: '100%',
    },
    textFieldRoot: {
        height: ({ size }: GeneralSearchFieldStyleProps) => size === 'small' ? '100%' : '3.25em',
        backgroundColor: 'var(--general-search-bg)',
        borderRadius: ({ size }: GeneralSearchFieldStyleProps) => size === "small" ? '0' : '1em',
        '& > div': {
            height: '100%',
        },
        '& fieldset': {
            border: 'none',
        },
        '& input': {
            boxSizing: 'border-box',
        },
    },
    searchResultWindow: {
        position: 'absolute',
        left: '0',
        right: '0',
        maxWidth: '40em',
        backgroundColor: 'var(--content-bg)',
        borderRadius: '1em',
        padding: '1em',
        boxSizing: 'border-box',
        transition: 'all 0.3s ease-in-out',

        opacity: '0',
        top: '1em',
        pointerEvents: 'none',
    },
    searchResultWindowOpen: {
        pointerEvents: 'auto',
        opacity: '1',
        top: '3.5em',
    },
    searchResultRow: {
        '&:hover': {
            backgroundColor: 'var(--content-secondary-bg)',
        },
    },
}));

interface SearchResultSectionProps<T> {
    title?: string,
    rowComponent: ComponentType<{ data: T }>,
    data?: T[],
    getKey: (data: T) => string,
    onClose?: () => void,
    getLinkPath: (data: T) => string,
};
const SearchResultSection = <T extends unknown>({ title, rowComponent: RowComp, data, getKey, onClose, getLinkPath }: SearchResultSectionProps<T>) => {
    const classes = useStyles({});

    return data?.length ? (
        <Grid columns="1fr" rows="2em 1fr">
            <Cell>
                <Typography variant="h6">
                    {title}
                </Typography>
            </Cell>
            <Cell>
                <List>
                    {
                        data.map(d => (
                            <ListItem component={Link} to={getLinkPath(d)} button key={getKey(d)} className={classes.searchResultRow} onClick={onClose}>
                                <RowComp data={d} />
                            </ListItem>
                        ))
                    }
                </List>
            </Cell>
        </Grid >
    )
        : null;
};

export interface GeneralSearchFieldProps {
    size?: 'small' | 'large',
};
const GeneralSearchField = ({ size = 'large' }: GeneralSearchFieldProps) => {
    const [searchValue, setSearchValue] = useState<string>();
    const [searchResults, setSearchResults] = useState<GeneralSearchResult | null>(null);
    const [searchResultsWindowOpen, setSearchResultsWindowOpen] = useState(false);

    const generalSearch = useGeneralSearchMutation();

    const showSearchResultsWindow = useMemo(() => searchResultsWindowOpen && searchValue,
        [searchResultsWindowOpen, searchValue]
    );

    const searchInputRef = useRef<HTMLInputElement>(null);

    const classes = useStyles({ size });

    const openSearchResultWindow = () => {
        setSearchResultsWindowOpen(true);
    };

    const closeSearchResultWindow = () => {
        setSearchResultsWindowOpen(false);
    };

    const closeAndClear = () => {
        if (searchInputRef.current)
            searchInputRef.current.value = '';

        setSearchValue('');
        closeSearchResultWindow();
        setSearchResults(null);
    };

    useEffect(() => {
        if (searchValue)
            generalSearch.mutate({ query: searchValue }, {
                onSuccess: (data) => {
                    setSearchResults(data);
                },
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const handleInput = useDebounce(useCallback((e) => {
        setSearchValue(e.target.value);
    }, []));

    return (
        <div className={classes.containerDiv} onFocus={openSearchResultWindow} onBlur={closeSearchResultWindow}>
            <TextField inputRef={searchInputRef} variant="outlined" defaultValue={searchValue} onInput={handleInput} placeholder="Search here for people or badges"
                classes={{ root: classes.textFieldRoot }} InputProps={{ endAdornment: <SearchIcon height="1.25em" width="1.25em" fill="var(--icon-fill)" /> }} />
            <div className={clsx(classes.searchResultWindow, { [classes.searchResultWindowOpen]: showSearchResultsWindow })}>
                {
                    generalSearch.isLoading ?
                        <Loader />
                        :
                        searchResults?.accounts.length || searchResults?.groups.length ?
                            (
                                <>
                                    <SearchResultSection title="Accounts" data={searchResults?.accounts} rowComponent={SearchResultAccountRow} onClose={closeAndClear}
                                        getLinkPath={(account) => `/profile/${account.username}`} getKey={(account) => account._id} />
                                    <SearchResultSection title="Groups" data={searchResults?.groups} rowComponent={SearchResultGroupRow} onClose={closeAndClear}
                                        getLinkPath={(group: Group) => `/groups/${group.name}`} getKey={(group) => group.groupId} />
                                </>
                            )
                            :
                            (
                                <Typography variant="body1">
                                    {`No results found`}
                                </Typography>
                            )
                }
            </div>
        </div>

    );
};

export default GeneralSearchField;