import React from 'react';
import { Group } from '../../../../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import GroupSettingsTabTemplate from '../GroupSettingsTabTemplate/GroupSettingsTabTemplate';
import UploadImageCard from '../../../../../../../Templates/UploadImageCard/UploadImageCard';

import { AvatarIcon, ImageIcon } from '../../../../../../../../utils/Icons';
import { useUploadGroupAvatarMutation, useUploadGroupCoverMutation } from '../../../../../../../../utils/mutations/groupMutations';

export interface GroupSettingsSettingsProps {
    group: Group,
    onChange: (change: Partial<Group>) => void,
};
const GroupSettingsSettings = ({ group: { groupId, type = "open", private: privateType = false }, onChange }: GroupSettingsSettingsProps) => {
    const uploadGroupAvatar = useUploadGroupAvatarMutation();
    const uploadGroupCover = useUploadGroupCoverMutation();

    const generateChangeHandler = (id: string) =>
        (e: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
            if (value == null)
                return;

            onChange({ [id]: value });
        };

    const handleUploadGroupAvatar = (image: string) => {
        uploadGroupAvatar.mutate({ image, groupId });
    };

    const handleUploadGroupCover = (image: string) => {
        uploadGroupCover.mutate({ image, groupId });
    };

    return (
        <GroupSettingsTabTemplate title="Group Settings">
            <Grid gap="2em" columns="1fr 1fr" rows="auto auto" areas={["groupType groupPrivate", "groupImages groupImages"]} className="center-horizontal-soft">
                <Cell area="groupType">
                    <ToggleButtonGroup exclusive value={type} onChange={generateChangeHandler('type')}>
                        <ToggleButton value="closed">
                            Closed Group
                        </ToggleButton>
                        <ToggleButton value="open">
                            Open Group
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Cell>
                <Cell area="groupPrivate">
                    <ToggleButtonGroup exclusive value={privateType} onChange={generateChangeHandler('private')}>
                        <ToggleButton value={true}>
                            Private Group
                        </ToggleButton>
                        <ToggleButton value={false}>
                            Public Group
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Cell>
                <Cell area="groupImages">
                    <Grid gap="1em" columns="repeat(auto-fill, 18em)" rows="repeat(auto-fill, 9em)" className="center-horizontal-soft">
                        <Cell>
                            <UploadImageCard icon={<AvatarIcon fill="var(--icon-fill)" height="1.3em" width="1.3em" />} title="Change Avatar" subtitle="110x110px size minimum"
                                onSelect={handleUploadGroupAvatar} isLoading={uploadGroupAvatar.isLoading} background="secondary" />
                        </Cell>
                        <Cell>
                            <UploadImageCard icon={<ImageIcon fill="var(--icon-fill)" height="1.3em" width="1.3em" />} title="Change Cover" subtitle="1184x300px size minimum"
                                onSelect={handleUploadGroupCover} isLoading={uploadGroupCover.isLoading} background="secondary" />
                        </Cell>
                    </Grid>
                </Cell>
            </Grid>
        </GroupSettingsTabTemplate>
    );
};

export default GroupSettingsSettings;