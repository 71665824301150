import React from 'react';

import clsx from 'clsx';

import { CardHeader, CardHeaderProps, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardHeaderRoot: {
            color: 'var(--text-disabled-color) !important',
        },
    })
);

const DisabledCardHeader = ({ className, children, ...others }: CardHeaderProps) => {
    const classes = useStyles();

    return (
        <CardHeader className={clsx(className, classes.cardHeaderRoot)} {...others}>
            {children}
        </CardHeader>
    );
};

export default DisabledCardHeader;