import React, { useMemo, useRef, useState } from 'react';
import { Post, PostReaction, PostReactionType } from '../../../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';
import clsx from 'clsx';

import { Avatar, AvatarProps, createStyles, Grow, makeStyles, Popper, Theme, Typography } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';

import { usePostInfo } from '../../../../../../../CustomHooks/usePostInfo';
import { getReactionImageUrl } from '../../../../../../../utils/api/Posts';

type PostReactionAvatarSize = 'small' | 'medium';

export interface PostCardReactionStyleProps {
    avatarSize: string,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        reactionAvatarRoot: {
            width: ({ avatarSize }: PostCardReactionStyleProps) => avatarSize,
            height: ({ avatarSize }: PostCardReactionStyleProps) => avatarSize,
            border: '2px solid var(--content-bg)',
        },
        reactionDetailsTitleText: {
            fontWeight: 700,
            display: 'inline-block',
            paddingLeft: '0.5em',
        },
        reactionDetailsGrid: {
            borderRadius: '1em',
            padding: '0.5em',
            backgroundColor: 'var(--icon-bg-hover)',
        },
        reactionDetailsReactionIcon: {
            width: '1.5em',
            height: '1.5em',
        },
        reactionDetailsTitleCell: {
            gridAutoFlow: 'column',
            paddingBottom: '0.5em',
            boxSizing: 'border-box',
        },
        reactionDetailsUserText: {
            lineHeight: '1',
        },
        reactionDetailsMoreText: {
            lineHeight: '1',
            fontWeight: 700,
        },
        reactionCountText: {
            fontWeight: 700,
        },
    })
);

const maxAccountsInPopOver = 6;

const reactionAvatarSizeMap: Record<PostReactionAvatarSize, string> = {
    medium: '1.2em',
    small: '0.8em',
};

interface PostCardReactionsDetailsProps {
    reactionType: PostReactionType,
    reactions?: PostReaction[],
    size?: PostReactionAvatarSize,
};
const PostCardReactionsDetails = ({ reactionType, reactions, size = "medium" }: PostCardReactionsDetailsProps) => {
    const classes = useStyles({ avatarSize: reactionAvatarSizeMap[size] });

    return (
        <Grid rowGap="0" columns="1fr" className={classes.reactionDetailsGrid}>
            <Cell className={clsx(classes.reactionDetailsTitleCell, "center-vertical")}>
                <img src={getReactionImageUrl(reactionType)} alt={reactionType} className={classes.reactionDetailsReactionIcon} />
                <Typography variant="body1" className={classes.reactionDetailsTitleText}>
                    {reactionType}
                </Typography>
            </Cell>
            {
                reactions?.slice(0, maxAccountsInPopOver).map(r => (
                    <Cell key={r.account.username}>
                        <Typography variant="body1" className={classes.reactionDetailsUserText}>
                            {r.account.username}
                        </Typography>
                    </Cell>
                ))
            }
            {
                reactions?.length && reactions?.length > maxAccountsInPopOver &&
                <Cell>
                    <Typography variant="body1" className={classes.reactionDetailsMoreText}>
                        {`and ${reactions?.length - maxAccountsInPopOver} more...`}
                    </Typography>
                </Cell>
            }
        </Grid>
    );
};

interface PostCardReactionAvatarProps extends AvatarProps {
    reactionType: PostReactionType,
    reactions?: PostReaction[],
    size?: PostReactionAvatarSize,
};
const PostCardReactionAvatar = ({ reactions, reactionType, size = "medium", ...others }: PostCardReactionAvatarProps) => {
    const [reactionDetailsOpen, setReactionDetailsOpen] = useState(false);

    const avatarRef = useRef(null);

    const classes = useStyles({ avatarSize: reactionAvatarSizeMap[size] });

    const handleOpenReactionsDetails = () => {
        setReactionDetailsOpen(true);
    };

    const handleCloseReactionsDetails = () => {
        setReactionDetailsOpen(false);
    };

    return (
        <>
            <Avatar ref={avatarRef} src={getReactionImageUrl(reactionType)} alt={reactionType} classes={{ root: classes.reactionAvatarRoot }}
                onMouseEnter={handleOpenReactionsDetails} onMouseLeave={handleCloseReactionsDetails} {...others} />
            <Popper open={reactionDetailsOpen} anchorEl={avatarRef.current} placement={'top-start'} transition>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} timeout={350} style={{ transformOrigin: '0 100% 0' }}>
                        <div>
                            <PostCardReactionsDetails reactionType={reactionType} reactions={reactions} />
                        </div>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export interface PostCardReactionsProps {
    size?: PostReactionAvatarSize,
};
const PostCardReactions = ({ size = "medium" }: PostCardReactionsProps) => {
    const { post: { reactions } = {} as Post } = usePostInfo();

    const reactionTypes = useMemo(() => {
        const reactionTypes: Partial<Record<PostReactionType, PostReaction[]>> = {};
        reactions?.forEach(r => {
            if (!reactionTypes[r.reaction])
                reactionTypes[r.reaction] = [];

            reactionTypes[r.reaction]?.push(r);
        });

        return reactionTypes;
    }, [reactions]);

    const classes = useStyles({ avatarSize: reactionAvatarSizeMap[size] });

    return (
        <Grid rows="1fr" columns="auto 1fr">
            <AvatarGroup max={3}>
                {
                    (Object.keys(reactionTypes || {}) as PostReactionType[]).map((k) => (
                        <PostCardReactionAvatar key={k} reactionType={k} reactions={reactionTypes[k]} size={size} />
                    ))
                }
            </AvatarGroup>
            <Cell className="center-vertical">
                {
                    reactions && reactions?.length > 0 &&
                    <Typography variant="body1" className={classes.reactionCountText}>
                        {reactions?.length}
                    </Typography>
                }
            </Cell>
        </Grid>
    );
};

export default PostCardReactions;