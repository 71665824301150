import React from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { CardActions, CardContent, createStyles, makeStyles, Theme } from '@material-ui/core';

import DisabledCard from '../../../../../Design/DisabledCard/DisabledCard';
import DisabledCardHeader from '../../../../../Design/DisabledCardHeader/DisabledCardHeader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        badgesGrid: {
            gridAutoRows: '4em !important',
        },
        badgeCell: {
            border: '2px dashed #2f3749',
            borderRadius: '1em',
            boxSizing: 'border-box',
        },
    })
);

const ProfileManageBadges = () => {
    const classes = useStyles();

    return (
        <DisabledCard>
            <DisabledCardHeader title="Manage Badges" subheader="Choose the order in which your badges are shown. Just drag and place them wherever you want!" />
            <CardContent>
                <Grid gap="1.5em" columns="repeat(auto-fill, 4em)" className={classes.badgesGrid}>
                    <Cell className={classes.badgeCell}>

                    </Cell>
                    <Cell className={classes.badgeCell}>

                    </Cell>
                    <Cell className={classes.badgeCell}>

                    </Cell>
                    <Cell className={classes.badgeCell}>

                    </Cell>
                    <Cell className={classes.badgeCell}>

                    </Cell>
                </Grid>
            </CardContent>
            <CardActions>
            </CardActions>
        </DisabledCard>
    );
};

export default ProfileManageBadges;