import { useCallback, useMemo } from "react";

import axios from "axios";

import { useSnackbar } from "notistack";

import { useMutation, UseMutationResult, UseQueryOptions, UseQueryResult } from "react-query";

import { realData } from "./apiConsts";
import { defaultErrorMsgs } from "../mutations/mutationsConsts";

export type RequestType = 'get' | 'post';

export type RequestOptions = {
    dataParser?: (data: any) => any,
    body?: any
};

const simpleGetRequest = async <T extends unknown>(path: string, mockResult: T, options?: RequestOptions): Promise<T> => {
    if (!realData)
        // return { data: mockResult };
        return mockResult;

    return axios.get(`${path}`, { withCredentials: true }).then(res => {
        if (!res || res.status !== 200)
            return { error: 'error' };

        let parsedData = res.data;
        if (options?.dataParser)
            parsedData = options.dataParser(res.data);

        return parsedData;
        // return { data: parsedData };
    });
};

const simplePostRequest = async <T extends unknown>(path: string, mockResult: any, options?: RequestOptions): Promise<T> => {
    if (!realData)
        // return { data: mockResult };
        return mockResult;

    return axios.post(`${path}`, options?.body, { withCredentials: true }).then(res => {
        if (!res || res.status !== 200)
            return { error: 'error' };

        let parsedData = res.data;
        if (options?.dataParser)
            parsedData = options.dataParser(res.data);

        return parsedData;
        // return { data: parsedData };
    });
};

export const simpleRequest = async <T extends unknown>(type: RequestType, path: string, mockResult: T, options?: RequestOptions): Promise<T> => {
    if (type === 'get')
        return simpleGetRequest(path, mockResult, options);
    else if (type === 'post')
        return simplePostRequest(path, mockResult, options);

    throw new Error('unknown request type');
    // return { error: 'unknown request type' };
};

export type OperationValidationOptions = {
    errorMsg?: string,
    successMsg?: string,
    onSuccess?: (data: any) => void,
    onError?: (reason: any) => void,
    onSettled?: (data?: any, reason?: any) => void,
};
export const useOperationValidation = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useCallback(<T extends unknown>(promise: Promise<T>, { errorMsg, successMsg, onSuccess, onError, onSettled }: OperationValidationOptions) => {
        return promise.then(data => {
            if (data == null) {
                if (errorMsg)
                    enqueueSnackbar(errorMsg, { variant: 'error' });

                return;
            }
            if (successMsg)
                enqueueSnackbar(successMsg, { variant: 'success' });

            onSuccess && onSuccess(data as T);
            onSettled && onSettled(data);
        },
            (reason) => {
                if (onError)
                    return onError(reason);

                if (onSettled)
                    return onSettled(undefined, reason);

                enqueueSnackbar('Uncaught error in operation validation', { variant: 'error' });
                // throw new Error(reason);
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export type MutationValidationOptions<R, A, C> = {
    errorMsg?: string | ((variables: A) => string),
    successMsg?: string | ((variables: A) => string),
    successResponseMsgMap?: Record<string, string | ((variables: A) => string)>,
    onMutate?: (variables: A) => C,
    onSuccess?: (data: R, variables: A, context?: C) => void,
    onError?: (error: any, variables?: A, context?: C) => void,
    onSettled?: (data: R, error?: any, variables?: A, context?: C) => void,
};
export const useMutationValidation = <A extends unknown, R extends unknown, C extends unknown>(apiFunc: (arg: A) => Promise<R>,
    { successMsg, successResponseMsgMap, errorMsg, onMutate, onSuccess, onError, onSettled }: MutationValidationOptions<R, A, C>): UseMutationResult<R, unknown, A, C | undefined> => {

    const { enqueueSnackbar } = useSnackbar();

    const handleError = (error: unknown, variables: A, context: C | undefined) => {
        if (errorMsg) {
            let responseMsg = errorMsg;
            if (typeof errorMsg === 'function')
                responseMsg = errorMsg(variables);

            enqueueSnackbar(responseMsg, { variant: 'error' });
        }

        onError && onError(error, variables, context);
    };

    return useMutation(apiFunc, {
        onMutate: (variables): C | undefined => {
            if (onMutate)
                return onMutate(variables);
        },
        onSuccess: (data, variables, context) => {
            if (data == null) {
                handleError(null, variables, context);
                return;
            }

            if (typeof data === 'string') {
                const responseMap = { ...successResponseMsgMap, ...defaultErrorMsgs };
                let responseMsg = responseMap[data];

                if (typeof responseMsg === "function")
                    responseMsg = responseMsg(variables);

                if (responseMsg)
                    enqueueSnackbar(responseMsg, { variant: 'info' });

                return;
            }

            if (successMsg) {
                let sucessMsgText = successMsg;
                if (typeof successMsg === "function")
                    sucessMsgText = successMsg(variables);

                enqueueSnackbar(sucessMsgText, { variant: 'success' });
            }

            onSuccess && onSuccess(data, variables, context);
        },
        onError: (error, variables, context) => {
            handleError(error, variables, context);

            // if (errorMsg) {
            //     let responseMsg = errorMsg;
            //     if (typeof errorMsg === 'function')
            //         responseMsg = errorMsg(variables);

            //     enqueueSnackbar(responseMsg, { variant: 'error' });
            // }

            // onError && onError(error, variables, context);
        },
        onSettled: (data, error, variables, context) => {
            onSettled && onSettled(data as R, error, variables, context);
        },
    });
};

export type FetchValidationOptions<T, A> = {
    errorMsg?: string,
    successMsg?: string,
    options?: UseQueryOptions<T>,
    queryArgs?: A,
    onSuccess?: (data: any) => void,
    onError?: (err: any) => void,
};
export const useFetchValidation = <T extends unknown>(queryGenerator: (...args: any) => UseQueryResult<T, unknown>,
    { errorMsg, successMsg, options, queryArgs, onSuccess, onError }: FetchValidationOptions<T, Parameters<typeof queryGenerator>>): UseQueryResult<T, unknown> => {
    const { enqueueSnackbar } = useSnackbar();

    const queryOptions = useMemo<UseQueryOptions<T>>(() => ({
        onError: (err) => {
            enqueueSnackbar(errorMsg, { variant: 'error' });

            onError && onError(err);
        },
        onSuccess: (data) => {
            if (successMsg)
                enqueueSnackbar(successMsg, { variant: 'success' });

            onSuccess && onSuccess(data);
        },
        ...options,

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [errorMsg, options, successMsg]);

    return queryGenerator(...(queryArgs || []), queryOptions);
};