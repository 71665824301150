import React, { useEffect, useState } from 'react';
import { Account } from '../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import AccountHubPageTemplate from '../AccountHubPageTemplate/AccountHubPageTemplate';
import AccountHubMenuButtons from '../AccountHubMenu/components/AccountHubMenuButtons/AccountHubMenuButtons';
import ProfileAbout from './components/ProfileAbout/ProfileAbout';
import ProfileImagesSelection from './components/ProfileImagesSelection/ProfileImagesSelection';
import ProfileInterests from './components/ProfileInterests/ProfileInterests';
import ProfileManageBadges from './components/ProfileManageBadges/ProfileManageBadges';

import { useAccountInfo } from '../../../../CustomHooks/useAccountInfo';

import { useUpdateAccountMutation } from '../../../../utils/mutations/accountMutations';

export interface AccountHubProfileInfoProps {
    menuButtonsContainer: HTMLDivElement | null
    section: string,
    title: string,
};
const AccountHubProfileInfo = ({ menuButtonsContainer, section, title }: AccountHubProfileInfoProps) => {
    const { accountInfo } = useAccountInfo();
    const [updatedAccount, setUpdatedAccount] = useState<Account>(accountInfo);

    const updateAccount = useUpdateAccountMutation();

    useEffect(() => {
        if (Object.keys(updatedAccount).length)
            return;

        setUpdatedAccount(accountInfo);
    }, [accountInfo, updatedAccount]);

    const onChange = (id: string, value: any) => {
        let newUpdatedAccount: Account = {
            ...updatedAccount,
            [id]: value
        };

        setUpdatedAccount(newUpdatedAccount);
    };

    const onSave = () => {
        updateAccount.mutate(updatedAccount);
    };

    const onDiscard = () => {
        setUpdatedAccount(accountInfo);
    };

    return (
        <>
            <AccountHubMenuButtons menuButtonsContainer={menuButtonsContainer} onSave={onSave} onDiscard={onDiscard} isSaving={updateAccount.isLoading} />
            <AccountHubPageTemplate section={section} title={title}>
                <Grid gap="1em" columns="1fr" rows="repeat(4, auto)">
                    <Cell>
                        <ProfileImagesSelection />
                    </Cell>
                    <Cell>
                        <ProfileAbout accountInfo={updatedAccount} onChange={onChange} />
                    </Cell>
                    <Cell>
                        <ProfileManageBadges />
                    </Cell>
                    <Cell>
                        <ProfileInterests accountInfo={updatedAccount} onChange={onChange} />
                    </Cell>
                </Grid>
            </AccountHubPageTemplate>
        </>
    );
};

export default AccountHubProfileInfo;