import React, { ReactElement } from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        valueSkeletonLoader: {
            width: '2em',
        },
    })
);

interface StatCellProps {
    label: string,
    value: ReactElement | string | number,
    isLoading: boolean,
};
const StatCell = ({ label, value, isLoading }: StatCellProps) => {
    const classes = useStyles();

    return (
        <Grid gap="0" columns="1fr" rows="2em 1em" className="center-horizontal">
            <Cell>
                <Typography variant="h6">
                    {
                        isLoading ?
                            <Skeleton variant="text" className={classes.valueSkeletonLoader} />
                            :
                            value
                    }
                </Typography>
            </Cell>
            <Cell>
                <Typography variant="caption" className="font-weight-700">
                    {label}
                </Typography>
            </Cell>
        </Grid>
    );
};

export default StatCell;