import React, { ReactElement } from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageGrid: {
            textAlign: 'initial',
        },
        sectionText: {
            textTransform: 'uppercase',
        },
    })
);

export interface AccountHubPageTemplateProps {
    section: string,
    title: string,
    children?: ReactElement | JSX.Element[],
};
const AccountHubPageTemplate = ({ section, title, children }: AccountHubPageTemplateProps) => {
    const classes = useStyles();

    return (
        <Grid gap="1em" columns="1fr" rows="auto 1fr" className={classes.pageGrid}>
            <Cell>
                <Typography variant="caption" className={classes.sectionText}>
                    {section}
                </Typography>
                <Typography variant="h5">
                    {title}
                </Typography>
            </Cell>
            <Cell>
                {children}
            </Cell>
        </Grid>
    );
};

export default AccountHubPageTemplate;