import React, { useEffect, useState } from 'react';

import { Redirect } from 'react-router';

import { useAccountInfo } from '../../CustomHooks/useAccountInfo';

import { useLogoutMutation } from '../../utils/mutations/authMutations';

export interface LogoutPageProps {
    onLogout: () => void
};
const LogoutPage = ({ onLogout }: LogoutPageProps) => {
    const [loggedOut, setLoggedOut] = useState<boolean | null>(null);
    const { clearData } = useAccountInfo();

    const logout = useLogoutMutation({
        onSuccess: () => {
            clearData();
            onLogout();
            setTimeout(() => {
                setLoggedOut(true)
            }, 100);
        },
    });

    useEffect(() => {
        logout.mutate({});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loggedOut == null)
        return (
            <div>
                Logging Out...
            </div>
        );

    return (
        <Redirect to="/login" />
    );
};

export default LogoutPage;