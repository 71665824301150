import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Account } from '../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core';

import Loader from '../../Design/Loader/Loader';

import SmallAccountRow from '../SmallAccountRow/SmallAccountRow';
import AccountInfoProvider from '../../InfoProviders/AccountInfoProvider/AccountInfoProvider';

import { useAllAccountsQuery } from '../../../utils/queries/accountQueries';

import { useDebounce } from '../../../CustomHooks/useDebounce';
import { useMergeInfiniteQueryData } from '../../../CustomHooks/useMergeInfiniteQueryData';
import { useInView } from 'react-intersection-observer';

export interface MembersSearchWindowStyleProps {
    inline?: boolean
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accountsGrid: {
            padding: '1em',
        },
        filerInputCell: {
            padding: '0.5em',
        },
        accountRow: {
            backgroundColor: ({ inline }: MembersSearchWindowStyleProps) => inline ? 'var(--content-secondary-bg)' : 'var(--content-bg)',
        },
        focusedAccountRow: {
            borderColor: 'white',
        },
    })
);

export interface MembersSearchWindowProps {
    onSelect?: (account: Account) => void,
    showLoader?: boolean,
    filterFunc?: (account: Account) => boolean,
    minimal?: boolean,
    inline?: boolean,
    focusedIndex?: number,
    visibleAccounts?: React.MutableRefObject<Account[]>,
};
const MembersSearchWindow = ({ onSelect, showLoader = false, filterFunc, minimal, inline, focusedIndex = 0, visibleAccounts }: MembersSearchWindowProps) => {
    const { data, isLoading, hasNextPage: morePages, isFetchingNextPage, fetchNextPage } = useAllAccountsQuery();

    const allAccountsData: Account[] = useMergeInfiniteQueryData(data, 'accounts');

    const [filter, setFilter] = useState('');
    const handleFilterChange = useDebounce(useCallback((e) => {
        setFilter(e.target.value);
    }, []), 250);

    const filterRegEx = useMemo(() => {
        try {
            return new RegExp(filter);
        }
        catch {
            return new RegExp('');
        }
    }, [filter]);

    const filteredAccounts = useMemo(() => {
        const searchFields = ['username', 'name'];

        let filteredAccounts = allAccountsData?.filter(a => searchFields.some(f => filterRegEx.test(a[f]))) || [];

        if (filterFunc)
            filteredAccounts = filteredAccounts?.filter(filterFunc);

        if (visibleAccounts)
            visibleAccounts.current = filteredAccounts;

        return filteredAccounts;

    }, [allAccountsData, filterRegEx, filterFunc, visibleAccounts]);

    const { ref: moreCellRef, inView: moreCellInView } = useInView({
        initialInView: true,
    });

    useEffect(() => {
        if (moreCellInView && morePages && !isFetchingNextPage)
            fetchNextPage?.();
    }, [fetchNextPage, morePages, isFetchingNextPage, moreCellInView]);

    const classes = useStyles({ inline });

    const generateAccountSelectHandler = (account: Account) => {
        return () => {
            onSelect && onSelect(account);
        };
    };

    return (
        <Grid columns="1fr" className={classes.accountsGrid}>
            {
                !minimal &&
                <Cell>
                    <Typography variant="h6">
                        {`Accounts`}
                    </Typography>
                </Cell>
            }
            {
                !minimal &&
                <Cell className={classes.filerInputCell}>
                    <TextField variant="outlined" placeholder="Filter" size="small" onInput={handleFilterChange} />
                </Cell>
            }
            {
                showLoader || isLoading ?
                    <Loader />
                    :
                    (
                        filteredAccounts?.length ?
                            filteredAccounts?.map((a, idx) => (
                                <Cell key={a.username}>
                                    <AccountInfoProvider accountData={a}>
                                        <SmallAccountRow onClick={generateAccountSelectHandler(a)}
                                            className={`${classes.accountRow} ${inline && idx === focusedIndex ? classes.focusedAccountRow : ''}`} />
                                    </AccountInfoProvider>
                                </Cell>
                            ))
                            :
                            <Typography variant="body1">
                                {`No accounts to show`}
                            </Typography>
                    )
            }
            {
                morePages
                &&
                <Cell ref={moreCellRef}>
                    {
                        isFetchingNextPage &&
                        <Loader />
                    }
                </Cell>
            }
        </Grid>
    );
};

export default MembersSearchWindow;