import { useQueryClient } from "react-query";
import { UseMutationHandlerOptions } from "./types";

import { addAccountBadge, removeAccountBadge, updateAccount, markAllNotificationsAsRead, changeAccountPassword, createAccount, uploadAccountAvatar, uploadAccountCover } from "../api/Account";

import { useMutationValidation } from "../api/apiHelpers";

export const useUpdateAccountMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(updateAccount, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['accounts', undefined]);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: `Updating account failed`,
        successMsg: `Account updated`,
    });
};

export const useCreateAccountMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    return useMutationValidation(createAccount, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
        errorMsg: `Creating account failed`,
        successMsg: `Account created, please login`,
    });
};

export const useChangeAccountPasswordMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    return useMutationValidation(changeAccountPassword, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
        errorMsg: `Error changing password`,
        successMsg: `Account password Changed`,
    });
};

export const useAddAccountBadgeMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(addAccountBadge, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['accounts']);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: `Adding account badge failed`,
        successMsg: `Account badge added`,
    });
};

export const useRemoveAccountBadgeMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(removeAccountBadge, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['accounts']);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: `Removing account badge failed`,
        successMsg: `Account badge removed`,
    });
};

export const useMarkAllNotificationsAsReadMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(markAllNotificationsAsRead, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['accounts', undefined]);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: `Marking all notifications as read failed`,
    });
};

export const useUploadAccountAvatarMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    // const queryClient = useQueryClient();

    return useMutationValidation(uploadAccountAvatar, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
        errorMsg: `Error uploading image`,
        successMsg: `Image uploaded, will take effect in a minute`,
    });
};

export const useUploadAccountCoverMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    // const queryClient = useQueryClient();

    return useMutationValidation(uploadAccountCover, {
        onMutate,
        onError,
        onSettled,
        onSuccess,
        errorMsg: `Error uploading image`,
        successMsg: `Image uploaded, will take effect in a minute`,
    });
};