import React, { FormEvent, useMemo } from 'react';
import { CreatePostData, Post } from '../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, IconButton, List, ListItem, makeStyles, TextField, Theme, Typography } from '@material-ui/core';

import CreatePostStatus from '../CreatePostStatus/CreatePostStatus';

import AntDateTimePicker from '../../../../Pickers/AntDateTimePicker/AntDateTimePicker';

import { PlusIcon, MinusIcon } from '../../../../../utils/Icons';

import { addDays } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pollGrid: {
            padding: '2em 2em 0 2em',
            borderTop: '1px solid var(--content-inside-border-color)',
        },
        pollAnswerGrid: {
            width: '100%',
        },
        addAnswerCell: {
            display: 'flex',
            justifyContent: 'start',
        },
        textContainer: {
            position: 'relative',
        },
    })
);

interface PollAnswerListItemProps {
    onChange: (value: string) => void,
    onDelete: () => void,
    value: string,
    index: number,
    disableEdit?: boolean,
};
const PollAnswerListItem = ({ onChange, onDelete, value, index, disableEdit = false }: PollAnswerListItemProps) => {
    const classes = useStyles();

    const handleAnswerInput = (e: FormEvent<HTMLDivElement>) => {
        onChange((e.target as HTMLInputElement).value);
    };

    return (
        <Grid gap="1em" rows="1fr" columns="1.5em 1fr 1.5em" className={classes.pollAnswerGrid}>
            <Cell className="center-vertical">
                <Typography variant="body1">
                    {`${index + 1}.`}
                </Typography>
            </Cell>
            <Cell className="center-vertical">
                <TextField disabled={disableEdit} size="small" variant="outlined" onInput={handleAnswerInput} value={value} />
            </Cell>
            {
                !disableEdit &&
                <Cell className="center-vertical">
                    <IconButton onClick={onDelete}>
                        <MinusIcon width="0.5em" fill="inherit" />
                    </IconButton>
                </Cell>
            }
        </Grid>
    );
};

export interface CreatePostPollProps {
    postData: CreatePostData,
    onChange: (data: CreatePostData) => void,
    initialData?: Post,
    clearTextEditorSelection?: React.MutableRefObject<() => void>,
};
const CreatePostPoll = ({ postData, onChange, initialData, clearTextEditorSelection }: CreatePostPollProps) => {
    const { text, pollDefinition: { answers = ['', ''], title = '', expirationDate = addDays(new Date(), 1) } = {} } = postData;

    const disableEdit = useMemo(() => initialData != null,
        [initialData]
    );

    const classes = useStyles();

    const handleChange = (change: Partial<CreatePostData>) => {
        onChange({
            text: change.text || text,
            pollDefinition: {
                title,
                expirationDate,
                answers,
                ...change.pollDefinition,
            },
        });
    };

    const handleTitleInput = (e: FormEvent<HTMLDivElement>) => {
        handleChange({ pollDefinition: { title: (e.target as HTMLInputElement).value } });
    };

    const generatePollAnswerChangeHandler = (idx: number) => {
        return (value: string) => {
            const updatedAnswers = [...answers];
            updatedAnswers[idx] = value;

            handleChange({ pollDefinition: { answers: updatedAnswers } });
        };
    };

    const generatePollAnswerDeleteHandler = (idx: number) => {
        return () => {
            const updatedAnswers = [...answers];
            updatedAnswers.splice(idx, 1);

            handleChange({ pollDefinition: { answers: updatedAnswers } });
        };
    };

    const handleAddAnswer = () => {
        const updatedAnswers = [...answers];
        updatedAnswers.push('');

        handleChange({ pollDefinition: { answers: updatedAnswers } });
    };

    const handleExpirationDateChangeTest = (date: Date) => {
        handleChange({ pollDefinition: { expirationDate: date } });
    };

    return (
        <>
            <div className={classes.textContainer}>
                <CreatePostStatus postData={postData} onChange={handleChange} clearTextEditorSelection={clearTextEditorSelection} />
            </div>
            <Grid rowGap="0" columns="1fr auto" rows="2em 1fr" areas={["title date", "answers answers"]} className={classes.pollGrid}>
                <Cell area="title">
                    <TextField disabled={disableEdit} value={title} size="small" variant="outlined" label="Title" onInput={handleTitleInput} />
                </Cell>
                <Cell area="date">
                    <AntDateTimePicker value={expirationDate} onChange={handleExpirationDateChangeTest} minDate={new Date()} showTime />
                </Cell>
                <Cell area="answers">
                    <List>
                        {
                            answers.map((a, idx) => (
                                <ListItem key={idx}>
                                    <PollAnswerListItem value={a} index={idx} onChange={generatePollAnswerChangeHandler(idx)} onDelete={generatePollAnswerDeleteHandler(idx)}
                                        disableEdit={disableEdit} />
                                </ListItem>
                            ))
                        }
                    </List>
                </Cell>
                {
                    !disableEdit &&
                    <Cell className={classes.addAnswerCell}>
                        <IconButton onClick={handleAddAnswer}>
                            <PlusIcon width="0.75em" fill="inherit" />
                        </IconButton>
                    </Cell>
                }
            </Grid>
        </>
    );
};

export default CreatePostPoll;