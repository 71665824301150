import React, { useRef, useState } from 'react';
import { Account, Comment } from '../../../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, IconButton, makeStyles, Popover, Theme, Typography } from '@material-ui/core';

import PostInfoProvider from '../../../../../../InfoProviders/PostInfoProvider/PostInfoProvider';
import AccountInfoProvider from '../../../../../../InfoProviders/AccountInfoProvider/AccountInfoProvider';

import AccountProfileHexagon from '../../../../../../AccountProfileHexagon/AccountProfileHexagon';
import PostCardReactions from '../../../PostCardDetails/components/PostCardReactions/PostCardReactions';
import PostCardCommentsList from '../../PostCardCommentsList';
import CreateComment from '../../../CreateComment/CreateComment';
import PostReactionsMenu from '../../../PostCardActions/components/PostReactionsMenu/PostReactionsMenu';

import HumanizeDuration from 'humanize-duration';

import { usePostInfo } from '../../../../../../../CustomHooks/usePostInfo';

import { useCountPostCommentsQuery } from '../../../../../../../utils/queries/postsQueries';
import { MoreDotsIcon } from '../../../../../../../utils/Icons';
import PostCardCommentSettingsMenu from './components/PostCardCommentSettingsMenu/PostCardCommentSettingsMenu';
import TextEditorInput from '../../../../../TextEditorInput/TextEditorInput';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        commentGrid: {
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            position: 'relative',
        },
        commentWriterText: {
            fontWeight: 700,
        },
        commentContentCell: {
            paddingLeft: '2.5em',
        },
        commentCommentsCell: {
            paddingLeft: '0.5em',
        },
        commentSideLine: {
            position: 'absolute',
            top: '3em',
            bottom: '0em',
            backgroundColor: 'var(--caption-color)',
            width: '1px',
            left: '1em',
        },
        settingMenuPaper: {
            backgroundColor: 'var(--icon-bg-hover)',
            width: '10em',
        },
    })
);

interface PostCardCommentProps {
    comment: Comment,
    level: number
};
const PostCardComment = ({ comment, level }: PostCardCommentProps) => {
    const [showComments, setShowComments] = useState(false);
    const [createCommentOpen, setCreateCommentOpen] = useState(false);
    const [reactionsMenuOpen, setReactionsMenuOpen] = useState(false);
    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
    const [editComment, setEditComment] = useState(false);

    const { post: fatherPost } = usePostInfo();
    const { data: commentsCount, isLoading: loadingCommentsCount } = useCountPostCommentsQuery(comment._id);

    const reactionMenuCellRef = useRef();
    const settingsMenuCellRef = useRef();

    const classes = useStyles();

    const toggleShowComments = () => {
        setShowComments(!showComments);
    };

    const toggleCreateComment = () => {
        setCreateCommentOpen(!createCommentOpen);
    };

    const openReactionsMenu = () => {
        setReactionsMenuOpen(true);
    };

    const closeReactionsMenu = () => {
        setReactionsMenuOpen(false);
    };

    const commentPosted = () => {
        toggleCreateComment();
        setShowComments(true);
    };

    const openSettingsMenu = () => {
        setSettingsMenuOpen(true);
    };

    const closeSettingsMenu = () => {
        setSettingsMenuOpen(false);
    };

    const toggleEditComment = () => {
        setEditComment(!editComment);
    };

    return (
        <PostInfoProvider post={comment} fatherPost={fatherPost}>
            <Grid columns="1fr" className={classes.commentGrid}>
                <div className={classes.commentSideLine} />
                <Cell>
                    <Grid rows="auto" columns="2em auto 1fr">
                        <Cell className="center-vertical">
                            <AccountInfoProvider username={(comment.writer as Account).username}>
                                <AccountProfileHexagon size="2em" levelSize="medium" />
                            </AccountInfoProvider>
                        </Cell>
                        <Cell className="center-vertical">
                            <Typography variant="body1" className={classes.commentWriterText}>
                                {(comment.writer as Account).name}
                            </Typography>
                        </Cell>
                        <Cell className="center-vertical">
                            <Typography variant="caption">
                                {`${HumanizeDuration(Date.now() - comment.date.valueOf(), { round: true, largest: 1 })} ago`}
                                {
                                    comment.editedAt &&
                                    ` (last edited ${HumanizeDuration(Date.now() - comment.editedAt.valueOf(), { largest: 1, round: true })} ago)`
                                }
                            </Typography>
                        </Cell>
                    </Grid>
                </Cell>
                <Cell className={classes.commentContentCell}>
                    {
                        editComment ?
                            <CreateComment postId={fatherPost?._id || ''} initialData={comment} onClose={toggleEditComment} update />
                            :
                            <TextEditorInput value={comment.text} readOnly />
                    }
                </Cell>
                <Cell className={classes.commentContentCell}>
                    <Grid gap="0.5em" rows="1fr" columns="repeat(5, auto) 1fr">
                        <Cell className="center-vertical">
                            <PostCardReactions size="small" />
                        </Cell>
                        <Cell ref={reactionMenuCellRef}>
                            <Button color="primary" classes={{ root: "minimal secondary" }} onClick={openReactionsMenu}>
                                {`React!`}
                            </Button>
                            <PostReactionsMenu
                                open={reactionsMenuOpen}
                                closeMenu={closeReactionsMenu}
                                anchorEl={reactionMenuCellRef.current}
                                size="small"
                            />
                        </Cell>
                        <Cell>
                            <Button color="primary" classes={{ root: "minimal secondary" }} onClick={toggleCreateComment}>
                                {`Reply`}
                            </Button>
                        </Cell>
                        <Cell>
                            <Button color="primary" classes={{ root: "minimal secondary" }} onClick={toggleShowComments}>
                                {`${loadingCommentsCount ? '?' : commentsCount} comments`}
                            </Button>
                        </Cell>
                        <Cell ref={settingsMenuCellRef} className="center-vertical">
                            {
                                !comment.deleted &&
                                <>
                                    <IconButton size="small" color="primary" onClick={openSettingsMenu}>
                                        <MoreDotsIcon height="0.4em" width="1.2em" fill="inherit" />
                                    </IconButton>
                                    <Popover
                                        anchorEl={settingsMenuCellRef.current}
                                        open={settingsMenuOpen}
                                        onClose={closeSettingsMenu}
                                        classes={{ paper: classes.settingMenuPaper }}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <PostCardCommentSettingsMenu onClose={closeSettingsMenu} toggleEditComment={toggleEditComment} />
                                    </Popover>
                                </>
                            }
                        </Cell>
                    </Grid>
                </Cell>
                {
                    createCommentOpen &&
                    <Cell className={classes.commentContentCell}>
                        <CreateComment postId={comment._id} onClose={commentPosted} />
                    </Cell>
                }
                {
                    showComments && !loadingCommentsCount && commentsCount != null && commentsCount > 0 &&
                    <Cell className={classes.commentCommentsCell}>
                        <PostCardCommentsList level={level + 1} />
                    </Cell>
                }
            </Grid>
        </PostInfoProvider>
    );
};

export default PostCardComment;