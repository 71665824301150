import React, { ReactElement } from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import BannerHeader, { BannerHeaderProps } from '../../BannerHeader/BannerHeader';

import { useBreakpoint } from '../../../CustomHooks/useBreakpoint';

interface BannerPageTemplateStyleProps {
    breakpointIndex: number,
};
const useStyles = makeStyles((theme: Theme) => createStyles({
    pageMainGrid: {
        padding: ({ breakpointIndex }: BannerPageTemplateStyleProps) => breakpointIndex < 2 ? '0.5em' : '2em',
        width: '100%',
    },
    bannerCell: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export interface BannerPageTemplateProps extends BannerHeaderProps {
    children?: ReactElement,
    centerContent?: boolean,
};
const BannerPageTemplate = ({ children, title, subtitle, iconUrl, centerContent = false }: BannerPageTemplateProps) => {
    const { index: breakpointIndex } = useBreakpoint();

    const classes = useStyles({ breakpointIndex });

    return (
        <Grid gap="4em" columns="1fr" rows="auto 1fr" className={classes.pageMainGrid}>
            <Cell className={classes.bannerCell}>
                <BannerHeader title={title} subtitle={subtitle} iconUrl={iconUrl} />
            </Cell>
            <Cell className={centerContent ? "center-horizontal" : null}>
                {children}
            </Cell>
        </Grid>
    )
};

export default BannerPageTemplate;