import { useMemo } from "react";

import { useAccountInfo } from "./useAccountInfo";

import { getAccountImageUrl } from '../utils/api/Account';

export const useAccountImage = (type: string) => {
    const { accountInfo: { accountId } } = useAccountInfo();

    const accountProfileImageUrl = useMemo(() => accountId ? getAccountImageUrl(accountId, type) : '',
        [accountId, type]
    );

    return accountProfileImageUrl;
};