import { useMemo } from 'react';
import { Comment } from '../utils/types';

import { useGetPostCommentsQuery } from '../utils/queries/postsQueries';

const usePostComments = (postId: string) => {
    const getPostCommentsQuery = useGetPostCommentsQuery(postId);

    const postComments = useMemo(() => {
        const data: Comment[] = [];
        getPostCommentsQuery.data?.pages.forEach(p => {
            data.push(...p.comments);
        });

        return data;
    }, [getPostCommentsQuery.data]);

    return { ...getPostCommentsQuery, data: postComments };
};

export default usePostComments;