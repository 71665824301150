import React from 'react';

const AccountHubGeneralSettings = () => {
    return (
        <div>
            AccountHubGeneralSettings
        </div>
    );
};

export default AccountHubGeneralSettings;