import React from 'react';

import { Cell } from 'styled-css-grid';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typographyLabelColor: {
            color: 'var(--text-label-color)',
        },
    })
);

interface ProfilePageAboutFieldProps {
    label: string,
    value?: string,
    loading?: boolean,
};
const ProfilePageAboutField = ({ label, value, loading = false }: ProfilePageAboutFieldProps) => {
    const classes = useStyles();

    return (
        <>
            <Cell>
                <Typography variant="body1" className={classes.typographyLabelColor}>
                    {label}
                </Typography>
            </Cell>
            <Cell>
                {
                    loading ?
                        <Skeleton variant="text" />
                        :
                        value
                }
            </Cell>
        </>
    );
};

export default ProfilePageAboutField;