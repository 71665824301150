import React from 'react';
import { Account } from '../../utils/types';

import AccountCard from '../Templates/AccountCard/AccountCard';

import CardSearchTemplatePage from '../Templates/CardSearchTemplatePage/CardSearchTemplatePage';

import { useAllAccountsQuery } from '../../utils/queries/accountQueries';
// import { useFetchValidation } from '../../utils/api/apiHelpers';

import { useMergeInfiniteQueryData } from '../../CustomHooks/useMergeInfiniteQueryData';

const AccountsPage = () => {
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useAllAccountsQuery();
    // const allAccountsQuery = useFetchValidation(useAllAccountsQuery, {
    //     errorMsg: 'Error loading accounts',
    // });

    const allAccounts: Account[] = useMergeInfiniteQueryData(data, 'accounts');

    return (
        <CardSearchTemplatePage bannerTitle="Members" bannerSubtitle="Browse all the members of the community!" data={allAccounts}
            searchLabel="Search Members" gridCardComponent={AccountCard} baseUrl="/members" filterKey={['name', 'username']}
            fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} morePages={hasNextPage} />
    );
};

export default AccountsPage;