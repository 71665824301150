import React from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import BannerPageTemplate from '../Templates/BannerPageTemplate/BannerPageTemplate';

import Loader from '../Design/Loader/Loader';

import QuestPageSection from './components/QuestPageSection/QuestPageSection';

import FeaturedQuestCard from '../Templates/FeaturedQuestCard/FeaturedQuestCard';
import QuestRow from '../Templates/QuestRow/QuestRow';

import { useFetchValidation } from '../../utils/api/apiHelpers';
import { useAllQuestsQuery } from '../../utils/queries/questsQueries';
import { baseBannerPageImages } from '../../utils/api/apiConsts';

import { useBreakpoint } from '../../CustomHooks/useBreakpoint';

export interface QuestPageStyleProps {
    breakpointIndex: number,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        questPageContentGrid: {
            justifyContent: 'center',
            width: '100%',
        },
        featuredQuestsCell: {
            justifyContent: 'center',
            display: 'flex',
        },
        featuredQuestsGrid: {
            justifyContent: 'center',
            width: '100%',
        },
        allQuestsCell: {
            justifyContent: 'center',
            display: 'flex',
        },
        allQuestsGrid: {
            width: '100%',
            '&[class*="Grid"]': {
                gridAutoRows: ({ breakpointIndex }: QuestPageStyleProps) => breakpointIndex < 3 ? 'auto' : '3em',
            },
        },
    })
);

const QuestsPage = () => {
    const quests = useFetchValidation(useAllQuestsQuery, {
        errorMsg: 'Loading quests failed',
    });

    const { index: breakpointIndex } = useBreakpoint();

    let classes = useStyles({ breakpointIndex });

    return (
        <BannerPageTemplate title="Quests" subtitle="Complete quests to gain experience and level up!" iconUrl={`${baseBannerPageImages}/quests-icon`} centerContent>
            {
                quests.isLoading ?
                    <Loader />
                    :
                    <Grid gap="1em" columns="1fr" rows="auto auto" className={classes.questPageContentGrid}>
                        <Cell className={classes.featuredQuestsCell}>
                            <QuestPageSection title="Featured Quests" subHeader="GET AN ERALY LEAD!">
                                <Grid gap="1em" rows="1fr" columns="repeat(auto-fit, 18em)" className={classes.featuredQuestsGrid}>
                                    {
                                        quests.data?.filter(q => q.featured).map((q, idx) => (
                                            <Cell key={q.questId}>
                                                <FeaturedQuestCard quest={q} index={idx} />
                                            </Cell>
                                        ))
                                    }
                                </Grid>
                            </QuestPageSection>
                        </Cell>
                        <Cell className={classes.allQuestsCell}>
                            <QuestPageSection title="Browse All Quests" subHeader="GAIN EXP AND LEVEL UP!">
                                <Grid gap="0.5em" columns="1fr" className={classes.allQuestsGrid}>
                                    {
                                        quests.data?.map((q, idx) => (
                                            <Cell key={q.questId}>
                                                <QuestRow quest={q} />
                                            </Cell>
                                        ))
                                    }
                                </Grid>
                            </QuestPageSection>
                        </Cell>
                    </Grid>
            }
        </BannerPageTemplate>
    )
};

export default QuestsPage;