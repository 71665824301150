import React from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { useParams } from 'react-router';

import ProfilePageHeader from './components/ProfilePageHeader/ProfilePageHeader';
import ProfileTabs from './components/ProfileTabs/ProfileTabs';
import ProfileBadges from './components/ProfileBadges/ProfileBadges';
import ProfilePageAbout from './components/ProfilePageAbout/ProfilePageAbout';

import AccountInfoProvider from '../InfoProviders/AccountInfoProvider/AccountInfoProvider';

import { pathParams } from '../../utils/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainGrid: {
            padding: '2em',
            maxWidth: '74em',
            width: '100%',
        },
        mainGridCell: {
            backgroundColor: 'var(--content-bg)',
            borderRadius: '1em',
        },
    })
);

const tabsContent = {
    about: <ProfilePageAbout />,
    badges: <ProfileBadges />,
    manageBadges: <ProfileBadges manage />,
};

const ProfilePage = () => {
    const { currentTab = "about", userId } = useParams<pathParams>();
    const classes = useStyles();

    return (
        <AccountInfoProvider username={userId}>
            <Grid columns="1fr" rows="27em 5em 1fr" className={classes.mainGrid}>
                <Cell className={classes.mainGridCell}>
                    <ProfilePageHeader />
                </Cell>
                <Cell className={classes.mainGridCell}>
                    <ProfileTabs />
                </Cell>
                <Cell>
                    {tabsContent[currentTab as keyof typeof tabsContent]}
                </Cell>
            </Grid>
        </AccountInfoProvider>
    );
};

export default ProfilePage;