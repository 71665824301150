import React from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import { CustomTextMarks } from '../../../types';

import TextEditorToolbarButton from './TextEditorToolbarButton';

const isMarkActive = (editor: Editor, format: CustomTextMarks) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false
}

const toggleMark = (editor: Editor, format: CustomTextMarks) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

export interface TextEditorToolbarMarkButtonProps {
    format: CustomTextMarks,
    tooltip: string,
    children?: React.ReactNode,
};
const TextEditorToolbarMarkButton = ({ format, children, tooltip }: TextEditorToolbarMarkButtonProps) => {
    const editor = useSlate();

    const handleClick = () => {
        toggleMark(editor, format);
    };

    return (
        <TextEditorToolbarButton active={isMarkActive(editor, format)} onClick={handleClick} tooltip={tooltip}>
            {children}
        </TextEditorToolbarButton>
    )
};

export default TextEditorToolbarMarkButton;