import { Comment, Post, PostReactionType } from "../types";

import { baseReactionsImageUrl, baseServerUrl } from "./apiConsts";
import { simpleRequest } from "./apiHelpers";

import { mockPostComments, mockAllPost, mockPosts, mockPollResults } from "../MockData";

export const getReactionImageUrl = (reaction: PostReactionType) => `${baseReactionsImageUrl}/${reaction}`;

export const getAllPosts = async (page: number = 0) => {
    return simpleRequest('get', `${baseServerUrl}/posts/all/${page}`, mockAllPost);
};

export const getPost = async (postId: string) => {
    return simpleRequest('get', `${baseServerUrl}/posts/${postId}`, mockPosts[0]);
};

export interface GetGroupPostsProps {
    groupId: string,
    page: number,
};
export const getGroupPosts = async ({ groupId, page = 0 }: GetGroupPostsProps) => {
    return simpleRequest('get', `${baseServerUrl}/groups/${groupId}/posts/${page}`, mockAllPost);
};

export const createPost = async (postData: Partial<Post>) => {
    return simpleRequest('post', `${baseServerUrl}/posts/create`, true, { body: postData });
};

export const sharePost = async (postId: string) => {
    return simpleRequest('get', `${baseServerUrl}/posts/${postId}/share`, true);
};

export interface CreateGroupPostProps {
    groupId: string,
    postData: Partial<Post>,
};
export const createGroupPost = async ({ groupId, postData }: CreateGroupPostProps) => {
    return simpleRequest('post', `${baseServerUrl}/groups/${groupId}/posts/create`, true, { body: postData });
};

export interface UpdatePostProps {
    postId: string,
    postData: Partial<Post>,
    fatherPostId?: string,
};
export const updatePost = async ({ postId, postData }: UpdatePostProps) => {
    return simpleRequest('post', `${baseServerUrl}/posts/${postId}/update`, true, { body: postData });
};

export interface CreateCommentProps {
    postId: string,
    commentData: Partial<Comment>
};
export const createComment = async ({ postId, commentData }: CreateCommentProps) => {
    return simpleRequest('post', `${baseServerUrl}/posts/${postId}/comments/create`, true, { body: commentData });
};

export interface DeletePostProps {
    postId: string,
    fatherPostId?: string
};
export const deletePost = async ({ postId }: DeletePostProps) => {
    return simpleRequest('get', `${baseServerUrl}/posts/${postId}/delete`, true);
};

export interface ReactToPostProps {
    postId: string,
    reaction: string,
    fatherPostId: string,
};
export const reactToPost = async ({ postId, reaction }: ReactToPostProps) => {
    return simpleRequest('post', `${baseServerUrl}/posts/${postId}/react`, true, { body: { reaction } });
};

export interface GetPostCommentsProps {
    postId: string,
    page?: number,
};
export const getPostComments = async ({ postId, page = 0 }: GetPostCommentsProps) => {
    return simpleRequest('get', `${baseServerUrl}/posts/${postId}/comments/${page}`, mockPostComments);
};

export const countPostComments = async (postId: string) => {
    return simpleRequest('get', `${baseServerUrl}/posts/${postId}/comments/count`, 3);
};

export interface SubmitPollAnswerProps {
    postId: string,
    answer: number,
};
export const submitPollAnswer = async ({ postId, answer }: SubmitPollAnswerProps) => {
    return simpleRequest('get', `${baseServerUrl}/posts/${postId}/poll/vote/${answer}`, true);
};

export const getPollResults = async (postId: string) => {
    return simpleRequest('get', `${baseServerUrl}/posts/${postId}/poll/results`, mockPollResults);
};

export const getMyPollAnswer = async (postId: string) => {
    return simpleRequest('get', `${baseServerUrl}/posts/${postId}/poll/myVote`, 0);
};