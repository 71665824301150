import React from 'react';

import { Grid } from 'styled-css-grid';

import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import ProfilePageAboutField from '../../../../../Templates/ProfilePageAboutField/ProfilePageAboutField';

import { useAccountInfo } from '../../../../../../CustomHooks/useAccountInfo';

import { format } from 'date-fns';
import HumanizeDuration from 'humanize-duration';

const ProfilePageAboutAboutSection = () => {
    const { accountInfo: { about, createdAt, city, birthday }, isLoading } = useAccountInfo();

    return (
        <Card>
            <CardHeader title="About Me" />
            <CardContent>
                <Typography variant="body1">
                    {
                        isLoading ?
                            <Skeleton variant="rect" />
                            :
                            about
                    }
                </Typography>
            </CardContent>
            <CardContent>
                <Grid columns="5em 1fr">
                    <ProfilePageAboutField label="Joined" value={createdAt ? format(createdAt, 'MMMM do, yyyy') : undefined} loading={isLoading} />
                    <ProfilePageAboutField label="City" value={city} loading={isLoading} />
                    <ProfilePageAboutField label="Age" value={birthday ? HumanizeDuration(Date.now() - birthday.valueOf(), { units: ['y'], maxDecimalPoints: 0 }) : ''}
                        loading={isLoading} />
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ProfilePageAboutAboutSection;