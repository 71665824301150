import React from 'react';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import { JoinGroupIcon, LeaveGroupIcon } from '../../../../utils/Icons';

import AccountHubPageTemplate from '../AccountHubPageTemplate/AccountHubPageTemplate';
import GroupCard from '../../../Templates/GroupCard/GroupCard';

import Loader from '../../../Design/Loader/Loader';

import { useAccountInfo } from '../../../../CustomHooks/useAccountInfo';
import AccountProfileHexagon from '../../../AccountProfileHexagon/AccountProfileHexagon';
import AccountInfoProvider from '../../../InfoProviders/AccountInfoProvider/AccountInfoProvider';

import { useAcceptGroupInviteMutation, useDeclineGroupInviteMutation } from '../../../../utils/mutations/groupMutations';
import { Account, Group } from '../../../../utils/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonCell: {
            padding: '0.5em',
        },
        buttonRoot: {
            width: '100%',
            height: '3.5em',
        },
        inviteByCell: {
            backgroundColor: 'var(--content-secondary-bg)',
            borderTop: '1px solid var(--content-inside-border-color)',
            padding: '0.5em',
        },
        invitedByIcon: {
            paddingRight: '0.5em',
        },
        invitedByTitle: {
            lineHeight: '1',
        },
        invitedByText: {
            lineHeight: '1',
            fontWeight: 700,
        },
    })
);

const AccountHubGroupInvitations = () => {
    const { accountInfo: { groupInvites }, isLoading } = useAccountInfo();

    const acceptGroupInvite = useAcceptGroupInviteMutation();
    const declineGroupInvite = useDeclineGroupInviteMutation();

    const classes = useStyles();

    const generateAcceptInviteHandler = (groupId: string) => {
        return () => acceptGroupInvite.mutate(groupId);
    };

    const generateDeclineInviteHandler = (groupId: string) => {
        return () => declineGroupInvite.mutate(groupId);
    };

    return (
        <AccountHubPageTemplate section="GROUPS" title="Invitations">
            <Grid columns="repeat(auto-fill, 18em)">
                {
                    isLoading ?
                        <Loader />
                        :
                        groupInvites.length ?
                            groupInvites.map(invite => (
                                <GroupCard viewType="gridCard" groupId={(invite.group as Group).groupId} >
                                    <Grid columns="1fr 1fr" rows="1fr auto" areas={["decline accept", "invitedBy invitedBy"]}>
                                        <Cell area="decline" className={classes.buttonCell}>
                                            <Button variant="outlined" color="secondary" className={classes.buttonRoot}
                                                onClick={generateDeclineInviteHandler((invite.group as Group).groupId)}>
                                                <LeaveGroupIcon width="1.5em" fill="var(--icon-fill)" />
                                            </Button>
                                        </Cell>
                                        <Cell area="accept" className={classes.buttonCell}>
                                            <Button variant="contained" color="secondary" className={classes.buttonRoot}
                                                onClick={generateAcceptInviteHandler((invite.group as Group).groupId)}>
                                                <JoinGroupIcon width="1.5em" fill="white" />
                                            </Button>
                                        </Cell>
                                        <Cell area="invitedBy" className={classes.inviteByCell} >
                                            <Grid gap="0" columns="auto 1fr" rows="auto auto" areas={["icon title", "icon name"]}>
                                                <Cell area="icon" className={clsx(classes.invitedByIcon, "center-vertical")}>
                                                    <AccountInfoProvider username={(invite.sentBy as Account).username}>
                                                        <AccountProfileHexagon progress={0} showLevel={false} size="2em" />
                                                    </AccountInfoProvider>
                                                </Cell>
                                                <Cell area="title">
                                                    <Typography variant="body2" className={classes.invitedByTitle}>
                                                        {`Invited By`}
                                                    </Typography>
                                                </Cell>
                                                <Cell area="name">
                                                    <Typography variant="body1" className={classes.invitedByText}>
                                                        {(invite.sentBy as Account).username}
                                                    </Typography>
                                                </Cell>
                                            </Grid>
                                        </Cell>
                                    </Grid>
                                </GroupCard>
                            ))
                            :
                            <Typography variant="body1">
                                {`No pending invites`}
                            </Typography>
                }
            </Grid>
        </AccountHubPageTemplate>
    );
};

export default AccountHubGroupInvitations;