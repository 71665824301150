import React, { FormEvent, useMemo, useRef } from 'react';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Redirect } from 'react-router';

import { useSnackbar } from 'notistack';

import { Button, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core';

import { VikingerLogo } from '../../utils/Icons';
import { useLoginMutation } from '../../utils/mutations/authMutations';

import { useAccountInfo } from '../../CustomHooks/useAccountInfo';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loginForm: {
            height: '100%',
        },
        containerGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        loginGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        logoCell: {
            paddingBottom: '5em',
        },
        loginButtonRoot: {
            width: '100%',
        },
    })
)

export interface LoginPageProps {
    onVerify: () => void
};
const LoginPage = ({ onVerify }: LoginPageProps) => {
    const { accountInfo } = useAccountInfo();

    const { enqueueSnackbar } = useSnackbar();

    const usernameInputRef = useRef<HTMLInputElement>();
    const passwordInputRef = useRef<HTMLInputElement>();

    const loginMutation = useLoginMutation({ onSuccess: onVerify });

    const classes = useStyles();

    const loggedIn = useMemo(() => accountInfo != null && Object.keys(accountInfo).length > 0,
        [accountInfo]
    );

    const attemptLogin = () => {
        // ofirl:test123
        if (!usernameInputRef.current?.value || !passwordInputRef.current?.value) {
            enqueueSnackbar('Username and Password must be filled', { variant: 'error' });
            return;
        }

        loginMutation.mutate({ username: usernameInputRef.current?.value, password: passwordInputRef.current?.value });
    };

    const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        attemptLogin();
    };

    if (loggedIn)
        return (
            <Redirect to="/" />
        );

    return (
        <form className={classes.loginForm} onSubmit={onFormSubmit}>
            <Grid rows="1fr auto 1fr" columns="1fr auto 1fr" areas={[". . .", ". content .", ". . ."]} className={classes.containerGrid}>
                <Cell area="content">
                    <Grid columns="1fr" rows="20em auto auto auto auto auto" className={clsx(classes.loginGrid, "center-vertical")}>
                        <Cell className={clsx(classes.logoCell, "center-horizontal")}>
                            <VikingerLogo fill="white" width="14em" />
                        </Cell>
                        <Cell className="center-horizontal">
                            <Typography variant="body2">
                                {`Test account: shakedb / test123`}
                            </Typography>
                        </Cell>
                        <Cell>
                            <TextField autoFocus inputRef={usernameInputRef} label="Username" variant="outlined" />
                        </Cell>
                        <Cell>
                            <TextField inputRef={passwordInputRef} label="Password" type="password" variant="outlined" />
                        </Cell>
                        <Cell>
                            <Button variant="contained" color="primary" type="submit" classes={{ root: classes.loginButtonRoot }}>
                                Log In
                            </Button>
                        </Cell>
                        <Cell>
                            <Grid columns="1fr auto" rows="1fr" areas={["register forgotPassword"]}>
                                <Cell area="register">
                                    <Link to="/newAccount">
                                        <Button variant="text" color="secondary">
                                            New account
                                    </Button>
                                    </Link>
                                </Cell>
                                <Cell area="forgotPassword">
                                    <Button disabled variant="text" color="secondary">
                                        Forgot password
                                    </Button>
                                </Cell>
                            </Grid>
                        </Cell>
                    </Grid>
                </Cell>
            </Grid>
        </form>
    );
};

export default LoginPage;