import React from 'react';
import { useGroupInfo } from '../../../../CustomHooks/useGroupInfo';
import { useGetGroupPostsQuery } from '../../../../utils/queries/postsQueries';
import PostList from '../../../PostList/PostList';

const GroupProfilePagePosts = () => {
    const { groupInfo: { groupId } } = useGroupInfo();
    const { data: postsQueryData, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useGetGroupPostsQuery(groupId);

    return (
        <PostList locationType="Group" data={postsQueryData} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage} isLoading={isLoading} refetch={refetch} />
    );
};

export default GroupProfilePagePosts;