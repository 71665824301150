import { Account, AccountBadge } from "../types";

import { baseServerUrl } from "./apiConsts";

import { mockAccount, mockAllAccounts } from "../MockData";
import { simpleRequest } from "./apiHelpers";

// https://res.cloudinary.com/ofirl/image/upload/badger/images/accounts/avatars/603a79332f49e509ec9341fa.jpg
const baseAccountImageUrl = `https://res.cloudinary.com/ofirl/image/upload/badger/images/accounts`;
export const getAccountImageUrl = (accountId: string, type: string): string => `${baseAccountImageUrl}/${accountId}/${type}`;

// export const getAccountImageUrl = (username: string, type: string): string => `${baseServerUrl}/static/profiles/${username}-${type}.jpg`;

export const getAccountProfile = async (username?: string) => {
    return simpleRequest('get', `${baseServerUrl}/accounts/get${username ? `/${username}` : ''}`, mockAccount);
};

export const updateAccount = async (account: Partial<Account>) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/accounts/update`, true, { body: account });
};

export const checkUsernameAvailability = async (username: string) => {
    return simpleRequest<boolean>('get', `${baseServerUrl}/public/checkUsernameAvailability/${username}`, true);
};

export interface CreateAccountParams {
    username: string,
    password: string,
};
export const createAccount = async ({ username, password }: CreateAccountParams) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/public/createAccount`, true, {
        body: { username, password },
        // dataParser: (data) => data === true,
    });
};

export interface ChangeAccountPasswordProps {
    oldPassword: string,
    newPassword: string,
};
export const changeAccountPassword = async ({ oldPassword, newPassword }: ChangeAccountPasswordProps) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/accounts/changePassword`, true, { body: { oldPassword, newPassword } });
};

export const getAllAccounts = async (page: number = 0) => {
    return simpleRequest('get', `${baseServerUrl}/accounts/all/${page}`, mockAllAccounts);
};

export interface addAccountBadgeParams {
    accountId: string,
    badge: AccountBadge,
};
export const addAccountBadge = async ({ accountId, badge }: addAccountBadgeParams) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/accounts/addBadge`, true, { body: { accountId, badge } });
};

export interface removeAccountBadgeParams {
    accountId: string,
    badge: AccountBadge,
};
export const removeAccountBadge = async ({ accountId, badge }: removeAccountBadgeParams) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/accounts/removeBadge`, true, { body: { accountId, badge } });
};

export const acceptGroupInvite = async (groupId: string) => {
    return simpleRequest('post', `${baseServerUrl}/accounts/acceptGroupInvite`, true, { body: { groupId } });
};

export const declineGroupInvite = async (groupId: string) => {
    return simpleRequest('post', `${baseServerUrl}/accounts/declineGroupInvite`, true, { body: { groupId } });
};

export const markAllNotificationsAsRead = async () => {
    return simpleRequest('get', `${baseServerUrl}/accounts/markAllNotificationsAsRead`, true);
};

export const uploadAccountAvatar = async (image: string) => {
    return simpleRequest('post', `${baseServerUrl}/accounts/uploadAccountAvatar`, true, { body: { image } });
};

export const uploadAccountCover = async (image: string) => {
    return simpleRequest('post', `${baseServerUrl}/accounts/uploadAccountCover`, true, { body: { image } });
};