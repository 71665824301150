import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { RenderElementProps, useFocused, useSelected } from "slate-react";

import { CustomMentionElement } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mentionLink: {
            color: 'var(--primary-color)',
            fontWeight: 700,
            borderRadius: '0.2em',
            boxSizing: 'border-box',
            border: '1px solid transparent',
            '&.focused': {
                borderColor: 'var(--primary-color)',
            },
        },
    })
);

export const Header1Element = ({ attributes, children, element }: RenderElementProps) => {
    return (
        <h1 {...attributes}>
            {children}
        </h1>
    );
};

export const Header2Element = ({ attributes, children, element }: RenderElementProps) => {
    return (
        <h2 {...attributes}>
            {children}
        </h2>
    );
};

export const Header3Element = ({ attributes, children, element }: RenderElementProps) => {
    return (
        <h3 {...attributes}>
            {children}
        </h3>
    );
};

export const Header4Element = ({ attributes, children, element }: RenderElementProps) => {
    return (
        <h4 {...attributes}>
            {children}
        </h4>
    );
};

export const Header5Element = ({ attributes, children, element }: RenderElementProps) => {
    return (
        <h5 {...attributes}>
            {children}
        </h5>
    );
};

export const Header6Element = ({ attributes, children, element }: RenderElementProps) => {
    return (
        <h6 {...attributes}>
            {children}
        </h6>
    );
};

export const BulletListElement = ({ attributes, children, element }: RenderElementProps) => {
    return (
        <ul {...attributes}>
            {children}
        </ul>
    );
};

export const NumberListElement = ({ attributes, children, element }: RenderElementProps) => {
    return (
        <ol {...attributes}>
            {children}
        </ol>
    );
};

export const ListItemElement = ({ attributes, children, element }: RenderElementProps) => {
    return (
        <li {...attributes}>
            {children}
        </li>
    );
};

export const MentionElement = ({ attributes, children, element }: RenderElementProps) => {
    const selected = useSelected()
    const focused = useFocused()

    const classes = useStyles();

    const mentionElement = element as CustomMentionElement;
    return (
        <span {...attributes} contentEditable={false} className={clsx(classes.mentionLink, { focused: selected && focused })}>
            <a href={`/profile/${mentionElement.accountUsername}`}>
                {mentionElement.accountName}
                {children}
            </a>
        </span>
    );
};