import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Account } from '../../../../../utils/types';
import { CustomMentionElement } from '../../types';

import { createStyles, makeStyles, Paper, Popper, PopperProps, Theme } from '@material-ui/core';

import { TextEditorChangeHandler, TextEditorKeyDownHandler } from '../../TextEditorInput';

import MembersSearchWindow from '../../../MembersSearchWindow/MembersSearchWindow';

import { BaseRange, Descendant, Editor, Range, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mentionsMenuContainer: {
            backgroundColor: 'var(--content-secondary-bg)',
        },
    })
);

export interface MentionsPortalProps {
    value: Descendant[],
    addEditorChangeHandler: (handler: TextEditorChangeHandler) => void,
    removeEditorChangeHandler: (handler: TextEditorChangeHandler) => void,
    addEditorKeyDownHandler: (handler: TextEditorKeyDownHandler) => void,
    removeEditorKeyDownHandler: (handler: TextEditorKeyDownHandler) => void,
};
const MentionsPortal = ({ value, addEditorChangeHandler, removeEditorChangeHandler, addEditorKeyDownHandler, removeEditorKeyDownHandler }: MentionsPortalProps) => {
    const [mentionMenuOpen, setMentionMenuOpen] = useState(false);
    const [target, setTarget] = useState<BaseRange | null>();
    const [searchValue, setSearchValue] = useState('');
    const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const visibleAccounts = useRef<Account[]>([]);

    const editor = useSlate();

    const classes = useStyles();

    const onEditorChange = useCallback((editor: Editor) => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);
            const wordBefore = Editor.before(editor, start, { unit: 'word' });
            const before = wordBefore && Editor.before(editor, wordBefore);
            const beforeRange = before && Editor.range(editor, before, start);
            const beforeText = beforeRange && Editor.string(editor, beforeRange);
            const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
            const after = Editor.after(editor, start);
            const afterRange = Editor.range(editor, start, after);
            const afterText = Editor.string(editor, afterRange);
            const afterMatch = afterText.match(/^(\s|$)/);

            if (beforeMatch && afterMatch) {
                setTarget(beforeRange);
                setSearchValue(beforeMatch[1]);
                return;
            }

            setTarget(null);
        }
    }, []);

    useEffect(() => {
        if (!target) {
            setMentionMenuOpen(false);
            return;
        }

        const boundingRect = ReactEditor.toDOMRange(editor, target).getBoundingClientRect();

        setMentionMenuOpen(true);
        setAnchorEl({
            clientWidth: boundingRect.width,
            clientHeight: boundingRect.height,
            getBoundingClientRect: () => boundingRect,
        });

        setSelectedIndex(0);
    }, [target, editor]);

    const insertMention = useCallback((account: Account) => {
        const mention: CustomMentionElement = {
            type: 'mention',
            accountId: account.accountId,
            accountName: account.name,
            accountUsername: account.username,
            children: [{ text: account.name || account.username }],
        };

        Transforms.insertNodes(editor, mention);
        Transforms.move(editor);
    }, [editor]);

    const onEditorKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
        if (target) {
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    setSelectedIndex(Math.abs((selectedIndex - 1) % visibleAccounts.current.length));
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setSelectedIndex(Math.abs((selectedIndex + 1) % visibleAccounts.current.length));
                    break
                case 'Tab':
                case 'Enter':
                    e.preventDefault();
                    Transforms.select(editor, target);
                    insertMention(visibleAccounts.current[selectedIndex]);
                    setTarget(null);
                    break;
                case 'Escape':
                    e.preventDefault();
                    setTarget(null);
                    break;
            }
        }
    }, [editor, selectedIndex, target, visibleAccounts, insertMention]);

    useEffect(() => {
        addEditorChangeHandler(onEditorChange);
        addEditorKeyDownHandler(onEditorKeyDown);

        return () => {
            removeEditorChangeHandler(onEditorChange);
            removeEditorKeyDownHandler(onEditorKeyDown);
        };

    }, [addEditorChangeHandler, removeEditorChangeHandler, onEditorChange,
        addEditorKeyDownHandler, removeEditorKeyDownHandler, onEditorKeyDown]);

    const handleAccountSelect = (account: Account) => {
        Transforms.select(editor, target as BaseRange);
        insertMention(account);
        setTarget(null);
    };

    const accountFilterFunc = (account: Account) =>
        (['username', 'name'] as (keyof Account)[])
            .some(f => account[f].includes(searchValue));

    return (
        <Popper open={mentionMenuOpen} anchorEl={anchorEl} placement="bottom-start" >
            <Paper classes={{ root: classes.mentionsMenuContainer }}>
                <MembersSearchWindow showLoader={false} filterFunc={accountFilterFunc} minimal inline onSelect={handleAccountSelect} focusedIndex={selectedIndex}
                    visibleAccounts={visibleAccounts} />
            </Paper>
        </Popper>
    );
};

export default MentionsPortal;