import { useCallback, useRef } from "react";

export const useThrottle: <T extends unknown[], R extends unknown> (func: (...args: T) => R | undefined, maxRate: number) => (...args: T) => R | unknown = (func, maxRate = 200) => {
    type T = typeof func;

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const throttledFunc = useCallback<T>((...args) => {
        if (timeoutRef.current)
            return;

        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;
        }, maxRate);

        return func(...args);
    }, [func, maxRate]);

    return throttledFunc
};