import React, { ReactElement } from 'react';

import clsx from 'clsx';

import { Chip, createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
        },
        iconButtonRoot: {
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
                fill: 'white',
                '& .buttonLabel': {
                    transform: 'translateX(-50%)',
                    bottom: '90%',
                    opacity: 1,
                },
            },
        },
        iconButtonLabel: {
            transition: 'all 0.3s ease-in-out',
            position: 'absolute',
            opacity: 0,
            pointerEvents: 'none',
            left: '50%',
            bottom: '50%',
            transform: 'translateX(-50%)',
            fontWeight: 700,
        },
    })
);

export interface CardSearchViewTypeButtonProps {
    label?: string,
    children: ReactElement,
    onClick?: () => void,
};
const CardSearchViewTypeButton = ({ label, children, onClick }: CardSearchViewTypeButtonProps) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onClick}>
                {children}
                {label &&
                    <Chip color="primary" label={label} classes={{ root: clsx("buttonLabel", classes.iconButtonLabel) }} />
                }
            </IconButton>
        </div>
    );
};

export default CardSearchViewTypeButton;