import React from 'react';

import clsx from 'clsx';

import { createStyles, makeStyles, Radio, RadioProps, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: '50%',
            backgroundColor: 'var(--content-bg)',
            height: '1em',
            width: '1em',
            border: '1px solid var(--content-inside-border-color)',
            transition: 'all 0.2s ease-in-out',
            'input:disabled ~ &': {
                background: 'var(--content-disabled-bg)',
            },
        },
        checkedIcon: {
            backgroundColor: 'var(--primary-color)',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: '1em',
                height: '1em',
                backgroundImage: 'radial-gradient(var(--content-bg),var(--content-bg) 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: 'var(--button-primary-bg-hover)',
            },
        },
    })
);

const RadioButton = (props: RadioProps) => {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
};

export default RadioButton;