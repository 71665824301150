import React from 'react';

const defaultSvgAttributes = {
    fill: "inherit",
    height: '100%',
    width: '100%',
};

export const BoldIcon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 298.667 298.667" preserveAspectRatio="xMinYMin meet">
            <path d="M218.133,144.853c20.587-14.4,35.2-37.653,35.2-59.52C253.333,37.227,216.107,0,168,0H34.667v298.667h150.187
			    c44.693,0,79.147-36.267,79.147-80.853C264,185.387,245.547,157.76,218.133,144.853z M98.667,53.333h64c17.707,0,32,14.293,32,32
			    s-14.293,32-32,32h-64V53.333z M173.333,245.333H98.667v-64h74.667c17.707,0,32,14.293,32,32S191.04,245.333,173.333,245.333z"/>
        </svg>
    );
};

export const ItalicIcon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 298.667 298.667" preserveAspectRatio="xMinYMin meet">
            <polygon points="106.667,0 106.667,64 153.92,64 80.747,234.667 21.333,234.667 21.333,298.667 192,298.667 192,234.667 
			144.747,234.667 217.92,64 277.333,64 277.333,0"/>
        </svg>
    );
};

export const UnderlineIcon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 384 384" preserveAspectRatio="xMinYMin meet">
            <path d="M192,298.667c70.72,0,128-57.28,128-128V0h-53.333v170.667c0,41.28-33.387,74.667-74.667,74.667
 				s-74.667-33.387-74.667-74.667V0H64v170.667C64,241.387,121.28,298.667,192,298.667z"/>
            <rect x="42.667" y="341.333" width="298.667" height="42.667" />
        </svg>
    );
};

export const LineThroughIcon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 384 384" preserveAspectRatio="xMinYMin meet">
            <polygon points="149.333,160 234.667,160 234.667,96 341.333,96 341.333,32 42.667,32 42.667,96 149.333,96" />
            <rect x="149.333" y="288" width="85.333" height="64" />
            <rect x="0" y="202.667" width="384" height="42.667" />
        </svg>
    );
};

export const Header1Icon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 384 384" preserveAspectRatio="xMinYMin meet">
            <path d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667
			    C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M234.667,298.667H192V128h-42.667V85.333h85.333
			    V298.667z"/>
        </svg>
    );
};

export const Header2Icon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 384 384" preserveAspectRatio="xMinYMin meet">
            <path d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667
			    C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M256,170.667c0,23.573-19.093,42.667-42.667,42.667
			    h-42.667V256H256v42.667H128v-85.333c0-23.573,19.093-42.667,42.667-42.667h42.667V128H128V85.333h85.333
			    C236.907,85.333,256,104.427,256,128V170.667z"/>
        </svg>
    );
};

export const Header3Icon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 384 384" preserveAspectRatio="xMinYMin meet">
            <path d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667
			    C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M256,160c0,17.707-14.293,32-32,32
			    c17.707,0,32,14.293,32,32v32c0,23.573-19.093,42.667-42.667,42.667H128V256h85.333v-42.667h-42.667v-42.667h42.667V128H128
			    V85.333h85.333C236.907,85.333,256,104.427,256,128V160z"/>
        </svg>
    );
};

export const Header4Icon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 384 384" preserveAspectRatio="xMinYMin meet">
            <path d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667
			    C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M256,298.667h-42.667v-85.333H128v-128h42.667
			    v85.333h42.667V85.333H256V298.667z"/>
        </svg>
    );
};

export const Header5Icon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 384 384" preserveAspectRatio="xMinYMin meet">
            <path d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667
			    C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M256,128h-85.333v42.667h42.667
			    c23.573,0,42.667,19.093,42.667,42.667V256c0,23.573-19.093,42.667-42.667,42.667H128V256h85.333v-42.667H128v-128h128V128z"/>
        </svg>
    );
};

export const Header6Icon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 384 384" preserveAspectRatio="xMinYMin meet">
            <rect x="170.667" y="213.333" width="42.667" height="42.667" />
            <path d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667
				C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M256,128h-85.333v42.667h42.667
				c23.573,0,42.667,19.093,42.667,42.667V256c0,23.573-19.093,42.667-42.667,42.667h-42.667C147.093,298.667,128,279.573,128,256
				V128c0-23.573,19.093-42.667,42.667-42.667H256V128z"/>
        </svg>
    );
};

export const BulletListIcon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 394.667 394.667" preserveAspectRatio="xMinYMin meet">
            <path d="M32,37.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S49.707,37.333,32,37.333z" />
            <path d="M32,165.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S49.707,165.333,32,165.333z" />
            <path d="M32,293.333c-17.813,0-32,14.4-32,32c0,17.6,14.4,32,32,32c17.6,0,32-14.4,32-32C64,307.733,49.813,293.333,32,293.333z" />
            <rect x="96" y="304" width="298.667" height="42.667" />
            <rect x="96" y="48" width="298.667" height="42.667" />
            <rect x="96" y="176" width="298.667" height="42.667" />
        </svg>
    );
};

export const NumberedListIcon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 405.333 405.333" preserveAspectRatio="xMinYMin meet">
            <polygon points="0,309.333 42.667,309.333 42.667,320 21.333,320 21.333,341.333 42.667,341.333 42.667,352 0,352 0,373.333 
				64,373.333 64,288 0,288"/>
            <polygon points="21.333,117.333 42.667,117.333 42.667,32 0,32 0,53.333 21.333,53.333" />
            <polygon points="0,181.333 38.4,181.333 0,226.133 0,245.333 64,245.333 64,224 25.6,224 64,179.2 64,160 0,160" />
            <rect x="106.667" y="309.333" width="298.667" height="42.667" />
            <rect x="106.667" y="53.333" width="298.667" height="42.667" />
            <rect x="106.667" y="181.333" width="298.667" height="42.667" />
        </svg>
    );
};

export const CodeBlockIcon = (props: any) => {
    return (
        <svg {...defaultSvgAttributes} {...props} viewBox="0 0 94.504 94.504" preserveAspectRatio="xMinYMin meet">
            <path d="M93.918,45.833L69.799,21.714c-0.75-0.75-2.077-0.75-2.827,0l-5.229,5.229c-0.781,0.781-0.781,2.047,0,2.828
			    l17.477,17.475L61.744,64.724c-0.781,0.781-0.781,2.047,0,2.828l5.229,5.229c0.375,0.375,0.884,0.587,1.414,0.587
			    c0.529,0,1.039-0.212,1.414-0.587l24.117-24.118C94.699,47.881,94.699,46.614,93.918,45.833z"/>
            <path d="M32.759,64.724L15.285,47.248l17.477-17.475c0.375-0.375,0.586-0.883,0.586-1.414c0-0.53-0.21-1.039-0.586-1.414
                l-5.229-5.229c-0.375-0.375-0.884-0.586-1.414-0.586c-0.53,0-1.039,0.211-1.414,0.586L0.585,45.833
                c-0.781,0.781-0.781,2.047,0,2.829L24.704,72.78c0.375,0.375,0.884,0.587,1.414,0.587c0.53,0,1.039-0.212,1.414-0.587l5.229-5.229
                C33.542,66.771,33.542,65.505,32.759,64.724z"/>
            {/* <path d="M60.967,13.6c-0.254-0.466-0.682-0.812-1.19-0.962l-4.239-1.251c-1.058-0.314-2.172,0.293-2.484,1.352L33.375,79.382
                c-0.15,0.509-0.092,1.056,0.161,1.521c0.253,0.467,0.682,0.812,1.19,0.963l4.239,1.251c0.189,0.056,0.38,0.083,0.567,0.083
                c0.863,0,1.66-0.564,1.917-1.435l19.679-66.644C61.278,14.612,61.221,14.065,60.967,13.6z"/> */}
        </svg>
    );
};