import React, { ReactElement, useMemo } from 'react';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import AccountProfileHexagon from '../../AccountProfileHexagon/AccountProfileHexagon';

import { useAccountImage } from '../../../CustomHooks/useAccountImage';
import { useAccountInfo } from '../../../CustomHooks/useAccountInfo';

interface SmallAccountRowStyleProps {
    hoverEffect: boolean,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        memberRowGrid: ({ hoverEffect }: SmallAccountRowStyleProps) => ({
            backgroundColor: 'var(--content-secondary-bg)',
            borderRadius: '0.5em',
            border: '1px solid var(--content-inside-border-color)',
            width: '100%',
            '&[class*="Grid"]': {
                height: '100%',
            },
            ...(hoverEffect ? {
                '&:hover': {
                    backgroundColor: 'var(--content-secondary-bg)',
                },
            } : {}),
        }),
        memberProfileHexagonCell: {
            justifyItems: 'end',
        },
        memberActionCell: {
            paddingRight: '1em',
        },
        buttonRoot: {
            width: '100%',
            textAlign: 'initial',
            padding: '0',
            borderRadius: '0.5em',
        },
    })
);

interface SmallAccountRowProps {
    children?: ReactElement,
    onClick?: () => void
    className?: string,
};
const SmallAccountRow = ({ children, onClick, className }: SmallAccountRowProps) => {
    const accountCoverImageUrl = useAccountImage('cover');
    const { accountInfo: { name, username } } = useAccountInfo();

    const isClickable = useMemo(() => onClick != null,
        [onClick]
    );

    const classes = useStyles({ hoverEffect: isClickable });

    const accountRow = (
        <Grid rows="1fr" columns="3em 1em 1fr auto" areas={[". . title actions"]} className={clsx(classes.memberRowGrid, className, { pointer: isClickable })} onClick={onClick}>
            <Cell left={1} height={1} width={1} top={1} style={{ background: `rgba(0, 0, 0, 0) url("${accountCoverImageUrl}") no-repeat scroll center center / cover` }}>
            </Cell>
            <Cell left={1} top={1} width={2} height={1} className={clsx("center-horizontal center-vertical", classes.memberProfileHexagonCell)}>
                <AccountProfileHexagon size="3em" showLevel={false} />
            </Cell>
            <Cell area="title" className="center-vertical">
                <Typography variant="body2">
                    {name}
                </Typography>
                <Typography variant="body2">
                    {`@${username}`}
                </Typography>
            </Cell>
            <Cell area="actions" className={clsx(classes.memberActionCell, "center-vertical-soft")}>
                {children}
            </Cell>
        </Grid>
    );

    return isClickable ?
        (
            <Button classes={{ root: classes.buttonRoot }}>
                {accountRow}
            </Button>
        )
        :
        (
            accountRow
        );
};

export default SmallAccountRow;