import React from 'react';
import { CustomElementType, CustomTextMarks } from '../../types';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import {
    BoldIcon, ItalicIcon, LineThroughIcon, UnderlineIcon, Header1Icon, Header2Icon, Header3Icon, Header4Icon,
    Header5Icon, Header6Icon, BulletListIcon, NumberedListIcon, CodeBlockIcon
} from './ToolbarIcons';

import TextEditorToolbarMarkButton from './TextEditorToolbarButton/TextEditorToolbarMarkButton';
import TextEditorToolbarBlockButton from './TextEditorToolbarButton/TextEditorToolbarBlockButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbarRoot: {
            padding: '0.5em',
            paddingBottom: '1em',
            '& > .text-editor-toolbar-cell:nth-child(1) > .text-editor-toolbar-button': {
                // borderRadius: '1em 0em 0em 1em',
            },
            '& > .text-editor-toolbar-cell:last-child > .text-editor-toolbar-button': {
                // borderRadius: '0em 1em 1em 0em',
            },
        },
    })
);

export type TextEditorToolbarButtonType = 'mark' | 'block';
export type TextEditorToolbarButtonDefinition = {
    type: TextEditorToolbarButtonType,
    label: React.ReactNode,
    format: CustomTextMarks | CustomElementType,
    tooltip: string,
};

const toolbarButtonTypeMap: Record<TextEditorToolbarButtonType, React.ComponentType<any>> = {
    block: TextEditorToolbarBlockButton,
    mark: TextEditorToolbarMarkButton,
};

const TextEditorToolbar = () => {
    const classes = useStyles();

    const toolbarButtons: TextEditorToolbarButtonDefinition[] = [
        {
            type: 'mark',
            label: <BoldIcon />,
            format: 'bold',
            tooltip: 'Bold',
        },
        {
            type: 'mark',
            label: <ItalicIcon />,
            format: 'italic',
            tooltip: 'Italic',
        },
        {
            type: 'mark',
            label: <UnderlineIcon />,
            format: 'underline',
            tooltip: 'Underline',
        },
        {
            type: 'mark',
            label: <LineThroughIcon />,
            format: 'lineThrough',
            tooltip: 'Line Through',
        },
        {
            type: 'mark',
            label: <CodeBlockIcon />,
            format: 'code',
            tooltip: 'Code Block',
        },
        {
            type: 'block',
            label: <Header1Icon />,
            format: 'header1',
            tooltip: 'Header 1',
        },
        {
            type: 'block',
            label: <Header2Icon />,
            format: 'header2',
            tooltip: 'Header 2',
        },
        {
            type: 'block',
            label: <Header3Icon />,
            format: 'header3',
            tooltip: 'Header 3',
        },
        {
            type: 'block',
            label: <Header4Icon />,
            format: 'header4',
            tooltip: 'Header 4',
        },
        {
            type: 'block',
            label: <Header5Icon />,
            format: 'header5',
            tooltip: 'Header 5',
        },
        {
            type: 'block',
            label: <Header6Icon />,
            format: 'header6',
            tooltip: 'Header 6',
        },
        {
            type: 'block',
            label: <BulletListIcon />,
            format: 'bulleted-list',
            tooltip: 'Bullet List',
        },
        {
            type: 'block',
            label: <NumberedListIcon />,
            format: 'numbered-list',
            tooltip: 'Number List',
        },
    ];

    return (
        <Grid gap="0" rows="1fr" columns="repeat(auto-fill, 2.5em)" className={classes.toolbarRoot}>
            {
                toolbarButtons.map(button => (
                    <Cell key={button.format} className="text-editor-toolbar-cell">
                        {
                            (() => {
                                const ButtonComp = toolbarButtonTypeMap[button.type];

                                return (
                                    <ButtonComp format={button.format} tooltip={button.tooltip}>
                                        {button.label}
                                    </ButtonComp>
                                );
                            })()
                        }
                    </Cell>
                ))
            }
        </Grid>
    );
};

export default TextEditorToolbar;