import React from 'react';
import { strokeWidth, styleProps, trackColor } from './types';

import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: ({ size }) => size,
            height: ({ size }) => size,
            clipPath: 'polygon(50% 4%, 95% 27%, 95% 80%, 50% 104%, 5% 80%, 5% 27%)',
            position: 'relative',
        },
        svgHexagon: {
            position: 'absolute',
            top: '0',
            left: '0',
        },
        track: {
            stroke: ({ trackColor }: styleProps) => trackColor,
            strokeWidth: ({ strokeWidth }) => strokeWidth,
            strokeLinecap: 'round',
            fill: 'transparent',
        },
        fill: {
            fill: 'transparent',
            strokeLinecap: 'round',
            strokeWidth: ({ strokeWidth }) => strokeWidth,
            strokeDasharray: '1020px',
        },
    })
);

export interface HexagonProps {
    progress?: number,
    trackColor?: trackColor,
    fillColor?: string | string[],
    strokeWidth?: strokeWidth,
    size?: string,
    children?: any,
    className?: string,
}
const Hexagon = ({ children, progress = 0, trackColor = "var(--icon-bg-hover)", fillColor = ["#62da54", "#a4ef5d"], strokeWidth = "40px", size = "3em", className }: HexagonProps) => {
    // clamp progress
    if (progress < 0)
        progress = 0;
    if (progress > 100)
        progress = 100;

    let classes = useStyles({ trackColor, strokeWidth, size });

    const firstHalfProgress = progress >= 50 ? 0 : 1 - (progress / 50);
    const secondHalfProgress = progress > 50 ? (50 - progress - 50) / 50 : 1;

    return (
        <div className={clsx(classes.container, "center-vertical", "center-horizontal", className)} >
            <svg x="0px" y="0px" viewBox="0 0 700 750" className={classes.svgHexagon}>
                <defs>
                    <linearGradient id="fillGradient1" x1="0%" y1="0%" x2="0%" y2="200%">
                        <stop offset="0%" stopColor={typeof (fillColor) === "object" ? fillColor[0] : fillColor} />
                        <stop offset="100%" stopColor={typeof (fillColor) === "object" ? fillColor[1] : fillColor} />
                    </linearGradient>
                    <linearGradient id="fillGradient2" x1="0%" y1="200%" x2="0%" y2="0%">
                        <stop offset="0%" stopColor={typeof (fillColor) === "object" ? fillColor[0] : fillColor} />
                        <stop offset="100%" stopColor={typeof (fillColor) === "object" ? fillColor[1] : fillColor} />
                    </linearGradient>
                    <clipPath id="clipPathTest">
                        <path fill="#FFFFFF" stroke="#000000" strokeWidth={strokeWidth} d="M350 50L650 200 650 550 350 700 50 550 50 200z" />
                    </clipPath>
                </defs>
                <path className={classes.track} d="M350 50L650 200 650 550 350 700 50 550 50 200z"></path>
                {
                    firstHalfProgress < 1 &&
                    <path className={classes.fill} strokeDashoffset={firstHalfProgress * 1020} stroke="url(#fillGradient1)" d="M350 50L650 200 650 550 350 700"></path>
                }
                {
                    secondHalfProgress < 1 &&
                    <path className={classes.fill} strokeDashoffset={secondHalfProgress * 1020} stroke="url(#fillGradient2)" d="M350 700L50 550 50 200 350 50"></path>
                }
            </svg>
            {children}
        </div>
    );
};

export default Hexagon;