import React, { useRef, useState } from 'react';
import { Group, Post } from '../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { Link } from 'react-router-dom';

import { createStyles, IconButton, makeStyles, Popover, Theme, Typography } from '@material-ui/core';

import AccountProfileHexagon from '../../../../AccountProfileHexagon/AccountProfileHexagon';
import PostSettingsMenu from './components/PostSettingsMenu/PostSettingsMenu';

import { useAccountInfo, useCurrentAccountInfo } from '../../../../../CustomHooks/useAccountInfo';

import HumanizeDuration from 'humanize-duration';

import { MoreDotsIcon, FilledArrowIcon } from '../../../../../utils/Icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleCell: {
            display: 'flex',
            alignItems: 'end',
        },
        postTitleWriter: {
            fontWeight: 700,
            lineHeight: '1',
        },
        postDate: {
            lineHeight: '1',
        },
        settingsButton: {
            '& svg': {
                fill: 'var(--icon-fill)',
                transition: 'all 0.2s ease-in-out',
            },
            '&:hover': {
                '& svg': {
                    fill: 'white',
                },
            },
        },
        settingMenuPaper: {
            backgroundColor: 'var(--icon-bg-hover)',
            width: '10em',
        },
        settingsListItemText: {
            fontSize: '0.75em',
            fontWeight: 700,
        },
    })
);

export interface PostCardHeaderProps {
    post: Post
    toggleEditPost?: () => void,
    shared?: boolean,
};
const PostCardHeader = ({ post, toggleEditPost, shared }: PostCardHeaderProps) => {
    const { date, editedAt, postLocation: { locationType, location } } = post;

    const { accountInfo: { username: currentUsername, type: accountType } } = useCurrentAccountInfo();
    const { accountInfo: { name, username } } = useAccountInfo();

    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);

    const settingsMenuButtonRef = useRef(null);

    const classes = useStyles();

    const openSettingsMenu = () => {
        setSettingsMenuOpen(true);
    };

    const closeSettingsMenu = () => {
        setSettingsMenuOpen(false);
    };

    return (
        <Grid rowGap="0" rows="1.5em auto" columns="3em 1fr auto" areas={["profileIcon title settings", "profileIcon date settings"]}>
            <Cell area="profileIcon" className="center-vertical">
                <Link to={`/profile/${username}`}>
                    <AccountProfileHexagon size="3em" levelSize="medium" />
                </Link>
            </Cell>
            <Cell area="title" className={classes.titleCell}>
                <Link to={`/profile/${username}`}>
                    <Typography variant="body1" className={classes.postTitleWriter}>
                        {name || username}
                    </Typography>
                </Link>
                {
                    locationType === 'Group' &&
                    <>
                        {` `}
                        <FilledArrowIcon width="1em" height="1em" fill="var(--icon-fill)" />
                        {` `}
                        <Link to={`/groups/${(location as Group)?.name}`}>
                            <Typography variant="body1" className={classes.postTitleWriter}>
                                {(location as Group)?.name}
                            </Typography>
                        </Link>
                    </>
                }
            </Cell>
            <Cell area="date">
                <Typography variant="caption" className={classes.postDate}>
                    {`${HumanizeDuration(Date.now() - date.valueOf(), { largest: 1, round: true })} ago`}
                    {
                        editedAt &&
                        ` (last edited ${HumanizeDuration(Date.now() - editedAt.valueOf(), { largest: 1, round: true })} ago)`
                    }
                </Typography>
            </Cell>
            <Cell area="settings">
                {
                    (currentUsername === username || accountType === "maintainer" || accountType === "admin") && !shared &&
                    <>
                        <IconButton ref={settingsMenuButtonRef} onClick={openSettingsMenu} classes={{ root: classes.settingsButton }}>
                            <MoreDotsIcon width="1em" fill="inherit" />
                        </IconButton>
                        <Popover
                            open={settingsMenuOpen}
                            onClose={closeSettingsMenu}
                            classes={{ paper: classes.settingMenuPaper }}
                            anchorEl={settingsMenuButtonRef.current}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <PostSettingsMenu post={post} onClose={closeSettingsMenu} toggleEditPost={toggleEditPost} />
                        </Popover>
                    </>
                }
            </Cell>
        </Grid >
    );
};

export default PostCardHeader;