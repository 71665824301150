import React from 'react';
import { pathParams, Post } from '../../utils/types';

import { useParams } from 'react-router';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Loader from '../Design/Loader/Loader';
import PostCard from '../Templates/PostCard/PostCard';

import { useGetPostQuery } from '../../utils/queries/postsQueries';

import { useBreakpoint } from '../../CustomHooks/useBreakpoint';

export interface PostPageStyleProps {
    breakpointIndex: number,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        postContainer: {
            width: '100%',
            maxWidth: '74em',
            padding: ({ breakpointIndex }: PostPageStyleProps) => breakpointIndex > 1 ? '2em' : '0.5em',
        },
    })
);

const PostPage = () => {
    const { postId } = useParams<pathParams>();
    const { index: breakpointIndex } = useBreakpoint();

    const { data: postData, isLoading } = useGetPostQuery(postId);

    const classes = useStyles({ breakpointIndex });

    return isLoading ?
        <Loader />
        :
        <div className={classes.postContainer}>
            <PostCard post={postData as Post} defaultCommentsOpen />
        </div>
        ;
};

export default PostPage;