import React, { ChangeEvent, ReactElement } from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { Card, CardContent, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Loader from '../../Design/Loader/Loader';

export interface UploadImageCardStyleProps {
    background: 'primary' | 'secondary',
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        changeCardGrid: {
            paddingTop: '0.5em',
            boxSizing: 'border-box',
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        changeCardFileInput: {
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            position: 'absolute',
            opacity: '0',
            cursor: 'pointer',
        },
        changeCardContentRoot: {
            height: '100%',
            boxSizing: 'border-box',
            position: 'relative',
        },
        cardRoot: {
            width: '100%',
            height: '100%',
            backgroundColor: ({ background }: UploadImageCardStyleProps) => background === "primary" ? `var(--content-bg)` : `var(--content-secondary-bg)`,
            transition: 'all 0.2s linear',
            '& svg': {
                transition: 'all 0.2s linear',
            },
        },
        cardHoverEffect: {
            '&:hover': {
                transform: 'translateY(-0.3em)',
                '& svg': {
                    fill: 'var(--primary-color)',
                },
            },
        },
    })
);

export interface UploadImageCardProps {
    icon: ReactElement,
    title: string,
    subtitle: string,
    onSelect: (image: string) => void,
    isLoading?: boolean,
    background?: 'primary' | 'secondary';
};
export const UploadImageCard = ({ icon, title, subtitle, onSelect, isLoading = false, background = 'primary' }: UploadImageCardProps) => {
    const classes = useStyles({ background });

    const onImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files?.[0] as Blob);
        reader.onloadend = () => {
            const base64data = reader.result as string;
            onSelect(base64data);
        }
    };

    return (
        <Card classes={{ root: clsx(classes.cardRoot, classes.cardHoverEffect) }}>
            <CardContent classes={{ root: clsx(classes.changeCardContentRoot, "center-vertical") }}>
                {
                    isLoading ?
                        <Loader />
                        :
                        <>
                            <input type="file" onChange={onImageSelect} className={classes.changeCardFileInput} />
                            <Grid columns="1fr" rows="1fr 1fr" className={classes.changeCardGrid}>
                                <Cell className="center-horizontal center-vertical">
                                    <div>
                                        {icon}
                                    </div>
                                </Cell>
                                <Cell className="center-horizontal center-vertical">
                                    <Typography variant="body2" className="font-weight-700">
                                        {title}
                                    </Typography>
                                    <Typography variant="caption">
                                        {subtitle}
                                    </Typography>
                                </Cell>
                            </Grid>
                        </>
                }
            </CardContent>
        </Card>
    );
};

export default UploadImageCard;