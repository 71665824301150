import { baseServerUrl } from "./apiConsts";
import { simpleRequest } from "./apiHelpers";

export const verifyAuthentication = async () => {
    return simpleRequest<boolean>('get', `${baseServerUrl}/auth/verify`, true, {
        dataParser: (data) => data === true
    });
};

export interface LoginParams {
    username: string,
    password: string,
};
export const login = async ({ username, password }: LoginParams) => {
    return simpleRequest<boolean>('post', `${baseServerUrl}/auth/password`, true, {
        body: { username, password },
        dataParser: (data) => data === true
    });
};

export const logout = async () => {
    return simpleRequest<boolean>('get', `${baseServerUrl}/auth/logout`, true, {
        dataParser: (data) => data === true
    });
};