import React, { useMemo } from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { AppBar, createStyles, Divider, makeStyles, Theme, Toolbar } from '@material-ui/core';

import TopBarIcon from '../TopBarIcon/TopBarIcon';
import { topBarStyles, getTopBarHeight } from '../../TopBar';

import { NotificationsIcon, SettingsIcon } from '../../../../utils/Icons';

import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bottomAppBar: {
            bottom: '0',
            top: 'auto',
        },
        bottomBarToolbarRoot: {
            justifyContent: 'center',
        },
    }));

export interface BottomBarProps {

};
const BottomBar = () => {
    const topBarHeight = useMemo(() => getTopBarHeight(true), []);

    const classes = { ...topBarStyles({ topBarHeight }), ...useStyles() };

    return (
        <AppBar color="primary" position="fixed" className={classes.bottomAppBar}>
            <Toolbar classes={{ root: classes.bottomBarToolbarRoot }}>
                <Grid columns="auto auto auto">
                    <Cell>
                        <Link to="/accounthub/profile/notifications">
                            <TopBarIcon>
                                <NotificationsIcon />
                            </TopBarIcon>
                        </Link>
                    </Cell>
                    <Cell className="center-vertical-soft">
                        <Divider orientation="vertical" classes={{ root: classes.topBarDivider }} />
                    </Cell>
                    <Cell>
                        <Link to="/accounthub">
                            <TopBarIcon>
                                <SettingsIcon />
                            </TopBarIcon>
                        </Link>
                    </Cell>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default BottomBar;