import React from 'react';

import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';
import { CustomElementType } from '../../../types';
import TextEditorToolbarButton from './TextEditorToolbarButton';

const listTypes = ['bulleted-list', 'numbered-list'] as const;
export type CustomElementListType = typeof listTypes[number];

const isBlockActive = (editor: Editor, format: CustomElementType) => {
    const [match] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match;
};

const toggleBlock = (editor: Editor, format: CustomElementType) => {
    const isActive = isBlockActive(editor, format);
    const isList = listTypes.includes(format as CustomElementListType);

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && listTypes.includes(n.type as CustomElementListType),
        split: true,
    });
    const newProperties: Partial<SlateElement> = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

export interface TextEditorToolbarBlockButtonProps {
    format: CustomElementType,
    tooltip: string,
    children?: React.ReactNode,
};
const TextEditorToolbarBlockButton = ({ format, tooltip, children }: TextEditorToolbarBlockButtonProps) => {
    const editor = useSlate();

    const handleClick = () => {
        toggleBlock(editor, format);
    };

    return (
        <TextEditorToolbarButton active={isBlockActive(editor, format)} onClick={handleClick} tooltip={tooltip}>
            {children}
        </TextEditorToolbarButton>
    );
};

export default TextEditorToolbarBlockButton;