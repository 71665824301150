import React from 'react';
import { pathParams } from '../../../../utils/types';

import { useParams } from 'react-router-dom';

import PageTabs, { TabDefinition } from '../../../Templates/PageTabs/PageTabs';

import { AboutIcon, BadgesIcon } from '../../../../utils/Icons';
import { useAccountInfo, useCurrentAccountInfo } from '../../../../CustomHooks/useAccountInfo';

const profileTabs: TabDefinition[] = [
    {
        id: 'about',
        label: 'About',
        icon: <AboutIcon height="1.5em" width="1.5em" />
    },
    {
        id: 'badges',
        label: 'Badges',
        icon: <BadgesIcon height="1.5em" width="1.5em" />
    },
    {
        id: 'manageBadges',
        label: 'Manage Badges',
        icon: <BadgesIcon height="1.5em" width="1.5em" />,
        permission: ['admin'],
    },
];
export interface ProfileTabsProps {
};
const ProfileTabs = () => {
    const { accountInfo: { type } } = useCurrentAccountInfo();
    const { accountInfo: { username } } = useAccountInfo();
    const { userId = username } = useParams<pathParams>();

    return (
        <PageTabs baseUrl={`/profile/${userId}`} defaultTab="about" tabs={profileTabs.filter(t => !t.permission || t.permission.includes(type))} />
    );
};

export default ProfileTabs;