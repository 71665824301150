import React, { ReactElement } from 'react';
import { Group } from '../../../utils/types';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Link } from 'react-router-dom';

import { createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { PrivateGroupIcon, PublicGroupIcon } from '../../../utils/Icons';

import Hexagon from '../../Design/Hexagon/Hexagon';

import { SearchPageViewType } from '../CardSearchTemplatePage/CardSearchTemplatePage';
import GroupInfoProvider from '../../InfoProviders/GroupInfoProvider/GroupInfoProvider';
import GroupProfileHexagon from '../../GroupProfileHexagon/GroupProfileHexagon';
import GroupStats from '../GroupStats/GroupStats';

import { useBreakpoint } from '../../../CustomHooks/useBreakpoint';
import { useGroupImage } from '../../../CustomHooks/useGroupImage';
import { useGroupInfo } from '../../../CustomHooks/useGroupInfo';

export interface GroupCardStyleProps {
    viewType?: SearchPageViewType,
    breakpointIndex?: number,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            height: '100%',
            borderRadius: '1em',
            overflow: 'hidden',
            position: 'relative',
        },
        paperGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        groupProfileImageCell: {
            alignItems: ({ viewType }: GroupCardStyleProps) => viewType === "list" ? 'center' : 'end',
            justifyItems: ({ viewType }: GroupCardStyleProps) => viewType === "list" ? 'end' : 'center',
        },
        groupNameCell: {
            display: 'grid',
            textAlign: ({ viewType }: GroupCardStyleProps) => viewType === "list" ? 'start' : 'center',
            alignItems: ({ viewType }: GroupCardStyleProps) => viewType === "list" ? 'end' : 'center',
        },
        groupNameText: {
            fontSize: ({ viewType }: GroupCardStyleProps) => viewType === "list" ? '0.9em' : '1.25em',
        },
        groupDescriptionCell: {
            textAlign: ({ viewType }: GroupCardStyleProps) => viewType === "list" ? 'start' : 'center',
        },
        groupProfileImageContainer: {
            backgroundColor: 'var(--content-bg)',
        },
        groupDescriptionText: {
            fontWeight: 700,
            color: 'var(--text-label-color)',
            fontSize: '0.6875em',
        },
        contentCell: {
            paddingTop: '0',
            boxSizing: 'border-box',
        },
        groupTypeContainer: {
            position: 'absolute',
            top: '0.5em',
            right: '0.5em',
            height: '2em',
            width: '2em',
            backgroundColor: 'var(--secondary-color)',
            borderRadius: '0.3em',
            padding: '0.2em',
        },
    })
);

const groupCoverHeightMap = {
    gridCard: 1,
    list: 2,
};

interface CoverImageCellProps {
    viewType: SearchPageViewType,
};
const CoverImageCell = ({ viewType }: CoverImageCellProps) => {
    const groupCoverImageUrl = useGroupImage('cover');

    return (
        <Cell top={1} left={1} width={1} height={groupCoverHeightMap[viewType]}
            style={{ background: `rgba(0, 0, 0, 0) url("${groupCoverImageUrl}") no-repeat scroll center center / cover` }}>
        </Cell>
    );
};

const groupImageLayoutMap = {
    gridCard: {
        width: 1,
        height: 2,
    },
    list: {
        width: 2,
        height: 2,
    }
};
const groupImageSizeMap = {
    gridCard: {
        backgroundSize: 10,
        size: 8,
    },
    list: {
        backgroundSize: 4,
        size: 3,
    },
};

interface GroupImageProps {
    viewType: SearchPageViewType,
};
const GroupImage = ({ viewType }: GroupImageProps) => {
    const { groupInfo: { name }, isLoading } = useGroupInfo();
    const classes = useStyles({ viewType });

    return (
        <Cell top={1} left={1} {...groupImageLayoutMap[viewType]} className={clsx("center-horizontal", classes.groupProfileImageCell)}>
            <Link to={`/groups/${name}`}>
                {
                    isLoading ?
                        <Skeleton variant="circle" />
                        :
                        <Hexagon progress={0} size={`${groupImageSizeMap[viewType].backgroundSize}em`} trackColor="var(--content-bg)" fillColor="var(--content-bg)" className={classes.groupProfileImageContainer}>
                            <GroupProfileHexagon size={`${groupImageSizeMap[viewType].size}em`} />
                        </Hexagon>
                }
            </Link>
        </Cell>
    );
};

interface GroupTypeIndicatorProps {
    viewType: SearchPageViewType,
};
const GroupTypeIndicator = ({ viewType }: GroupTypeIndicatorProps) => {
    const { groupInfo: { private: groupPrivateType }, isLoading } = useGroupInfo();
    const { index: breakpointIndex = 0 } = useBreakpoint();

    const classes = useStyles({ viewType, breakpointIndex });
    return isLoading ?
        (
            viewType === "gridCard" ?
                <div className={clsx(classes.groupTypeContainer, "center-horizontal", "center-vertical")}>
                    {
                        groupPrivateType ?
                            <PrivateGroupIcon width="1.5em" fill="white" />
                            :
                            <PublicGroupIcon width="1.5em" fill="white" />
                    }
                </div>
                :
                null
        )
        : null;
};

interface GroupNameProps {
    viewType: SearchPageViewType,
    showTagline: boolean,
};
const GroupName = ({ viewType, showTagline = true }: GroupNameProps) => {
    const { groupInfo: { name, tagline }, isLoading } = useGroupInfo();
    const { index: breakpointIndex = 0 } = useBreakpoint();

    const classes = useStyles({ viewType, breakpointIndex });

    return (
        <>
            <Cell area="name" className={classes.groupNameCell}>
                <Typography variant="h6" className={classes.groupNameText}>
                    {
                        isLoading ?
                            <Skeleton />
                            :
                            name
                    }
                </Typography>
            </Cell>
            {
                showTagline &&
                <Cell area="tagline" className={classes.groupDescriptionCell}>
                    <Typography variant="body2" className={classes.groupDescriptionText}>
                        {
                            isLoading ?
                                <Skeleton />
                                :
                                tagline
                        }
                    </Typography>
                </Cell>
            }
        </>
    );
};

const gridLayoutMap = {
    gridCard: {
        columns: "1fr",
        rows: "6em 4em 2em 2em auto 1fr auto",
        areas: [".", ".", "name", "tagline", "stats", "members", "content"],
    },
    list: {
        columns: "5em 1.5em auto auto 1fr auto",
        rows: "1fr 1fr",
        areas: [". . name stats members content", ". . tagline stats members content"],
    },
};

export interface GroupCardProps {
    groupData?: Group,
    groupId?: string,
    viewType: SearchPageViewType,
    showStats?: boolean,
    showMembers?: boolean,
    showTagline?: boolean,
    children?: ReactElement | string,
};
const GroupCard = ({ groupData, groupId, viewType, showStats = true, showMembers = false, showTagline = true, children }: GroupCardProps) => {
    const { index: breakpointIndex = 0 } = useBreakpoint();

    const classes = useStyles({ viewType, breakpointIndex });

    return (
        <GroupInfoProvider groupData={groupData} groupId={groupId}>
            <Paper classes={{ root: classes.paperRoot }}>
                <Grid {...gridLayoutMap[viewType]} className={classes.paperGrid}>
                    {/* cover photo */}
                    <CoverImageCell viewType={viewType} />
                    {/* profile icon */}
                    <GroupImage viewType={viewType} />
                    {/* group type */}
                    <GroupTypeIndicator viewType={viewType} />
                    <GroupName viewType={viewType} showTagline={showTagline} />
                    {
                        breakpointIndex >= 2 && showStats &&
                        <Cell area="stats" className="center-horizontal center-vertical">
                            <GroupStats />
                        </Cell>
                    }
                    {
                        breakpointIndex >= 3 && showMembers &&
                        <Cell area="members">
                            {`members`}
                        </Cell>
                    }
                    <Cell area="content" className={clsx(classes.contentCell, "center-vertical-soft")}>
                        {children}
                    </Cell>
                </Grid>
            </Paper>
        </GroupInfoProvider >
    );
};

export default GroupCard;