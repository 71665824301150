import { useQueryClient } from "react-query";
import { UseMutationHandlerOptions } from "./types";

import { useMutationValidation } from "../api/apiHelpers";

import { createPost, deletePost, reactToPost, createComment, updatePost, createGroupPost, sharePost, submitPollAnswer } from "../api/Posts";

export const useCreatePostMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(createPost, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, postData, context) => {
            queryClient.invalidateQueries(['posts', 'all']);

            onSuccess && onSuccess(data, postData, context);
        },
        errorMsg: `Creating post failed`,
        successMsg: `Post created`,
    });
};

export const useSharePostMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(sharePost, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, postId, context) => {
            queryClient.invalidateQueries(['posts', 'all']);

            onSuccess && onSuccess(data, postId, context);
        },
        errorMsg: `Sharing post failed`,
        successMsg: `Post Shared`,
    });
};

export const useCreateGroupPostMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(createGroupPost, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['posts', 'all']);
            queryClient.invalidateQueries(['posts', variables.groupId]);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: `Creating post failed`,
        successMsg: `Post created`,
    });
};

export const useUpdatePostMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(updatePost, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['posts', 'all']);
            queryClient.invalidateQueries(['posts', variables.postId]);
            if (variables.fatherPostId)
                queryClient.invalidateQueries(['posts', variables.fatherPostId]);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: ({ fatherPostId }) => `Updating ${fatherPostId ? 'comment' : 'post'} failed`,
        successMsg: ({ fatherPostId }) => `${fatherPostId ? 'Comment' : 'Post'} updated`,
    });
};

export const useCreateCommentMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(createComment, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['posts', variables.postId, 'comments']);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: `Commenting failed`,
        successMsg: `Comment created`,
    });
};

export const useDeletePostMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(deletePost, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['posts', 'all']);
            queryClient.invalidateQueries(['posts', variables.postId]);
            if (variables.fatherPostId)
                queryClient.invalidateQueries(['posts', variables.fatherPostId]);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: ({ fatherPostId }) => `Deleting ${fatherPostId ? 'comment' : 'post'} failed`,
        successMsg: ({ fatherPostId }) => `${fatherPostId ? 'Comment' : 'Post'} deleted`,
    });
};

export const useReactToPostMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(reactToPost, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['posts', 'all']);
            queryClient.invalidateQueries(['posts', variables.postId]);
            queryClient.invalidateQueries(['posts', variables.fatherPostId]);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: `Reacting to post failed`,
        successMsg: `Reaction saved`,
    });
};

export const useSubmitPollAnswerMutation = ({ onMutate, onSuccess, onError, onSettled }: UseMutationHandlerOptions | undefined = {}) => {
    const queryClient = useQueryClient();

    return useMutationValidation(submitPollAnswer, {
        onMutate,
        onError,
        onSettled,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['posts', variables.postId, 'poll', 'results']);
            queryClient.invalidateQueries(['posts', variables.postId, 'poll', 'myVote']);

            onSuccess && onSuccess(data, variables, context);
        },
        errorMsg: `Answering poll failed`,
        successMsg: `Vote saved`,
    });
};