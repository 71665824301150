import React from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, Divider, makeStyles, Theme } from '@material-ui/core';

import StatCell from '../StatCell/StatCell';

import { useGroupInfo } from '../../../CustomHooks/useGroupInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        profileStatDivider: {
            backgroundColor: 'var(--content-inside-border-color)',
            marginRight: '1.5em',
            marginLeft: '1.5em',
            marginTop: '30%',
            height: '40%',
        },
    })
);

const GroupStats = () => {
    const { groupInfo: { members }, isLoading } = useGroupInfo();

    const classes = useStyles();

    return (
        <Grid gap="0" rows="1fr" columns="repeat(3, auto)">
            <Cell>
                <StatCell label="MEMBERS" value={members?.length || 0} isLoading={isLoading} />
            </Cell>
            <Cell>
                <Divider orientation="vertical" classes={{ root: classes.profileStatDivider }} />
            </Cell>
            <Cell>
                <StatCell label="POSTS" value="10" isLoading={isLoading} />
            </Cell>
        </Grid>
    );
};

export default GroupStats;