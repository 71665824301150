import React, { useMemo } from 'react';
import { Account, pathParams } from '../../../../utils/types';

import { useParams } from 'react-router-dom';

import { AboutIcon, AvatarIcon, NewsfeedIcon } from '../../../../utils/Icons';

import PageTabs, { TabDefinition } from '../../../Templates/PageTabs/PageTabs';

import { useGroupInfo } from '../../../../CustomHooks/useGroupInfo';
import { useAccountInfo } from '../../../../CustomHooks/useAccountInfo';

const groupProfileTabs: TabDefinition[] = [
    {
        id: 'about',
        label: 'About',
        icon: <AboutIcon height="1.5em" width="1.5em" />
    },
    {
        id: 'members',
        label: 'Members',
        icon: <AvatarIcon height="1.5em" width="1.5em" />
    },
    {
        id: 'posts',
        label: 'Discussion',
        icon: <NewsfeedIcon height="1.5em" width="1.5em" />
    },
];
export interface ProfileTabsProps {
};
const GroupProfileTabs = () => {
    const { accountInfo: { accountId } } = useAccountInfo();
    const { groupInfo: { admins, maintainers, members } } = useGroupInfo();
    const { groupName } = useParams<pathParams>();

    const memberType = useMemo(() => {
        if ((admins as Account[])?.find(a => a.accountId === accountId))
            return 'admin';
        if ((maintainers as Account[])?.find(m => m.accountId === accountId))
            return 'maintainer';
        if (members?.find(m => (m.account as Account).accountId === accountId))
            return 'member';

        return 'viewer';
    }, [admins, maintainers, members, accountId]);

    return (
        <PageTabs baseUrl={`/groups/${groupName}`} defaultTab="about" tabs={groupProfileTabs.filter(t => !t.permission || t.permission.includes(memberType))} />
    );
};

export default GroupProfileTabs;