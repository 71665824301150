import React, { ComponentType, useMemo } from 'react';
import { Notification, NotificationCategory, NotificationType } from '../../../utils/types';

import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import AccountInfoProvider from '../../InfoProviders/AccountInfoProvider/AccountInfoProvider';
import AccountProfileHexagon from '../../AccountProfileHexagon/AccountProfileHexagon';

import HumanizeDuration from 'humanize-duration';

import { CommentIcon, JoinGroupIcon, LeaveGroupIcon, ShareIcon, ThumbsUpIcon } from '../../../utils/Icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rowGrid: {
            width: '100%',
        },
        descriptionText: {
            display: 'inline',
        },
        descriptionUsername: {
            fontWeight: 700,
        },
        descriptionName: {
            color: 'var(--icon-fill-hover)',
            fontWeight: 600,
        },
        iconCell: {
            opacity: 0.4,
        },
    })
);

const notificationIconMap: Record<NotificationCategory, ComponentType<any>> = {
    reaction: ThumbsUpIcon,
    comment: CommentIcon,
    share: ShareIcon,
    recievedGroupInvite: JoinGroupIcon,
    acceptedGroupInvite: JoinGroupIcon,
    declinedGroupInvite: LeaveGroupIcon,
};

const notificationDescriptionsMap: Record<NotificationCategory, string | ((name: string) => string)> = {
    reaction: (name) => `left a ${name} reaction on your`,
    comment: `posted a comment on your`,
    share: `shared your`,
    recievedGroupInvite: 'sent you a group invite to',
    acceptedGroupInvite: 'accepted your group invite to',
    declinedGroupInvite: 'declined your group invite to',
};

const notificationSubjectPathMap: Record<NotificationType, (subjectId: string, subjectName: string) => string> = {
    blogPost: (id, name) => `/blogPost/${id}`,
    comment: (id, name) => `/comment/${id}`,
    poll: (id, name) => `/poll/${id}`,
    status: (id, name) => `/status/${id}`,
    groupInvite: (id, name) => `/accounthub/groups/groupInvitations`,
    groupInviteResponse: (id, name) => `/groups/${name}`,
};

const notificationSubjectTextMap: Record<NotificationType, (subjectId: string, subjectName: string) => string> = {
    blogPost: (id, name) => `blog post`,
    comment: (id, name) => `comment`,
    poll: (id, name) => `poll`,
    status: (id, name) => `status update`,
    groupInvite: (id, name) => `${name}`,
    groupInviteResponse: (id, name) => `${name}`,
};

const parseNotification = ({ category, type, name, subjectId, subjectName = '' }: Notification) => {
    let description = notificationDescriptionsMap[category];
    if (typeof description === "function")
        description = description(name || '');

    return {
        description,
        subject: {
            path: notificationSubjectPathMap[type](subjectId, subjectName),
            text: notificationSubjectTextMap[type](subjectId, subjectName),
        },
    };
};

export interface NotificationRowProps {
    notification: Notification,
    onClose?: () => void,
};
const NotificationRow = ({ notification, onClose }: NotificationRowProps) => {
    const { category, date, account: { username } } = notification;
    const classes = useStyles();

    const parsedNotification = useMemo(() =>
        parseNotification(notification),
        [notification]
    );

    return (
        <Grid gap="0" columnGap="0.5em" rows="auto auto" columns="auto 1fr auto" areas={["profileIcon description icon", "profileIcon date icon"]}
            className={classes.rowGrid}>
            <Cell area="profileIcon">
                <AccountInfoProvider username={username}>
                    <Link to={`/profile/${username}`} onClick={onClose}>
                        <AccountProfileHexagon size="3em" levelSize="medium" />
                    </Link>
                </AccountInfoProvider>
            </Cell>
            <Cell area="description">
                <Typography variant="body1" className={clsx(classes.descriptionText, classes.descriptionUsername)}>
                    <Link to={`/profile/${username}`} onClick={onClose}>
                        {`${username}`}
                    </Link>
                </Typography>
                <Typography variant="body1" className={clsx(classes.descriptionText)}>
                    {` `}
                    {parsedNotification.description}
                    {` `}
                </Typography>
                <Typography variant="body1" className={clsx(classes.descriptionText, classes.descriptionName)}>
                    <Link to={`${parsedNotification.subject.path}`} onClick={onClose}>
                        {parsedNotification.subject.text}
                    </Link>
                </Typography>
            </Cell>
            <Cell area="date">
                <Typography variant="caption">
                    {`${HumanizeDuration(Date.now() - date.valueOf(), { largest: 1 })} ago`}
                </Typography>
            </Cell>
            <Cell area="icon" className={clsx(classes.iconCell, "center-vertical")}>
                {(() => {
                    const IconComp = notificationIconMap[category];
                    return <IconComp fill="var(--icon-fill)" width="1.5em" />
                })()}
            </Cell>
        </Grid>
    );
};

export default NotificationRow;