import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { Cell, Grid } from 'styled-css-grid';

import BannerPageTemplate from '../Templates/BannerPageTemplate/BannerPageTemplate';

import BadgeCard from './components/BadgeCard/BadgeCard';

import { baseBannerPageImages } from '../../utils/api/apiConsts';

import { useBadges } from '../../CustomHooks/useBadges';

const useStyles = makeStyles((theme: Theme) => createStyles({
    badgesGrid: {
        justifyContent: 'center',
    },
}));

const BadgesPage = () => {
    const badges = useBadges();

    let classes = useStyles();

    return (
        <BannerPageTemplate title="Badges" subtitle="Check out all your unlocked and locked badges!" iconUrl={`${baseBannerPageImages}/badges-icon`}>
            <Grid gap="1em" columns="repeat(auto-fill, 18em)" rows="repeat(auto-fit, 23em)" className={classes.badgesGrid}>
                {
                    badges?.map(badge => (
                        <Cell key={badge.badgeId}>
                            <BadgeCard badge={badge} />
                        </Cell>
                    ))
                }
            </Grid>
        </BannerPageTemplate>
    )
};

export default BadgesPage;