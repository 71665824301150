import React, { ReactElement, useCallback } from 'react';
import { Account } from '../../../utils/types';

import { useQueryClient } from 'react-query';

import { AccountInfoContext, CurrentAccountInfoContext } from '../../../context/AccountInfoContext';

import { useAccountInfoQuery } from '../../../utils/queries/accountQueries';

export interface AccountInfoProviderProps {
    username?: string,
    accountData?: Partial<Account>,
    children: ReactElement | string,
    enabled?: boolean,
    currentAccount?: boolean,
};
const AccountInfoProvider = ({ username, accountData, children, enabled = true, currentAccount = false }: AccountInfoProviderProps) => {
    const queryClient = useQueryClient();
    const accountInfoQuery = useAccountInfoQuery((username || accountData?.username) as string, {
        enabled,
        initialData: accountData as Account,
    });

    const clearData = useCallback(() => {
        queryClient.setQueryData(['accounts', username || accountData?.username], {});
    }, [accountData?.username, queryClient, username]);

    if (currentAccount)
        return (
            <CurrentAccountInfoContext.Provider value={{
                clearData,
                accountInfo: accountInfoQuery.data || {} as Account,
                reloadAccountInfo: accountInfoQuery.refetch,
                isLoading: accountInfoQuery.isLoading,
            }}>
                {children}
            </CurrentAccountInfoContext.Provider>
        );

    return (
        <AccountInfoContext.Provider value={{
            clearData,
            accountInfo: accountInfoQuery.data || {} as Account,
            reloadAccountInfo: accountInfoQuery.refetch,
            isLoading: accountInfoQuery.isLoading,
        }}>
            {children}
        </AccountInfoContext.Provider>
    );
};

export default AccountInfoProvider;