import { createContext } from "react";
import { Group } from "../utils/types";

export type GroupInfoContextType = {
    groupInfo: Group,
    reloadGroupInfo: () => void,
    clearData: () => void,
    isLoading: boolean,
};
export const GroupInfoContext = createContext<GroupInfoContextType>({
    groupInfo: {} as Group,
    reloadGroupInfo: () => { },
    clearData: () => { },
    isLoading: false,
});