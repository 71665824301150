import React, { FormEvent } from 'react';
import { Group } from '../../../../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { TextField, Typography } from '@material-ui/core';

import GroupSettingsTabTemplate from '../GroupSettingsTabTemplate/GroupSettingsTabTemplate';

import Loader from '../../../../../../../Design/Loader/Loader';

import { useDeleteGroupMutation } from '../../../../../../../../utils/mutations/groupMutations';

export interface GroupSettingsDeleteProps {
    group: Group,
    onChange: (change: Partial<Group>) => void,
    closeWindow?: (reloadAllGroups?: boolean) => void,
};
const GroupSettingsDelete = ({ group, onChange, closeWindow }: GroupSettingsDeleteProps) => {
    const deleteGroup = useDeleteGroupMutation({ onSuccess: () => closeWindow && closeWindow(true) });

    const handleDelete = () => {
        deleteGroup.mutate(group.groupId)
    };

    const handleInput = (e: FormEvent<HTMLDivElement>) => {
        const value = (e.target as HTMLInputElement).value;

        if (value === group.name)
            handleDelete();
    };

    return (
        <GroupSettingsTabTemplate title="Delete Group">
            <Grid columns="1fr" rows="auto auto">
                <Cell>
                    <Typography variant="body1">
                        {`To delete the group type "${group.name}"`}
                    </Typography>
                </Cell>
                <Cell>
                    {
                        deleteGroup.isLoading ?
                            (
                                <Loader />
                            )
                            :
                            (
                                <TextField variant="outlined" onInput={handleInput} />
                            )
                    }
                </Cell>
            </Grid>
        </GroupSettingsTabTemplate>
    );
};

export default GroupSettingsDelete;