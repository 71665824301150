import React from 'react';

import { Cell, Grid } from 'styled-css-grid';

import GroupProfilePageAboutAboutSection from './components/GroupProfilePageAboutAboutSection/GroupProfilePageAboutAboutSection';

import { useBreakpoint } from '../../../../CustomHooks/useBreakpoint';

const SmallLayout = () => {
    return (
        <Grid columns="1fr" areas={["about", "rules", "timeline"]}>
            <Cell area="about">
                <GroupProfilePageAboutAboutSection />
            </Cell>
            <Cell area="rules">
                
            </Cell>
            <Cell area="timeline">

            </Cell>
        </Grid>
    );
};

const MediumLayout = () => {
    return (
        <Grid columns="19em 1fr" areas={["generalFields interests"]}>
            <Cell area="generalFields">
                <Grid columns="1fr" areas={["about", "rules"]}>
                    <Cell area="about">
                        <GroupProfilePageAboutAboutSection />
                    </Cell>
                    <Cell area="rules">
                        
                    </Cell>
                </Grid>
            </Cell>
            <Cell area="timeline">
                
            </Cell>
        </Grid>
    );
};

const LargeLayout = () => {
    return (
        <Grid columns="19em 1fr 19em" areas={["generalFields interests completion"]}>
            <Cell area="generalFields">
                <Grid columns="1fr" areas={["about"]}>
                    <Cell area="about">
                        <GroupProfilePageAboutAboutSection />
                    </Cell>
                </Grid>
            </Cell>
            <Cell area="rules">
                
            </Cell>
            <Cell area="timeline">
                
            </Cell>
        </Grid>
    );
};

const GroupProfilePageAbout = () => {
    const { index: breakpointIndex } = useBreakpoint();

    if (breakpointIndex <= 2)
        return <SmallLayout />;
    if (breakpointIndex <= 3)
        return <MediumLayout />;

    return <LargeLayout />;
};

export default GroupProfilePageAbout;