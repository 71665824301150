import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Cell, Grid } from 'styled-css-grid';
import clsx from 'clsx';

import { createStyles, List, ListItem, makeStyles, Theme } from '@material-ui/core';

import { getHours, getMinutes, getSeconds } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timeListRoot: {
            overflow: 'hidden',
            height: '100%',
            paddingTop: '0',
            paddingBottom: '0',
            '&:hover': {
                overflow: 'auto',
            },
        },
        timeListBorder: {
            borderRight: '1px solid var(--content-inside-border-color)',
        },
        timeListItemSelected: {
            backgroundColor: 'var(--content-bg)',
            color: 'var(--primary-color)',
        },
        timeListItemRoot: {
            '&:hover': {
                backgroundColor: 'var(--content-bg)',
                paddingLeft: '1em',
            },
        },
    })
);

interface TimeListProp {
    range: (Date | null)[],
    value: Date,
    onChange: (date: Date) => void,
    valueExtractor: (date: Date) => number,
    hideBorder?: boolean,
};
const TimeList = ({ range, onChange, valueExtractor, value, hideBorder }: TimeListProp) => {
    const selectedDateRef = useRef<HTMLDivElement>(null);

    const classes = useStyles();

    const selectedValue = useMemo(() => valueExtractor(value),
        [value, valueExtractor]
    );

    useEffect(() => {
        selectedDateRef.current?.scrollIntoView(true);
    }, [selectedValue]);

    const handleClickGenerator = (date: Date) =>
        () => onChange(date);

    const isSelected = useCallback((d: Date) => valueExtractor(d) === valueExtractor(value),
        [value, valueExtractor]
    );

    return (
        <List classes={{ root: clsx(classes.timeListRoot, { [classes.timeListBorder]: !hideBorder }) }}>
            {
                range.map(d => d ? (
                    <ListItem ref={isSelected(d) ? selectedDateRef : undefined} key={d.valueOf()} button onClick={handleClickGenerator(d)} classes={{
                        root: clsx(classes.timeListItemRoot, {
                            [classes.timeListItemSelected]: valueExtractor(d) === valueExtractor(value),
                        })
                    }}>
                        {valueExtractor(d)}
                    </ListItem>
                )
                    :
                    <ListItem>
                        {/* NOT A SPACE! alt code 0160 */}
                        {` `}
                    </ListItem>
                )
            }
        </List>
    );
};

export interface AntDateTimePickerTimeViewProps {
    value: Date,
    showSeconds: boolean,
    onChange: (date: Date) => void,
};
const AntDateTimePickerTimeView = ({ value, showSeconds, onChange }: AntDateTimePickerTimeViewProps) => {
    const { hoursRange, minutesRange, secondsRange } = useMemo(() => ({
        hoursRange: new Array(30).fill(null).map((v, idx) => {
            if (idx > 23)
                return null;

            const d = new Date(value.valueOf())
            d.setHours(idx);

            return d;
        }),
        minutesRange: new Array(66).fill(null).map((v, idx) => {
            if (idx > 59)
                return null;

            const d = new Date(value.valueOf())
            d.setMinutes(idx);

            return d;
        }),
        secondsRange: new Array(66).fill(null).map((v, idx) => {
            if (idx > 59)
                return null;

            const d = new Date(value.valueOf())
            d.setSeconds(idx);

            return d;
        })
    }), [value]);

    return (
        <Grid gap="0" rows="14.5em" columns={`repeat(${showSeconds ? '3' : '2'}, 3.5em)`}>
            <Cell>
                <TimeList value={value} range={hoursRange} valueExtractor={getHours} onChange={onChange} />
            </Cell>
            <Cell>
                <TimeList value={value} range={minutesRange} valueExtractor={getMinutes} onChange={onChange} hideBorder={!showSeconds} />
            </Cell>
            {
                showSeconds &&
                <Cell>
                    <TimeList value={value} range={secondsRange} valueExtractor={getSeconds} onChange={onChange} hideBorder />
                </Cell>
            }
        </Grid>
    );
};

export default AntDateTimePickerTimeView;