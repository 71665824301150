import React, { useEffect, useMemo, useState } from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { Button, CircularProgress, createStyles, makeStyles, RadioGroup, Theme, Typography } from '@material-ui/core';

import { usePostInfo } from '../../../../../CustomHooks/usePostInfo';

import { PollPost, Post } from '../../../../../utils/types';

import HumanizeDuration from 'humanize-duration';
import RadioButton from '../../../../Pickers/RadioButton/RadioButton';
import { useGetMyPollAnswerQuery, useGetPollResultsQuery } from '../../../../../utils/queries/postsQueries';
import { useSubmitPollAnswerMutation } from '../../../../../utils/mutations/postsMutation';
import { PollResultReturn } from '../../../../../utils/MockData';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pollGrid: {
            backgroundColor: 'var(--content-secondary-bg)',
            borderRadius: '1em',
            padding: '1em',
        },
        pollTitleText: {
            fontWeight: 700,
        },
        pollButtonRoot: {
            height: '100%',
            width: '100%'
        },
        radioButtonLoader: {
            width: '1em !important',
            height: '1em !important',
        },
    })
);

interface PollAnswerItemProps {
    answer: string,
    idx: number,
    showResults?: boolean,
    result?: PollResultReturn,
    loadingMyVote?: boolean,
    loadingResults?: boolean,
    disabled?: boolean,
};
const PollAnswerItem = ({ answer, idx, showResults = false, result, loadingMyVote = false, loadingResults = false, disabled }: PollAnswerItemProps) => {
    const classes = useStyles();

    return (
        <Grid rows="3em" columns="3em 1fr">
            <Cell className="center-vertical center-horizontal">
                {
                    loadingMyVote ?
                        <CircularProgress className={classes.radioButtonLoader} />
                        :
                        <RadioButton disabled={disabled} value={answer} />
                }
            </Cell>
            <Cell className="center-vertical">
                <Typography variant="body1">
                    {answer}
                    {showResults &&
                        (
                            loadingResults ?
                                <>
                                    {` `}
                                    <CircularProgress className={classes.radioButtonLoader} />
                                </>
                                :
                                ` (${result?.total ?? '?'} votes - ${(result?.percentage || 0) * 100}%)`
                        )
                    }
                </Typography>
            </Cell>
        </Grid>
    );
};

const PostCardPoll = () => {
    const [showResults, setShowResults] = useState(false);

    const { post } = usePostInfo();
    const { _id: postId, pollDefinition: { title, answers, expirationDate } = {} as PollPost } = post as Post;

    const [selectedAnswer, setSelectedAnswer] = useState('');

    const { data: myVote, isLoading: myVoteIsLoading } = useGetMyPollAnswerQuery(postId);
    const { data: pollResults, isLoading: pollResultsIsLoading } = useGetPollResultsQuery(postId, {
        enabled: showResults
    });

    const submitPollAnswer = useSubmitPollAnswerMutation();

    const remainingDuration = useMemo(() => (expirationDate?.valueOf() || Number.MAX_VALUE) - Date.now(),
        [expirationDate]
    );

    const classes = useStyles();

    const toggleShowResults = () => {
        setShowResults(!showResults);
    };

    useEffect(() => {
        if (myVote == null || !answers)
            return;

        setSelectedAnswer(answers[myVote] || '');
    }, [myVote, answers]);

    const onAnswerChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setSelectedAnswer(value);
    };

    const handleVoteClicked = () => {
        submitPollAnswer.mutate({ postId, answer: answers.indexOf(selectedAnswer) });
    };

    const handleSeeResultsClicked = () => {
        toggleShowResults();
    };

    return (
        <Grid rowGap="2em" columns="1fr" rows="2em 1fr 3em" className={classes.pollGrid}>
            <Cell>
                <Typography variant="body1" className={classes.pollTitleText}>
                    {title}
                </Typography>
                {
                    expirationDate && (
                        remainingDuration > 0 ?
                            (
                                <Typography variant="caption">
                                    {`Poll ends in ${HumanizeDuration(remainingDuration, { round: true, largest: 1 })}`}
                                </Typography>
                            )
                            :
                            (
                                <Typography variant="caption">
                                    {`Poll ended ${HumanizeDuration(remainingDuration, { round: true, largest: 1 })} ago`}
                                </Typography>
                            )
                    )
                }
            </Cell>
            <Cell>
                <RadioGroup value={selectedAnswer} onChange={onAnswerChange}>
                    {
                        answers.map((a, idx) => (
                            <PollAnswerItem key={idx} answer={a} idx={idx} showResults={showResults} result={pollResults?.[idx]}
                                loadingMyVote={myVoteIsLoading} loadingResults={pollResultsIsLoading} disabled={remainingDuration < 0} />
                        ))
                    }
                </RadioGroup>
            </Cell>
            <Cell>
                <Grid columns="7em 7em" rows="2.5em">
                    <Button disabled={(expirationDate?.valueOf() || Infinity) - Date.now() < 0} variant="contained" color="secondary" classes={{ root: classes.pollButtonRoot }} onClick={handleVoteClicked}>
                        {`Vote Now!`}
                    </Button>
                    <Button variant="text" color="primary" classes={{ root: classes.pollButtonRoot }} onClick={handleSeeResultsClicked}>
                        {`See Results`}
                    </Button>
                </Grid>
            </Cell>
        </Grid>
    );
};

export default PostCardPoll;