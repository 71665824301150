import React from 'react';
import { Comment, Post } from '../../../utils/types';

import { PostInfoContext } from '../../../context/PostInfoContext';

export interface PostInfoProviderProps {
    post: Post | Comment,
    fatherPost?: Post | Comment,
    children: React.ReactNode,
};
const PostInfoProvider = ({ post, children, fatherPost }: PostInfoProviderProps) => {
    return (
        <PostInfoContext.Provider value={{ post, fatherPost }}>
            {children}
        </PostInfoContext.Provider>
    );
};

export default PostInfoProvider;