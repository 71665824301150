import React, { FormEvent, useMemo, useRef, useState } from 'react';

import { Cell, Grid } from 'styled-css-grid';
import clsx from 'clsx';

import { createStyles, makeStyles, Popover, TextField, TextFieldProps, Theme, Typography } from '@material-ui/core';

import AntDateTimePickerDateHeader from './components/AntDateTimePickerDateHeader/AntDateTimePickerDateHeader';
import AntDateTimePickerDateView from './components/AntDateTimePickerDateView/AntDateTimePickerDateView';
import AntDateTimePickerTimeView from './components/AntDateTimePickerTimeView/AntDateTimePickerTimeView';

import { format } from 'date-fns';
import { useBreakpoint } from '../../../CustomHooks/useBreakpoint';

export interface AntDateTimePickerStyleProps {
    showTime?: boolean,
    showSeconds?: boolean,
    isNativePicker?: boolean,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        datePickerContainer: {
            position: 'relative',
            width: ({ showSeconds, showTime, isNativePicker }: AntDateTimePickerStyleProps) =>
                `${6 + (showTime ? 2 : 0) + (showSeconds || isNativePicker ? 1 : 0) + (isNativePicker ? 2 : 0)}em`,
        },
        datePickerPopperGrid: {
            backgroundColor: 'var(--content-secondary-bg)',
        },
        datePickerViewCell: {
            borderRight: ({ showTime }: AntDateTimePickerStyleProps) => showTime ? '1px solid var(--content-inside-border-color)' : '',
            boxSizing: 'border-box',
            padding: '0.5em',
        },
        datePickerDateHeaderCell: {
            borderRight: ({ showTime }: AntDateTimePickerStyleProps) => showTime ? '1px solid var(--content-inside-border-color)' : '',
            borderBottom: '1px solid var(--content-inside-border-color)',
            boxSizing: 'border-box',
            padding: '0.5em',
        },
        datePickerTimeHeaderCell: {
            borderBottom: '1px solid var(--content-inside-border-color)',
            boxSizing: 'border-box',
            padding: '0.5em',
        },
    })
);
export interface AntDateTimePickerProps {
    value?: Date,
    onChange?: (date: Date) => void,
    showTime?: boolean,
    showSeconds?: boolean,
    dateFormat?: string,
    timeFormat?: string,
    valueFormat?: string,
    inputSize?: TextFieldProps['size'],
    minDate?: Date,
    maxDate?: Date,
    disabledDates?: Date[] | ((date: Date) => boolean),
};

type AntDateTimePickerCalculatedDefaultFields = 'valueFormat' | 'timeFormat';
type AntDateTimePickerNonCalculatedProps = Omit<AntDateTimePickerProps, AntDateTimePickerCalculatedDefaultFields>;
type AntDateTimePickerCalculatedProps = Pick<AntDateTimePickerProps, AntDateTimePickerCalculatedDefaultFields>;

const dateTimePickerDefaults: Required<AntDateTimePickerNonCalculatedProps> = {
    value: new Date(),
    onChange: () => { },
    showTime: false,
    showSeconds: false,
    dateFormat: 'dd/MM/yyyy',
    inputSize: 'small',
    minDate: null as unknown as Date,
    maxDate: null as unknown as Date,
    disabledDates: [],
};

const dateTimePickerCalculatedDefaults = ({ dateFormat, timeFormat, showTime, showSeconds }: AntDateTimePickerProps): Required<AntDateTimePickerCalculatedProps> => {
    const defaultTimeFormat = showSeconds ? 'HH:mm:ss' : 'HH:mm';

    return {
        timeFormat: defaultTimeFormat,
        valueFormat: `${dateFormat} ${showTime ? (timeFormat ?? defaultTimeFormat) : ''}`,
    };
};

const AntDateTimePicker = (props: AntDateTimePickerProps) => {
    const { onChange, value, showTime, showSeconds, inputSize, timeFormat, valueFormat, minDate, maxDate, disabledDates } = useMemo(() => {
        const nonCalculatedFields = { ...dateTimePickerDefaults, ...props };
        const calculatedFields = { ...dateTimePickerCalculatedDefaults(nonCalculatedFields), ...props };

        return { ...nonCalculatedFields, ...calculatedFields };
    }, [props]);

    const [pickerOpen, setPickerOpen] = useState(false);
    const [shownDate, setShownDate] = useState(value);
    const [nativeValue, setNativeValue] = useState('');

    const { index: breakpointIndex } = useBreakpoint();
    const isNativePicker = useMemo(() => breakpointIndex < 2,
        [breakpointIndex]
    );

    const containerRef = useRef(null);

    const classes = useStyles({ showTime, showSeconds, isNativePicker });

    const handleDateChange = (date: Date) => {
        if (date) {
            if (!showTime) {
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
            }
            if (!showSeconds)
                date.setSeconds(0);
        }

        onChange(date);
    };

    const handleNativeDateInput = (e: FormEvent<HTMLDivElement>) => {
        if (!isNativePicker)
            return;

        const updatedNativeValue = (e.target as HTMLInputElement).value;
        const date = new Date(updatedNativeValue);

        setNativeValue(updatedNativeValue);
        handleDateChange(date);
    };

    const openPicker = () => {
        if (isNativePicker)
            return;

        setPickerOpen(true);
    };

    const closePicker = () => {
        setPickerOpen(false);
    };

    return (
        <div ref={containerRef} className={classes.datePickerContainer}>
            <TextField label="Expiration Date" type={isNativePicker ? 'datetime-local' : 'text'} size={inputSize} variant="outlined" onClick={openPicker}
                onInput={handleNativeDateInput} value={isNativePicker ? nativeValue : format(value, valueFormat)} />
            <Popover
                open={pickerOpen}
                onClose={closePicker}
                anchorEl={containerRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid className={classes.datePickerPopperGrid} gap="0" columns="auto auto" rows="auto 14.5em"
                    areas={["dateHeader timeHeader", "datePicker timePicker"]}>
                    <Cell area="dateHeader" className={classes.datePickerDateHeaderCell}>
                        <AntDateTimePickerDateHeader value={shownDate} onShownDateChange={setShownDate} showTime={showTime} />
                    </Cell>
                    {
                        showTime &&
                        <Cell area="timeHeader" className={clsx("center-vertical center-horizontal", classes.datePickerTimeHeaderCell)}>
                            <Typography variant="body1">
                                {format(value, timeFormat)}
                            </Typography>
                        </Cell>
                    }
                    <Cell area="datePicker" className={classes.datePickerViewCell}>
                        <AntDateTimePickerDateView onDateSelect={handleDateChange} value={value} shownDate={shownDate} minDate={minDate}
                            maxDate={maxDate} disabledDates={disabledDates} />
                    </Cell>
                    {
                        showTime &&
                        <Cell area="timePicker">
                            <AntDateTimePickerTimeView value={value} showSeconds={showSeconds} onChange={handleDateChange} />
                        </Cell>
                    }
                </Grid>
            </Popover>
        </div>
    );
};

export default AntDateTimePicker;