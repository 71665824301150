import React from 'react';
import { CustomElementType } from '../../types';

import { DefaultElement, RenderElementProps } from 'slate-react';

import {
    BulletListElement, Header1Element, Header2Element, Header3Element, Header4Element, Header5Element,
    Header6Element, ListItemElement, MentionElement, NumberListElement
} from './SimpleBlockElements';

const elementTypeMap: Record<CustomElementType, React.ComponentType<RenderElementProps>> = {
    paragraph: DefaultElement,
    header1: Header1Element,
    header2: Header2Element,
    header3: Header3Element,
    header4: Header4Element,
    header5: Header5Element,
    header6: Header6Element,
    "bulleted-list": BulletListElement,
    "numbered-list": NumberListElement,
    "list-item": ListItemElement,
    mention: MentionElement,
};

const BlockElement = ({ element, ...others }: RenderElementProps) => {
    const Element = elementTypeMap[element.type];
    return (
        <Element element={element} {...others} />
    );
};

export default BlockElement;