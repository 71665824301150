import React, { useState } from 'react';
import { Group } from '../../../../utils/types';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { useQueryClient } from 'react-query';

import { Button, createStyles, Dialog, makeStyles, Paper, Theme, Typography } from '@material-ui/core';

import { GroupIcon } from '../../../../utils/Icons';

import { Skeleton } from '@material-ui/lab';

import Hexagon from '../../../Design/Hexagon/Hexagon';
import Loader from '../../../Design/Loader/Loader';

import GroupInfoProvider from '../../../InfoProviders/GroupInfoProvider/GroupInfoProvider';
import GroupSettingsWindow from './components/GroupSettingsWindow/GroupSettingsWindow';
import AccountHubPageTemplate from '../AccountHubPageTemplate/AccountHubPageTemplate';

import GroupProfileHexagon from '../../../GroupProfileHexagon/GroupProfileHexagon';

import { useGroupInfo } from '../../../../CustomHooks/useGroupInfo';
import { useGroupImage } from '../../../../CustomHooks/useGroupImage';

import { useFetchValidation } from '../../../../utils/api/apiHelpers';
import { useAllMaintainableGroupsQuery } from '../../../../utils/queries/groupsQueries';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupsCardsGrid: {
            justifyContent: 'center',
            '&[class*="Grid"]': {
                gridAutoRows: '18em',
            },
        },
        groupCardPaperRoot: {
            height: '100%',
        },
        groupCardGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        groupProfileImageCell: {
            alignItems: 'end',
            justifyItems: 'center',
        },
        groupProfileImageContainer: {
            backgroundColor: 'var(--content-bg)',
        },
        groupNameCell: {
            display: 'grid',
            textAlign: 'center',
            alignItems: 'center',
            paddingRight: '1em',
            paddingLeft: '1em',
        },
        groupButtonCell: {
            padding: '1em',
        },
        groupButtonRoot: {
            width: '100%',
        },
        groupIconSkeleton: {
            width: '6em',
            height: '6em',
        },
        groupSettingsWindowPaper: {
            height: '35em',
        },
    })
);

interface GroupCardProps {
    newGroup?: boolean,
};
const GroupCard = ({ newGroup = false }: GroupCardProps) => {
    const [groupSettingsOpen, setGroupSettingsOpen] = useState(false);

    const { groupInfo: { name }, isLoading, reloadGroupInfo } = useGroupInfo();
    const groupCoverImageUrl = useGroupImage('cover');

    const queryClient = useQueryClient();

    const classes = useStyles();

    const openGroupSettingsWindow = () => {
        setGroupSettingsOpen(true);
    };

    const closeGroupSettings = () => {
        setGroupSettingsOpen(false);
    };

    const onGroupSettingsClose = (reloadAllGroups = false) => {
        closeGroupSettings();

        if (reloadAllGroups)
            queryClient.invalidateQueries(['groups']);
        else
            reloadGroupInfo();
    };

    return (
        <Paper className={classes.groupCardPaperRoot}>
            <Grid columns="1fr" rows="5em 3em 1fr auto" areas={[".", ".", "name", "button"]} className={classes.groupCardGrid}>
                <Cell top={1} left={1} width={1} height={1}
                    style={{
                        background: `rgba(0, 0, 0, 0) url("${groupCoverImageUrl}") no-repeat scroll center center / cover`,
                        backgroundColor: 'var(--content-secondary-bg)',
                    }}>
                </Cell>
                <Cell top={1} left={1} width={1} height={2} className={clsx("center-horizontal", classes.groupProfileImageCell)}>
                    {
                        isLoading ?
                            (
                                <Skeleton variant="circle" className={classes.groupIconSkeleton} />
                            )
                            :
                            (
                                <Hexagon progress={0} size={`7em`} trackColor="var(--content-bg)" fillColor="var(--content-bg)" className={classes.groupProfileImageContainer}>
                                    {
                                        newGroup ?
                                            (
                                                <Hexagon size="6em">
                                                    <GroupIcon width="1.5em" fill="var(--secondary-color)" />
                                                </Hexagon>
                                            )
                                            :
                                            (
                                                <GroupProfileHexagon size={`6em`} />
                                            )
                                    }
                                </Hexagon>
                            )
                    }
                </Cell>
                <Cell area="name" className={classes.groupNameCell}>
                    <Typography variant="h6">
                        {
                            isLoading ?
                                (
                                    <Skeleton variant="text" />
                                )
                                :
                                (
                                    newGroup ?
                                        'Create New Group'
                                        :
                                        name
                                )
                        }
                    </Typography>
                </Cell>
                <Cell area="button" className={classes.groupButtonCell}>
                    {
                        isLoading ?
                            (
                                <Skeleton variant="rect" />
                            )
                            :
                            (
                                <Button variant={newGroup ? "contained" : "outlined"} color={newGroup ? "secondary" : "primary"}
                                    classes={{ root: classes.groupButtonRoot }} onClick={openGroupSettingsWindow}>
                                    {newGroup ? `Start Creating!` : `Manage Group`}
                                </Button>
                            )
                    }
                </Cell>
            </Grid>
            <Dialog maxWidth="md" fullWidth open={groupSettingsOpen} onClose={closeGroupSettings} classes={{ paper: classes.groupSettingsWindowPaper }} >
                <GroupSettingsWindow closeWindow={onGroupSettingsClose} newGroup={newGroup} />
            </Dialog>
        </Paper>
    );
};

export interface AccountHubManageGroupsProps {
    section: string,
    title: string,
};
const AccountHubManageGroups = ({ section, title }: AccountHubManageGroupsProps) => {
    const allGroups = useFetchValidation(useAllMaintainableGroupsQuery, { errorMsg: 'Error loading groups' });

    const classes = useStyles();

    return (
        <AccountHubPageTemplate section={section} title={title}>
            {
                allGroups.isLoading ?
                    (
                        <Loader />
                    )
                    :
                    (
                        <Grid columns="repeat(auto-fill, 18em)" className={classes.groupsCardsGrid}>
                            <Cell>
                                <GroupInfoProvider groupData={{} as Group} enabled={false}>
                                    <GroupCard newGroup={true} />
                                </GroupInfoProvider>
                            </Cell>
                            {
                                allGroups.data?.map(g =>
                                    <Cell key={g.name}>
                                        <GroupInfoProvider groupData={g}>
                                            <GroupCard />
                                        </GroupInfoProvider>
                                    </Cell>
                                )
                            }
                        </Grid>
                    )
            }
        </AccountHubPageTemplate>
    );
};

export default AccountHubManageGroups;