import React from 'react';
import { Comment, Post } from '../../../../../../../../../utils/types';

import { createStyles, List, ListItem, makeStyles, Theme, Typography } from '@material-ui/core';

import Loader from '../../../../../../../../Design/Loader/Loader';
import { useCurrentAccountInfo } from '../../../../../../../../../CustomHooks/useAccountInfo';
import { useDeletePostMutation } from '../../../../../../../../../utils/mutations/postsMutation';
import { usePostInfo } from '../../../../../../../../../CustomHooks/usePostInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        settingsListItemText: {
            fontSize: '0.75em',
            fontWeight: 700,
        },
    })
);

export interface PostCardCommentSettingsMenuProps {
    onClose?: () => void,
    toggleEditComment?: () => void,
};
const PostCardCommentSettingsMenu = ({ onClose, toggleEditComment }: PostCardCommentSettingsMenuProps) => {
    const { post: comment, fatherPost: { _id: fatherPostId } = {} as Post } = usePostInfo();
    const { _id: commentId, writer: { accountId: writerAccountId }, deleted } = comment as Comment;

    const { accountInfo: { accountId: currentAccountId } } = useCurrentAccountInfo();

    const deleteComment = useDeletePostMutation();

    const classes = useStyles();

    const handleEditComment = () => {
        toggleEditComment && toggleEditComment();
        onClose && onClose();
    };

    const handleDeleteComment = () => {
        deleteComment.mutate({ postId: commentId, fatherPostId }, {
            onSuccess: () => {
                onClose && onClose();
            },
        })
    };

    const settingsMenuActions = [
        ...(
            writerAccountId === currentAccountId && !deleted ?
                [{
                    label: 'Edit comment',
                    action: handleEditComment,
                }]
                :
                []
        ),
        ...(
            !deleted ?
                [{
                    label: 'Delete comment',
                    action: handleDeleteComment,
                }]
                :
                []
        ),
    ];

    return deleteComment.isLoading ?
        <Loader />
        :
        (
            <List>
                {
                    settingsMenuActions.map(actionDefinition => (
                        <ListItem key={actionDefinition.label} button onClick={actionDefinition.action}>
                            <Typography variant="body1" className={classes.settingsListItemText}>
                                {actionDefinition.label}
                            </Typography>
                        </ListItem>
                    ))
                }
            </List>
        );
};

export default PostCardCommentSettingsMenu;