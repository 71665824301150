import React, { ReactElement } from 'react';

import { Box, CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

interface CircleStyleProps {
    color: string,
    trackColor: string,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ProgressCircleBackground: {
            color: ({ trackColor }: CircleStyleProps) => trackColor,
            position: 'absolute',
        },
        ProgressCircleProgress: {
            color: ({ color }: CircleStyleProps) => color,
        },
        progressCircleText: {
            fontWeight: 700,
        },
    })
);

export interface CircleProps {
    progress?: number,
    children?: ReactElement,
    color?: string,
    trackColor?: string,
    showValueLabel?: boolean,
    size?: string,
};
const Circle = ({ progress = 0, children, color = 'var(--primary-color)', trackColor = 'var(--content-secondary-bg)', showValueLabel = true, size = "9em" }: CircleProps) => {
    const classes = useStyles({ color, trackColor });

    // clamp progress
    if (progress < 0)
        progress = 0;
    if (progress > 100)
        progress = 100;

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" value={100} size={size} thickness={3} className={classes.ProgressCircleBackground} />
            <CircularProgress variant="determinate" value={progress} size={size} thickness={3} className={classes.ProgressCircleProgress} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {
                    showValueLabel ?
                        (
                            <Typography variant="h4" className={classes.progressCircleText}>
                                {`${progress}%`}
                            </Typography>
                        )
                        :
                        children
                }
                {children}
            </Box>
        </Box>
    );
};

export default Circle;