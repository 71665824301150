import React from 'react';

import { Grid } from 'styled-css-grid';

import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { format } from 'date-fns';

import ProfilePageAboutField from '../../../../../Templates/ProfilePageAboutField/ProfilePageAboutField';

import { useGroupInfo } from '../../../../../../CustomHooks/useGroupInfo';
import { Account } from '../../../../../../utils/types';

const GroupProfilePageAboutAboutSection = () => {
    const { groupInfo: { createdAt, createdBy, description, private: privateType, type }, isLoading } = useGroupInfo();

    return (
        <Card>
            <CardHeader title="Group Info" />
            <CardContent>
                <Typography variant="body1">
                    {
                        isLoading ?
                            <Skeleton variant="rect" />
                            :
                            description
                    }
                </Typography>
            </CardContent>
            <CardContent>
                <Grid columns="5em 1fr">
                    <ProfilePageAboutField label="Created" value={createdAt ? format(createdAt, 'MMMM do, yyyy') : undefined} loading={isLoading} />
                    <ProfilePageAboutField label="Created By" value={(createdBy as Account)?.username} loading={isLoading} />
                    <ProfilePageAboutField label="Type" value={`${privateType ? 'Private' : 'Public'} / ${type?.[0].toUpperCase() + type?.slice(1)}`} loading={isLoading} />
                </Grid>
            </CardContent>
        </Card>
    );
};

export default GroupProfilePageAboutAboutSection;