import React from 'react'

import { createStyles, List, ListItem, makeStyles, Theme, Typography } from '@material-ui/core';

import Loader from '../../../Design/Loader/Loader';

import NotificationRow from '../../../Templates/NotificationRow/NotificationRow';

import { useCurrentAccountInfo } from '../../../../CustomHooks/useAccountInfo';
import clsx from 'clsx';

interface NotificationsMenuStyleProps {
    maxWidth: string,
    dense: boolean,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItemRoot: {
            height: ({ dense }: NotificationsMenuStyleProps) => dense ? 'auto' : '5.5em',
            marginTop: ({ dense }: NotificationsMenuStyleProps) => dense ? '0' : '1em',
            marginBottom: ({ dense }: NotificationsMenuStyleProps) => dense ? '0' : '1em',
            borderRadius: ({ dense }: NotificationsMenuStyleProps) => dense ? '0' : '1em',
            backgroundColor: ({ dense }: NotificationsMenuStyleProps) => dense ? 'transparent' : 'var(--content-secondary-bg)',
            '&:hover': {
                backgroundColor: ({ dense }: NotificationsMenuStyleProps) => dense ? 'var(--icon-bg-hover)' : '',
            },
        },
        newNotification: {
            backgroundColor: ({ dense }: NotificationsMenuStyleProps) => dense ? 'var(--content-secondary-bg)' : 'var(--icon-bg-hover)',
        },
        notificationsMenuList: {
            maxWidth: ({ maxWidth }: NotificationsMenuStyleProps) => maxWidth ? maxWidth : 'auto',
            borderRadius: '6em',
        },
        textButtonRoot: {
            color: 'var(--caption-color)',
            backgroundColor: 'transparent',
            marginLeft: 'auto',
            '&:hover': {
                color: 'var(--icon-fill-hover)',
                backgroundColor: 'transparent'
            },
        },
        textButtonText: {
            color: 'inherit',
            fontWeight: 700,
            opacity: 0.6,
        },
    })
);

export interface NotificationsMenuProps {
    onClose?: () => void,
    maxWidth?: string,
    showHeader?: boolean,
    dense?: boolean,
};
const NotificationsMenu = ({ onClose, maxWidth = '', showHeader = true, dense = true }: NotificationsMenuProps) => {
    const { accountInfo: { notifications }, isLoading } = useCurrentAccountInfo();

    const classes = useStyles({ maxWidth, dense });

    return (
        <List classes={{ root: classes.notificationsMenuList }}>
            {
                showHeader &&
                <ListItem className="center-vertical">
                    <Typography variant="h6">
                        {`Notifications`}
                    </Typography>
                </ListItem>
            }
            {
                isLoading ?
                    <Loader />
                    :
                    notifications.map((n, idx) => (
                        <ListItem key={n.account.username + idx.toString()} className={clsx(classes.listItemRoot, { [classes.newNotification]: !n.isRead })}>
                            <NotificationRow notification={n} onClose={onClose} />
                        </ListItem>
                    ))
            }
        </List>
    );
};

export default NotificationsMenu;