import { BadgeList, Account, Group, GroupMemberDetails, GroupInviteeDetails, Quest, Post, Comment } from "./types";

import { getBadgeIconUrl } from "./api/Badge";

export const mockAccount: Account = {
    accountId: 'asdasd',
    username: 'ofirl',
    name: 'Ofir',
    type: 'admin',
    createdAt: new Date(2021, 0, 1),
    city: 'holon',
    birthday: new Date(2021, 0, 24),
    about: 'about my self',
    email: 'ofirl24@gmail.com',
    badges: [
        {
            badgeId: 'bronzeUser',
            recievedDate: new Date(2021, 2, 1),
        }
    ],
    quests: [
        {
            questId: 'friendlyUser',
            amount: 5,
            completed: false,
        },
    ],
    exp: 10,
    level: 2,
    interests: {
        tv: 'show',
        music: 'band',
        movies: 'movies',
        books: 'book',
        games: 'games',
        hobbies: 'hobbies',
    },
    groupInvites: [{
        group: '123',
        invitee: 'asdakjjkda',
        sentAt: new Date(2021, 1, 5),
        sentBy: 'ofirl',
    }],
    notifications: [{
        category: 'reaction',
        type: 'comment',
        name: 'like',
        subjectId: '123',
        account: {
            username: 'shakedb',
        },
        date: new Date(2021, 1, 2, 22, 45, 34),
        isRead: false,
    }],
};

export type GetAllAccountsReturn = {
    accounts: Account[],
    more: boolean,
};
export const mockAllAccounts: GetAllAccountsReturn = {
    accounts: [mockAccount],
    more: true,
};

export const mockGroupMemberDetail: GroupMemberDetails = {
    ...mockAccount,
    joinedAt: new Date(2021, 1, 5),
};

export const mockGroupInviteeDetail: GroupInviteeDetails = {
    ...mockAccount,
    sentAt: new Date(2021, 1, 5),
};

export const mockBadges: BadgeList = [
    {
        badgeId: "bronzeUser",
        title: "Bronze User",
        description: "Has done something",
        exp: 20,
        iconUrl: getBadgeIconUrl("bronzeUser"),
    },
    {
        badgeId: "silverUser",
        title: "Silver User",
        description: "Has done something more awsome",
        exp: 40,
        iconUrl: getBadgeIconUrl("silverUser"),
    },
    {
        badgeId: "goldUser",
        title: "Gold User",
        description: "Has done something more more awsome",
        exp: 60,
        iconUrl: getBadgeIconUrl("goldUser"),
    },
    {
        badgeId: "platinumUser",
        title: "Platinum User",
        description: "Has done something more more most awsome",
        exp: 100,
        iconUrl: getBadgeIconUrl("platinumUser"),
    },
    {
        badgeId: "caffeinated",
        title: "Caffeinated",
        description: "Has worked all night",
        exp: 40,
        iconUrl: getBadgeIconUrl("caffeinated"),
    },
    {
        badgeId: "ultraPowered",
        title: "Ultra Powered",
        description: "Ultra!!!",
        exp: 80,
        iconUrl: getBadgeIconUrl("ultraPowered"),
    },
    {
        badgeId: "scientist",
        title: "Crazy Scientist",
        description: "Has came up with a super crazy idea",
        exp: 40,
        iconUrl: getBadgeIconUrl("scientist"),
    },
    {
        badgeId: "nightCreature",
        title: "Night Creature",
        description: "Has worked all nights for a week",
        exp: 20,
        iconUrl: getBadgeIconUrl("nightCreature"),
    },
    {
        badgeId: "warrior",
        title: "The Warrior",
        description: "Helped enforce the rules",
        exp: 40,
        iconUrl: getBadgeIconUrl("warrior"),
    },
    {
        badgeId: "liked",
        title: "Liked User",
        description: "People likes you :)",
        exp: 60,
        iconUrl: getBadgeIconUrl("liked"),
    },
    {
        badgeId: "superLoved",
        title: "Super Loved User",
        description: "People realy loves you :)",
        exp: 80,
        iconUrl: getBadgeIconUrl("superLoved"),
    },
    {
        badgeId: "questConquerer_1",
        title: "Quest Conquerer",
        description: "Completed 10 quests",
        exp: 40,
        iconUrl: getBadgeIconUrl("questConquerer_1"),
    },
    {
        badgeId: "villain",
        title: "Villain",
        description: "Why are you so mean?",
        exp: 40,
        iconUrl: getBadgeIconUrl("villain"),
    },
    {
        badgeId: "thunder",
        title: "Thunder",
        description: "Complete Quests within 30 minutes of its creation",
        exp: 60,
        iconUrl: getBadgeIconUrl("thunder"),
    },
    {
        badgeId: "gem",
        title: "Rare Gem",
        description: "Gems!",
        exp: 80,
        iconUrl: getBadgeIconUrl("gem"),
    },
    {
        badgeId: "phantom",
        title: "Phantom",
        description: "You are like a ghost!",
        exp: 40,
        iconUrl: getBadgeIconUrl("phantom"),
    },
    {
        badgeId: "superPlanner",
        title: "Super Planner",
        description: "Plan 10 events",
        exp: 20,
        iconUrl: getBadgeIconUrl("superPlanner"),
    },
    {
        badgeId: "proPhotographer",
        title: "Pro Photographer",
        description: "Upload 100 images",
        exp: 40,
        iconUrl: getBadgeIconUrl("proPhotographer"),
    },
];

export const mockGroups: Group[] = [
    {
        groupId: 'asdasdasd',
        admins: ["ofirl"],
        createdBy: "ofirl",
        name: "illuminati",
        description: 'group description test',
        tagline: 'tagggg!!',
        members: [
            {
                account: {
                    username: "ofirl",
                },
                joinedAt: new Date(2021, 1, 3),
            }
        ],
        createdAt: new Date(2021, 1, 3),
        maintainers: ["shakedb"],
        private: true,
        type: "open",
        invites: [
            {
                sentAt: new Date(2021, 1, 3),
                sentBy: "ofirl",
                group: 'asdasd',
                invitee: {
                    username: 'shakedb',
                },
            }
        ]
    }
];

export const mockQuests: Quest[] = [{
    questId: 'friendlyUser',
    title: 'Friendly User',
    description: `Give 50 like and/or love reactions on your friend's posts`,
    amount: 50,
    exp: 40,
    featured: true,
}];

export const mockPosts: Post[] = [{
    _id: '12',
    text: [{
        type: 'paragraph',
        children: [{ text: 'post' }],
    }],
    date: new Date(2021, 1, 2),
    postLocation: {
        locationType: 'Account',
        location: 'abc',
    },
    postType: 'status',
    writer: {
        _id: 'asdasdasd',
        username: 'ofirl',
        name: 'Ofir Levi',
    },
}];

export type GetAllPostsReturn = {
    posts: Post[],
    more: boolean,
};
export const mockAllPost: GetAllPostsReturn = {
    posts: mockPosts,
    more: true,
};

export const mockComments: Comment[] = [{
    _id: '12',
    fatherPost: '12',
    text: [{
        type: 'paragraph',
        children: [{ text: 'comment' }],
    }],
    date: new Date(2021, 1, 2),
    writer: {
        _id: 'asdasdasd',
        username: 'ofirl',
        name: 'Ofir Levi',
    },
}];

export type PostCommentsReturn = {
    comments: Comment[],
    more: boolean,
};
export const mockPostComments: PostCommentsReturn = {
    comments: mockComments,
    more: true,
};

export type PollResultReturn = {
    total: number,
    percentage: number,
};
export const mockPollResults: PollResultReturn[] = [
    {
        percentage: 0.66,
        total: 2
    },
    {
        percentage: 0.33,
        total: 1
    }
];