import React, { useMemo } from 'react';
import { Account } from '../../../../../../utils/types';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { isMobile } from "react-device-detect";

import { Cell, Grid } from 'styled-css-grid';

import { Card, CardContent, CardHeader, TextField } from '@material-ui/core';

import MaterialDatePicker from '../../../../../Pickers/MaterialDatePicker/MaterialDatePicker';

import { useBreakpoint } from '../../../../../../CustomHooks/useBreakpoint';

import { format, parse } from 'date-fns';

export interface ProfileAboutProps {
    accountInfo: Account
    onChange: (id: string, value: any) => void,
};
const ProfileAbout = ({ accountInfo, onChange }: ProfileAboutProps) => {
    const { index: breakpointIndex = 0 } = useBreakpoint();

    const singleColumnLayout = useMemo(() => breakpointIndex < 2,
        [breakpointIndex]
    );

    const createInputHandler = (id: string): React.FormEventHandler<HTMLDivElement> => {
        return (e: React.FormEvent<HTMLDivElement>) => onChange(id, (e.target as HTMLInputElement).value);
    };

    const onBirthdayChange = (date: MaterialUiPickersDate) => {
        onChange('birthday', date);
    };

    const onNativeBirthdayChange = (e: React.FormEvent<HTMLInputElement>) => {
        onChange('birthday', parse((e.target as HTMLInputElement).value, 'yyyy-MM-dd', new Date(0)));
    };

    return (
        <Card>
            <CardHeader title="About Your Pofile" />
            <CardContent>
                <Grid gap="0.7em" columns={singleColumnLayout ? "1fr" : "1fr 1fr"}
                    areas={singleColumnLayout ? ["name", "email", "about", "city", "birthday"] : ["name email", "about city", "about birthday"]}>
                    <Cell area="name">
                        <TextField value={accountInfo.name || ''} size="small" variant="outlined" label="Profile Name" onInput={createInputHandler('name')} />
                    </Cell>
                    <Cell area="about">
                        <TextField value={accountInfo.about || ''} size="small" variant="outlined" placeholder="Write a little description about you..." multiline rows="3"
                            onInput={createInputHandler('about')} />
                    </Cell>
                    <Cell area="email">
                        <TextField value={accountInfo.email || ''} type="email" size="small" variant="outlined" label="Email" onInput={createInputHandler('email')} />
                    </Cell>
                    <Cell area="city">
                        <TextField value={accountInfo.city || ''} type="email" size="small" variant="outlined" label="City" onInput={createInputHandler('city')} />
                    </Cell>
                    <Cell area="birthday">
                        {
                            isMobile ?
                                <TextField size="small" value={accountInfo.birthday ? format(accountInfo.birthday, 'yyyy-MM-dd') : ''} variant="outlined" label="Birthday" type="date"
                                    InputLabelProps={{ shrink: true }} onInput={onNativeBirthdayChange} />
                                :
                                <MaterialDatePicker
                                    // disableFuture
                                    maxDate={new Date()}
                                    openTo="year"
                                    format="dd/MM/yyyy"
                                    label="Date of birth"
                                    views={["year", "month", "date"]}
                                    value={accountInfo.birthday || null}
                                    onChange={onBirthdayChange}
                                    variant='inline'
                                    inputVariant="outlined"
                                    size="small"
                                />
                        }
                    </Cell>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ProfileAbout;