import React, { useMemo } from 'react';
import { Quest } from '../../../utils/types';

import { Grid, Cell } from 'styled-css-grid';
import clsx from 'clsx';

import { Card, CardContent, Chip, createStyles, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';

import { useAccountInfo } from '../../../CustomHooks/useAccountInfo';

import { PlusIcon } from '../../../utils/Icons';
import { baseQuestsImageUrl } from '../../../utils/api/apiConsts';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        featuredQuestCardRoot: {
            backgroundColor: 'var(--content-bg)',
            color: 'white',
            height: '100%',
            width: '100%',
            borderRadius: '1em',
            position: 'relative',
            overflow: 'visible',
        },
        featuredQuestCardContentGrid: {
            padding: '1em',
        },
        questCover: {
            position: 'absolute',
            borderRadius: '1em 1em 0 0',
            overflow: 'hidden',
            top: '0',
            left: '0',
            right: '0',
            height: '7em',
        },
        completedQuestIconCell: {
            zIndex: 1,
        },
        completedQuestIcon: {
            height: '3em',
            width: '3em',
            backgroundColor: 'var(--content-secondary-bg)',
            borderRadius: '50%',
            padding: '0.4em',
        },
        featuredQuestProgressBar: {
            background: 'linear-gradient(to right, var(--progress-start), var(--progress-end) 100%)'
        },
        featuredQuestExp: {
            backgroundColor: 'var(--icon-bg-hover)',
            position: 'absolute',
            top: '1em',
            right: '-0.5em',
            color: 'white',
        },
        featuredQuestExpLabel: {
            fontWeight: 700,
            gridAutoFlow: 'column',
        },
        featuredQuestExpPlusIcon: {
            paddingRight: '0.5em',
            paddingLeft: '0.25em',
        },
    })
);

export interface FeaturedQuestCardProps {
    quest: Quest,
    index: number,
};
const FeaturedQuestCard = ({ quest, index }: FeaturedQuestCardProps) => {
    const { accountInfo: { quests: accountQuests } } = useAccountInfo();

    let classes = useStyles();

    const currentAccountQuest = useMemo(() =>
        accountQuests?.find(q => q.questId === quest.questId),
        [quest, accountQuests]
    );

    return (
        <Card classes={{ root: classes.featuredQuestCardRoot }}>
            <CardContent>
                <Grid columns="1fr" rows="2.6em auto auto 5em 1.5em auto" areas={["cover", "icon", "title", "description", "progress", "status"]} className={classes.featuredQuestCardContentGrid}>
                    <div className={classes.questCover}>
                        <img src={`${baseQuestsImageUrl}/cover-${index + 1}.png`} alt={`cover${index + 1}`} width="100%" height="100%" />
                    </div>
                    <Cell area="icon" className={classes.completedQuestIconCell}>
                        <img src={`${baseQuestsImageUrl}/${currentAccountQuest?.completed ? '' : 'un'}completedQuest.png`} alt={`completed`} className={classes.completedQuestIcon} />
                    </Cell>
                    <Cell area="title">
                        <Typography variant="h6">
                            {quest.title}
                        </Typography>
                    </Cell>
                    <Cell area="description">
                        <Typography color="textSecondary" variant="body2" className="font-weight-500">
                            {quest.description}
                        </Typography>
                    </Cell>
                    <Cell area="progress">
                        <LinearProgress variant="determinate" classes={{ bar: classes.featuredQuestProgressBar }} value={((currentAccountQuest?.amount || 0) / quest.amount) * 100} />
                    </Cell>
                    <Cell area="status" className="center-horizontal center-vertical">
                        <Typography variant="body1" className="font-weight-700">
                            {`${currentAccountQuest?.amount || 0}/${quest.amount} completed`}
                        </Typography>
                    </Cell>
                </Grid>
                <Chip classes={{ root: classes.featuredQuestExp }} label={
                    <Typography variant="body2" className={clsx(classes.featuredQuestExpLabel, "center-vertical")}>
                        <PlusIcon fill="var(--icon-fill-hover)" height="0.5em" width="0.5em" className={classes.featuredQuestExpPlusIcon} />
                        {`${quest.exp} EXP`}
                    </Typography>
                } />
            </CardContent>
        </Card>
    );
};

export default FeaturedQuestCard;