import React, { useMemo } from 'react';
import { Badge } from '../../../../utils/types';

import { Grid, Cell } from 'styled-css-grid';
import clsx from 'clsx';

import { Card, CardContent, CardMedia, Chip, createStyles, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';

import { useAccountInfo } from '../../../../CustomHooks/useAccountInfo';

import { PlusIcon } from '../../../../utils/Icons';

const useStyles = makeStyles((theme: Theme) => createStyles({
    badgeCardRoot: {
        backgroundColor: 'var(--content-bg)',
        color: 'white',
        height: '100%',
        width: '100%',
        borderRadius: '1em',
        position: 'relative',
        overflow: 'visible',
    },
    badgeCardContentGrid: {
        padding: '1em',
    },
    badgeIcon: {
        height: '5.5em',
        width: '5em',
    },
    badgeProgressBar: {
        background: 'linear-gradient(to right, var(--progress-start), var(--progress-end) 100%)'
    },
    badgeExp: {
        backgroundColor: 'var(--icon-bg-hover)',
        position: 'absolute',
        top: '1em',
        right: '-0.5em',
        color: 'white',
    },
    badgeExpLabel: {
        fontWeight: 700,
        gridAutoFlow: 'column',
    },
    badgeExpPlusIcon: {
        paddingRight: '0.5em',
        paddingLeft: '0.25em',
    },
}));

export interface CardProps {
    badge: Badge
};
const BadgeCard = ({ badge }: CardProps) => {
    const { accountInfo: { badges } } = useAccountInfo();

    let classes = useStyles();

    const badgeUnlocked = useMemo(() =>
        badges?.find(b => b.badgeId === badge.badgeId) ? true : false,
        [badges, badge.badgeId]
    );

    return (
        <Card classes={{ root: classes.badgeCardRoot }}>
            <CardContent>
                <Grid columns="1fr" rows="7em auto 5em 1.5em auto" areas={["icon", "title", "description", "progress", "status"]} className={classes.badgeCardContentGrid}>
                    <Cell area="icon" className="center-horizontal">
                        <CardMedia image={badge.iconUrl} title={badge.title} className={classes.badgeIcon} />
                    </Cell>
                    <Cell area="title" className="center-horizontal">
                        <Typography variant="h6">
                            {badge.title}
                        </Typography>
                    </Cell>
                    <Cell area="description" className="center-horizontal">
                        <Typography color="textSecondary" variant="body2" className="font-weight-500">
                            {badge.description}
                        </Typography>
                    </Cell>
                    <Cell area="progress">
                        <LinearProgress variant="determinate" classes={{ bar: classes.badgeProgressBar }} value={badgeUnlocked ? 100 : 0} />
                    </Cell>
                    <Cell area="status" className="center-horizontal center-vertical">
                        <Typography variant="caption" className="font-weight-700">
                            {
                                badgeUnlocked ? 'UNLOCKED!' : 'LOCKED'
                            }
                        </Typography>
                    </Cell>
                </Grid>
                <Chip classes={{ root: classes.badgeExp }} label={
                    <Typography variant="body2" className={clsx(classes.badgeExpLabel, "center-vertical")}>
                        <PlusIcon fill="var(--icon-fill-hover)" height="0.5em" width="0.5em" className={classes.badgeExpPlusIcon} />
                        {`${badge.exp} EXP`}
                    </Typography>
                } />
            </CardContent>
        </Card>
    );
};

export default BadgeCard;