import React from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import Hexagon from '../../../Design/Hexagon/Hexagon';
import AccountProfileHexagon from '../../../AccountProfileHexagon/AccountProfileHexagon';

import ProfileStats from '../../../Templates/ProfileStats/ProfileStats';

import { useAccountImage } from '../../../../CustomHooks/useAccountImage';
import { useAccountInfo } from '../../../../CustomHooks/useAccountInfo';
import { useBreakpoint } from '../../../../CustomHooks/useBreakpoint';

interface ProfilePageHeaderStyleProps {
    breakpointName?: string,
    breakpointIndex?: number,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accountProfileImageContainer: {
            backgroundColor: 'var(--content-bg)',
        },
        profileButtonsCell: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
        },
        profileButtonsGrid: {
            justifyContent: 'center',
            paddingTop: ({ breakpointName }: ProfilePageHeaderStyleProps) => breakpointName === "xl" ? 0 : '1em',
        },
        profileButton: {
            width: ({ breakpointIndex }: ProfilePageHeaderStyleProps) => breakpointIndex != null && breakpointIndex > 1 ? '13em' : 'auto',
            height: '3em',
        },
        profileStatDivider: {
            backgroundColor: '#2f3749',
            marginRight: '1.5em',
            marginLeft: '1.5em',
            marginTop: '30%',
            height: '40%',
        },
        nameSkeletonLoader: {
            width: '6em',
        },
        profileIconSkeletonLoader: {
            width: '8em',
            height: '8em',
        },
    })
);

const profilePageSectionsLayout = {
    coverPhoto: {
        xl: {
            top: 1,
            left: 1,
            width: 3,
            height: 2,
        },
        default: {
            top: 1,
            left: 1,
            width: 3,
            height: 2,
        },
    },
    stats: {
        xl: {
            top: 4,
            left: 1,
            width: 1,
            height: 1,
        },
        default: {
            top: 5,
            left: 2,
            width: 1,
            height: 1,
        },
    },
    profilePicture: {
        xl: {
            top: 2,
            left: 2,
            width: 1,
            height: 2,
        },
        default: {
            top: 2,
            left: 2,
            width: 1,
            height: 2,
        },
    },
    profileName: {
        xl: {
            top: 4,
            left: 2,
            width: 1,
            height: 1,
        },
        default: {
            top: 4,
            left: 2,
            width: 1,
            height: 1,
        },
    },
    buttons: {
        xl: {
            top: 4,
            left: 3,
            width: 1,
            height: 1,
        },
        default: {
            top: 6,
            left: 2,
            width: 1,
            height: 1,
        },
    },
};

const coverPhotoHeight = {
    xl: '11em',
    default: '5em',
};

const ProfilePageHeader = () => {
    const { accountInfo: { name }, isLoading } = useAccountInfo();

    const accountCoverImageUrl = useAccountImage('cover');

    const { name: breakpointName, index: breakpointIndex } = useBreakpoint();

    const classes = useStyles({ breakpointName, breakpointIndex });

    return (
        <Grid columns="1fr auto 1fr" rows={`${coverPhotoHeight[breakpointName as 'xl' | 'default'] ?? coverPhotoHeight.default} 7em auto auto auto auto`}>
            {/* cover photo */}
            <Cell {...(profilePageSectionsLayout.coverPhoto[breakpointName as 'xl' | 'default'] ?? profilePageSectionsLayout.coverPhoto.default)}
                style={{ background: `rgba(0, 0, 0, 0) url("${accountCoverImageUrl}") no-repeat scroll center center / cover` }} />
            {/* stats */}
            <Cell {...(profilePageSectionsLayout.stats[breakpointName as 'xl' | 'default'] ?? profilePageSectionsLayout.stats.default)} className="center-horizontal">
                <ProfileStats />
            </Cell>
            {/* profile picture */}
            <Cell {...(profilePageSectionsLayout.profilePicture[breakpointName as 'xl' | 'default'] ?? profilePageSectionsLayout.profilePicture.default)}
                className="center-horizontal">
                <Hexagon progress={0} size="10em" trackColor="var(--content-bg)" fillColor="var(--content-bg)" className={classes.accountProfileImageContainer}>
                    {
                        isLoading ?
                            <Skeleton variant="circle" className={classes.profileIconSkeletonLoader} />
                            :
                            <AccountProfileHexagon size="8em" />
                    }
                </Hexagon>
            </Cell>
            {/* profile name */}
            <Cell {...(profilePageSectionsLayout.profileName[breakpointName as 'xl' | 'default'] ?? profilePageSectionsLayout.profileName.default)} className="center-horizontal">
                <Typography variant="h6">
                    {
                        isLoading ?
                            <Skeleton variant="text" className={classes.nameSkeletonLoader} />
                            :
                            name
                    }
                </Typography>
            </Cell>
            {/* buttons */}
            <Cell {...(profilePageSectionsLayout.buttons[breakpointName as 'xl' | 'default'] ?? profilePageSectionsLayout.buttons.default)} className={classes.profileButtonsCell}>
                <Grid gap="1.5em" columns="1fr 1fr" rows="1fr" className={classes.profileButtonsGrid}>
                    <Cell>
                        <Button variant="contained" color="secondary" className={classes.profileButton} >
                            {
                                breakpointIndex != null && breakpointIndex > 1 && 'Add '
                            }
                            Friend +
                        </Button>
                    </Cell>
                    <Cell>
                        <Button variant="contained" color="primary" className={classes.profileButton} >
                            {
                                breakpointIndex != null && breakpointIndex > 1 && 'Send '
                            }
                            Message
                        </Button>
                    </Cell>
                </Grid>
            </Cell>
        </Grid>
    );
};

export default ProfilePageHeader;