import React, { useMemo } from 'react';
import { Account, Group } from '../../../../utils/types';

import clsx from 'clsx';

import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';

import { JoinGroupIcon } from '../../../../utils/Icons';

import { SearchPageViewType } from '../../../Templates/CardSearchTemplatePage/CardSearchTemplatePage';
import GroupCard from '../../../Templates/GroupCard/GroupCard';

import { useBreakpoint } from '../../../../CustomHooks/useBreakpoint';
import { useCurrentAccountInfo } from '../../../../CustomHooks/useAccountInfo';
import { useJoinGroupMutation } from '../../../../utils/mutations/groupMutations';

export interface GroupGridCardStyleProps {
    viewType?: SearchPageViewType,
    breakpointIndex?: number,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            width: ({ viewType }: GroupGridCardStyleProps) => viewType === "list" ? '3em' : '100%',
            padding: '1em',
            boxSizing: ({ viewType }: GroupGridCardStyleProps) => viewType === "list" ? 'initial' : 'border-box',
        },
        buttonRoot: {
            // marginRight: '1em',
            // marginLeft: '1em',
            boxSizing: 'border-box',
            height: ({ viewType, breakpointIndex }: GroupGridCardStyleProps) => viewType === "list" && breakpointIndex != null && breakpointIndex <= 1 ? '3em' : '4em',
            paddingRight: ({ viewType, breakpointIndex }: GroupGridCardStyleProps) => viewType === "list" && breakpointIndex != null && breakpointIndex <= 1 ? '0' : '0',
            paddingLeft: ({ viewType, breakpointIndex }: GroupGridCardStyleProps) => viewType === "list" && breakpointIndex != null && breakpointIndex <= 1 ? '0' : '0',
            width: ({ viewType }: GroupGridCardStyleProps) => viewType === "list" ? '3em' : '100%',
            minWidth: ({ viewType, breakpointIndex }: GroupGridCardStyleProps) => viewType === "list" && breakpointIndex != null && breakpointIndex <= 1 ? '0' : 'auto',
            '& svg': {
                fill: ({ viewType }: GroupGridCardStyleProps) => viewType === "list" ? 'white' : '',
                transition: 'all 0.2s ease-in-out',
            },
            '&:hover': {
                '& svg': {
                    fill: 'white',
                },
            },
        },
        joinButtonText: {
            paddingLeft: ({ viewType }: GroupGridCardStyleProps) => viewType === "list" ? '0' : '1em',
        },
    })
);

const screenConfigMap = {
    gridCard: {
        showButtonsDivider: true,
        buttonVariant: 'contained' as 'contained',
        buttonsGridSingleRow: true,
        buttonsGridSingleColumn: true,
    },
    list: {
        showButtonsDivider: false,
        buttonVariant: 'contained' as 'contained',
        buttonsGridSingleRow: false,
        buttonsGridSingleColumn: false,
    },
};

export interface GroupBigCardProps {
    data: Group,
    viewType: SearchPageViewType,
};
const GroupGridCard = ({ data: group, viewType }: GroupBigCardProps) => {
    const { accountInfo: { accountId } } = useCurrentAccountInfo();

    const joinGroup = useJoinGroupMutation();

    const { index: breakpointIndex = 0 } = useBreakpoint();

    const isMember = useMemo(() => group.members.find(m => (m.account as Account).accountId === accountId) != null,
        [group.members, accountId]
    );

    const classes = useStyles({ viewType, breakpointIndex });

    const handleJoinGroup = () => {
        joinGroup.mutate(group.groupId);
    };

    return (
        <GroupCard groupData={group} viewType={viewType} showMembers>
            <div className={clsx(classes.buttonContainer, "center-horizontal")}>
                <Button disabled={group.type === "closed" || isMember} variant={screenConfigMap[viewType].buttonVariant} color="secondary"
                    classes={{ root: classes.buttonRoot }} onClick={handleJoinGroup} >
                    {
                        group.type === "closed" ?
                            (
                                `Closed Group`
                            )
                            :
                            (
                                <>
                                    <JoinGroupIcon width="1.5em" fill="white" />
                                    {
                                        viewType === "gridCard" &&
                                        <div className={classes.joinButtonText}>
                                            {
                                                isMember ?
                                                    `Already a Memeber`
                                                    :
                                                    `Join Group!`
                                            }
                                        </div>
                                    }
                                </>
                            )
                    }
                </Button>
            </div>
        </GroupCard>
    );
};

export default GroupGridCard;