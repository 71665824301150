import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Account } from '../../../../utils/types';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, makeStyles, Popover, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { JoinGroupIcon, LeaveGroupIcon } from '../../../../utils/Icons';

import Hexagon from '../../../Design/Hexagon/Hexagon';

import GroupProfileHexagon from '../../../GroupProfileHexagon/GroupProfileHexagon';

import { useBreakpoint } from '../../../../CustomHooks/useBreakpoint';
import { useGroupImage } from '../../../../CustomHooks/useGroupImage';
import { useGroupInfo } from '../../../../CustomHooks/useGroupInfo';
import { useAccountInfo } from '../../../../CustomHooks/useAccountInfo';

import MembersSearchWindow from '../../../Templates/MembersSearchWindow/MembersSearchWindow';
import GroupStats from '../../../Templates/GroupStats/GroupStats';

import { useInviteToGroupMutation, useJoinGroupMutation, useLeaveGroupMutation } from '../../../../utils/mutations/groupMutations';

interface GroupProfilePageHeaderStyleProps {
    breakpointName?: string,
    breakpointIndex?: number,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accountProfileImageContainer: {
            backgroundColor: 'var(--content-bg)',
        },
        profileButtonsCell: {
            paddingLeft: ({ breakpointName }: GroupProfilePageHeaderStyleProps) => breakpointName === "xl" ? '1em' : '0',
            // paddingBottom: ({ breakpointName }: GroupProfilePageHeaderStyleProps) => breakpointName === "xl" ? '0' : '1em',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            paddingRight: ({ breakpointName }: GroupProfilePageHeaderStyleProps) => breakpointName === "xl" ? '1em' : '0',
        },
        profileButtonsGrid: {
            justifyContent: 'center',
            paddingTop: ({ breakpointName }: GroupProfilePageHeaderStyleProps) => breakpointName === "xl" ? 0 : '1em',
        },
        profileButton: {
            height: '3em',
            '& svg': {
                transition: 'all 0.2s ease-in-out',
            },
            '&:hover': {
                '& svg': {
                    fill: 'white',
                },
            },
        },
        profileStatDivider: {
            backgroundColor: '#2f3749',
            marginRight: '1.5em',
            marginLeft: '1.5em',
            marginTop: '30%',
            height: '40%',
        },
        nameSkeletonLoader: {
            width: '6em',
        },
        profileIconSkeletonLoader: {
            width: '8em',
            height: '8em',
        },
    })
);

const groupProfilePageSectionsLayout = {
    coverPhoto: {
        xl: {
            top: 1,
            left: 1,
            width: 4,
            height: 2,
        },
        default: {
            top: 1,
            left: 1,
            width: 4,
            height: 2,
        },
    },
    stats: {
        xl: {
            top: 3,
            left: 3,
            width: 1,
            height: 1,
        },
        default: {
            top: 5,
            left: 1,
            width: 4,
            height: 1,
        },
    },
    profilePicture: {
        xl: {
            top: 2,
            left: 1,
            width: 1,
            height: 2,
        },
        default: {
            top: 2,
            left: 1,
            width: 4,
            height: 2,
        },
    },
    profileName: {
        xl: {
            top: 3,
            left: 2,
            width: 1,
            height: 1,
        },
        default: {
            top: 4,
            left: 1,
            width: 4,
            height: 1,
        },
    },
    buttons: {
        xl: {
            top: 3,
            left: 4,
            width: 1,
            height: 1,
        },
        default: {
            top: 6,
            left: 1,
            width: 4,
            height: 1,
        },
    },
};

const coverPhotoHeight = {
    xl: '16em',
    default: '5em',
};

const GroupProfilePageHeader = () => {
    const { groupInfo: { groupId, name, tagline, members }, isLoading } = useGroupInfo();
    const { accountInfo: { accountId } } = useAccountInfo();

    const groupCoverImageUrl = useGroupImage('cover');
    const { name: breakpointName, index: breakpointIndex } = useBreakpoint();

    const joinGroupButtonRef = useRef(null);
    const [searchMemberOpen, setSearchMemberOpen] = useState(false);

    const isMember = useMemo(() => members?.find(m => (m.account as Account).accountId === accountId),
        [members, accountId]
    );

    const closeSearchMemberWindow = () => {
        setSearchMemberOpen(false);
    };

    const leaveGroup = useLeaveGroupMutation();
    const joinGroup = useJoinGroupMutation();
    const inviteToGroup = useInviteToGroupMutation({ onSuccess: closeSearchMemberWindow });

    const classes = useStyles({ breakpointName, breakpointIndex });

    const handleLeaveGroup = () => {
        leaveGroup.mutate(groupId);
    };

    const handleJoinGroup = () => {
        joinGroup.mutate(groupId);
    };

    const openSearchMemberWindow = () => {
        setSearchMemberOpen(true);
    };

    const handleInviteToGroup = (account: Account) => {
        inviteToGroup.mutate({ groupId, invitee: account.accountId, username: account.username });
    };

    const accountsFilterFunc = useCallback((account: Account) =>
        members.find(m => (m.account as Account).accountId === account.accountId) == null,
        [members]
    );

    return (
        <Grid columns="11em 1fr auto auto" rows={`${coverPhotoHeight[breakpointName as 'xl' | 'default'] ?? coverPhotoHeight.default} 4em auto auto auto auto`}>
            {/* cover photo */}
            <Cell {...(groupProfilePageSectionsLayout.coverPhoto[breakpointName as 'xl' | 'default'] ?? groupProfilePageSectionsLayout.coverPhoto.default)}
                style={{ background: `rgba(0, 0, 0, 0) url("${groupCoverImageUrl}") no-repeat scroll center center / cover` }} />
            {/* stats */}
            <Cell {...(groupProfilePageSectionsLayout.stats[breakpointName as 'xl' | 'default'] ?? groupProfilePageSectionsLayout.stats.default)} className="center-horizontal center-vertical">
                <GroupStats />
            </Cell>
            {/* profile picture */}
            <Cell {...(groupProfilePageSectionsLayout.profilePicture[breakpointName as 'xl' | 'default'] ?? groupProfilePageSectionsLayout.profilePicture.default)}
                className="center-horizontal">
                <Hexagon progress={0} size="10em" trackColor="var(--content-bg)" fillColor="var(--content-bg)" className={classes.accountProfileImageContainer}>
                    {
                        isLoading ?
                            <Skeleton variant="circle" className={classes.profileIconSkeletonLoader} />
                            :
                            <GroupProfileHexagon size="8em" />
                    }
                </Hexagon>
            </Cell>
            {/* profile name */}
            <Cell {...(groupProfilePageSectionsLayout.profileName[breakpointName as 'xl' | 'default'] ?? groupProfilePageSectionsLayout.profileName.default)}
                className={clsx({ "center-horizontal": breakpointIndex < 4 })}>
                <Typography variant="h6">
                    {
                        isLoading ?
                            <Skeleton variant="text" className={classes.nameSkeletonLoader} />
                            :
                            name
                    }
                </Typography>
                <Typography variant="caption">
                    {
                        isLoading ?
                            <Skeleton variant="text" className={classes.nameSkeletonLoader} />
                            :
                            tagline
                    }
                </Typography>
            </Cell>
            {/* buttons */}
            <Cell {...(groupProfilePageSectionsLayout.buttons[breakpointName as 'xl' | 'default'] ?? groupProfilePageSectionsLayout.buttons.default)}
                className={classes.profileButtonsCell}>
                <Grid gap="1.5em" columns="1fr 1fr" rows="1fr" className={classes.profileButtonsGrid}>
                    <Cell>
                        <Button ref={joinGroupButtonRef} variant="contained" color="primary" className={classes.profileButton}
                            onClick={isMember ? openSearchMemberWindow : handleJoinGroup} >
                            <JoinGroupIcon width="1.5em" fill="white" />
                        </Button>
                        <Popover
                            open={searchMemberOpen}
                            onClose={closeSearchMemberWindow}
                            anchorEl={joinGroupButtonRef.current}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MembersSearchWindow onSelect={handleInviteToGroup} showLoader={inviteToGroup.isLoading} filterFunc={accountsFilterFunc} />
                        </Popover>
                    </Cell>
                    {
                        isMember &&
                        <Cell>
                            <Button variant="outlined" color="secondary" className={classes.profileButton} onClick={handleLeaveGroup} >
                                <LeaveGroupIcon width="1.5em" fill="var(--icon-fill)" />
                            </Button>
                        </Cell>
                    }
                </Grid>
            </Cell>
        </Grid>
    );
};

export default GroupProfilePageHeader;