import React, { ReactElement, useCallback, useMemo } from 'react';
import { Group } from '../../../utils/types';


import { GroupInfoContext } from '../../../context/GroupInfoContext';
import { useGetGroupIdQuery, useGetGroupInfoQuery } from '../../../utils/queries/groupsQueries';
import { useQueryClient } from 'react-query';

export interface GroupInfoProviderProps {
    groupId?: string,
    groupName?: string,
    groupData?: Group,
    children: ReactElement,
    enabled?: boolean,
};
const GroupInfoProvider = ({ groupId, groupData, groupName, children, enabled = true }: GroupInfoProviderProps) => {
    const queryClient = useQueryClient();

    const effectiveGroupName = useMemo(() =>
        groupName || groupData?.name,
        [groupData?.name, groupName]
    );
    const checkedGroupId = useGetGroupIdQuery(effectiveGroupName as string, {
        enabled: enabled && effectiveGroupName != null,
        initialData: groupId,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });

    const groupInfoQuery = useGetGroupInfoQuery((groupId || checkedGroupId.data) as string, {
        enabled: enabled && checkedGroupId.data != null,
        initialData: groupData as Group,
    });

    const clearData = useCallback(() => {
        queryClient.setQueryData(['groups', groupId || checkedGroupId.data], {});
    }, [checkedGroupId.data, groupId, queryClient]);

    return (
        <GroupInfoContext.Provider value={{
            clearData,
            groupInfo: groupInfoQuery.data || {} as Group,
            reloadGroupInfo: groupInfoQuery.refetch,
            isLoading: groupInfoQuery.isLoading,
        }}>
            {children}
        </GroupInfoContext.Provider>
    );
};

export default GroupInfoProvider;