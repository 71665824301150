import React, { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Paper, TextField, Theme } from '@material-ui/core';

import BannerPageTemplate from '../BannerPageTemplate/BannerPageTemplate';
import PageTabs, { PageTabsProps, TabDefinition } from '../PageTabs/PageTabs';

import Loader from '../../Design/Loader/Loader';

import CardSearchViewTypeButton from './components/CardSearchViewTypeButton/CardSearchViewTypeButton';

import { ListIcon, SmallGridIcon } from '../../../utils/Icons';
import { baseBannerPageImages } from '../../../utils/api/apiConsts';

import { useDebounce } from '../../../CustomHooks/useDebounce';
import { useBreakpoint } from '../../../CustomHooks/useBreakpoint';
import { useInView } from 'react-intersection-observer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainGrid: {
            width: '100%',
        },
        paperRoot: {
            height: '100%',
        },
        filterRowGrid: {
            paddingLeft: '1em',
            paddingRight: '1em',
            // paddingTop: '0.5em',
            // paddingBottom: '0.5em',
            boxSizing: 'border-box',
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
        dataGrid: {
            paddingTop: '1em',
        },
    })
);

const NullComponent = () => null;

const gridProps = {
    gridCard: {
        style: { gridAutoRows: '30em' },
        columns: "repeat(auto-fit, 18em)",
    },
    list: {
        style: { gridAutoRows: '6em' },
        columns: "1fr",
    },
};

export type CardSearchTabDefinition = TabDefinition & {
    filter: (data: any) => boolean,
};
export type SearchPageViewType = 'gridCard' | 'list';

export interface CardSearchTemplatePageProps extends Omit<PageTabsProps, 'tabs' | 'onChange' | 'tabVariant'> {
    data: any[],
    bannerTitle?: string,
    bannerSubtitle?: string,
    showBanner?: boolean,
    isLoading?: boolean,
    searchLabel: string,
    filterKey: string[],
    gridCardComponent?: ComponentType<any>,
    filterTabs?: CardSearchTabDefinition[],
    morePages?: boolean,
    fetchNextPage?: () => void,
    isFetchingNextPage?: boolean,
};
const CardSearchTemplatePage = ({ data, bannerTitle, bannerSubtitle, searchLabel, gridCardComponent, filterTabs = [], baseUrl, defaultTab, filterKey,
    urlParam, showBanner = true, isLoading = false, morePages, fetchNextPage, isFetchingNextPage }: CardSearchTemplatePageProps) => {
    const { index: breakpointIndex, initialData } = useBreakpoint();
    const [filter, setFilter] = useState('');

    const [viewType, setViewType] = useState<SearchPageViewType>(() => {
        if (breakpointIndex < 3)
            return 'list';

        return 'gridCard';
    });

    useEffect(() => {
        if (initialData)
            return;

        if (breakpointIndex < 3)
            setViewType('list');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData]);

    const { ref: moreCellRef, inView: moreCellInView } = useInView({
        initialInView: true,
    });

    useEffect(() => {
        if (moreCellInView && morePages && !isFetchingNextPage)
            fetchNextPage?.();
    }, [fetchNextPage, morePages, isFetchingNextPage, moreCellInView]);

    const classes = useStyles();

    const GridCardComponent = useMemo(() => gridCardComponent || NullComponent,
        [gridCardComponent]
    );

    const handleFilterChange = useCallback((e: React.FormEvent<HTMLDivElement>) => {
        setFilter((e.target as HTMLInputElement).value);
    }, []);

    const handleFilterChangeDelayed = useDebounce(handleFilterChange, 500);

    const filteredData = useMemo(() => data?.filter(d => filterKey.some(k => d[k]?.includes(filter))),
        [data, filter, filterKey]
    );

    const generateViewTypeChangeHandler = (type: SearchPageViewType) => {
        return () => {
            setViewType(type);
        };
    };

    const page = (
        <Grid columns="1fr" rows="6em 1fr auto" className={classes.mainGrid}>
            <Cell>
                <Paper classes={{ root: classes.paperRoot }}>
                    <Grid columns="auto 1fr auto" rows="1fr" className={clsx(classes.filterRowGrid, "center-vertical")}>
                        <Cell className="center-vertical">
                            <TextField onInput={handleFilterChangeDelayed} variant="outlined" label={searchLabel} />
                        </Cell>
                        <Cell>
                            <PageTabs tabs={filterTabs} baseUrl={baseUrl} defaultTab={defaultTab} urlParam={urlParam} />
                        </Cell>
                        <Cell className="center-vertical">
                            <Grid rpows="1fr" columns="auto auto">
                                <Cell>
                                    <CardSearchViewTypeButton label={"Grid"} onClick={generateViewTypeChangeHandler('gridCard')}>
                                        <SmallGridIcon width="1em" />
                                    </CardSearchViewTypeButton>
                                </Cell>
                                <Cell>
                                    <CardSearchViewTypeButton label={"List"} onClick={generateViewTypeChangeHandler('list')}>
                                        <ListIcon width="1em" />
                                    </CardSearchViewTypeButton>
                                </Cell>
                            </Grid>
                        </Cell>
                    </Grid>
                </Paper>
            </Cell>
            <Cell>
                {
                    isLoading ?
                        <Loader />
                        :
                        <Grid gap="1em" {...gridProps[viewType]} className={classes.dataGrid}>
                            {
                                filteredData?.map(d => (
                                    <Cell key={d.username || d.name}>
                                        <GridCardComponent data={d} viewType={viewType} />
                                    </Cell>
                                ))
                            }
                        </Grid>
                }
            </Cell>
            {
                morePages
                &&
                <Cell ref={moreCellRef}>
                    {
                        isFetchingNextPage &&
                        <Loader />
                    }
                </Cell>
            }
        </Grid>
    );

    return showBanner ?
        (
            <BannerPageTemplate centerContent title={`${bannerTitle} (${data.length})`} subtitle={bannerSubtitle} iconUrl={`${baseBannerPageImages}/members-icon`}>
                {page}
            </BannerPageTemplate>
        )
        :
        page
        ;
};

export default CardSearchTemplatePage;