import React from 'react';
import { createPortal } from 'react-dom';

import { Grid } from 'styled-css-grid';

import { Button, CircularProgress, createStyles, makeStyles, Paper, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bottomButtonsGrid: {
            padding: '2em',
        },
        loader: {
            height: '1.5em !important',
            width: '1.5em !important',
        },
    })
);

export interface AccountHubMenuButtonsProps {
    menuButtonsContainer: HTMLDivElement | null,
    onSave: () => void,
    onDiscard: () => void,
    isSaving?: boolean,
};
const AccountHubMenuButtons = ({ menuButtonsContainer, onSave, onDiscard, isSaving }: AccountHubMenuButtonsProps) => {
    const classes = useStyles();

    if (!menuButtonsContainer)
        return null;

    return createPortal((
        <Paper>
            <Grid columns="1fr" rows="1fr 1fr" className={classes.bottomButtonsGrid}>
                <Button color="primary" variant="contained" onClick={onSave}>
                    {
                        isSaving ?
                            <CircularProgress color="primary" classes={{ root: classes.loader }} />
                            :
                            'Save Changes!'
                    }
                </Button>
                <Button disabled={isSaving} color="secondary" variant="text" onClick={onDiscard}>
                    Discard All
                </Button>
            </Grid>
        </Paper>
    ), menuButtonsContainer);
};

export default AccountHubMenuButtons;