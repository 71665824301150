import React from 'react';

import { Grid } from 'styled-css-grid';

import { Card, CardContent, CardHeader } from '@material-ui/core';

import ProfilePageAboutField from '../../../../../Templates/ProfilePageAboutField/ProfilePageAboutField';

import { useAccountInfo } from '../../../../../../CustomHooks/useAccountInfo';

import { format } from 'date-fns';

const ProfilePageAboutPersonalSection = () => {
    const { accountInfo: { birthday, email }, isLoading } = useAccountInfo();

    return (
        <Card>
            <CardHeader title="Personal Info" />
            <CardContent>
                <Grid columns="5em 1fr">
                    <ProfilePageAboutField label="Birthday" value={birthday ? format(birthday, 'MMMM do, yyyy') : ''} loading={isLoading} />
                    <ProfilePageAboutField label="Email" value={email} loading={isLoading} />
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ProfilePageAboutPersonalSection;