import React, { FormEvent } from 'react';

import clsx from 'clsx';

import { Chip, ChipProps, createStyles, makeStyles, TextField, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tagChip: {
            backgroundColor: 'var(--button-secondary-bg)',
            '&:hover, &:focus': {
                backgroundColor: 'var(--button-secondary-bg-hover)',
            },
        },
        tagChipInput: {
            '& .MuiInput-underline:after': {
                border: 'none',
            },
            '&:hover .MuiInput-underline:before': {
                border: 'none',
            },
            '& .MuiInput-underline:before': {
                border: 'none',
            },
        },
    })
);

interface EditableLabelProps {
    value: React.ReactNode,
    onChange?: (value: string) => void
};
const EditableLabel = ({ value, onChange }: EditableLabelProps) => {
    const classes = useStyles();

    const handleChange = (e: FormEvent<HTMLDivElement>) => {
        const value = (e.target as HTMLInputElement).value;
        onChange && onChange(value);
    };

    return (
        <TextField value={value} variant="standard" onInput={handleChange} classes={{ root: classes.tagChipInput }} />
    );
};

export interface PostTagChipProps extends ChipProps {
    editable?: boolean,
    onLabelChange?: (value: string) => void,
};
const PostTagChip = ({ editable = false, onLabelChange, classes: { root: rootClass, ...otherClasses } = {}, label, ...others }: PostTagChipProps) => {
    const classes = useStyles();

    return (
        <Chip color="primary" classes={{ root: clsx(classes.tagChip, rootClass), ...otherClasses }}
            label={editable ? <EditableLabel value={label} onChange={onLabelChange} /> : label} {...others} />
    );
};

export default PostTagChip;