import React from 'react';
import { AccountInterests } from '../../../../../../utils/types';

import { Card, CardContent, CardHeader, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useAccountInfo } from '../../../../../../CustomHooks/useAccountInfo';

interface ProfilePageAboutInterestsSectionStyleProps {
    value?: string,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionTitle: {
            fontWeight: 700,
            color: ({ value }: ProfilePageAboutInterestsSectionStyleProps) => value ? '' : 'var(--text-disabled-color)',
        },
    })
);

interface InterestSectionProps {
    title: string,
    value: string,
    loading?: boolean,
};
const InterestSection = ({ title, value, loading = false }: InterestSectionProps) => {
    const classes = useStyles({ value });

    return (
        <>
            <Typography variant="body1" className={classes.sectionTitle}>
                {title}
            </Typography>
            <Typography variant="body1">
                {
                    loading ?
                        <Skeleton variant="rect" />
                        :
                        value || <div>&nbsp;</div>
                }
            </Typography>
        </>
    );
};

const ProfilePageAboutInterestsSection = () => {
    const { accountInfo: { interests: { tv, books, games, hobbies, movies, music } = {} as AccountInterests }, isLoading } = useAccountInfo();

    const sections = [
        {
            title: 'Hobbies',
            value: hobbies
        },
        {
            title: 'Favourite TV Shows',
            value: tv
        },
        {
            title: 'Favourite Music Bands / Artists',
            value: music
        },
        {
            title: 'Favourite Movies',
            value: movies
        },
        {
            title: 'Favourite Books',
            value: books
        },
        {
            title: 'Favourite Games',
            value: games
        },
    ];

    return (
        <Card>
            <CardHeader title="Interests" />
            <CardContent>
                {
                    sections.map(s => (
                        <InterestSection key={s.title} title={s.title} value={s.value} loading={isLoading} />
                    ))
                }
            </CardContent>
        </Card>
    );
};

export default ProfilePageAboutInterestsSection;