import { createContext } from "react";
import { Account } from "../utils/types";

export type AccountInfoContextType = {
    accountInfo: Account,
    reloadAccountInfo: () => void,
    clearData: () => void,
    isLoading: boolean,
};
export const AccountInfoContext = createContext<AccountInfoContextType>({
    accountInfo: {} as Account,
    reloadAccountInfo: () => { },
    clearData: () => { },
    isLoading: false,
});

export const CurrentAccountInfoContext = createContext<AccountInfoContextType>({
    accountInfo: {} as Account,
    reloadAccountInfo: () => { },
    clearData: () => { },
    isLoading: false,
});