import React from 'react';

import { Grid, Cell } from 'styled-css-grid';

import ProfilePageAboutAboutSection from './components/ProfilePageAboutAboutSection/ProfilePageAboutAboutSection';
import ProfilePageAboutPersonalSection from './components/ProfilePageAboutPersonalSection/ProfilePageAboutPersonalSection';
import ProfilePageAboutInterestsSection from './components/ProfilePageAboutInterestsSection/ProfilePageAboutInterestsSection';
import ProfilePageAboutCompletionSection from './components/ProfilePageAboutCompletionSection/ProfilePageAboutCompletionSection';

import { useBreakpoint } from '../../../../CustomHooks/useBreakpoint';

const SmallLayout = () => {
    return (
        <Grid columns="1fr" areas={["about", "personal", "interests", "completion"]}>
            <Cell area="about">
                <ProfilePageAboutAboutSection />
            </Cell>
            <Cell area="personal">
                <ProfilePageAboutPersonalSection />
            </Cell>
            <Cell area="interests">
                <ProfilePageAboutInterestsSection />
            </Cell>
            <Cell area="completion">
                <ProfilePageAboutCompletionSection />
            </Cell>
        </Grid>
    );
};

const MediumLayout = () => {
    return (
        <Grid columns="19em 1fr" areas={["generalFields interests"]}>
            <Cell area="generalFields">
                <Grid columns="1fr" areas={["about", "personal", "completion"]}>
                    <Cell area="about">
                        <ProfilePageAboutAboutSection />
                    </Cell>
                    <Cell area="personal">
                        <ProfilePageAboutPersonalSection />
                    </Cell>
                    <Cell area="completion">
                        <ProfilePageAboutCompletionSection />
                    </Cell>
                </Grid>
            </Cell>
            <Cell area="interests">
                <ProfilePageAboutInterestsSection />
            </Cell>
        </Grid>
    );
};

const LargeLayout = () => {
    return (
        <Grid columns="19em 1fr 19em" areas={["generalFields interests completion"]}>
            <Cell area="generalFields">
                <Grid columns="1fr" areas={["about", "personal"]}>
                    <Cell area="about">
                        <ProfilePageAboutAboutSection />
                    </Cell>
                    <Cell area="personal">
                        <ProfilePageAboutPersonalSection />
                    </Cell>

                </Grid>
            </Cell>
            <Cell area="interests">
                <ProfilePageAboutInterestsSection />
            </Cell>
            <Cell area="completion">
                <ProfilePageAboutCompletionSection />
            </Cell>
        </Grid>
    );
};

const ProfilePageAbout = () => {
    const { index: breakpointIndex } = useBreakpoint();

    if (breakpointIndex <= 2)
        return <SmallLayout />;
    if (breakpointIndex <= 3)
        return <MediumLayout />;

    return <LargeLayout />;
};

export default ProfilePageAbout;