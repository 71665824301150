import React from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, Divider, makeStyles, Theme } from '@material-ui/core';

import StatCell from '../StatCell/StatCell';

import { useAccountInfo } from '../../../CustomHooks/useAccountInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        profileStatDivider: {
            backgroundColor: 'var(--content-inside-border-color)',
            marginRight: '1.5em',
            marginLeft: '1.5em',
            marginTop: '30%',
            height: '40%',
        },
        valueSkeletonLoader: {
            width: '2em',
        },
    })
);

export interface ProfileStatsProps {

};
const ProfileStats = () => {
    const { accountInfo: { badges }, isLoading } = useAccountInfo();

    const classes = useStyles();

    return (
        <Grid gap="0" rows="1fr" columns="repeat(5, auto)">
            <Cell>
                <StatCell label="BADGES" value={badges?.length || 0} isLoading={isLoading} />
            </Cell>
            <Cell>
                <Divider orientation="vertical" classes={{ root: classes.profileStatDivider }} />
            </Cell>
            <Cell>
                <StatCell label="QUESTS" value="10" isLoading={isLoading} />
            </Cell>
            <Cell>
                <Divider orientation="vertical" classes={{ root: classes.profileStatDivider }} />
            </Cell>
            <Cell>
                <StatCell label="FRIENDS" value="50" isLoading={isLoading} />
            </Cell>
        </Grid>
    );
};

export default ProfileStats;