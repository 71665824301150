import React, { useState } from 'react';
import { Comment } from '../../../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';

import TextEditorInput from '../../../TextEditorInput/TextEditorInput';
import Loader from '../../../../Design/Loader/Loader';

import { useCreateCommentMutation, useUpdatePostMutation } from '../../../../../utils/mutations/postsMutation';

import { Descendant } from 'slate';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        createCommentGrid: {
            borderBottom: '1px solid var(--content-inside-border-color)',
        },
        textAreaCell: {
            // borderBottom: '1px solid var(--content-inside-border-color)',
            border: '1px solid var(--secondary-color)',
            borderRadius: '1em',
            boxSizing: 'border-box',
            position: 'relative',
            paddingBottom: '1em',
            paddingRight: '1em',
            paddingLeft: '1em',
            justifyItems: 'start',
        },
        bottomRow: {
            paddingRight: '2em',
            paddingLeft: '2em',
        },
    })
);

export interface CreateCommentProps {
    postId: string,
    onClose?: () => void,
    update?: boolean,
    initialData?: Comment,
};
const CreateComment = ({ postId, onClose, update = false, initialData }: CreateCommentProps) => {
    const [postData, setPostData] = useState<Descendant[]>(initialData?.text ||
        [{
            type: 'paragraph',
            children: [{ text: '' }],
        }]
    );

    const createComment = useCreateCommentMutation();
    const updateComment = useUpdatePostMutation();

    const classes = useStyles();

    const clearPost = () => {
        if (update)
            onClose && onClose();
        else
            setPostData([{
                type: 'paragraph',
                children: [{ text: '' }],
            }]);
    };

    const handleCreateCommentClick = () => {
        if (update)
            updateComment.mutate({
                postId: initialData?._id as string,
                postData: {
                    text: postData,
                },
                fatherPostId: postId,
            }, {
                onSuccess: () => {
                    onClose && onClose();
                },
            });
        else
            createComment.mutate({
                postId,
                commentData: {
                    text: postData,
                },
            }, {
                onSuccess: () => {
                    clearPost();
                    onClose && onClose();
                },
            });
    };

    return (
        <Grid gap="0" columns="1fr" rows="1fr 4em" className={classes.createCommentGrid}>
            <Cell className={classes.textAreaCell}>
                <TextEditorInput value={postData} onChange={setPostData} variant="outlined" placeholder="What are your thoughts?" showCommandToolbar={false} />
            </Cell>
            <Cell className="center-vertical">
                <Grid gap="1em" rows="1fr" columns="1fr auto auto" className={classes.bottomRow} areas={[". discard post"]}>
                    <Cell area="discard" className="center-vertical">
                        <Button disabled={createComment.isLoading || updateComment.isLoading} variant="text" color="primary" onClick={clearPost}>
                            {update ? `Cancel` : `Discard`}
                        </Button>
                    </Cell>
                    <Cell area="post" className="center-vertical">
                        <Button disabled={!postData} variant="contained" color="secondary" onClick={handleCreateCommentClick}>
                            {
                                createComment.isLoading || updateComment.isLoading ?
                                    <Loader height="1.5em" />
                                    :
                                    update ? `Update` : `Comment`
                            }
                        </Button>
                    </Cell>
                </Grid>
            </Cell>
        </Grid>
    );
};

export default CreateComment;