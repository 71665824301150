import React from 'react';

import './App.scss';

import MainContainer from './components/MainContainer/MainContainer';

import { ThemeProvider } from '@material-ui/core/styles';
import { custumTheme } from './CustomTheme';

import { SnackbarProvider } from 'notistack';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={custumTheme}>
            <MainContainer />
          </ThemeProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </QueryClientProvider>
  );
}

export default App;
