import React, { useRef, useState } from 'react';

import { Popover } from '@material-ui/core';

import TopBarIcon from '../TopBarIcon/TopBarIcon';
import SettingsMenu from '../SettingsMenu/SettingsMenu';

import { SettingsIcon } from '../../../../utils/Icons';

export interface SettingsMenuButtonProps {
    menuPosition?: 'top' | 'bottom',
};
const SettingsMenuButton = ({ menuPosition = "top" }: SettingsMenuButtonProps) => {
    const [settingsWindowOpen, setSettingsWindowOpen] = useState<boolean>(false);

    const settingsWindowAnchor = useRef();

    const openSettingsWindow = () => {
        setSettingsWindowOpen(true);
    };

    const closeSettingsWindow = () => {
        setSettingsWindowOpen(false);
    };

    return (
        <>
            <TopBarIcon innerRef={settingsWindowAnchor} onClick={openSettingsWindow}>
                <SettingsIcon />
            </TopBarIcon>
            <Popover
                anchorEl={settingsWindowAnchor.current}
                open={settingsWindowOpen}
                onClose={closeSettingsWindow}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SettingsMenu onClose={closeSettingsWindow} />
            </Popover>
        </>
    );
};

export default SettingsMenuButton;