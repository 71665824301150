import { useCallback, useEffect, useState } from "react";
import { useThrottle } from "./useThrottle";

export type Breakpoint = {
    index: number,
    name: string,
    maxWidth: number,
};

export const breakpointsConfig: Breakpoint[] = [
    {
        name: 'xs',
        maxWidth: 320,
        index: 0,
    },
    {
        name: 'sm',
        maxWidth: 720,
        index: 1,
    },
    {
        name: 'md',
        maxWidth: 960,
        index: 2,
    },
    {
        name: 'lg',
        maxWidth: 1400,
        index: 3,
    },
    {
        name: 'xl',
        maxWidth: 99999,
        index: 4,
    },
];

const getDeviceConfig: (width: number) => Breakpoint = (width) => {
    let found: Breakpoint | null = null;
    breakpointsConfig.forEach((p, idx) => {
        if (!found && width < p.maxWidth)
            found = p;
    });

    return found ?? { ...breakpointsConfig[breakpointsConfig.length - 1], index: breakpointsConfig.length - 1 };
}

export const useBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState<Breakpoint>(breakpointsConfig[breakpointsConfig.length - 1]);
    const [initialData, setInitialData] = useState(true);

    const calcInnerWidth = useCallback(() => {
        setBreakpoint(getDeviceConfig(window.innerWidth));
        setInitialData(false);
    }, []);

    const throttledCalcInnerWidth = useThrottle(calcInnerWidth, 200);

    useEffect(() => {
        throttledCalcInnerWidth();
        window.addEventListener('resize', throttledCalcInnerWidth);
        return () => window.removeEventListener('resize', throttledCalcInnerWidth);
    }, [throttledCalcInnerWidth]);

    return { ...breakpoint, initialData };
};