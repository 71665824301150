import React, { useMemo, useRef, useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AccountBadge, Badge } from '../../../../utils/types';

import clsx from 'clsx';
import { Cell, Grid } from 'styled-css-grid';

import { Button, Card, CardContent, createStyles, makeStyles, Popover, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import MaterialDatePicker from '../../../Pickers/MaterialDatePicker/MaterialDatePicker';
import Loader from '../../../Design/Loader/Loader';

import { useAccountInfo } from '../../../../CustomHooks/useAccountInfo';
import { useBadges } from '../../../../CustomHooks/useBadges';

import { format } from 'date-fns';

import { useAddAccountBadgeMutation, useRemoveAccountBadgeMutation } from '../../../../utils/mutations/accountMutations';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        badgeCardRoot: {
            height: '100%',
            width: '100%',
            position: 'relative',
        },
        badgeCardContentRoot: {
            paddingLeft: '2em',
        },
        profileBadgeRecievedDate: {
            textTransform: 'uppercase',
        },
        removeBadgeButtonRoot: {
            position: 'absolute',
            top: '0',
            right: '0',
        },
        addBadgeButtonRoot: {
            position: 'absolute',
            bottom: '0',
            right: '0',
        },
    })
);

export interface extendedAccountBadge extends Badge, AccountBadge { };
export interface ProfileBadgeCardProps {
    badge?: Partial<extendedAccountBadge>,
    manage?: boolean,
};
export const ProfileBadgeCard = ({ badge, manage = false }: ProfileBadgeCardProps) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const addBadgeIconRef = useRef(null);

    const { accountInfo: { accountId } } = useAccountInfo();

    const addAccountBadge = useAddAccountBadgeMutation();
    const removeAccountBadge = useRemoveAccountBadgeMutation();

    const classes = useStyles();

    const onAddBadgeClick = () => {
        setDatePickerOpen(true);
    };

    const onDatePickerClose = () => {
        setDatePickerOpen(false);
    };

    const onDatePickerPick = (date: MaterialUiPickersDate) => {
        setDatePickerOpen(false);

        addAccountBadge.mutate({ accountId, badge: { ...(badge as AccountBadge), recievedDate: date as Date } });
    };

    const onRemoveBadge = () => {
        removeAccountBadge.mutate({ accountId, badge: badge as AccountBadge });
    };

    return (
        <Card classes={{ root: clsx(classes.badgeCardRoot, "center-vertical") }}>
            <CardContent classes={{ root: classes.badgeCardContentRoot }}>
                <Grid rowGap="0" columnGap="2em" columns="5em 1fr" rows="auto 1fr auto" areas={["icon title", "icon description", "icon recievedDate"]}>
                    <Cell area="icon">
                        {
                            badge ?
                                <img src={badge.iconUrl} alt={badge.badgeId} />
                                :
                                <Skeleton variant="circle" height="100%" />
                        }
                    </Cell>
                    <Cell area="title">
                        <Typography variant="h6">
                            {
                                badge ?
                                    badge.title
                                    :
                                    <Skeleton variant="text" />
                            }
                        </Typography>
                    </Cell>
                    <Cell area="description">
                        <Typography variant="body1">
                            {
                                badge ?
                                    badge.description
                                    :
                                    <Skeleton variant="text" />
                            }
                        </Typography>
                    </Cell>
                    {
                        badge ?
                            badge.recievedDate &&
                            <Cell area="recievedDate">
                                <Typography variant="caption" className={classes.profileBadgeRecievedDate}>
                                    {`Unlocked ${format(badge.recievedDate, 'MMM do, yyyy')}`}
                                </Typography>
                            </Cell>
                            :
                            <Skeleton variant="text" />
                    }
                    {
                        manage &&
                        <>
                            {
                                badge?.recievedDate &&
                                <Button color="secondary" variant="contained" classes={{ root: classes.removeBadgeButtonRoot }} onClick={onRemoveBadge}>
                                    <DeleteOutlineIcon />
                                </Button>
                            }
                            <Button ref={addBadgeIconRef} color="primary" variant="contained" classes={{ root: classes.addBadgeButtonRoot }} onClick={onAddBadgeClick}>
                                {
                                    badge?.recievedDate ?
                                        <EditOutlinedIcon />
                                        :
                                        <AddIcon />
                                }
                            </Button>
                            <Popover
                                anchorEl={addBadgeIconRef.current}
                                open={datePickerOpen}
                                onClose={onDatePickerClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <MaterialDatePicker onChange={onDatePickerPick} value={null} variant="static" maxDate={new Date()} />
                            </Popover>
                        </>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};

export interface ProfileBadgesProps {
    manage?: boolean,
};
const ProfileBadges = ({ manage = false }: ProfileBadgesProps) => {
    const badges = useBadges();

    const { accountInfo: { badges: accountBadges }, isLoading } = useAccountInfo();

    const badgesData = useMemo(() =>
        manage ?
            badges.map(b => ({
                ...b,
                ...accountBadges?.find(badge => badge.badgeId === b.badgeId)
            }))
            :
            accountBadges?.map(d => ({
                ...badges?.find(b => b.badgeId === d.badgeId) as Badge,
                ...d,
            })) || []
        , [manage, accountBadges, badges]
    );

    if (!badges.length || (!manage && isLoading))
        return (
            <Loader />
        );

    if (!isLoading && !badgesData.length)
        return (
            <div className="center-horizontal">
                <Typography variant="h6">
                    No badges to show yet
                </Typography>
                <Typography variant="body1">
                    Go and earn some badges!
                </Typography>
            </div>
        );

    return (
        <Grid rows="repeat(auto-fill, 10em)" columns="repeat(auto-fill, minmax(1em, 24em))">
            {
                isLoading ?
                    (
                        badgesData.map(s => (
                            <Cell key={`skeleton-${s.badgeId}`}>
                                <ProfileBadgeCard />
                            </Cell>
                        ))
                    )
                    :
                    (
                        badgesData.map(b =>
                            <Cell key={b.badgeId}>
                                <ProfileBadgeCard badge={b} manage={manage} />
                            </Cell>
                        )
                    )
            }
        </Grid>
    );
};

export default ProfileBadges;