import React from 'react';
import { Post } from '../../../utils/types';

import CreatePostCard from '../CreatePostCard/CreatePostCard';

import { usePostInfo } from '../../../CustomHooks/usePostInfo';

export interface UpdatePostEditorProps {
    onClose?: () => void,
};
const UpdatePostEditor = ({ onClose }: UpdatePostEditorProps) => {
    const { post: postInfo } = usePostInfo();
    const post = postInfo as Post;
    const { postLocation: { locationType } } = post;

    return (
        <CreatePostCard locationType={locationType} initialData={post} update onClose={onClose} />
    );
};

export default UpdatePostEditor;