import React, { useMemo } from 'react';
import { Quest } from '../../../utils/types';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';

import { PlusIcon } from '../../../utils/Icons';
import { baseQuestsImageUrl } from '../../../utils/api/apiConsts';

import { useAccountInfo } from '../../../CustomHooks/useAccountInfo';
import { useBreakpoint } from '../../../CustomHooks/useBreakpoint';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        questRowGrid: {
            width: '100%',
        },
        completedQuestIcon: {
            height: '2em',
            width: '2em',
        },
        questTitleText: {
            fontWeight: 700,
        },
        questExpText: {
            fontWeight: 700,
        },
        questProgressBar: {
            background: 'linear-gradient(to right, var(--progress-start), var(--progress-end) 100%)'
        },
    })
);

const rowLayoutMap = {
    small: {
        rows: 'auto auto',
        columns: `2em 1fr auto auto`,
        areas: ["icon title exp progressText", "icon description exp progressText"],
    },
    large: {
        rows: "1fr",
        columns: `2em auto 1fr auto 10em auto`,
        areas: ["icon title description exp progressBar progressText"],
    },
};

export interface QuestRowProps {
    quest: Quest
};
const QuestRow = ({ quest }: QuestRowProps) => {
    const { accountInfo: { quests: accountQuests } } = useAccountInfo();

    const { index: breakpointIndex } = useBreakpoint();

    let classes = useStyles();

    const currentAccountQuest = useMemo(() =>
        accountQuests?.find(q => q.questId === quest.questId),
        [quest, accountQuests]
    );

    return (
        <Grid rowGap="0" {...rowLayoutMap[breakpointIndex < 3 ? 'small' : 'large']} className={classes.questRowGrid}>
            <Cell area="icon" className="center-vertical">
                <img src={`${baseQuestsImageUrl}/${currentAccountQuest?.completed ? '' : 'un'}completedQuest.png`} alt={`completed`} className={classes.completedQuestIcon} />
            </Cell>
            <Cell area="title" className="center-vertical">
                <Typography variant="body1" className={classes.questTitleText}>
                    {quest.title}
                </Typography>
            </Cell>
            <Cell area="description" className="center-vertical">
                <Typography variant="caption">
                    {quest.description}
                </Typography>
            </Cell>
            <Cell area="exp" className="center-vertical">
                <Typography variant="body1" className={classes.questExpText}>
                    <PlusIcon fill="var(--icon-fill-hover)" width="0.75em" /> {`${quest.exp} EXP`}
                </Typography>
            </Cell>
            {
                breakpointIndex >= 3 &&
                <Cell area="progressBar" className="center-vertical">
                    <LinearProgress variant="determinate" classes={{ bar: classes.questProgressBar }} value={((currentAccountQuest?.amount || 0) / quest.amount) * 100} />
                </Cell>
            }
            <Cell area="progressText" className="center-vertical">
                <Typography variant="body1" className="font-weight-700">
                    {`${currentAccountQuest?.amount || 0}/${quest.amount}`}
                </Typography>
            </Cell>
        </Grid>
    );
};

export default QuestRow;