import React, { useEffect } from 'react';
import { Post, PostLocationType } from '../../utils/types';
import { GetAllPostsReturn } from '../../utils/MockData';
import { UseInfiniteQueryResult } from 'react-query';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Loader from '../Design/Loader/Loader';

import CreatePostCard from '../Templates/CreatePostCard/CreatePostCard';
import PostCard from '../Templates/PostCard/PostCard';

import { useInView } from 'react-intersection-observer';
import { useMergeInfiniteQueryData } from '../../CustomHooks/useMergeInfiniteQueryData';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        newsfeedPageGrid: {
            width: '100%',
            maxWidth: '75em',
        },
    })
);

export type PostListPageProps = Pick<UseInfiniteQueryResult<GetAllPostsReturn>, 'data' | 'fetchNextPage' | 'hasNextPage' | 'isFetchingNextPage' | 'isLoading' | 'refetch'> & {
    locationType: PostLocationType,
    enableCreatePost?: boolean,
};
const PostList = ({ locationType, data: postsQueryData, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch, enableCreatePost = true }: PostListPageProps) => {
    const { ref: morePostsCellRef, inView: morePostsCellInView } = useInView({
        initialInView: true,
    });

    const postsList: Post[] = useMergeInfiniteQueryData(postsQueryData, 'posts');

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const classes = useStyles();

    useEffect(() => {
        if (morePostsCellInView && hasNextPage && !isFetchingNextPage)
            fetchNextPage();
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, morePostsCellInView]);

    return (
        <Grid columns="1fr" className={classes.newsfeedPageGrid}>
            {
                enableCreatePost &&
                <Cell>
                    <CreatePostCard locationType={locationType} />
                </Cell>
            }
            <Cell>
                <Grid columns="1fr">
                    {
                        isLoading ?
                            <Loader />
                            :
                            postsList?.map(post => (
                                <PostCard key={post._id} post={post} />
                            ))
                    }
                </Grid>
            </Cell>
            <Cell ref={morePostsCellRef}>
                {
                    isFetchingNextPage &&
                    <Loader />
                }
            </Cell>
        </Grid>
    );
};

export default PostList;