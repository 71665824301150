import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Hexagon from '../Design/Hexagon/Hexagon';

import { useGroupImage } from '../../CustomHooks/useGroupImage';
import { useGroupInfo } from '../../CustomHooks/useGroupInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hexagonContainer: {
            position: 'relative',
        }
    })
);

export interface GroupProfileHexagonProps {
    size?: string,
    progress?: number,
};
const GroupProfileHexagon = ({ size = '3em', progress = 78 }: GroupProfileHexagonProps) => {
    const groupProfileImageUrl = useGroupImage('profile');

    const { groupInfo: { name } } = useGroupInfo();

    const classes = useStyles();

    return (
        <div className={classes.hexagonContainer}>
            <Hexagon progress={progress} size={size}>
                <img src={groupProfileImageUrl} alt={name} style={{ width: '87%', height: '87%', paddingTop: '7%' }} />
            </Hexagon>
        </div>
    );
};

export default GroupProfileHexagon;