import React, { useMemo, useRef, useState } from 'react';

import { Badge, Popover } from '@material-ui/core';

import TopBarIcon from '../TopBarIcon/TopBarIcon';
import NotificationsMenu from '../NotificationsMenu/NotificationsMenu';

import { NotificationsIcon } from '../../../../utils/Icons';

import { useCurrentAccountInfo } from '../../../../CustomHooks/useAccountInfo';
import { useMarkAllNotificationsAsReadMutation } from '../../../../utils/mutations/accountMutations';

export interface NotificationsMenuButtonProps {
    menuPosition?: 'top' | 'bottom',
};
const NotificationsMenuButton = ({ menuPosition = "top" }: NotificationsMenuButtonProps) => {
    const [notificationsWindowOpen, setNotificationsWindowOpen] = useState<boolean>(false);
    const { accountInfo: { notifications } } = useCurrentAccountInfo();

    const unreadNotifications = useMemo(() =>
        notifications?.some(n => !n.isRead),
        [notifications]
    );

    const notificationsWindowAnchor = useRef();

    const markAllNotificationsAsRead = useMarkAllNotificationsAsReadMutation();

    const openNotificationsWindow = () => {
        setNotificationsWindowOpen(true);
        markAllNotificationsAsRead.mutate({});
    };

    const closeNotificationsWindow = () => {
        setNotificationsWindowOpen(false);
    };

    return (
        <>
            <TopBarIcon innerRef={notificationsWindowAnchor} onClick={openNotificationsWindow}>
                <Badge color="primary" variant="dot" invisible={!unreadNotifications}>
                    <NotificationsIcon />
                </Badge>
            </TopBarIcon>
            <Popover
                anchorEl={notificationsWindowAnchor.current}
                open={notificationsWindowOpen}
                onClose={closeNotificationsWindow}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <NotificationsMenu onClose={closeNotificationsWindow} maxWidth="25em" />
            </Popover>
        </>
    );
};

export default NotificationsMenuButton;