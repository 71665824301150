import React, { ReactElement, useMemo } from 'react';

import clsx from 'clsx';

import { createStyles, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';

import { Link, useParams } from 'react-router-dom';

import { AccountType, GroupMemberType, pathParams } from '../../../utils/types';

import { useBreakpoint } from '../../../CustomHooks/useBreakpoint';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabsRoot: {
            height: '100%',
            '& .profileTabLink:nth-child(1) button': {
                '&:before': {
                    content: '""',
                    backgroundColor: 'var(--icon-fill)',
                    height: '1.5em',
                    width: '2px',
                    position: 'relative',
                },
                '&:hover': {
                    '&:before': {
                        top: '0.2em',
                    },
                },
            },
        },
        tabsFlexContainer: {
            height: '100%',
        },
        tabRoot: {
            height: '100%',
            opacity: '1',
            padding: '0',
            minWidth: '10em',
            '&:hover': {
                borderBottom: '0.3em solid var(--button-primary-bg)',
                '&:after': {
                    top: '0.2em',
                },
            },
            '&:after': {
                content: '""',
                backgroundColor: 'var(--icon-fill)',
                height: '1.5em',
                width: '1px',
                position: 'relative',
            },
            '& .profileTabLabel': {
                opacity: 0,
                transform: 'translateX(-50%) translateY(20px)'
            },
            '& .profileTabIcon': {
                opacity: 1,
                transform: 'translateX(-50%) translateY(0px)'
            },
            '&:hover .profileTabLabel': {
                opacity: 1,
                transform: 'translateX(-50%) translateY(0px)'
            },
            '&:hover .profileTabIcon': {
                opacity: 0,
                transform: 'translateX(-50%) translateY(-20px)'
            },
        },
        tabSelected: {
            color: 'var(--button-primary-bg)',
        },
        tabWrapper: {
            textTransform: 'initial',
        },
        tabIndicator: {
            backgroundColor: 'var(--button-primary-bg)',
            height: '0.25em',
        },
        tabLabelWrapper: {
            position: 'relative',
            width: '100%',
        },
        tabLabelText: {
            position: 'absolute',
            left: '50%',
            transition: 'all 0.2s ease-in-out',
        },
        tabLabelIcon: {
            fill: 'var(--icon-fill)',
            color: 'var(--icon-fill)',
            position: 'absolute',
            left: '50%',
            transition: 'all 0.2s ease-in-out',
        },
        tabLabelIconSelected: {
            '& svg': {
                color: 'var(--button-primary-bg)',
                fill: 'var(--button-primary-bg)',
            },
        },
    })
);

export type TabDefinition = {
    id: string,
    label: string,
    icon: ReactElement
    permission?: (AccountType | GroupMemberType | 'viewer')[],
};

interface TabLabelProps {
    tab: TabDefinition,
    selected: boolean,
};
const TabLabel = ({ tab: { icon, label }, selected }: TabLabelProps) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.tabLabelWrapper, "center-vertical")}>
            <div className={clsx("profileTabLabel", classes.tabLabelText)}>
                {label}
            </div>
            <div className={clsx("profileTabIcon", classes.tabLabelIcon, { [classes.tabLabelIconSelected]: selected })}>
                {icon}
            </div>
        </div>
    );
};

interface TabComponentProps {
    tab: TabDefinition,
    children: ReactElement,
    baseUrl: string,
};
const TabComponent = ({ tab: { id, label, icon }, children, baseUrl }: TabComponentProps) => {
    return (
        <Link to={`${baseUrl}/${id}`} className={clsx("profileTabLink", "center-horizontal")}>
            {children}
        </Link>
    );
};

export interface PageTabsProps {
    tabs: TabDefinition[],
    defaultTab?: string,
    baseUrl: string,
    urlParam?: string,
    tabVariant?: 'centered' | 'scrollable',
    onChange?: (tabId: string) => void,
};
const PageTabs = ({ tabs, defaultTab, baseUrl, tabVariant, urlParam = 'currentTab', onChange }: PageTabsProps) => {
    const { [urlParam]: currentTabId = defaultTab || tabs[0]?.id } = useParams<pathParams>();

    const { index: breakpointIndex } = useBreakpoint();

    const classes = useStyles();

    const currentTab = useMemo(() => tabs.findIndex(t => t.id === currentTabId),
        [tabs, currentTabId]
    );

    const tabsVariants = useMemo(() => {
        const centerVariant = { centered: true };
        const scrollableVariant = { variant: 'scrollable' as 'scrollable' };

        if (tabVariant === "centered")
            return centerVariant;
        if (tabVariant === "scrollable")
            return scrollableVariant;

        return breakpointIndex != null && breakpointIndex > 1 ? centerVariant : scrollableVariant;
    },
        [breakpointIndex, tabVariant]
    );

    const handleTabChange = (e: React.ChangeEvent<{}>, value: string) => {
        onChange && onChange(value);
    };

    return (
        <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            {...tabsVariants}
            classes={{ root: classes.tabsRoot, indicator: classes.tabIndicator, flexContainer: classes.tabsFlexContainer }}
        >
            {
                tabs.map(t => (
                    <TabComponent key={t.id} tab={t} baseUrl={baseUrl}>
                        <Tab value={t.id} label={<TabLabel tab={t} selected={currentTabId === t.id} />} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
                    </TabComponent>
                ))
            }
        </Tabs>
    );
};

export default PageTabs;