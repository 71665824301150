import React from 'react';

import { Cell, Grid } from 'styled-css-grid';

import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';

import { LeftMinimalArrowIcon, LeftMinimalDoubleArrowIcon, RightMinimalArrowIcon, RightMinimalDoubleArrowIcon } from '../../../../../utils/Icons';

import { addMonths, addYears, format } from 'date-fns';

export interface AntDateTimePickerDateHeaderStyleProps {
    showTime?: boolean,
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dateTimePickerDateHeaderGrid: {
            '&[class*="Grid"]': {
                height: '100%',
            },
        },
    })
);

export interface AntDateTimePickerDateHeaderProps {
    value: Date
    onShownDateChange: (date: Date) => void,
    showTime: boolean,
};
const AntDateTimePickerDateHeader = ({ value, onShownDateChange, showTime }: AntDateTimePickerDateHeaderProps) => {
    const classes = useStyles({ showTime });

    const handlePrevYearClick = () => {
        onShownDateChange(addYears(value, -1));
    };

    const handleNextYearClick = () => {
        onShownDateChange(addYears(value, 1));
    };

    const handlePrevMonthClick = () => {
        onShownDateChange(addMonths(value, -1));
    };

    const handleNextMonthClick = () => {
        onShownDateChange(addMonths(value, 1));
    };

    return (
        <Grid gap="0.2em" rows="1fr" columns="1em 1em 5em 3em 1em 1em" className={classes.dateTimePickerDateHeaderGrid}>
            <Cell className="center-vertical">
                <IconButton size="small" onClick={handlePrevYearClick}>
                    <LeftMinimalDoubleArrowIcon fill="inherit" width="0.75em" />
                </IconButton>
            </Cell>
            <Cell className="center-vertical">
                <IconButton size="small" onClick={handlePrevMonthClick}>
                    <LeftMinimalArrowIcon fill="inherit" width="0.75em" />
                </IconButton>
            </Cell>
            <Cell className="center-vertical center-horizontal">
                {format(value, 'MMMM')}
            </Cell>
            <Cell className="center-vertical center-horizontal">
                {format(value, 'yyyy')}
            </Cell>
            <Cell className="center-vertical">
                <IconButton size="small" onClick={handleNextMonthClick}>
                    <RightMinimalArrowIcon fill="inherit" width="0.75em" />
                </IconButton>
            </Cell>
            <Cell className="center-vertical">
                <IconButton size="small" onClick={handleNextYearClick}>
                    <RightMinimalDoubleArrowIcon fill="inherit" width="0.75em" />
                </IconButton>
            </Cell>
        </Grid>
    );
};

export default AntDateTimePickerDateHeader;